import React, { Fragment, useCallback, useEffect, useState } from "react";
import Loader from "../../Loader";
import {
    Container,
    ContainerHeader,
    ContainerList,
    CATALOG_CONTAINER_WIDTH,
} from "./styles";
import { CardTitle } from "../../../styles/stylesheet";
import { colors, fonts } from "../../../styles/styleguide";
import OrderListItem, { ORDER_LIST_ITEM_CONTEXT_TYPES } from "../../../views/OrdersManagement/orderListItem";
import { CATALOG_TYPES } from "shared/src/modules/catalog";
import AutoSuggest from "../../AutoSuggest";
import { noProductImageUrl, ORDER_MANAGEMENT_CONTEXT } from "shared/src/constants/orders";
import AddButton from "../../AddButton";
import { getItemPackage } from "shared/src/selectors/orders";

const Catalog = ( {
    query,
    updateQueryHandler,
    getCatalogHandler,
    orderId,
    context,
    catalog,
    isLoadingCatalog,
    contractorId,
    supplierId,
    disabledCatalogItems,
    onAdToCart,
    onAddGenericProduct,
    addNotFoundProduct,
    formContext,
    hidePrices,
} ) => {
    const [ makatQuery, setMakatQuery ] = useState("")

    const onChange = useCallback(( newValue ) => {
        updateQueryHandler(
            newValue,
            formContext === ORDER_LIST_ITEM_CONTEXT_TYPES.PO
            ? CATALOG_TYPES.PO
            : CATALOG_TYPES.ORDERS,
        );
    }, [ updateQueryHandler, formContext ])

    // useEffect(() => {
    //     if (
    //         !!query &&
    //         !!addNotFoundProduct &&
    //         !isLoadingCatalog
    //     ) {
    //         catalog.push(
    //             <div style={{width: "100%"}}>
    //                 <div>לא מצאתם את המוצר שחיפשתם? הוסיפו לסל ונמצא אותו בשבילכם</div>
    //                 <OrderListItem
    //                     imgSrc={noProductImageUrl}
    //                     name={query}
    //                     context={ORDER_LIST_ITEM_CONTEXT_TYPES.NOT_FOUND_CATALOG}
    //                     onAddItem={(room, count, action = null, note) => {
    //                         addNotFoundProduct(query, room, count);
    //                         //    this.openProductModal(item.item, ORDER_LIST_ITEM_CONTEXT_TYPES.MODAL_ADD)
    //                     }}
    //                     showAdd={true}
    //                     // room={defaultRoom}
    //                     // rooms={rooms}
    //                     formContext={formContext}
    //                 />
    //             </div>
    //         );
    //     }
    // }, [query, isLoadingCatalog])

    return (
        <Fragment>
            <div style={ { width: CATALOG_CONTAINER_WIDTH } }>
                <CardTitle style={ { color: colors.charcoal } }>קטלוג מוצרים</CardTitle>

                <SearchComponent
                    query={ query }
                    makatQuery={ makatQuery }
                    orderId={ orderId }
                    supplierId={ supplierId }
                    contractorId={ contractorId }
                    onAddGenericProduct={ onAddGenericProduct }
                    getCatalogHandler={ getCatalogHandler }
                    context={ context }
                    formContext={ formContext }
                    onChange={ onChange }
                    onMakatChange={ setMakatQuery }
                />

                <CatalogListComponent
                    isLoadingCatalog={ isLoadingCatalog }
                    context={ context }
                    catalog={ catalog }
                    onAdToCart={ onAdToCart }
                    disabledCatalogItems={ disabledCatalogItems }
                    formContext={ formContext }
                    hidePrices={ hidePrices }
                    query={ query }
                    addNotFoundProduct={ addNotFoundProduct }
                />
            </div>
        </Fragment>
    )
}

const NOT_FOUND_TITLE_STYLE = {
    fontWeight: "bold",
    fontSize: 20,
    fontFamily: fonts.OpenSansHebrew,
    color: colors.greyish,
}

const SEARCH_INPUT_STYLE = {
    border: `1px solid ${ colors.greyish }`,
}

const SearchComponent = ( {
    query,
    makatQuery,
    orderId,
    supplierId,
    contractorId,
    onAddGenericProduct,
    getCatalogHandler,
    context,
    formContext,
    onChange,
    onMakatChange,
} ) => (
    <div
        style={ {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
        } }
    >
        <div style={ { flex: 4 } }>
            <AutoSuggest
                supplierId={ supplierId }
                contractorId={ contractorId }
                onChange={ onChange }
                onSelection={ ( value ) => {
                    onChange(value)

                    getCatalogHandler({
                                          query: value,
                                          orderId,
                                          supplierId,
                                          contractorId,
                                          context,
                                          catalogName:
                                              formContext === ORDER_LIST_ITEM_CONTEXT_TYPES.PO
                                              ? CATALOG_TYPES.PO
                                              : CATALOG_TYPES.ORDERS,
                                          isMakat: false,
                                      });
                } }
                query={ query }
                inputStyle={ SEARCH_INPUT_STYLE }
            />
        </div>

        { context === ORDER_MANAGEMENT_CONTEXT.SUPPLIER && (
            <div style={ { flex: 4 } }>
                <AutoSuggest
                    isMakat={ true }
                    supplierId={ supplierId }
                    contractorId={ contractorId }
                    onChange={ onMakatChange }
                    onSelection={ ( value ) => {
                        onMakatChange(value)

                        getCatalogHandler({
                                              query: value,
                                              supplierId,
                                              contractorId,
                                              context,
                                              catalogName:
                                                  formContext === ORDER_LIST_ITEM_CONTEXT_TYPES.PO
                                                  ? CATALOG_TYPES.PO
                                                  : CATALOG_TYPES.ORDERS,
                                              isMakat: true,
                                          });
                    } }
                    query={ makatQuery }
                    placeholder={ 'חיפוש לפי מק"ט' }
                    inputStyle={ SEARCH_INPUT_STYLE }
                />
            </div>
        ) }

        { !!onAddGenericProduct && (
            <div style={ { flex: 4 } }>
                <AddButton
                    label={ "הוספת מוצר כללי" }
                    onClick={ onAddGenericProduct }
                />
            </div>
        ) }
    </div>
)

const CatalogListComponent = ( {
    isLoadingCatalog,
    context,
    catalog,
    onAdToCart,
    disabledCatalogItems,
    formContext,
    hidePrices,
    query,
    addNotFoundProduct,
} ) => (
    <Container>
        <ContainerHeader>
            { "נא לבחור מוצרים, סימונם יאפשר להכניסם לסיכום הזמנה" }
        </ContainerHeader>

        <ContainerList>
            { isLoadingCatalog
              ? <Loader/>
              : getCatalogList({
                                   context,
                                   catalog,
                                   onAdToCart,
                                   disabledCatalogItems,
                                   formContext,
                                   hidePrices,
                               })
            }

            {
                !!addNotFoundProduct && !!query && !isLoadingCatalog && (
                    <div style={ { width: "100%", margin: "16px 0 16px 0" } }>
                        <div style={ NOT_FOUND_TITLE_STYLE }>
                            { "לא מצאתם את המוצר שחיפשתם? הוסיפו לסל ונמצא אותו בשבילכם" }
                        </div>
                        <OrderListItem
                            imgSrc={ noProductImageUrl }
                            name={ query }
                            context={ ORDER_LIST_ITEM_CONTEXT_TYPES.NOT_FOUND_CATALOG }
                            addNotFoundProduct={ ( room, count, note ) => {
                                addNotFoundProduct(query, room, count, note);
                                //    this.openProductModal(item.item, ORDER_LIST_ITEM_CONTEXT_TYPES.MODAL_ADD)
                            } }
                            showAdd={ true }
                            // room={defaultRoom}
                            // rooms={rooms}
                            formContext={ formContext }
                        />
                    </div>
                )
            }
        </ContainerList>
    </Container>
)

const getCatalogList = ( {
    context,
    catalog,
    onAdToCart,
    disabledCatalogItems,
    formContext,
    hidePrices,
} ) => (
    catalog.map(( item, index ) => (
               <OrderListItem
                   key={ `item_${ item.id }_${ item.room ? item.room.id : index }_${ index }` }
                   catalogId={ item.catalogId }
                   imgSrc={
                       item.images && item.images.length
                       ? item.images[ 0 ].downloadURL
                       : noProductImageUrl
                   }
                   name={ item.name }
                   price={ item.default_price }
                   context={
                       formContext === ORDER_LIST_ITEM_CONTEXT_TYPES.PO
                           ? formContext
                           : ORDER_LIST_ITEM_CONTEXT_TYPES.CATALOG
                   }
                   onAddPOItem={ (
                       count,
                       userSelectedRentInterval,
                   ) => {
                       onAdToCart(
                           item,
                           count,
                           userSelectedRentInterval,
                       );
                   } }
                   onAddOrderItem={ (
                       room,
                       count,
                       action = null,
                       note,
                       userSelectedRentInterval,
                       // specialPrice
                   ) => {
                       onAdToCart(
                           item,
                           room,
                           count,
                           action,
                           note,
                           userSelectedRentInterval,
                           // specialPrice
                       );
                   } }
                   item={ item }
                   showAdd={ true }
                   disabled={ disabledCatalogItems }
                   formContext={ formContext }
                   packageInfo={ getItemPackage(item) }
                   hidePrices={ hidePrices }
                   simpleAdd={ false }
                   negativeCount={ context === ORDER_MANAGEMENT_CONTEXT.SUPPLIER }
               />
           ))
)

export default Catalog;