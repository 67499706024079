import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { bindActionCreators, compose } from 'redux';
import { firebaseConnect, isLoaded, getVal } from 'react-redux-firebase'
import _, { cloneDeep } from "lodash"

import { uuidWithoutSpace } from "shared/src/utils";
import { isUsersAdmin } from "shared/src/selectors/roles";

import { Row, Col } from "reactstrap"
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import styled from "styled-components";
import Select, { Creatable } from 'react-select';
import FontIcon from 'material-ui/FontIcon';
import { toastr } from "react-redux-toastr";
import { getContractors, getContractorUsers } from "shared/src/api/contractors";
import { transformPermissionToSchema } from "shared/src/utils/permissions"
import ROUTES from "../../constants/routes";
import { api } from "shared/src/constants/api";
import { validateTaxId } from "../../utils/taxId";
import Checkbox from 'material-ui/Checkbox';
import IconInput, { IconWrapper } from "../../components/Form/IconInput";
import Confirm from "../../components/Confirm"

import { normalizePhoneNumber, isValid as isValidPhoneNumber, convertPhoneNumberToLocal } from "shared/src/utils/phone";

import { Card, CartHeader, CardTitle, InputLabel, InputGroup } from "../../styles/stylesheet"
import ConfirmButton from "../../components/ConfirmButton"
import AddButton from "../../components/AddButton";

import AddToList from "../../components/Form/AddToList/AddToList"

import ReactTable from 'react-table'
import RoundIconButton from "../../components/RoundIconButton"
import { colors } from "../../styles/styleguide";
import { buildEnv } from "shared/src/constants/buildEnv";

class ContractorForm extends Component {

    constructor(props) {
        super(props);
        console.log("constructor")
        console.log("props", props)

        this.defaultContractor = {
            id: null,
            address: "",
            name: "",
            contractorManagers: {},
            users: {},
            taxId: ""

        }

        this.defaultPerm = [
            //{project: null, perm: null}
        ]

        this.emptyPerm = {project: "", perm: ""};


        this.state = {
            readOnly: false,
            contractor: this.defaultContractor,
            isInviteModalOpen: false,
            inviteEmail: "",
            invitePhone: "",
            invitePhoneError: "",
            inviteEmailError: "",
            inviteError: "",
            inviteModalContext: "",
            modalPermissions: this.defaultPerm,
            modalPermissionsLevel: null,
            users: {},
            isOpenMessageModal: false,
            message: "",
            messageError: "",
            allUsers: false,
            allManagers: false,
            contractorManagers: false,
            shouldValidateTaxId: true,
            isValidTaxId: true,
            modalPermissionsError: "",
            deleteUserPermissionsModalOpen: false,
            isOpenContractorDetailsModal: false,
            modalTaxId: "",
            modalName: "",
            deleteInvidedUserModalOpen: false,
            tempPerm: {
                project: null,
                perm: null
            },
            currentPerm: null
        }
    }

    initUsers = () => {
        const {contractor, firebase} = this.props;
        const tempCont = contractor || this.defaultContractor
        this.setState({
            contractor: tempCont
        })
        const _users = tempCont.users || {}
        getContractorUsers(firebase, Object.keys(_users), this.props.match.params.contractorId).then(users => {
            this.setState({users})
        })
    }

    componentDidMount() {
        this.initUsers()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!_.isEqual(prevProps.contractorUsers, this.props.contractorUsers) || !_.isEqual(prevProps.contractor, this.props.contractor)) {
            this.initUsers()
        }
    }

    transformToSchema = (contractor, id) => {
        if (!contractor.id) {
            contractor.id = id;
        }
        delete contractor.contractorManagers
        //console.log("transformToSchema: ", contractor)
        return contractor;
    }

    transformSelectedUserToObject = (user, isAdmin = true) => {

        const obj = {};
        for (let key in user) {
            const row = user[key];
            if (isAdmin) {
                obj[row.value] = {admin: 200}
            } else {
                obj[row.value] = {id: row.value}
            }


        }
        return obj;
    }

    transformObjectToSelectList = (obj, type = "suppliers") => {
        //console.log("transformObjectToSelectList", type, obj);
        const list = [];
        for (let key in obj) {
            const row = obj[key];
            const item = {value: key};
            if (type === "suppliers") {
                // get supplier name
                const {suppliers} = this.props;
                if (suppliers[key]) {
                    item.label = suppliers[key].name
                } else {
                    item.label = "לא ידוע"
                }
            } else if (type === "users") {
                // console.log("user item", row);
                item.label = `${row.displayName || ""} - ${row.email}`;
            } else if (type === "contractorUsers") {
                const {users} = this.state;
                item.label = `${users[key].displayName || ""} - ${users[key].email}`;
            } else {
                item.label = row.name;
            }
            list.push(item);

        }
        return list;

    }

    onContractorManagerChange = (value) => {
        //console.log("onContractorManagerChange", value);
        const {contractor} = this.state;
        const clone = _.cloneDeep(contractor);
        const pm = this.transformSelectedUserToObject(value);
        //console.log(pm);
        clone.contractorManagers = pm;
        this.setState({contractor: clone});

    }

    onCrewMembersChange = (value) => {
        //console.log("onCrewMembersChange", value);
        const {contractor} = this.state;
        const clone = _.cloneDeep(contractor);
        const pm = this.transformSelectedUserToObject(value, false);
        clone.users = pm;
        this.setState({contractor: clone});

    }

    closeInviteModal = () => {
        this.setState({
            inviteEmail: "",
            invitePhone: "",
            invitePhoneError: "",
            inviteError: "",
            inviteEmailError: "",
            modalPermissionsError: "",
            isInviteModalOpen: false,
            modalPermissions: this.defaultPerm,
            modalPermissionsLevel: null,
            inviteModalContext: "",
            currentPerm: null
        })
    }

    closeMessageModal = () => {
        this.setState({
            isOpenMessageModal: false,
            message: "",
            messageError: "",
            allUsers: false,
            allManagers: false,
            contractorManagers: false
        })
    }

    sendMessage = () => {
        const {account: {token}, match} = this.props;
        const {message, allUsers, allManagers, contractorManagers} = this.state;
        const {contractorId} = match.params;
        const scope = {
            "contractorManagers": contractorManagers,
            "allManagers": allManagers,
            "allUsers": allUsers
        };

        //todo: validate message

        fetch(api.sendContractorNotification, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({scope, contractorId, message})
        }).then((response) => {
            return response.json();
        }).then((json) => {
            //console.log("send message success", json);
            toastr.success('ההודעה נשלחה בהצלחה')

        }).catch((e) => {
            //console.log("send message error", e)
            toastr.error('שליחת ההודעה נכשלה, אנא נסו שוב')
        }).finally(() => {
            this.closeMessageModal()
        })
    }

    inviteUser = () => {
        const {firebase} = this.props;
        const {modalPermissions, modalPermissionsLevel, currentPerm} = this.state;
        const {inviteEmail, invitePhone} = this.state;
        if (this.validateInvite()) {

            let permissions = {
                level: modalPermissionsLevel
            };

            if (modalPermissionsLevel === "byProject") {
                if(currentPerm && currentPerm.project && currentPerm.perm){
                    modalPermissions.push(currentPerm);
                }
                permissions["byProject"] = modalPermissions
            }
            permissions = transformPermissionToSchema(permissions);
            let invite = {
                permissions
            };
            if (invitePhone) {
                invite["phone"] = normalizePhoneNumber(invitePhone);
            } else if (inviteEmail) {
                invite["email"] = inviteEmail;
            }
            // add to firebase
            const invitationId = uuidWithoutSpace();
            firebase.set(`pendingInvitationsByContractor/${this.props.match.params.contractorId}/${invitationId}`,
                invite,
                (result) => {
                    //console.log("complete save invitation", result)
                    // show success notification
                    toastr.success('ההזמנה נשלחה בהצלחה');
                    // close modal
                    this.closeInviteModal()
                });

        }
    }

    deleteInviteUser = (invitationId) => {
        const {firebase} = this.props;
        firebase.remove(`pendingInvitationsByContractor/${this.props.match.params.contractorId}/${invitationId}`,
            (result) => {
                //console.log("complete delete invitation", result)
                // show success notification
                toastr.success('המזמנה נמחקה בהצלחה');
                // close modal
                this.closeDeleteInviteUserModal()
            });
    }

    validatePermissions = () => {
        const {modalPermissions, modalPermissionsLevel} = this.state;
        /*  if (!modalPermissionsLevel) {
              this.setState({
                  modalPermissionsError: "יש לבחור הרשאה"
              })
              return false;
          } else*/
        if (modalPermissionsLevel === "byProject" && modalPermissions.length > 0) {
            const lastPerm = modalPermissions[modalPermissions.length - 1];
            if (!((!lastPerm.project && !lastPerm.perm) || (lastPerm.project && lastPerm.perm))) {
                this.setState({
                    modalPermissionsError: "אנא ודאו שכל ההרשאות מלאות"
                })
                return false;
            }
        }

        return true;
    }

    validateInvite = () => {
        return (this.validateForm() && this.validatePermissions())
    }

    validateForm = () => {

        const {inviteEmail, invitePhone} = this.state;

        const isEmail = !!inviteEmail
        const isPhone = !!invitePhone

        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        /*if(inviteEmail && invitePhone || !inviteEmail && !invitePhone){
            this.setState({inviteError: "יש למלא או טלפון או אימייל (רק דרך אחת)"});
            return false;
        }else if(inviteEmail){
            const isValidEmail = re.test(String(inviteEmail).toLowerCase());
            if (!isValidEmail) {
                this.setState({inviteEmailError: "כתובת אימייל לא חוקית"});
                return false;
            }else {
                return true
            }
        }

        else*/
        if (!isPhone) {
            if (!isEmail) {
                this.setState({invitePhoneError: "אנא ודאו שמספר הטלפון תקין"});
                return false
            }

        } else {
            const isValidPhone = isValidPhoneNumber(String(invitePhone));

            if (!isValidPhone) {
                this.setState({invitePhoneError: "אנא ודאו שמספר הטלפון תקין"});
                return false;
            }
        }


        if (!inviteEmail) {
            if (!isPhone) {
                this.setState({inviteEmailError: "אנא ודאו שהאימייל תקין"});
                return false
            }
        } else {
            // const isValid = isValidEmail(inviteEmail);
            //
            // if (!isValid) {
            //     this.setState({inviteEmailError: "אנא ודאו שהאימייל תקין"});
            //     return false;
            // }
        }

        return true;
    }

    setPriceVisabillity = (hidePrices) => {
        const {firebase} = this.props;
        firebase.set(`contractors/${this.props.match.params.contractorId}/settings/hidePrices`,
            hidePrices
            , (result) => {
                //console.log("complete hidePrices", hidePrices, result)
                // show success notification
            });
    }

    renderUsersList = (list, isObject, labelAccessors = [], returnRaw = false) => {
        const toRender = [];
        //console.log("render user list", list)
        for (let key in list) {
            let label = "";

            if (isObject) {
                if (!_.isEmpty(labelAccessors)) {
                    labelAccessors.forEach((labelAccessor) => {
                        let listElement = list[key][labelAccessor];
                        if (labelAccessor === "phone") {
                            listElement = convertPhoneNumberToLocal(listElement) || "";
                        }
                        if (listElement) {
                            label = label + listElement + " "
                        }

                    })

                } else {
                    label = `${list[key].displayName || ""} - ${list[key].email || ""} ${convertPhoneNumberToLocal(list[key].phoneNumber) || ""}`
                }
            } else {
                label = list[key]
            }
            let permissions = list[key]["permissions"]
            if (returnRaw) {
                toRender.push({
                    label,
                    original: list[key],
                    key,
                    permissions
                })
            } else {
                toRender.push(<div key={key}>{label}</div>);
            }
        }
        return toRender;
    }

    save(successCallback) {
        const {contractor, shouldValidateTaxId} = this.state;
        const {firebase} = this.props;
        let id = contractor.id || this.props.match.params.contractorId;
        if (shouldValidateTaxId) {
            this.validateTaxId(contractor.taxId, () => {
                firebase.update(`contractors/${id}`, this.transformToSchema(contractor, id), (result) => {
                    if (successCallback) {
                        successCallback(result)
                    }
                });
            })
        } else {
            firebase.update(`contractors/${id}`, this.transformToSchema(contractor, id), (result) => {
                if (successCallback) {
                    successCallback(result)
                }
            });
        }
    }

    validateTaxId = (taxId, successCB) => {
        this.setState({
            validatingTaxId: true
        })
        validateTaxId(taxId, (companyName) => {
            this.setState({
                isValidTaxId: true,
                shouldValidateTaxId: false,
                validatingTaxId: false
            });
            if (!this.state.contractor.name) {
                let c = this.state.contractor;
                c.name = companyName;
                this.setState({contractor: c});
            }
            if (successCB) {
                successCB(companyName);
            }
        }, () => {
            this.setState({
                isValidTaxId: false,
                shouldValidateTaxId: false,
                validatingTaxId: false
            })
        })

    }

    renderPermissions = () => {
        const {modalPermissions, modalPermissionsLevel, currentPerm} = this.state;
        const {projects} = this.props;

        let toRender = [
            <div style={{width: 318}} key={"modalPermissionsLevel"}>
                <InputGroup>
                    <IconInput
                        type={"select"}
                        options={[
                            {id: "contractorManager", name: "מנהל"},
                            {id: "byProject", name: "לפי פרוייקט"},
                        ]}
                        labelAccessor={"name"}
                        valueAccessor={"id"}
                        value={modalPermissionsLevel}
                        onChange={(newValue) => {
                            this.setState({
                                modalPermissionsLevel: newValue
                            })
                        }}
                    />
                </InputGroup>
            </div>

        ];


        switch (modalPermissionsLevel) {
            case "contractorManager":
                toRender.push(
                    <div style={{ margin: "12px 0" }}>
                        <label>
                            {"הרשאות קריאה בלבד?"}
                            <input
                                style={{ margin: "0 12px" }}
                                type="checkbox"
                                checked={this.state.readOnly}
                                onChange={event => {
                                    const readOnly = event.target.checked;
                                    this.setState({ readOnly })
                                }}
                            />
                        </label>
                    </div>
                )
                break
            case "byProject":
                if (!_.isEmpty(projects)) {
                    let selectedProjects = [];
                    let projectMap = [];
                    let projectIds = [];

                    if(_.isEmpty(modalPermissions)){
                        for (let key in projects) {
                            const projectId = projects[key].data.id;
                            if(!projectIds.includes(projectId)){
                                projectMap.push({id: projectId, name: projects[key].data.name})
                                projectIds.push(projectId);
                            }
                        }
                    }else {
                        modalPermissions.map((perm, index) => {
                            for (let key in projects) {
                                const projectId = projects[key].data.id;
                                //    console.log("perm project", perm.project, projectId)
                                const projectIndex = _.findIndex(modalPermissions, {project: projectId});
                                //    console.log("perm project", perm.project, projectId)
                                const include = projectIds.includes(projectId);
                                //  console.log("include ?", include, projectId)
                                if (perm.project !== projectId && (-1 === projectIndex || (modalPermissions[projectIndex].project === key && index === projectIndex)) && !include) {
                                    projectMap.push({id: projectId, name: projects[key].data.name})
                                    projectIds.push(projectId);
                                }
                            }

                        })
                    }

                    projectMap = _.orderBy(projectMap, ["name"], ["asc"]);
                    //console.log("modalPermissions", modalPermissions);

                    toRender.push(
                        <div style={{marginRight: -15, marginLeft: -15}}>
                            <AddToList
                                defaultValue={_.cloneDeep(this.emptyPerm)}
                                list={modalPermissions}
                                onChange={(value)=>{
                                    console.log("current perm change", value)
                                    this.setState({currentPerm: value})}}
                                onAddToList={(item, nextIndex) => {
                                    console.log("onAddToList", item, modalPermissions)
                                    if(!item.project || !item.perm){
                                        return;
                                    }
                                    let perms = _.cloneDeep(modalPermissions)
                                    perms.push(item)
                                    //  perms.push(this.emptyPerm);
                                    this.setState({
                                        modalPermissions: perms
                                    })
                                }}
                                onRemoveListItem={(index, item) => {
                                    let clone = _.cloneDeep(modalPermissions)
                                    clone.splice(index, 1);
                                    this.setState({
                                        modalPermissions: clone
                                    });
                                }}
                                getAddToListComponent={(value, onChangeHandler) => {
                                    //  console.log("getAddToListComponent", value)
                                    console.log(this.emptyPerm);
                                    return (
                                        <div style={{display: "flex", flexDirection: "row"}}>
                                            <InputGroup style={{width: 318}}>
                                                <IconInput
                                                    type={"select"}
                                                    options={projectMap}
                                                    labelAccessor={"name"}
                                                    valueAccessor={"id"}
                                                    value={value.project}
                                                    onChange={(newValue) => {
                                                        value.project = newValue;
                                                        onChangeHandler(value)
                                                    }}
                                                    placeholder={"פרוייקט"}
                                                />
                                            </InputGroup>
                                            <InputGroup style={{width: 226, marginRight: 10}}>
                                                <IconInput
                                                    type={"select"}
                                                    options={[
                                                        {id: "projectManager", name: "מנהל פרוייקט"},
                                                        {id: "crewMember", name: "איש צוות"}
                                                    ]}
                                                    labelAccessor={"name"}
                                                    valueAccessor={"id"}
                                                    value={value.perm}
                                                    onChange={(newValue) => {
                                                        value.perm = newValue;
                                                        onChangeHandler(value)
                                                    }}
                                                    placeholder={"הרשאה"}
                                                />
                                            </InputGroup>
                                        </div>
                                    )
                                }}
                                getListItemComponent={(item) => {
                                    console.log("getListItemComponent", item)
                                    if(!item.perm || !item.project){
                                        return null;
                                    }

                                    const projectName = item && item.project && projects[item.project] && projects[item.project].data ? projects[item.project].data.name : ""
                                    if(!projectName){
                                        //     console.warn("no project name!!!", item.project, projects[item.project], projects)
                                    }
                                    return (
                                        <div style={{display: "flex", flexDirection: "row", width: 318}}>
                                            <div>{projectName}</div>
                                            <div
                                                style={{marginRight: 10}}>{item.perm === "projectManager" ? "מנהל פרוייקט" : "איש צוות"}</div>
                                        </div>
                                    )
                                }}
                                addToListPosition={"bottom"}
                            />
                        </div>
                    )
                }
                break
        }

        return toRender;
    }

    updatePermissions = (user, deleteAll = false) => {
        if (this.validatePermissions()) {
            const {firebase} = this.props;
            const {modalPermissions, modalPermissionsLevel, contractor, currentPerm, readOnly} = this.state;
            let permissions = {}

            //console.log("updatePermissions", user)

            if (!deleteAll) {
                if (modalPermissionsLevel) {
                    permissions = {
                        level: modalPermissionsLevel
                    };

                    if (modalPermissionsLevel === "byProject") {
                        if(currentPerm && currentPerm.project && currentPerm.perm){
                            modalPermissions.push(currentPerm);
                        }
                        permissions["byProject"] = modalPermissions
                    } else {
                        permissions[modalPermissionsLevel] = { readOnly }
                    }
                }

                permissions = transformPermissionToSchema(permissions);
            }

            let id = contractor.id || this.props.match.params.contractorId;
            let userData = {
                email: user.email,
                permissions
            }
            //console.log("updating permissions", id, user, permissions)
            firebase.set(`contractors/${id}/users/${user.id}`, userData, (result) => {
                toastr.success('ההרשאות עודכנו בהצלחה');
                if (deleteAll) {
                    this.closeDeletePermissionsModal()
                } else {
                    this.closeInviteModal()
                }

            });
        }
    }

    getRegisteredUsersColumns = () => {
        return [{
            id: 'label',
            Header: 'משתמשים רשומים',
            accessor: 'label', // String-based value accessors!,
        }, {
            id: 'permissions',
            Header: 'הרשאות',
            accessor: u => u.permissions, // String-based value accessors!,
            Cell: props => this.renderUserPermissions(props.original)//<EllipsisText text={props.value || ''} length={18}/>, // Custom cell components!
        }
        ]
    }

    renderUserPermissions = (user) => {
        //console.log("render user permissions", user.original);
        let label = ""
        let modalPermissionsLevel = null;
        let modalPermissions = this.defaultPerm;
        if (!_.isEmpty(user.original.permissions)) {
            label = this.getLabelForPermissionLevel(user.original.permissions.level);
            modalPermissionsLevel = user.original.permissions.level;
            if (user.original.permissions.byProject) {
                modalPermissions = user.original.permissions.byProject;
            }
        } else {
            label = "NA"
        }
        const level = !!user.original.permissions && user.original.permissions.level

        return (
            <div style={{
                flexDirection: "row",
                justifyContent: "space-between",
                flex: 1,
                display: "flex",
                alignItems: "center",
                paddingLeft: 15
            }}>
                <div onClick={() => {

                    this.setState({
                        isInviteModalOpen: true,
                        inviteModalContext: user.original,
                        modalPermissions,
                        modalPermissionsLevel,
                        readOnly: level === "contractorManager" && ( user.original.permissions[level] &&
                            user.original.permissions[level].readOnly )
                    })
                }}><i className={"fa fa-edit"}/> {label}</div>

                <div onClick={() => {
                    this.setState({
                        deleteUserPermissionsModalOpen: true,
                        inviteModalContext: user.original,
                        readOnly: level === "contractorManager" && ( user.original.permissions[level] &&
                            user.original.permissions[level].readOnly )
                    })
                }}>
                    <i className={"fa fa-ban"}/>
                </div>

            </div>
        );
    }

    getLabelForPermissionLevel = (level) => {
        if (level === "contractorManager") {
            return "מנהל"
        } else if (level === "byProject") {
            return "לפי פרוייקט"
        }
        return "NA"
    }

    closeDeletePermissionsModal = () => {
        this.setState({deleteUserPermissionsModalOpen: false, inviteModalContext: ""})
    }

    submitContractorDetailsModal = () => {
        const {modalTaxId, modalName, contractor} = this.state;
        this.validateTaxId(modalTaxId, (name) => {
            const clone = _.cloneDeep(contractor);
            contractor.taxId = modalTaxId;
            contractor.name = modalName || name;
            this.setState({
                contractor
            });
            this.closeContractorDetailsModal();
        })
    }

    closeContractorDetailsModal = () => {
        this.setState({
            isOpenContractorDetailsModal: false,
            modalTaxId: "",
            modalName: ""
        });
    }

    openContractorDetailsModal = () => {
        const {contractor} = this.state;
        this.setState({
            isOpenContractorDetailsModal: true,
            modalTaxId: contractor.taxId,
            modalName: contractor.name
        });

    }

    closeDeleteInviteUserModal = () => {
        this.setState({
            deleteInvidedUserModalOpen: false,
            inviteModalContext: ""
        })
    }

    render() {

        const {
            contractor, inviteEmail, isInviteModalOpen,
            inviteEmailError, isOpenMessageModal, messageError, message,
            allUsers,
            allManagers,
            contractorManagers, isValidTaxId, validatingTaxId,
            modalPermissionsError,
            inviteModalContext,
            deleteUserPermissionsModalOpen,
            isOpenContractorDetailsModal,
            modalTaxId,
            modalName, deleteInvidedUserModalOpen,
            invitePhone,
            invitePhoneError,
            inviteError
        } = this.state;
        const {firebase, isUsersAdmin, pendingInvitations, hidePrices} = this.props;
        const users = this.state.users || {};

        //console.log("contractor 1", contractor);

        const usersWithPermissions = this.renderUsersList(users, true, undefined, true)
        const pendingInvititationsUsers = this.renderUsersList(pendingInvitations, true, ["email", "phone"], true);

        //console.log("contractor 2", contractor);
        return (
            <div style={{position: "relative"}}>
                <img src={"img/bgLogo.png"} style={{
                    position: "absolute",
                    bottom: -50,
                    right: -150,
                    width: 660,
                    height: 771,
                }}/>
                <Row>
                    <Col md={12}>
                        <Card>
                            <CardTitle>פרטי הקבלן</CardTitle>

                            <Row>
                                <Col md={4}>
                                    <Row>
                                        <Col>
                                            <InputGroup>
                                                <InputLabel>שם</InputLabel>
                                                <IconInput
                                                    type={"text"}
                                                    disabled={true}
                                                    value={contractor.name}
                                                    icon={<img src={"img/icons/person.svg"}/>}
                                                    onHoverEdit={this.openContractorDetailsModal}
                                                />
                                            </InputGroup>
                                        </Col>
                                        <Col>
                                            <InputGroup>
                                                <InputLabel>
                                                    ח.פ
                                                </InputLabel>
                                                <IconInput
                                                    type={"text"}
                                                    disabled={true}
                                                    value={contractor.taxId}
                                                    icon={<img src={"img/icons/person.svg"}/>}
                                                    onHoverEdit={this.openContractorDetailsModal}
                                                />
                                                {!isValidTaxId && !validatingTaxId && (
                                                    <div style={{color: "red"}}>ח.פ לא תקין</div>)}
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col md={2}>
                                    <InputGroup>
                                        <InputLabel>כתובת</InputLabel>
                                        <IconInput
                                            type={"text"}
                                            onChange={(text) => {
                                                const clone = _.cloneDeep(contractor);
                                                clone.address = text;
                                                this.setState({contractor: clone})
                                            }}
                                            value={contractor.address}
                                            icon={<img src={"img/icons/location.svg"}/>}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col md={2}>
                                    <div style={{marginTop: 36}}>
                                        <ConfirmButton
                                            disabled={!isValidTaxId && !validatingTaxId}
                                            onClick={() => {
                                                this.save((result) => {
                                                    //console.log("complete save contractor", result)
                                                    toastr.success('השינויים נשמרו בהצלחה')
                                                    this.props.history.push(ROUTES.CONTRACTORS)
                                                });
                                                // this.setState({contractor: this.defaultContractor});
                                            }}
                                            label={"שמירה"}
                                        />
                                    </div>
                                </Col>
                                <Col md={3}>
                                    {contractor.id ?
                                        (
                                            <div style={{marginTop: 36, maxWidth: 200}}>
                                                <AddButton label={"שליחת הודעה"} onClick={() => {
                                                    this.setState({isOpenMessageModal: true});
                                                }}/>
                                            </div>
                                        )
                                        : null}
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <div style={{position: "relative", paddingBottom: 50, marginTop: 36}}>
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                marginBottom: 20
                            }}>
                                <CardTitle style={{marginLeft: 20}}>ניהול משתמשים</CardTitle>
                                <AddButton label={"הזמינו עוד"} onClick={() => {
                                    if (!contractor.id) {
                                        this.save();
                                    }
                                    this.setState({isInviteModalOpen: true})
                                }}/>
                            </div>


                            <Row>
                                <Col md={8}>
                                    <div>
                                        <div>
                                            <ReactTable
                                                data={usersWithPermissions}
                                                columns={this.getRegisteredUsersColumns()}
                                                showPagination={false}
                                                pageSize={usersWithPermissions.length}
                                                filterable={false}
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <div>
                                            <ReactTable
                                                style={{minHeight: 168}}
                                                data={pendingInvititationsUsers}
                                                columns={[
                                                    {
                                                        id: 'label',
                                                        Header: 'משתמשים מוזמנים',
                                                        accessor: 'label', // String-based value accessors!,
                                                    },
                                                    {
                                                        id: "delete",
                                                        Header: "",
                                                        width: 50,
                                                        Cell: props => {
                                                            //console.log("invited user", props)
                                                            return (<div onClick={() => {
                                                                this.setState({
                                                                    deleteInvidedUserModalOpen: true,
                                                                    inviteModalContext: props.original
                                                                })
                                                            }}><i className={"fa fa-ban"}/></div>)
                                                        }
                                                    }
                                                ]}
                                                showPagination={false}
                                                pageSize={pendingInvititationsUsers.length}
                                                filterable={false}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        <div style={{position: "relative", paddingBottom: 50, marginTop: 36}}>
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                marginBottom: 20
                            }}>
                                <CardTitle style={{marginLeft: 20}}>הגדרות</CardTitle>
                            </div>
                            <Card>
                                <Checkbox
                                    label={"הסתר מחירים"}
                                    checked={hidePrices}
                                    onCheck={(event, isInputChecked) => {
                                        this.setPriceVisabillity(isInputChecked)
                                    }}
                                />
                            </Card>
                        </div>
                    </Col>
                </Row>

                <Modal isOpen={isOpenContractorDetailsModal} toggle={this.closeContractorDetailsModal}>
                    <ModalHeader>{"עריכה"}</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <InputGroup>
                                    <InputLabel>שם</InputLabel>
                                    <IconInput
                                        type={"text"}
                                        onChange={(text) => {
                                            this.setState({modalName: text})
                                        }}
                                        value={modalName}
                                        icon={<img src={"img/icons/person.svg"}/>}
                                    />
                                </InputGroup>
                            </Col>


                            <Col>
                                <InputGroup>
                                    <InputLabel>
                                        ח.פ
                                    </InputLabel>
                                    <IconInput
                                        type={"text"}
                                        onChange={(text) => {
                                            this.setState({
                                                modalTaxId: text
                                            })
                                        }}
                                        value={modalTaxId}
                                        icon={<img src={"img/icons/person.svg"}/>}
                                    />
                                    {!isValidTaxId && !validatingTaxId && (
                                        <div style={{color: "red"}}>ח.פ לא תקין</div>)}
                                </InputGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <div style={{position: "absolute", bottom: -24, left: 20, display: "flex", flexDirection: "row", width: 243, justifyContent: "space-between"}}>
                            <RoundIconButton
                                iconSrc={"img/icons/close.svg"}
                                label={"ביטול"}
                                onClick={this.closeContractorDetailsModal}
                            />

                            <RoundIconButton
                                iconSrc={"img/icons/v_transparent.svg"}
                                color={colors.charcoal}
                                label={"שמירה"}
                                backgroundColor={colors.yellow}
                                onClick={this.submitContractorDetailsModal}
                            />
                        </div>
                    </ModalFooter>
                </Modal>

                <Modal size={"xl"} isOpen={isInviteModalOpen} toggle={this.closeInviteModal}>
                    <ModalHeader>{inviteModalContext ? ` ${inviteModalContext.displayName} עריכת הרשאות ` : "הזמנת משתמש"}</ModalHeader>
                    <ModalBody>
                        {!inviteModalContext && buildEnv !== "prod" && (
                            <InputGroup>
                                <InputLabel>
                                    אימייל
                                </InputLabel>
                                <input style={{width: "100%", height: 34}} type={"email"}
                                       onChange={(e) => {
                                           this.setState({inviteEmail: e.target.value})
                                       }}
                                       value={inviteEmail}
                                />
                                <div style={{color: "red"}}>{inviteEmailError}</div>
                            </InputGroup>
                        )}

                        {!inviteModalContext && (
                            <InputGroup>
                                <InputLabel>
                                    מספר טלפון
                                </InputLabel>
                                <input style={{width: "100%", height: 34}} type={"email"}
                                       onChange={(e) => {
                                           this.setState({invitePhone: e.target.value})
                                       }}
                                       value={invitePhone}
                                />
                                <div style={{color: "red"}}>{invitePhoneError}</div>
                            </InputGroup>
                        )}

                        {!inviteModalContext && (
                            <InputGroup>
                                <div style={{color: "red"}}>{inviteError}</div>
                            </InputGroup>
                        )}


                        <InputGroup>
                            <InputLabel>
                                הרשאות
                            </InputLabel>
                            {this.renderPermissions()}
                            <div style={{color: "red"}}>{modalPermissionsError}</div>
                        </InputGroup>
                    </ModalBody>
                    <ModalFooter>
                        <div style={{position: "absolute", bottom: -24, left: 20, display: "flex", flexDirection: "row", width: 243, justifyContent: "space-between"}}>
                            <RoundIconButton
                                iconSrc={"img/icons/close.svg"}
                                label={"ביטול"}
                                onClick={this.closeInviteModal}
                            />

                            <RoundIconButton
                                iconSrc={"img/icons/v_transparent.svg"}
                                color={colors.charcoal}
                                label={inviteModalContext ? "עדכן" : "הזמן"}
                                backgroundColor={colors.yellow}
                                onClick={
                                    () => {
                                        if (inviteModalContext) {
                                            this.updatePermissions(inviteModalContext)
                                        } else {
                                            this.inviteUser()
                                        }
                                    }
                                }
                            />
                        </div>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={isOpenMessageModal} toggle={this.closeMessageModal}>
                    <ModalHeader>שליחת הודעה</ModalHeader>
                    <ModalBody>
                        <InputGroup>
                            <InputLabel>
                                הודעה
                            </InputLabel>
                            <input style={{width: "100%", height: 68}} type={"textarea"}
                                   onChange={(e) => {
                                       this.setState({message: e.target.value})
                                   }}
                                   value={message}
                            />
                            <div style={{color: "red"}}>{messageError}</div>
                        </InputGroup>
                        <Checkbox
                            label={"כל המשתמשים"}
                            checked={allUsers}
                            onCheck={(event, isInputChecked) => {
                                this.setState({allUsers: isInputChecked})
                                if (isInputChecked) {
                                    this.setState({
                                        allManagers: isInputChecked,
                                        contractorManagers: isInputChecked
                                    })
                                }
                            }}
                        />
                        <Checkbox
                            label={"כל המנהלים"}
                            checked={allManagers}
                            onCheck={(event, isInputChecked) => {
                                this.setState({
                                    allManagers: isInputChecked
                                })
                                if (isInputChecked && !contractorManagers) {
                                    this.setState({contractorManagers: isInputChecked})
                                }
                            }}
                        />
                        <Checkbox
                            label={"מנהלי קבלן"}
                            checked={contractorManagers}
                            onCheck={(event, isInputChecked) => {
                                //console.log("contractorManagers", isInputChecked)
                                if ((!isInputChecked && (allManagers || allUsers))) {
                                    return
                                }
                                this.setState({contractorManagers: isInputChecked})
                            }}
                        />


                    </ModalBody>
                    <ModalFooter>

                        <div style={{position: "absolute", bottom: -24, left: 20, display: "flex", flexDirection: "row", width: 243, justifyContent: "space-between"}}>
                            <RoundIconButton
                                iconSrc={"img/icons/close.svg"}
                                label={"ביטול"}
                                onClick={this.closeMessageModal}
                            />

                            <RoundIconButton
                                iconSrc={"img/icons/v_transparent.svg"}
                                color={colors.charcoal}
                                label={"שליחה"}
                                backgroundColor={colors.yellow}
                                onClick={this.sendMessage}
                            />
                        </div>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={deleteUserPermissionsModalOpen} toggle={this.closeDeletePermissionsModal}>
                    <ModalHeader>{"מחיקת הרשאות למשתמש"}</ModalHeader>
                    <ModalBody>
                        <div>{`אתם עומדים למחוק את כל ההרשאות של ${inviteModalContext ? inviteModalContext.displayName : ""} האם אתם בטוחים?`}</div>
                    </ModalBody>
                    <ModalFooter>
                        <div style={{position: "absolute", bottom: -24, left: 20, display: "flex", flexDirection: "row", width: 230, justifyContent: "space-between"}}>
                            <RoundIconButton
                                iconSrc={"img/icons/close.svg"}
                                label={"ביטול"}
                                onClick={this.closeDeletePermissionsModal}
                            />

                            <RoundIconButton
                                iconSrc={"img/icons/v_transparent.svg"}
                                color={colors.charcoal}
                                label={"כן"}
                                backgroundColor={colors.yellow}
                                onClick={ () => {
                                    this.updatePermissions(inviteModalContext, true)
                                }}
                            />
                        </div>
                    </ModalFooter>
                </Modal>


                <Modal isOpen={deleteInvidedUserModalOpen} toggle={this.closeDeleteInviteUserModal}>
                    <ModalHeader>{"מחיקת משתמש מוזמן"}</ModalHeader>
                    <ModalBody>
                        <div>{`אתם עומדים למחוק את ההזמנה של ${inviteModalContext ? inviteModalContext.label : ""} האם אתם בטוחים?`}</div>
                    </ModalBody>
                    <ModalFooter>
                        <div style={{position: "absolute", bottom: -24, left: 20, display: "flex", flexDirection: "row", width: 230, justifyContent: "space-between"}}>
                            <RoundIconButton
                                iconSrc={"img/icons/close.svg"}
                                label={"ביטול"}
                                onClick={this.closeDeleteInviteUserModal}
                            />

                            <RoundIconButton
                                iconSrc={"img/icons/v_transparent.svg"}
                                color={colors.charcoal}
                                label={"כן"}
                                backgroundColor={colors.yellow}
                                onClick={ () => {
                                    this.deleteInviteUser(inviteModalContext.key)
                                }}
                            />
                        </div>
                    </ModalFooter>
                </Modal>

            </div>
        );
    }


}


const DetailsRow = styled.div`
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    color: #636363;
`;

const Detail = styled.span`
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    color: #636363;
    margin-right: 7px;
    vertical-align: text-bottom;
`;


const SendButton = styled.div`
border-radius: 3px;
font-family: 'Rubik', sans-serif;
font-size: 16px;
text-align: center;
color: #FFFFFF;
height: 50px;
line-height: 50px;
padding: 0 11px;
cursor:  ${props => props.disabled ? "default" : "pointer"};
box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);
background-color: ${props => props.disabled ? "#818181" : "#319CD8"};
`;

const ModalCloseButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
`;

ContractorForm.propTypes = {
    contractorId: PropTypes.string
};

ContractorForm.defaultProps = {};

const mapStateToProps = (state, props) => {
    const contractor = getVal(state.firebase.data, `contractors/${props.match.params.contractorId}`)
    const contractorUsers = getVal(state.firebase.data, `contractors/${props.match.params.contractorId}/users`)
    return {
        contractor,
        contractorUsers,
        isUsersAdmin: isUsersAdmin(state.roles.roles),
        account: state.account,
        pendingInvitations: getVal(state.firebase.data, `pendingInvitationsByContractor/${props.match.params.contractorId}`),
        projects: getVal(state.firebase.data, `projects/${props.match.params.contractorId}`),
        hidePrices: getVal(state.firebase.data, `contractors/${props.match.params.contractorId}/settings/hidePrices`, false),
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);


export default compose(
    firebaseConnect((props) => ([
        `contractors/${props.match.params.contractorId}`,
        `contractors/${props.match.params.contractorId}/users`,
        `contractors/${props.match.params.contractorId}/settings/hidePrices`,
        "suppliers",
        `pendingInvitationsByContractor/${props.match.params.contractorId}`,
        `projects/${props.match.params.contractorId}`
    ])),
    connect(
        mapStateToProps, mapDispatchToProps
    )
)(ContractorForm);
