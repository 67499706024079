import React, {Component} from 'react';
import AddButton from "../AddButton";
import EditableLabel from "./EditableLabel";


const List = ({items, onAddItem, onRemoveItem, onItemChange, itemLabelKey, addButtonLabel, inputLabelText, inputLabelTextKey, disabled = false}) => {

    return(
        <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
            {
                items.map((item, index)=>(
                    <div key={index} >
                        <EditableLabel
                            disabled={disabled}
                            onLabelChange={(value)=> onItemChange(item, value)}
                            label={item[itemLabelKey]}
                            onDelete={() => onRemoveItem(item)}
                            inputLabelText={inputLabelText ? inputLabelText : inputLabelTextKey ? item[inputLabelTextKey] : null}
                        />
                    </div>
                ))
            }
            { !disabled && (
                <div key={"add"} >
                    <AddButton
                        label={addButtonLabel}
                        onClick={onAddItem}
                    />
                </div>
            )}
        </div>
    )
};



export default List;