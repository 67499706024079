import { findDOMNode } from "react-dom";
const MAX_ATTEMPTS = 2;

export const print = (contentNodes, attempts = 0, onError = () => {}) =>{
    let copyStyles = true;
    let printWindow = window.open("", "Print", "status=no, toolbar=no, scrollbars=yes", "false");

    if(!printWindow){
        if(attempts >= MAX_ATTEMPTS){
            onError("אירעה שגיאה בנסיון להדפיס, אנא ודאו שלא מותקן חוסם פרסומות, במידה וכן יש לנטרל אותו")
        }else {
            setTimeout(()=>{
                print(contentNodes, attempts+1, onError)
            }, 1000)
            return;
        }
    }

    const imageNodes = [...contentNodes.getElementsByTagName("img")];
    const linkNodes = document.querySelectorAll('link[rel="stylesheet"]');

    let imageTotal = imageNodes.length;
    let imageLoaded = 0;

    let linkTotal = linkNodes.length;
    let linkLoaded = 0;


    const markLoaded = (type) => {

        if (type === 'image')
            imageLoaded++;
        else if (type === 'link')
            linkLoaded++;

        if (imageLoaded === imageTotal && linkLoaded === linkTotal) {
            triggerPrint(printWindow);
        }

    };

    function prepareAndPrint() {
        [...imageNodes].forEach((child) => {
            /** Workaround for Safari if the image has base64 data as a source */
            if (/^data:/.test(child.src)) {
                child.crossOrigin = 'anonymous';
            }
            child.setAttribute('src', child.src);
            child.onload = markLoaded.bind(null, 'image');
            child.onerror = markLoaded.bind(null, 'image');
            child.crossOrigin = 'use-credentials';
        });

        /*
         * IE does not seem to allow appendChild from different window contexts correctly.  They seem to come back
         * as plain objects. In order to get around this each tag is re-created into the printWindow
         * https://stackoverflow.com/questions/38708840/calling-adoptnode-and-importnode-on-a-child-window-fails-in-ie-and-edge
         */
        if (copyStyles !== false) {

            const headEls = document.querySelectorAll('style, link[rel="stylesheet"]');
            [...headEls].forEach(node => {

                const doc = printWindow.contentDocument || printWindow.document;
                let newHeadEl = doc.createElement(node.tagName);

                if (node.textContent)
                    newHeadEl.textContent = node.textContent;
                else if (node.innerText)
                    newHeadEl.innerText = node.innerText;

                let attributes = [...node.attributes];
                attributes.forEach(attr => {

                    let nodeValue = attr.nodeValue;

                    if (
                        attr.nodeName === 'href' &&
                        /^https?:\/\//.test(attr.nodeValue) === false &&
                        /^blob:/.test(attr.nodeValue) === false
                    ) {

                        const relPath = attr.nodeValue.substr(0, 3) === "../"
                            ? document.location.pathname.replace(/[^/]*$/, '')
                            : "/";

                        nodeValue = nodeValue.replace(/\/+/, '');
                        nodeValue = document.location.protocol + '//' + document.location.host + relPath + nodeValue;

                    }

                    newHeadEl.setAttribute(attr.nodeName, nodeValue);
                });

                if (node.tagName === 'LINK') {
                    newHeadEl.onload = markLoaded.bind(null, 'link');
                    newHeadEl.onerror = markLoaded.bind(null, 'link');
                }

                printWindow.document.head.appendChild(newHeadEl);

            });

        }

        /* remove date/time from top */
        let styleEl = printWindow.document.createElement('style');
        styleEl.appendChild(printWindow.document.createTextNode("@page { size: auto;  margin: 0mm; } @media print { body { -webkit-print-color-adjust: exact; } }"));

        printWindow.document.head.appendChild(styleEl);
        printWindow.document.body.innerHTML = contentNodes.outerHTML;

        if (imageTotal === 0 || copyStyles === false) {
            triggerPrint(printWindow);
        }
    }

    //load fonts
    let OpenSansHebrew = new FontFace("OpenSansHebrew", "url('./fonts/OpenSansHebrew-Regular.ttf')", {});
    let OpenSansHebrewBold = new FontFace("OpenSansHebrew", "url('./fonts/OpenSansHebrew-Bold.ttf')", {weight: "bold"});

     Promise.all([OpenSansHebrew.load(), OpenSansHebrewBold.load()]).then(loadedFaces => {

         loadedFaces.map(loadedFace => {
             printWindow.document.fonts.add(loadedFace);
         })
         printWindow.document.body.style.fontFamily = "OpenSansHebrew";

         prepareAndPrint();

      })
}

const triggerPrint = (target) => {

    console.log("** DEBUG MODE **");
    console.log(target.document);

    setInterval(function () {
        target.print();
        target.close();
    }, 500);
}