
import { clearCatalog, CATALOG_TYPES } from "shared/src/modules/catalog"

import  {SIGN_UP_SUCCESS} from "shared/src/modules/login"
import {LOGOUT} from "shared/src/modules/account";

import  {history} from '../store'

export const navigationMiddleware = ({dispatch, getState}) => next => action => {

    if(action.type === '@@router/LOCATION_CHANGE'){
        dispatch(clearCatalog(CATALOG_TYPES.PO));
        dispatch(clearCatalog(CATALOG_TYPES.AUTO_COMPLETE));
        dispatch(clearCatalog(CATALOG_TYPES.ORDERS));
        dispatch(clearCatalog(CATALOG_TYPES.RELATED_PRODUCT));
    }else if(action.type === SIGN_UP_SUCCESS){
        history.push("/welcome")
    }else if(action.type === LOGOUT){
        history.push("/")
        window.location.reload();
    }

    return next(action);

};

