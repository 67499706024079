import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types'
import _, { isEqual } from "lodash"
import { connect } from 'react-redux'
import { compose } from 'redux'
import { bindActionCreators } from 'redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Switch, Route, Redirect } from 'react-router-dom';
import { firebaseConnect, withFirestore } from 'react-redux-firebase'

import { matchPath } from 'react-router'

import OrdersTable from "./ordersTable";
import {

    setFieldValue,

    createNewOrder,
    initOrderFromList,
    lockOrder,
    sendOrder
} from "shared/src/modules/orders"
import {fetchItems} from "shared/src/api/orderItems"

import { setFetchOrdersCalled, setFieldValue as setLayoutFieldValue } from "shared/src/modules/ordersManagement"
import {
    filterColumn,
    setDates,
    setAmountFilter,
    filterToday,
    filterTomorrow,
    filterThisWeek,
    showAllOpen,
    showClosed,
    showCancelled,
    onSortChange
} from "shared/src/modules/ordersFilter"

import { getOrdersHistory, getOrderById } from "shared/src/api/ordersHistory"

import { getContractorProjects, getContractors } from "shared/src/api/contractors"
import { getContractorIds, getSupplierId } from "shared/src/selectors/roles";
import {roleToPermission} from "shared/src/constants/config"

import { comparatorStrings, ORDER_MANAGEMENT_CONTEXT } from "shared/src/constants/orders"

import OrderForm from "./orderForm"

import FirebaseObjectListSelect from "../../components/Form/FirebaseObjectListSelect"
import { Card, CardTitle, InputLabel, InputGroup, InputError } from "../../styles/stylesheet"

import ROUTES from "../../constants/routes";

import { Row, Col } from "reactstrap"

import CustomerSearchModal from "../../components/CustomerSearchModal";
import LoadingSpinner from "../../components/Loader";
import IconInput from "../../components/Form/IconInput";
import ConfirmButton from "../../components/ConfirmButton"



class HistoryOrdersManagement extends Component {

    constructor() {
        super()
        const today = new Date();
        this.state = {
            supplierIds: null,
            contractorId: null,
            project: null,
            year: today.getFullYear(),
            month: today.getMonth() + 1,
            orderDialogOpen: false,
            selectedProject: null,
            selectedSupplier: null,
            contractors: null,
            selectedContractor: {
                id: null,
                name: ""
            },
            isOpenContractorSearch: false
        }

    }

    componentDidMount() {
       this.initContractorsList();

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.isApplicationOrdersAdmin !== prevProps.isApplicationOrdersAdmin || !isEqual(this.props.contractorIds, prevProps.contractorIds) || !isEqual(this.props.contractors, prevProps.contractors)) {
            this.initContractorsList();
        }
    }

    initContractorsList = () =>{
        const {firebase, user, isApplicationOrdersAdmin, context} = this.props
        let stateContractors = {};
     //   console.log('History mounted for: ', user)
        if(context === ORDER_MANAGEMENT_CONTEXT.CONTRACTOR){
            if(isApplicationOrdersAdmin){
                getContractors(firebase, user).then(contractors => {
                    this.setState({contractors})
                    console.log('History contractors: ', contractors)
                    stateContractors = contractors;
                })
            }
            else {
                const {contractors} = this.props;
                if(!contractors){
                    console.log("skipping initializing")
                    return;
                }

                console.log("setting contractor contractors", this.props.contractorIds);
                this.props.contractorIds.contractorIds.map((id) => {
                    stateContractors[id] = contractors[id];
                })
                this.setState({
                    contractors: stateContractors
                })
            }
            const keys = Object.keys(stateContractors);
            if(keys.length === 1){
                this.onContractorSelection(keys[0]);
            }
        }

    }

    fetchScreenData = () => {

        const {selectedContractor, contractorId, projectId, month, year} = this.state;
        const {getOrdersHistory, getOrderById, history, context, roles, firestore, firebase} = this.props;
        console.log("fetch orders screen data");
        if (this.validateRequestData()) {
            // // Example of getOrderById usage
            // getOrderById(firestore, firebase, {orderId: "02f4890e0dde4ff8b49dc5cebeb12348", isSupplier: false})
            getOrdersHistory(firestore, firebase, {
                contractorId: context === ORDER_MANAGEMENT_CONTEXT.CONTRACTOR ? contractorId : selectedContractor.id,
                projectId,
                year, month,
                supplierId: getSupplierId(context, roles)})
            history.push(`/ordersHistory`);
        }
    }

    getYears = () => {
        let minYear = 2018;
        const today = new Date();
        let maxYear = today.getFullYear();
        const years = []
        for(var i = minYear; i<= maxYear; i++){
            years.push({label: i, value: i});

        }
        return years;
    }

    getMonths = () => {
        const months = []
        for(var i = 1; i<= 12; i++){
            months.push({label: i, value: i});
        }
        return months;
    }

    validateRequestData = () => {
        const {contractorId, projectId, month, year, selectedContractor} = this.state;
        const {context} = this.props;
        let isValid = true;

        if(context === ORDER_MANAGEMENT_CONTEXT.CONTRACTOR){
            if (!contractorId) {
                return false;
            }
            if (!projectId) {
                return false;
            }
        }
        else {
            if(!selectedContractor.id){
                return false;
            }
        }

        const today = new Date();
        if (!year || isNaN(year) || year < 2018 || year > today.getFullYear()) {
            return false;
        }
        if (!month || isNaN(month) || month < 0 || month > 12) {
            return false;
        }
        return isValid;
    }


    editOrder = (o) => {
        const order = o.order
        const {initOrderFromList, setFieldValue, history} = this.props;
        initOrderFromList(order);
        setFieldValue("contractorId", order.contractor.id);
        let supplierId = order.supplier.id;

        history.push(`/ordersHistory/${supplierId}/${order.contractor.id}/${order.project.id}/${order.data.id}`);
    }

    renderSuppliers = () => {
        const {
            selectedProject,
        } = this.state;


        let suppliers = [];

        if (selectedProject && selectedProject.suppliers.length > 0) {

            const sortedSuppliers = selectedProject.suppliers.length > 1 ?
                selectedProject.suppliers.sort((a, b) => comparatorStrings(a.name, b.name)) : selectedProject.suppliers;

            sortedSuppliers.map((supplier) => {
                suppliers.push(<option key={supplier.id} value={supplier.id}>{supplier.name}</option>)
            })
        }

        return suppliers;
    }

    closeModal = () => {
        this.setState({
            orderDialogOpen: false,
            selectedProject: null,
            selectedSupplier: null,
        })
    }

    onContractorSelection = (contractorId) => {
        const {firebase, roles} = this.props;
        console.log("contractor selected", contractorId)
        // setting selected contractors to match the roles selector schema
        // will use it to fetch screen data
        // const selectedContractor = {contractorIds: [contractorId], projectIds: []};
        getContractorProjects(firebase, contractorId, roles).then((projects => {
            console.log("contractor projects", projects);

            this.setState({
                //      selectedContractor: selectedContractor,
                contractorId: contractorId,
                contractorProjects: projects
            });
        }))

        //  this.props.setLayoutFieldValue("selectedContractor", selectedContractor)
        // this.fetchScreenData({contractorIds: selectedContractor, context: ORDER_MANAGEMENT_CONTEXT.CONTRACTOR});
    }

    onProjectSelection = (projectId) => {
        this.setState({
            projectId
        });
    }

    openContractorSearch = () => {
        this.setState({isOpenContractorSearch: true})
    }

    closeContractorSearch = () => {
        this.setState({isOpenContractorSearch: false})
    }

    onSupplierContractorSelect = (contractor) => {
        this.setState({selectedContractor: contractor})
        this.closeContractorSearch()
    }

    render() {
        const {contractors} = this.state;

        const {
           orders, isLoading, sendOrder, context, roles
        } = this.props;

        const {
            contractorId, contractorProjects, projectId, year,
            month, selectedContractor, isOpenContractorSearch
        } = this.state;

        const isValidaRequestData = this.validateRequestData();

        return (
            <div key={"ordersIndex"}>
                <Row style={{marginBottom: 20}}>
                    {context === ORDER_MANAGEMENT_CONTEXT.CONTRACTOR ? (
                        <Fragment>
                            <Col md={2} lg={2} xl={2}>
                                <InputGroup>
                                    <InputLabel>קבלן</InputLabel>
                                    <FirebaseObjectListSelect
                                        list={contractors}
                                        onChange={(value) => this.onContractorSelection(value)}
                                        hintText={"בחרו קבלן"}
                                        value={contractorId}
                                        useMaterialDesign={false}
                                        icon={<img src={"img/icons/person_outline.svg"}/>}
                                    />
                                </InputGroup>
                            </Col>

                            <Col md={2} lg={2} xl={2} >
                                <InputGroup>
                                    <InputLabel>פרוייקט</InputLabel>
                                    <FirebaseObjectListSelect
                                        list={contractorId ? contractorProjects : []}
                                        onChange={(value) => this.onProjectSelection(value)}
                                        hintText={"בחרו פרוייקט"}
                                        labelAccessor={"data.name"}
                                        value={projectId}
                                        useMaterialDesign={false}
                                        icon={<img src={"img/icons/home.svg"}/>}
                                    />
                                </InputGroup>
                            </Col>
                        </Fragment>
                    ) : (
                        <Col md={2} lg={2} xl={2}>
                            <InputGroup onClick={this.openContractorSearch}>
                                <InputLabel>קבלן</InputLabel>
                                <IconInput
                                    options={selectedContractor &&  selectedContractor.id ? [{label: selectedContractor.name, value: selectedContractor.id}] : []}
                                    value={selectedContractor.id}
                                    labelAccessor={"label"}
                                    valueAccessor={"value"}
                                    placeholder={"לחצו לחיפוש"}
                                    type={"select"}
                                    icon={<img src={"img/icons/person_outline.svg"}/>}
                                />
                            </InputGroup>
                        </Col>
                    )}



                    <Col md={2}>
                        <InputGroup>
                            <InputLabel>שנה</InputLabel>
                            <IconInput
                                options={this.getYears()}
                                labelAccessor={"label"}
                                valueAccessor={"value"}
                                type={"select"}
                                value={year}
                                onChange={(value)=>{this.setState({year: value})}}
                            />
                        </InputGroup>
                    </Col>

                    <Col md={2}>
                        <InputGroup>
                            <InputLabel>חודש</InputLabel>
                            <IconInput
                                options={this.getMonths()}
                                labelAccessor={"label"}
                                valueAccessor={"value"}
                                type={"select"}
                                value={month}
                                onChange={(value)=>{
                                    console.log("set month", value)
                                    this.setState({month: value})}}
                            />
                        </InputGroup>
                    </Col>

                    <Col md={1} style={{display: "flex", alignItems: "flex-end"}}>
                        <ConfirmButton onClick={this.fetchScreenData} label={"שלח"}/>
                    </Col>

                </Row>

                {isLoading && (<LoadingSpinner/>)}

                {context === ORDER_MANAGEMENT_CONTEXT.SUPPLIER && (
                <CustomerSearchModal
                    supplierId={getSupplierId(context, roles)}
                    isOpen={isOpenContractorSearch}
                    onClose={this.closeContractorSearch}
                    onSubmit={this.onSupplierContractorSelect}
                    hideAdvanced={true}
                />
                )}
                {isValidaRequestData && (
                    <Switch>
                        <Route exact path={`${ROUTES.ORDERS_HISTORY_MANAGEMENT}`} name="ordersHistory management"
                               render={(props) => (
                                   <OrdersTable
                                       orders={orders}
                                       isLoading={isLoading}
                                       onEditItem={this.editOrder}
                                       context={context}
                                       sendOrder={sendOrder}
                                       filterColumn={this.props.filterColumn}
                                       setDates={this.props.setDates}
                                       setAmountFilter={this.props.setAmountFilter}
                                       filterToday={this.props.filterToday}
                                       filterTomorrow={this.props.filterTomorrow}
                                       filterThisWeek={this.props.filterThisWeek}
                                       showAllOpen={this.props.showAllOpen}
                                       showClosed={this.props.showClosed}
                                       showCancelled={this.props.showCancelled}
                                       onSortChange={this.props.onSortChange}
                                       filtered={this.props.filtered}
                                       startDateFilter={this.props.startDateFilter}
                                       endDateFilter={this.props.endDateFilter}
                                       sorted={this.props.sorted}
                                       amountFilterCondition={this.props.amountFilterCondition}
                                       amountFilterValue={this.props.amountFilterValue}
                                   />
                               )}/>
                        <Route
                            path={`${ROUTES.ORDERS_HISTORY_MANAGEMENT}/:supplierId/:contractorId/:projectId/:orderId`}
                            name="ordersHistory Form"
                            render={(props) => (
                                <OrderForm  {...props} context={context}/>
                            )}/>
                    </Switch>
                )}

            </div>
        )
    }
}

HistoryOrdersManagement.propTypes = {
    orders: PropTypes.array,
    contractors: PropTypes.object
};

const mapStateToProps = state => ({
    orders: state.ordersHistory.orders,
    order: state.ordersHistory.currentOrder,
    roles: state.roles.roles,
    isLoading: state.ordersHistory.isLoading,
    user: state.roles.roles,
    // fetchOrdersCalled: state.layout.fetchOrdersCalled,
     contractors: state.firebase.data.contractors,
    context: state.ordersManagement.context,
    // isApplicationOrdersAdmin: state.ordersManagement.isApplicationOrdersAdmin,
    selectedContractor: state.ordersManagement.selectedContractor,
    fetchOrdersCalled: state.ordersManagement.fetchOrdersCalled,
    filtered: state.ordersFilter.filtered,
    startDateFilter: state.ordersFilter.startDateFilter,
    endDateFilter: state.ordersFilter.endDateFilter,
    sorted: state.ordersFilter.sorted,
    amountFilterCondition: state.ordersFilter.amountFilterCondition,
    amountFilterValue: state.ordersFilter.amountFilterValue,
    isApplicationOrdersAdmin: state.ordersManagement.isApplicationOrdersAdmin,
    contractorIds: getContractorIds(state.roles.roles, roleToPermission.projectManagers)
})

const mapDispatchToProps = dispatch => bindActionCreators({
    setFieldValue,

    createNewOrder,
    fetchItems,
    initOrderFromList,
    lockOrder,
    setFetchOrdersCalled,
    sendOrder,
    setLayoutFieldValue,
    filterColumn,
    setDates,
    setAmountFilter,
    filterToday,
    filterTomorrow,
    filterThisWeek,
    showAllOpen,
    showClosed,
    showCancelled,
    getOrdersHistory,
    getOrderById,
    onSortChange
}, dispatch);

//export default (connect(mapStateToProps, mapDispatchToProps)(HistoryOrdersManagement));

export default compose(
    withFirestore,
    firebaseConnect([
        'suppliers',
        'contractors'
    ]),
    connect(
        mapStateToProps, mapDispatchToProps
    )
)(injectIntl(HistoryOrdersManagement))



