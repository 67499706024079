import React, {Component} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types'

import {colors, fonts} from "../../styles/styleguide";

import EditableLable from "./EditableLabel";


export class TabHeader extends Component {
    constructor() {
        super();

    }

    render() {
        const {label, isActive, onSelect, onLabelChange, onDelete, showEdit, showDelete, viewOnly} = this.props;
        return (
            <Header onClick={onSelect} isActive={isActive}>
                <EditableLable
                    isActive={isActive}
                    label={label}
                    onLabelChange={onLabelChange}
                    onDelete={onDelete}
                    showEdit={showEdit}
                    showDelete={showDelete}
                    viewOnly={viewOnly}
                />
            </Header>
        )

    }
}

TabHeader.propTypes = {
    onSelect: PropTypes.func,
    isActive: PropTypes.bool,
    label: PropTypes.string,
    onLabelChange: PropTypes.func,
    onDelete: PropTypes.func,
    showEdit: PropTypes.bool,
    showDelete: PropTypes.bool,
    viewOnly: PropTypes.bool
};

TabHeader.defaultProps = {
    showEdit: true,
    showDelete: true,
    viewOnly: false
}



const Header = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 21px;
    text-align: center;
    padding: 5px 15px;
    cursor: pointer;
    font-family: ${fonts.OpenSansHebrew};
    color: ${colors.charcoal};
    font-weight: ${props => props.isActive ? 'bold' : 'normal'};
    border-bottom: ${props => props.isActive ? `3px solid ${colors.charcoal}` : 'none'};
`;



export default TabHeader;