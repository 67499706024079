import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux';

import Nav from "./nav"

class PO extends Component {

    render() {
        return (
            <Nav context={this.props.context}/>
        );
    }
}



const mapStateToProps = state => ({
    context: state.ordersManagement.context,
})

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default compose(connect(mapStateToProps, mapDispatchToProps))(PO)



