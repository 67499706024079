import React, {Component} from 'react';
import PropTypes from 'prop-types'
import FirebaseObjectListSelect from "../../components/Form/FirebaseObjectListSelect";
import {firebaseConnect} from "react-redux-firebase";
import {withSupplierDrivers} from "shared/src/HOC/supplierDrivers"

class DriverSelect extends Component {

    onSelect = (driverId) => {
        const {drivers, selectedDriver, onChange} = this.props;
        if(driverId){
            onChange(drivers[driverId]);
        }
        else {
            onChange(null);
        }

    }

  render() {

      const {drivers, selectedDriver, disabled} = this.props;
        // console.log("drivers", drivers);
        return (
            <FirebaseObjectListSelect
                disabled={disabled}
                onChange={this.onSelect}
                hintText={"בחירת נהג"}
                list={drivers}
                value={selectedDriver ? selectedDriver.uid : null}
                labelAccessor={"displayName"}
                style={{width: "100%", height: 34}}
                useMaterialDesign={false}
                icon={<img src={"img/icons/driver.svg"}/>}
            />
        );
  }
}

DriverSelect.propTypes = {
    drivers: PropTypes.object,
    selectedDriver: PropTypes.any,
    onChange: PropTypes.func,
    supplierId: PropTypes.string.isRequired,
    disabled: PropTypes.bool
};

DriverSelect.defaultProps = {
    disabled: false
};

export default withSupplierDrivers(DriverSelect, firebaseConnect)

