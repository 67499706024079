import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";

import memoize from "memoize-one";
import moment from "moment";
import _ from "lodash";

import { DateRangePicker } from "react-dates";
import * as reactDatesConstants from "react-dates/constants";
import "react-table/react-table.css";
import "react-dates/lib/css/_datepicker.css";
import "./react-table-overrides.scss";

import {
  ORDER_STATUSES,
  ORDER_MANAGEMENT_CONTEXT,
  FILTERS,
} from "shared/src/constants/orders";
import { PO_STATUSES } from "shared/src/constants/pos";
import {
  extractLatestStatus,
  // millisFromLatestStatus
    isExpiredStatus,
} from "shared/src/utils/orders";
import { getStatusLabel as getPOStatusLabel } from "shared/src/utils/po";
import { getSupplierIds } from "shared/src/selectors/roles";

import {
  simpleTextFilter,
  statusFilter,
  totalFilter,
  dateFilter,
  myOrdersFilter,
  booleanFilter,
} from "../../utils/filter";

import EllipsisText from "react-ellipsis-text";
import TableDropDown from "./tableActionsDropdown";

import { Button } from "reactstrap";

import FontIcon from "material-ui/FontIcon";

import * as tableSelectors from "./tableSelectors";
import { FEED_TYPES } from "shared/src/constants/feed";
import Checkbox from "../../components/Form/Checkbox";
import { colors, fonts } from "../../styles/styleguide";

import TextFilter from "../../components/Table/Filters/TextFilter";
import AmountFilter from "../../components/Table/Filters/AmountFilter";
import DateRangeFilter from "../../components/Table/Filters/DateRangeFilter";
import { inputStyle, wrapperStyle } from "../../components/Table/Filters/style";

import { HEB_PICK_UP } from "shared/src/constants/deliveries";

class OrdersTable extends Component {
  constructor() {
    super();
    this.state = {
      focusedInput: null,
      statusFilter: null,
      showDatePicker: false,
      columns: [],
      showOnlyMyOrders: false,
    };

    this.dateFilterOnChangeCallback = null;

    this.setOrderId = this.setOrderId.bind(this);
  }

  componentDidMount() {
    const { resetCurrentOrder } = this.props;
    resetCurrentOrder();
    this.setState({ columns: this.initColumns(this.props.context) });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.context !== prevProps.context ||
      (this.props.context === ORDER_MANAGEMENT_CONTEXT.CONTRACTOR &&
        !_.isEqual(this.props.selectedContractor, prevProps.selectedContractor))
    ) {
      this.setState({ columns: this.initColumns(this.props.context) });
    }
  }

  initColumns = (context) => {
    if (!context) {
      return [];
    }
    return context === ORDER_MANAGEMENT_CONTEXT.SUPPLIER
      ? this.getSupplierColums()
      : this.getContractorColumns();
  };

  getSupplierColums = () => {
    return [
      {
        id: "orderId",
        Header: "מס תעודת משלוח",
        accessor: "orderId", // String-based value accessors!,
        /*Cell: props => <EditableOrderId rowProps={props} setOrderId={this.setOrderId}
                                            saveOrderId={this.props.saveOrderId}/>, // Custom cell components!*/
        filterMethod: this._orderIdFilter,
        Filter: (
          <TextFilter
            onChange={(value) => {
              this._filterColumn("orderId", value);
            }}
            refHandler={(ref) => {
              this.orderId = ref;
            }}
          />
        ),
      },
      {
        id: "driver",
        Header: "נהג",
        accessor: tableSelectors.driver, // String-based value accessors!,
        filterMethod: this._driverFilter,
        Filter: (
          <TextFilter
            onChange={(value) => {
              this._filterColumn("driver", value);
            }}
            refHandler={(ref) => {
              this.driver = ref;
            }}
          />
        ),
      },
      {
        id: "notes",
        Header: "הערות",
        accessor: tableSelectors.notes, // String-based value accessors!,
        Cell: (props) => <EllipsisText text={props.value || ""} length={18} />, // Custom cell components!
        filterMethod: this._notesFilter,
        Filter: (
          <TextFilter
            onChange={(value) => {
              this._filterColumn("notes", value);
            }}
            refHandler={(ref) => {
              this.notes = ref;
            }}
          />
        ),
      },
      {
        id: "customer", // Required because our accessor is not a string
        Header: "לקוח",
        accessor: tableSelectors.contractor,
        filterMethod: this._customerFilter,
        Filter: (
          <TextFilter
            onChange={(value) => {
              this._filterColumn("customer", value);
            }}
            refHandler={(ref) => {
              this.customer = ref;
            }}
          />
        ),
        Cell: (props) => (
          <span
            className="number"
            onClick={() => {
              this.props.onEditItem(props.original);
            }}
          >
            {props.value}
          </span>
        ), // Custom cell components!
      },
      {
        id: "deliveryDate",
        Header: "תאריך אספקה",
        accessor: tableSelectors.date,
        filterMethod: dateFilter,
        Cell: (props) => {
          return moment(props.value).format("D/MM/YY");
        },
        Filter: ({ filter, onChange }) => this.renderDateFilterColumn(onChange),
      },
      {
        id: "approvalDate",
        Header: "התקבלה בתאריך",
        accessor: tableSelectors.approvalDate,
        filterMethod: (filter, row) => dateFilter(filter, row, "approvalDate"),
        Cell: (props) => {
          return (
            <div dir={"ltr"}>{moment(props.value).format("D/MM/YY H:mm")}</div>
          );
        },
        Filter: ({ filter, onChange }) =>
          this.renderDateFilterColumn(onChange, "approvalDate"),
      },

      {
        id: "address", // Required because our accessor is not a string
        Header: "כתובת",
        filterMethod: this._addressFilter,
        Filter: (
          <TextFilter
            onChange={(value) => {
              this._filterColumn("address", value);
            }}
            refHandler={(ref) => {
              this.address = ref;
            }}
          />
        ),
        accessor: tableSelectors.address,
      },
      {
        id: "total",
        Header: "סכום",
        filterMethod: totalFilter,
        Cell: (props) => <span dir={"ltr"}>{props.value}</span>,
        Filter: ({ filter, onChange }) => this.renderAmountFilter(onChange),
        accessor: (o) =>
          o.isRental
            ? o.rentalTotalPrice
            : o.summary
            ? o.summary.sum
            : undefined, // String-based value accessors!
      },
      {
        id: "combinedStatus",
        Header: "סטטוס",
        accessor: tableSelectors.combinedStatus,
        filterMethod: statusFilter,
        Cell: (props) => this.renderStatus(props.original),
        Filter: (
          <div style={wrapperStyle}>
            <select
              style={inputStyle}
              ref={(ref) => {
                this.combinedStatus = ref;
              }}
              onChange={(e) => {
                this.setState({ combinedStatusFilter: e.target.value });
                this._filterColumn("combinedStatus", {
                  value: e.target.value,
                  condition: "equal",
                });
              }}
              value={this.state.combinedStatusFilter}
            >
              <option value={""}>בחר סטטוס</option>
              <option value={"הזמנה"}>{"הזמנה"}</option>
              <option value={PO_STATUSES.APPROVED}>
                {getPOStatusLabel(PO_STATUSES.APPROVED)}
              </option>
              <option value={PO_STATUSES.SENT}>
                {getPOStatusLabel(PO_STATUSES.SENT)}
              </option>
              <option value={PO_STATUSES.DRAFT}>
                {getPOStatusLabel(PO_STATUSES.DRAFT)}
              </option>
              <option value={PO_STATUSES.CANCELED}>
                {getPOStatusLabel(PO_STATUSES.CANCELED)}
              </option>
              <option value={PO_STATUSES.CLOSED}>
                {getPOStatusLabel(PO_STATUSES.CLOSED)}
              </option>
            </select>
          </div>
        ),
      },
      {
        id: "actions",
        Header: "...",
        width: 50,
        //  accessor: o => o.note, // String-based value accessors!,
        Cell: (props) => {
          const latestStatus = extractLatestStatus(props.original.status);
          if (latestStatus === ORDER_STATUSES.LOCKED) {
            return (
              <div style={{ position: "absolute" }}>
                <TableDropDown
                  markUnread={() => {
                    this.markOrderAsUnread(props.original);
                  }}
                />
              </div>
            );
          }
          return null;
        }, // Custom cell components!
        sortable: false,
        filterable: false,
      },
      {
        id: "status",
        Header: "סטטוס1",
        filterMethod: statusFilter,
        accessor: (o) => extractLatestStatus(o.status),
        Cell: (props) => <span style={{ width: 0 }}>{props.value}</span>,
        style: { width: 0, padding: 0 },
        headerStyle: { width: 0, padding: 0 },
        maxWidth: 0,
        width: 0,
        expander: false,
        Filter: (
          <select
            ref={(ref) => {
              this.status = ref;
            }}
            onChange={(e) => {
              this.setState({ statusFilter: e.target.value });
              this._filterColumn("status", {
                value: e.target.value,
                condition: "equal",
              });
            }}
            value={this.state.statusFilter}
          >
            <option value={""}>בחר סטטוס</option>
            <option value={ORDER_STATUSES.CLOSED}>{"סגור"}</option>
            <option value={ORDER_STATUSES.APPROVED}>{"מאושר"}</option>
            <option value={ORDER_STATUSES.SENT}>{"נשלח"}</option>
            <option value={ORDER_STATUSES.LOCKED}>{"נעול לשינויים"}</option>
          </select>
        ),
        show: true,
      },

      {
        id: "feedType",
        Header: "type",
        filterMethod: simpleTextFilter,
        accessor: (o) => o.feedType,
        Cell: (props) => <span style={{ width: 0 }}>{props.value}</span>,

        style: { width: 0, padding: 0 },
        headerStyle: { width: 0, padding: 0 },
        maxWidth: 0,
        width: 0,
        expander: false,
        show: true,
      },

      {
        id: "isFDO",
        Header: "isFDO",
        filterMethod: (filter, row) => booleanFilter(filter, row, "isFDO"),
        accessor: (o) => {
          return o.isFDO;
        },
        Cell: (props) => <span style={{ width: 0 }}>{props.value}</span>,

        style: { width: 0, padding: 0 },
        headerStyle: { width: 0, padding: 0 },
        maxWidth: 0,
        width: 0,
        expander: false,
        show: true,
      },

      {
        id: "myOrders",
        Header: "myOrders",
        filterMethod: myOrdersFilter,
        accessor: (o) => o.feedType,
        Cell: (props) => <span style={{ width: 0 }}>{props.value}</span>,

        style: { width: 0, padding: 0 },
        headerStyle: { width: 0, padding: 0 },
        maxWidth: 0,
        width: 0,
        expander: false,
        show: true,
      },
    ];
  };

  markOrderAsUnread = (order) => {
    const { roles, firebase } = this.props;
    const suppliers = getSupplierIds(roles);
    let supplierId = suppliers.supplierIds[0];
    const { project, contractor } = order;
    const path = `ordersBySupplier/${supplierId}/${contractor.id}/${project.id}/${order.id}/status/locked`;
    // console.log("removing firebase path", path);
    firebase.remove(path, (result) => {
      //   console.log("complete markOrderAsUnread", result)
    });
  };

  getContractorColumns = () => {
    const { hidePrices } = this.props;
    const columns = [
      {
        id: "orderId",
        Header: "מס תעודת משלוח",
        accessor: "orderId", // String-based value accessors!,
        Cell: (props) => (
          <span>
            {![
              ORDER_STATUSES.LOCKED,
              ORDER_STATUSES.CLOSED,
              ORDER_STATUSES.CANCELED,
              ORDER_STATUSES.APPROVED,
              ORDER_STATUSES.PRODUCED,
            ].includes(extractLatestStatus(props.original.status))
              ? this.sendToSupplierButton(
                  props.original,
                  extractLatestStatus(props.original.status)
                )
              : props.value}
          </span>
        ), // Custom cell components!*/
        filterMethod: this._orderIdFilter,
        Filter: (
          <TextFilter
            onChange={(value) => {
              this._filterColumn("orderId", value);
            }}
            refHandler={(ref) => {
              this.orderId = ref;
            }}
          />
        ),
      },
      {
        id: "notes",
        Header: "הערות",
        accessor: (o) => o.note, // String-based value accessors!,
        Cell: (props) => <EllipsisText text={props.value || ""} length={18} />, // Custom cell components!
        filterMethod: this._notesFilter,
        Filter: (
          <TextFilter
            onChange={(value) => {
              this._filterColumn("notes", value);
            }}
            refHandler={(ref) => {
              this.notes = ref;
            }}
          />
        ),
      },
      {
        id: "supplier", // Required because our accessor is not a string
        Header: "ספק",
        accessor: (o) => o.supplier.name,
        filterMethod: this._supplierFilter,
        Filter: (
          <TextFilter
            onChange={(value) => {
              this._filterColumn("supplier", value);
            }}
            refHandler={(ref) => {
              this.supplier = ref;
            }}
          />
        ),
        Cell: (props) => (
          <span
            className="number"
            onClick={() => {
              this.props.onEditItem(props.original);
            }}
          >
            {props.value}
          </span>
        ), // Custom cell components!
      },
      {
        id: "deliveryDate",
        Header: "תאריך אספקה",
        accessor: "deliveryDate", // Custom value accessors!
        filterMethod: dateFilter,
        Cell: (props) => {
          return moment(props.value).format("D/MM/YY");
        },
        Filter: ({ filter, onChange }) => this.renderDateFilterColumn(onChange),
      },
      {
        id: "address", // Required because our accessor is not a string
        Header: "כתובת",
        filterMethod: this._addressFilter,
        Filter: (
          <TextFilter
            onChange={(value) => {
              this._filterColumn("address", value);
            }}
            refHandler={(ref) => {
              this.address = ref;
            }}
          />
        ),
        accessor: (o) =>
          `${_.get(o, "project.address")} (${_.get(o, "project.name")})`,
      },
    ];

    if (!hidePrices) {
      columns.push({
        id: "total",
        Header: "סכום",
        filterMethod: totalFilter,
        Cell: (props) => <span dir={"ltr"}>{props.value}</span>,
        Filter: ({ filter, onChange }) => this.renderAmountFilter(onChange),
        accessor: (o) =>
          o.isRental
            ? o.rentalTotalPrice
            : o.summary
            ? o.summary.sum
            : undefined, // String-based value accessors!
      });
    }

    columns.push({
      id: "status",
      Header: "סטטוס",
      filterMethod: statusFilter,
      accessor: (o) => extractLatestStatus(o.status),
      Cell: (props) => (
        <span>{this.getStatusLabel(props.value, props.original.orderId)}</span>
      ),
      Filter: (
        <div
          style={Object.assign({}, wrapperStyle, {
            paddingLeft: 5,
            paddingRight: 5,
          })}
        >
          <select
            style={inputStyle}
            ref={(ref) => {
              this.status = ref;
            }}
            onChange={(e) => {
              this.setState({ statusFilter: e.target.value });
              this._filterColumn("status", {
                value: e.target.value,
                condition: "equal",
              });
            }}
            value={this.state.statusFilter}
          >
            <option value={""}>בחר סטטוס</option>
            <option value={ORDER_STATUSES.CLOSED}>
              {this.getStatusLabel(ORDER_STATUSES.CLOSED)}
            </option>
            <option value={ORDER_STATUSES.APPROVED}>
              {this.getStatusLabel(ORDER_STATUSES.APPROVED)}
            </option>
            <option value={ORDER_STATUSES.SENT}>
              {this.getStatusLabel(ORDER_STATUSES.SENT)}
            </option>
            <option value={ORDER_STATUSES.LOCKED}>
              {this.getStatusLabel(ORDER_STATUSES.LOCKED)}
            </option>
            <option value={ORDER_STATUSES.DRAFT}>
              {this.getStatusLabel(ORDER_STATUSES.DRAFT)}
            </option>
            <option value={ORDER_STATUSES.RENT}>
              {this.getStatusLabel(ORDER_STATUSES.RENT)}
            </option>
          </select>
        </div>
      ),
      show: true,
    });

    return columns;
  };

  getStatusLabel = (status, orderId) => {
    switch (status) {
      case ORDER_STATUSES.APPROVED:
        return "מאושר - נשלח לספק";
      case ORDER_STATUSES.CANCELED:
        return "בוטל";
      case ORDER_STATUSES.CLOSED:
        return "סגור";
      case ORDER_STATUSES.DRAFT:
        return "טיוטה";
      case ORDER_STATUSES.LOCKED:
        if (orderId) {
          return "בדרך לאתר";
        }
        return "התקבל - נעול לשינויים";
      case ORDER_STATUSES.RENT:
        return "השכרה";
      case ORDER_STATUSES.SENT:
      case ORDER_STATUSES.READY:
        return "נדרש אישור";

      case ORDER_STATUSES.PRODUCED:
        return "הונפקה תעודת משלוח";
    }
    return status;
  };

  sendToSupplierButton = (order, status) => {
    return (
      <Button
        onClick={() => {
          this.props.sendOrder(order);
        }}
      >
        {order.isRental
          ? "החזרת מוצר השכרה"
          : status === ORDER_STATUSES.READY
          ? "אישור הזמנה"
          : "שלח הזמנה"}
      </Button>
    );
  };

  _filterColumn = (columnId, value) => {
    this.props.filterColumn(columnId, value);
  };

  _orderIdFilter = (filter, row) => {
    return simpleTextFilter(filter, row, "orderId");
  };

  _driverFilter = (filter, row) => {
    return simpleTextFilter(filter, row, "driver");
  };

  _notesFilter = (filter, row) => {
    return simpleTextFilter(filter, row, "notes");
  };

  _customerFilter = (filter, row) => {
    return simpleTextFilter(filter, row, "customer");
  };

  _supplierFilter = (filter, row) => {
    return simpleTextFilter(filter, row, "supplier");
  };

  _projectFilter = (filter, row) => {
    return simpleTextFilter(filter, row, "project");
  };

  _addressFilter = (filter, row) => {
    return simpleTextFilter(filter, row, "address");
  };

  showDatePicker = () => {
    this.setState({
      showDatePicker: true,
      focusedInput: reactDatesConstants.START_DATE,
    });
  };

  setOrderId(id, index, value) {
    this.props.setOrderId(index, value);
  }

  renderDateFilterColumn = (onChange, key = "deliveryDate") => {
    const { startDateFilter, endDateFilter } = this.props;
    this.dateFilterOnChangeCallback = onChange;

    return (
      <DateRangeFilter
        startDateFilter={startDateFilter}
        endDateFilter={endDateFilter}
        onChange={onChange}
        onChangeCallbackSetter={(onChange) => {
          this.dateFilterOnChangeCallback = onChange;
        }}
        showDatePicker={this.showDatePicker}
        onClose={() => {
          this._filterColumn(key, { startDate: null, endDate: null });
          this.setState({ startDateFilter: null, endDateFilter: null });
        }}
      />
    );
  };

  renderDatePicker = () => {
    return (
      <DateRangePicker
        startDate={this.props.startDateFilter} // momentPropTypes.momentObj or null,
        startDateId="startDateFilter" // PropTypes.string.isRequired,
        endDate={this.props.endDateFilter} // momentPropTypes.momentObj or null,
        endDateId="endDateFilter" // PropTypes.string.isRequired,
        onDatesChange={({ startDate, endDate }) => {
          this.props.setDates(startDate, endDate);
        }} // PropTypes.func.isRequired,
        focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
        onFocusChange={(focusedInput) => {
          this.setState({ focusedInput });
        }} // PropTypes.func.isRequired,
        isRTL={true}
        isOutsideRange={(day) => moment(day).isBefore("2018-01-01")}
        anchorDirection={reactDatesConstants.ANCHOR_RIGHT}
        showClearDates={true}
        withPortal={true}
        appendToBody={true}
        disableScroll={true}
        small={true}
        block={true}
      />
    );
  };

  renderAmountFilter = () => {
    const {
      amountFilterCondition,
      amountFilterValue,
      setAmountFilter,
    } = this.props;

    return (
      <AmountFilter
        onValueChange={(value) => {
          setAmountFilter({ amountFilterValue: value, amountFilterCondition });
        }}
        onConditionChange={(value) => {
          setAmountFilter({ amountFilterCondition: value, amountFilterValue });
        }}
        amountFilterValue={amountFilterValue}
        amountFilterCondition={amountFilterCondition}
      />
    );
  };

  filterToday = () => {
    this.props.filterToday();
  };

  filterTommorow = () => {
    this.props.filterTomorrow();
  };

  filterThisWeek = () => {
    this.props.filterThisWeek();
  };

  showAllOpen = () => {
    this.props.showAllOpen();
  };

  showClosed = () => {
    this.props.showClosed();
  };

  showCancelled = () => {
    this.props.showCancelled();
  };

  showFDO = () => {
    this.props.showFDO();
  };

  toggleMyOrders = (checked) => {
    this.props.toggleOnlyMyOrders(checked, this.props.uid);
  };

  getTableData = memoize((context, orders, pos) => {
    if (context === ORDER_MANAGEMENT_CONTEXT.SUPPLIER) {
      const filteredPOs = pos.filter((item) => !item.isPOContract);
      const list = [...filteredPOs, ...orders];
      console.log("combined list", list);
      return list;
    } else {
      return orders;
    }
  });

  renderStatus = (row) => {
    // console.log("renderStatus");
    // console.log("row", row);

    let label = tableSelectors.combinedStatus(row);

    // console.log("label", label);

    let backgroundColor = colors.charcoal;
    let iconSrc = "img/icons/orders/draft.png";
    let textColor = "#fff";
    switch (label) {
      case getPOStatusLabel(PO_STATUSES.APPROVED):
        iconSrc = "img/icons/orders/pendingApproval.png";
        break;
      case getPOStatusLabel(PO_STATUSES.SENT):
        iconSrc = "img/icons/orders/sent.png";
        break;
      case getPOStatusLabel(PO_STATUSES.CANCELED):
        label = "בוטל/נמחק"
        backgroundColor = "#FF0000";
        iconSrc = "img/icons/trash.png";
        break;
      case getPOStatusLabel(PO_STATUSES.CLOSED):
        iconSrc = "img/icons/orders/recieved.png";
        break;
      case getPOStatusLabel(PO_STATUSES.DRAFT):
        backgroundColor = "#efefef";
        textColor = colors.charcoal;
        break;
    }

    const isCanceled = !!row.status && !!row.status.canceled

    if (isCanceled) {
      label = "בוטל/נמחק"
      backgroundColor = "#FF0000";
      iconSrc = "img/icons/trash.png";
    } else {
      if (row.isFDO) {
        backgroundColor = "#10a882";
        textColor = "#fff";
        if ("הזמנה" === label) {
          iconSrc = "img/icons/orders/fdo.svg";
          label = "הזמנה בדלפק";
        }
      } else {
        if (!!row.selectedDelivery) {
          // label = row.selectedDelivery.label

          if (
              row.selectedDelivery.type !== undefined &&
              row.selectedDelivery.type === HEB_PICK_UP
          ) {
            backgroundColor = "#f5c608";
            textColor = "#000";

            label = row.selectedDelivery.type;
          }
        }
      }

      if (row.isRental) {
        iconSrc = "img/icons/orders/rent-inverted.png";
      }
    }

    return (
      <div
        style={{
          width: "100%",
          borderTopRightRadius: 15,
          borderBottomRightRadius: 15,
          backgroundColor,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          padding: 5,
          paddingRight: row.isFDO ? 8 : 5,
          color: textColor,
        }}
      >
        <img
          style={{
            maxHeight:
                !row.isFDO
                    ? isCanceled
                      ? 20
                      : 26
                    : 17,
            width: "auto",
            marginLeft: 5,
            marginRight: isCanceled ? 5 : 0,
          }}
          src={iconSrc}
        />
        {label}
      </div>
    );
  };

  render() {
    const {
      orders,
      pos,
      isLoading,
      filtered,
      sorted,
      onSortChange,
      currentFilterKey,
      context,
      showOnlyMyOrders,
    } = this.props;

    const tableData = this.getTableData(context, orders, pos);

    return (
      <div>
        <div>
          <div style={{ visibility: "hidden", height: 0 }}>
            {this.renderDatePicker()}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: " space-between",
            marginTop:
              context === ORDER_MANAGEMENT_CONTEXT.CONTRACTOR ? 15 : 25,
          }}
        >
          <div>
            {/*<Button style={{fontFamily: fonts.OpenSansHebrew, color: colors.charcoal}} color="link"
                                className={currentFilterKey === FILTERS.TODAY ? "px-0 font-weight-bold" : "px-0"}
                                onClick={this.filterToday}>הזמנות של היום</Button> |
                        <Button style={{fontFamily: fonts.OpenSansHebrew, color: colors.charcoal}} color="link"
                                className={currentFilterKey === FILTERS.TOMORROW ? "px-0 font-weight-bold" : "px-0"}
                                onClick={this.filterTommorow}>הזמנות של מחר</Button> |
                        <Button style={{fontFamily: fonts.OpenSansHebrew, color: colors.charcoal}} color="link"
                                className={currentFilterKey === FILTERS.THIS_WEEK ? "px-0 font-weight-bold" : "px-0"}
                                onClick={this.filterThisWeek}>הזמנות של השבוע</Button> |*/}
            <Button
              style={{
                fontFamily: fonts.OpenSansHebrew,
                color: colors.charcoal,
              }}
              color="link"
              className={
                currentFilterKey === FILTERS.OPEN
                  ? "px-0 font-weight-bold"
                  : "px-0"
              }
              onClick={this.showAllOpen}
            >
              הזמנות למשלוח
            </Button>{" "}
            |
            <Button
              style={{
                fontFamily: fonts.OpenSansHebrew,
                color: colors.charcoal,
              }}
              color="link"
              className={
                currentFilterKey === FILTERS.CLOSED
                  ? "px-0 font-weight-bold"
                  : "px-0"
              }
              onClick={this.showClosed}
            >
              הזמנות סגורות
            </Button>{" "}
            |
            {/*  <Button style={{fontFamily: fonts.OpenSansHebrew, color: colors.charcoal}} color="link"
                                className={currentFilterKey === FILTERS.CANCELLED ? "px-0 font-weight-bold" : "px-0"}
                                onClick={this.showCancelled}>הזמנות מבוטלות</Button> |*/}
            <Button
              style={{
                fontFamily: fonts.OpenSansHebrew,
                color: colors.charcoal,
              }}
              color="link"
              className={
                currentFilterKey === FILTERS.FDO
                  ? "px-0 font-weight-bold"
                  : "px-0"
              }
              onClick={this.showFDO}
            >
              הזמנות דלפק
            </Button>
          </div>
          <div>
            {this.props.context === ORDER_MANAGEMENT_CONTEXT.SUPPLIER && (
              <Checkbox
                checked={showOnlyMyOrders}
                label={"הצגת ההזמנות שלי בלבד"}
                onChange={(event) => {
                  this.toggleMyOrders(event.target.checked);
                }}
              />
            )}
          </div>
        </div>
        <ReactTable
          data={tableData}
          columns={this.state.columns}
          showPagination={false}
          pageSize={tableData.length}
          loading={isLoading}
          filtered={filtered}
          filterable
          sorted={sorted}
          onSortedChange={(sorted) => onSortChange(sorted)}
          getTdProps={(state, rowInfo, column, instance) => {
            const returnedObj = {
              onClick: (e, handleOriginal) => {
                if (column.id === "actions") {
                  if (handleOriginal) {
                    handleOriginal();
                  }
                  return;
                }
                //console.log("A Td Element was clicked!");
                //console.log("it produced this event:", e);
                //console.log("It was in this column:", column);
                //console.log("It was in this row:", rowInfo);
                //console.log("It was in this table instance:", instance);
                this.props.onEditItem(rowInfo.original);

                // IMPORTANT! React-Table uses onClick internally to trigger
                // events like expanding SubComponents and pivots.
                // By default a custom 'onClick' handler will override this functionality.
                // If you want to fire the original onClick handler, call the
                // 'handleOriginal' function.
                if (handleOriginal) {
                  handleOriginal();
                }
              },
            };

            if (
              this.props.context === ORDER_MANAGEMENT_CONTEXT.SUPPLIER &&
              rowInfo &&
              rowInfo.row &&
              (rowInfo.row.status === ORDER_STATUSES.APPROVED ||
                rowInfo.row.status === ORDER_STATUSES.SENT ||
                (rowInfo.row.isFDO &&
                  rowInfo.row.status ===
                    getPOStatusLabel(PO_STATUSES.CLOSED)) ||
                (rowInfo.original.isInStore &&
                  rowInfo.row.status === ORDER_STATUSES.LOCKED)) &&
              rowInfo.row.feedType !== FEED_TYPES.PO
            ) {
              //console.log("row status", rowInfo.row.status, rowInfo.original.status);
              returnedObj.style = { fontWeight: "bold" };
            }

            if (!!rowInfo) {
              const order = rowInfo.original
              const latestStatus = extractLatestStatus(order.status)

              // console.log("latestStatus", latestStatus)

              if (![ORDER_STATUSES.CANCELED, ORDER_STATUSES.CLOSED].includes(latestStatus)) {
                // const millis = millisFromLatestStatus(order)
                // const isPast24Hours = millis >= 1000 * 60 * 60 * 24
                const isExpired = isExpiredStatus(order)

                // console.log("isExpired", isExpired)

                if (isExpired) {
                  const styleRed = {
                    // "border-width": "2px",
                    // "border-color": colors.red,
                    "background-color": "#ef9a9a"
                  }

                  returnedObj.style = !!returnedObj.style ? {...returnedObj.style, ...styleRed} : styleRed
                }
              }
            }

            return returnedObj;
          }}
          getTheadFilterThProps={(state, rowInfo, column) => {
            return {
              style: {
                background: "#fff",
                border: "none",
              },
            };
          }}
        />
      </div>
    );
  }
}

OrdersTable.propTypes = {
  orders: PropTypes.array,
  setOrderId: PropTypes.func,
  saveOrderId: PropTypes.func,
  isLoading: PropTypes.bool,
  context: PropTypes.string,
  sendOrder: PropTypes.func,
  filterColumn: PropTypes.func,
  filterToday: PropTypes.func,
  filterTomorrow: PropTypes.func,
  filterThisWeek: PropTypes.func,
  showAllOpen: PropTypes.func,
  showClosed: PropTypes.func,
  showCancelled: PropTypes.func,
  showFDO: PropTypes.func,
  onSortChange: PropTypes.func,
  setDates: PropTypes.func,
  setAmountFilter: PropTypes.func,
  filtered: PropTypes.array,
  sorted: PropTypes.array,
  currentFilterKey: PropTypes.string,
  roles: PropTypes.object,
  resetCurrentOrder: PropTypes.func,
  hidePrices: PropTypes.bool,
  uid: PropTypes.string,
  showOnlyMyOrders: PropTypes.bool,
  toggleOnlyMyOrders: PropTypes.bool,
};

export default OrdersTable;
