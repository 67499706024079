import _ from "lodash"
import {
    CONVERT_PO_TO_ORDER_SUCCESS,
    CONVERT_PO_TO_ORDER_FAILURE
} from "shared/src/modules/pos";

import { history } from '../store'
import { toastr } from 'react-redux-toastr'
import { fetchItems } from "shared/src/api/orderItems";
import { initOrderById } from "shared/src/modules/orders"

import ROUTES from "../constants/routes";


export const posMiddleware = ({dispatch, getState}) => next => action => {

    if (action.type === CONVERT_PO_TO_ORDER_SUCCESS) {
        if (action.meta && action.meta.redirectOnSuccess && action.payload.order) {
            const {order} = action.payload;
            const {
                contractorId,
                id,
                projectId,
                supplierId
            } = order;

            toastr.success("ההצעה הפכה להזמנה בהצלחה")
            initOrder({contractorId, projectId, supplierId, orderId: id}, dispatch, getState, 0);

        } else {
            if (action.meta.isPOContract) {
                toastr.success("ההצעה התקבלה בהצלחה")
            } else {
                toastr.success("ההצעה הפכה להזמנה בהצלחה")
            }
            history.push(ROUTES.PO_LIST)

        }
    } else if (action.type === CONVERT_PO_TO_ORDER_FAILURE) {
        /*{
            "payload": {
                "name": "ApiError",
                "status": 400,
                "statusText": "",
                "response": {
                "error": "Closed PO"
            },
            "message": "400 - "
        },
            "type": "pos/CONVERT_PO_TO_ORDER_FAILURE",
            "error": true
        }*/
        if(_.get(action, "payload.status") === 400 && _.get(action, "payload.response.error") === "Closed PO"){
            toastr.warning('ההצעה כבר אושרה ע"י משתמש אחר')
        }else {
            toastr.error("אירעה שגיאה במהלך ההפיכה להזמנה, אנא נסו שוב. במידה והתקלה חוזרת יש לפנות לתמיכה")
        }
    }

    return next(action);

};

function initOrder(order, dispatch, getState, attempts) {
    if (attempts > 50) {
        const userId = getState().roles.roles.user_id;
        console.log("couldn't init order from po", {orderId: order.orderId, userId});
        const {contractorId, projectId, supplierId, orderId} = order;
        history.push(`/orders/${supplierId}/${contractorId}/${projectId}/${orderId}`)
        window.location.reload();
        return false;
    }

    const ordersState = getState().orders;
    const {contractorId, projectId, supplierId, orderId} = order;
    const {updateInProgress, ordersContextLoaded} = ordersState;
    if (ordersContextLoaded && !updateInProgress) {

        const userId = getState().roles.roles.user_id;
        console.log("init order from po", {orderId});

        // console.log("order before", ordersState.newOrder);
        dispatch(initOrderById(orderId));
        //      console.log("order after", ordersState.newOrder);
        if (ordersState.newOrder.id !== orderId) {
            console.log("unable to init order by id", {
                userId,
                attempts,
                orderId,
                order: ordersState.newOrder,
                allOrders: ordersState.orders
            });

            console.log("unable to init order by id", {orderId});

            setTimeout(() => {
                initOrder(order, dispatch, getState, attempts + 1)
            }, 100);
        } else {
            dispatch(fetchItems({contractorId, projectId, supplierId, orderId}));
            history.push(`/orders/${supplierId}/${contractorId}/${projectId}/${orderId}`)
            return true;
        }


    } else {
        if (attempts < 50) {
            // if not loaded yet wait 200 milliseconds and try again
            setTimeout(() => {
                initOrder(order, dispatch, getState, attempts + 1)
            }, 100);
        }
    }
}

