import { colors, fonts } from "../../../styles/styleguide";


export const wrapperStyle = {
    width: "auto",
    backgroundColor: "#f0f0f0",
    borderRadius: 21.5,
    paddingLeft: 17,
    paddingRight: 17,
    marginRight: 10,
    marginLeft: 10,
    position: "relative"
};

export const inputStyle = {
    border: "none",
    outline: "none",
    textAlign: "center",
    fontFamily: fonts.OpenSansHebrew,
    color: colors.charcoal,
    backgroundColor: "#f0f0f0",
    width: "calc( 100% - 17px )",
}

export const iconStyle = {
    position: "absolute",
    top: 5,
    right: 5
}