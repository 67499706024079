import React from "react";
import { fonts, colors } from "../../../styles/styleguide";
import {
    wrapperStyle,
    inputStyle,
    iconStyle
} from "./style"

const TextFilter = ({refHandler, onChange}) => (

    <div style={wrapperStyle}>
        <img style={iconStyle} src={"img/icons/search.svg"}/>
        <input
            style={inputStyle}
            type="text" ref={refHandler}
               onChange={(e) => {
                   onChange(e.target.value)
               }}
        />
    </div>

)

export default TextFilter;