import React, { Component } from "react";
import PropTypes from "prop-types";
import { fonts, colors } from "../../styles/styleguide";

class CountPicker extends Component {
  constructor() {
    super();
    this.state = {
      isOpenManualUpdate: false,
    };
  }

  increment = () => {
    const { tick, value, onValueChange, onCountBlur, max } = this.props;
    const newVal = Number(value) + tick;
    if (!(max && newVal > max)) {
      onValueChange(newVal);
      if (onCountBlur) {
        onCountBlur(newVal);
      }
    }
  };

  decrement = () => {
    const {
      tick,
      value,
      onValueChange,
      onCountBlur,
      min,
      negativeCount,
    } = this.props;
    const newVal = Number(value) - tick;
    console.log("decrement, newVal:", newVal);
    if (newVal >= min || negativeCount) {
      onValueChange(newVal);
      if (onCountBlur) {
        onCountBlur(newVal);
      }
    }
  };

  validateManualInput = (value) => {
    const { tick, allowFloatingPoint } = this.props;

    if (!value && isNaN(value)) {
      return false;
    }

    if (value === 0) {
      return false;
    }

    if (!allowFloatingPoint && value / tick - Math.floor(value / tick) !== 0) {
      return false;
    }

    return true;
  };

  render() {
    const {
      value,
      max,
      min,
      disabled,
      onValueChange,
      onCountBlur,
      width,
      negativeCount,
    } = this.props;

    const isDisabled = disabled || (Number(value) <= min && !negativeCount);

    const { isOpenManualUpdate } = this.state;

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          borderRadius: 5,
          backgroundColor: "rgb(238 ,238 ,238)",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 3,
          width,
        }}
      >
        <div disabled={isDisabled} onClick={this.decrement}>
          <img src={"img/picker/minus.png"} style={styles.button} />
        </div>
        {isOpenManualUpdate ? (
          <input
						width={width}
            autoFocus={true}
            value={value}
            onChange={(e) => {
              onValueChange(e.target.value);
            }}
            type={"number"}
            dir={"ltr"}
            onBlur={() => {
              this.setState({ isOpenManualUpdate: false });

              let newValue = Number(value)
              let isValid = this.validateManualInput(newValue);
              console.log("text blur", isValid);
              if (!isValid) {
                newValue = min;
              }
              if (onCountBlur) {
                onCountBlur(newValue);
              } else {
                onValueChange(newValue);
              }
            }}
            style={{
					    color: "#2e423d",
					    fontFamily: fonts.OpenSansHebrew,
					    fontSize: 15.5,
					    marginVertical: 8.5,
					    textAlign: "center",
					    maxWidth: width ? width - 27 * 2 : 100,
					  }}
          />
        ) : (
          <div
            disabled={disabled}
            style={{ width: "100%", textAlign: "center" }}
            onClick={() => {
              this.setState({ isOpenManualUpdate: true });
            }}
          >
            <div dir={"ltr"} style={styles.text}>
              {value || min}
            </div>
          </div>
        )}

        <div
          disabled={disabled || (max && Number(value) >= max)}
          onClick={this.increment}
        >
          <img src={"img/picker/plus.png"} style={styles.button} />
        </div>
      </div>
    );
  }
}

const styles = {
  text: {
    color: "#2e423d",
    fontFamily: fonts.OpenSansHebrew,
    fontSize: 15.5,
    marginVertical: 8.5,
  },
  button: {
    width: 27,
    height: 27,
  },
  // input: {
  //   color: "#2e423d",
  //   fontFamily: fonts.OpenSansHebrew,
  //   fontSize: 15.5,
  //   marginVertical: 8.5,
  //   textAlign: "center",
  //   maxWidth: 100,
  // },
};

CountPicker.propTypes = {
  value: PropTypes.number,
  tick: PropTypes.number,
  max: PropTypes.number,
  min: PropTypes.number,
  onValueChange: PropTypes.func,
  onCountBlur: PropTypes.func,
  disabled: PropTypes.bool,
  width: PropTypes.any,
  allowFloatingPoint: PropTypes.bool,
  negativeCount: PropTypes.bool,
};

CountPicker.defaultProps = {
  value: 1,
  tick: 1,
  onValueChange: (value) => {},
  min: 1,
  disabled: false,
  width: undefined,
  allowFloatingPoint: true,
  negativeCount: true,
};

export default CountPicker;
