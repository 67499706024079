import React from 'react';
import PropTypes from 'prop-types'
import {firebaseConnect} from "react-redux-firebase";
import {connect} from 'react-redux'
import {compose} from 'redux'
import {bindActionCreators} from 'redux';
import {injectIntl, FormattedMessage} from 'react-intl';
import styled from 'styled-components';


import {getArticles, updateQuery} from "shared/src/modules/articles";
import {getArticle, deleteArticle} from "shared/src/modules/article";
import {addArticle} from "shared/src/actions/article"
import ArticlesList from "./ArticlesList";


import StickyFloatingButton from "../../components/StickyFloatingButton"

import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button
} from 'reactstrap';

class ArticleManagement extends React.Component{

    constructor() {
        super();
        this.state = {
            page: 0,
            isModalOpen: false,
        };

        this.editArticle = this.editArticle.bind(this);
        this.fetchArticles = this.fetchArticles.bind(this);
        this.onDeleteItem = this.onDeleteItem.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);

    }

    componentDidMount(){
        this.fetchArticles();
    }

    fetchArticles() {
        const {getArticles} = this.props;
        getArticles();
    }

    editArticle(item){
        const {getArticle} = this.props;
        getArticle(item.userId, item.id);
        this.props.history.push("/article-management/article/"+item.id);
    }

    onDeleteItem(item) {
        console.log("delete item", item.id);
        this.openModal(item);
    }


    openModal(item) {
        this.setState({
            isModalOpen: true,
            itemToDelete: item
        })
    }

    confirmDelete() {
        const {itemToDelete} = this.state;
        const {deleteArticle} = this.props;
        deleteArticle(itemToDelete.userId, itemToDelete.articleId);
        this.closeModal();
        this.fetchArticles();
    }

    closeModal() {
        this.setState({
            isModalOpen: false,
            itemToDelete: null
        })
    }



    render() {
        const {isLoading, articles, totalItems, profile, auth, addArticle} = this.props;
        const {itemToDelete} = this.state;
        return (
            <div>
                <ArticlesList
                    list={articles}
                    onItemDelete={(item)=>{this.onDeleteItem(item)}}
                    onItemEdit={(item)=>{this.editArticle(item)}}
                    isLoading={isLoading}
                />
                <StickyFloatingButton
                    onPress={()=> {
                        addArticle(auth.uid);
                        //this.props.history.push("/article-management/article");
                    }}
                    icon={"add"}
                />
                <Modal isOpen={this.state.isModalOpen} className={'modal-danger '}>
                    <ModalHeader><FormattedMessage id="product.deleteModal.title"/></ModalHeader>
                    <ModalBody>
                        <FormattedMessage id="product.deleteModal.question"/>
                        {itemToDelete ? itemToDelete.title : ""} ?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={this.confirmDelete}><FormattedMessage
                            id="product.deleteModal.confirm"/></Button>{' '}
                        <Button color="secondary" onClick={this.closeModal}><FormattedMessage
                            id="product.deleteModal.cancel"/></Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

const StickyFloating = styled.div`
    position: fixed;
    bottom: 50px;
    left: 50px;
`;

const mapStateToProps = state => ({
    query: state.articles.query,
    isLoading: state.articles.isLoading,
    articles: state.articles.articles,
    totalItems: state.articles.totalItems,
    profile: state.firebase.profile,
    auth: state.firebase.auth,
})

const mapDispatchToProps = dispatch => bindActionCreators({
    getArticles,
    updateQuery,
    addArticle,
    getArticle,
    deleteArticle
}, dispatch);

export default firebaseConnect()(connect(mapStateToProps, mapDispatchToProps)(ArticleManagement));