import UAParser from "ua-parser-js";

const parser = new UAParser();
const device = parser.getDevice();

export const isMobile = () => {
    return device.type === "mobile";
}

export const isTablet = () => {
    return device.type === "tablet";
}

export const isMobileOrTablet = () => {
    return isMobile() || isTablet()
}