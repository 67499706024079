import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types'
import styled from "styled-components";
import { Col, Modal, ModalHeader,  ModalBody, Row } from "reactstrap";
import IconInput from "./Form/IconInput";
import RoundIconButton from "./RoundIconButton"
import TogglePicker from "./Form/TogglePicker";
import { colors, fonts } from "../styles/styleguide";
import Loader from "./Loader";
import { toastr } from "react-redux-toastr";
import {withCustomerSearch} from "shared/src/HOC/customerSearch"

const SEARCH_BY_OPTIONS = {
    NAME: "name",
    ERP_ID: "erpId",
    TAX_ID: "taxId"
}

const SEARCH_BY_OPTIONS_LABEL = {
    erpId: "מזהה לקוח",
    name: "שם",
    taxId: "ח.פ"
}


class CustomerSearchModal extends Component {

    constructor(){
        super();
        this._input = null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!this.props.isOpen && prevProps.isOpen){
            this.props.reset()
        }

       /* if(this.props.isOpen && !prevProps.isOpen && this._input){
            this._input.focus();
        }*/
    }

    renderResult = (customer) => {

        const {onSelect} = this.props

        return (
            <CustomerRow onClick={()=> onSelect(customer)}>
                <div>{customer.name}</div>
                <div>{`| ח.פ ${customer.taxId}`}</div>
                {customer.address && (<div>| {customer.address}</div>)}
                {customer.erpId && <div>{`| מזהה לקוח ${customer.erpId}`}</div>}
            </CustomerRow>
        )
    }

    render() {
        const {isOpen, onClose, hideAdvanced, query, isLoading, results, didSearched, advanced, searchBy, setSearchBy, onQueryChange, setAdvanced,
            search} = this.props;

        return (
            <Modal isOpen={isOpen} toggle={onClose} size={"xl"} onOpened={()=>{this._input && this._input.focus()}}>
                <ModalHeader>
                    חיפוש לקוחות
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md={12}>
                            {advanced && (
                                <div style={{marginBottom: 30}}>
                                    <div style={{marginBottom: 15}}>חיפוש לפי</div>
                                    <TogglePicker
                                        options={Object.values(SEARCH_BY_OPTIONS).map((value => ({value: value, label: SEARCH_BY_OPTIONS_LABEL[value]})))}
                                        value={searchBy}
                                        onChange={(value)=>{setSearchBy(value)}}
                                    />
                                </div>
                            )}
                            <IconInput
                                refHandler={(ref)=>{this._input = ref}}
                                autoFocus="true"
                                type={"text"}
                                value={query}
                                onChange={(value) => {onQueryChange(value)}}
                                icon={<img src={"img/icons/search_round.svg"}/>}
                                leftIcon={()=>(
                                    <RoundIconButton
                                        onClick={search}
                                        backgroundColor={colors.yellow}
                                        iconSrc={"img/icons/search_inverted.svg"}
                                        color={colors.charcoal}
                                        label={"חיפוש"}
                                        fontSize={16.5}
                                        height={39}
                                        letterSpacing={0.83}
                                        fontWeight={"normal"}
                                        imgSize={25}
                                    />
                                    )
                                }
                                leftIconTop={1}
                                leftIconLeft={2}
                                onKeyPress={(e)=>{
                                    if (e.key === 'Enter') {
                                        search();
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            {isLoading && <Loader/>}
                            {!isLoading && results.length > 0 && (
                                <Fragment>
                                    {results.map(item => this.renderResult(item))}
                                </Fragment>
                            )}
                            {!isLoading && didSearched && !advanced && !hideAdvanced &&
                            (<AdvancedSearchMessage onClick={setAdvanced}>לא מצאתם מה שחיפשתם? לחצו כאן לחיפוש מתקדם</AdvancedSearchMessage>)}
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        );
    }
}

CustomerSearchModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    supplierId: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onError: PropTypes.func,
    hideAdvanced: PropTypes.bool
};

CustomerSearchModal.defaultProps = {
    hideAdvanced: false,
    onError: ()=>{toastr.error("אירעה שגיאה בנסיון יצירת ההזמנה, אנא נסו שוב")}
};

const CustomerRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 19px;
  cursor: pointer;
  color: ${colors.charcoal};
  &:hover{
    color: ${colors.dark_gray};
  }
  margin-top: 5px;
`;

const AdvancedSearchMessage = styled.div`
  font-weight: bold;
  font-size: 19px;
  cursor: pointer;
  text-align: center;
  margin-top: 40px;
  &:hover{
  color: ${colors.dark_gray};
  }
`


export default withCustomerSearch(CustomerSearchModal);