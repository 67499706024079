import React from 'react';
import SearchBox from './SearchBox';

import WebSearchInput from './WebSearchInput';
import WebSearchResults from './WebSearchResults';

const WebSearchFrame = ({children}) => {
    return (
        <div className="animated fadeIn">
            {children}
        </div>
    );
};



const WebSearchBox = ListItem => SearchBox(WebSearchFrame, WebSearchInput, WebSearchResults(ListItem));

export default WebSearchBox;