import React from "react";
import Popup from "reactjs-popup";

import { CardTitle } from "../../../styles/stylesheet";
import { colors } from "../../../styles/styleguide";
import {
    Container,
    ContainerHeader,
    ContainerList,
    HoverText,
    ImageComponent
} from "./styles";

const SummaryContainer = ({
    productCount,
    totalPrice,
    onPrintClick,
    isCatalog,
    children,
}) => (
    <div
        style={{
            display: "flex",
            flexDirection: "column",
            width: isCatalog ? "95%" : "100%",
            flexGrow: 1,
            marginRight: isCatalog ? "15px" : 0,
            marginTop: "50px",
        }}
    >
        <CardTitle
            style={{
                display: "flex",
                flexDirection: "row",
                color: colors.charcoal,
            }}
        >
            {"סיכום"}

            {!!onPrintClick && (
                <Popup
                    trigger={
                        <div
                            style={{ marginRight: "16px", cursor: "pointer" }}
                            onClick={onPrintClick}
                        >
                            <ImageComponent src={"img/icons/print.svg"} height={30} />
                        </div>
                    }
                    on="hover"
                    position="top"
                    closeOnDocumentClick
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <HoverText>{"הצג לפני הדפסה"}</HoverText>
                    </div>
                </Popup>
            )}
        </CardTitle>

        <Container>
            <div
                style={{
                    backgroundColor: "#f9f9f9",
                    borderRadius: "15px",
                }}
            >
                <ContainerHeader style={{ justifyContent: "space-around" }}>
                    <div>
                            <span dir={"ltr"} style={{ fontWeight: "bold" }}>
                              {productCount}
                            </span>

                        <span>{` פריטים`}</span>
                    </div>

                    <span>|</span>

                    <div style={{ fontWeight: "bold" }}>
                        <span dir={"ltr"}>{totalPrice}</span>
                        <span>{"₪"}</span>
                    </div>
                </ContainerHeader>
            </div>

            <ContainerList>
                {children}
            </ContainerList>
        </Container>
    </div>
)

export default SummaryContainer;