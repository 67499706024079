import React from 'react';
import PropTypes from 'prop-types'
import styled from 'styled-components';
import {injectIntl, FormattedMessage, formatMessage} from 'react-intl'

import ProductRefSelect from "./ProductRefSelect";

import FontIcon from 'material-ui/FontIcon';
import RaisedButton from 'material-ui/RaisedButton';

const style = {
    raisedButton: {
        marginLeft: 10
    }
}

class ProductRefsGroup extends React.Component{

    constructor() {
        super()
    }



    render() {

        const {mainLabel, primaryButtonLabel, suppliersList, items, onAddGroup, onDeleteGroup, onItemChange, intl} = this.props;

        return (
            <Group>
                <div>
                    <h4>{mainLabel}</h4>
                    <RaisedButton
                        onClick={() => onAddGroup()}
                        label={primaryButtonLabel}
                        primary={true}
                        icon={<FontIcon className="material-icons">add</FontIcon>}
                        style={style.raisedButton}
                    />
                </div>
                {
                    items.map((item, index)=>{
                        return(
                            <Group>
                                <Field>
                                    <ProductRefSelect
                                        mainLabel={""}
                                        onItemIdChange={(itemId)=>{onItemChange({key: item.key, supplierId: item.supplierId, itemId})}}
                                        onSupplierChange={(supplierId)=>{onItemChange({key: item.key, supplierId, itemId: item.itemId})}}
                                        suppliersList={suppliersList}
                                        suppliersValue={item.supplierId}
                                        itemIdLabel={intl.formatMessage({id: "product.id"})}
                                        itemIdValue={item.itemId}
                                    />
                                    <RaisedButton
                                        onClick={() => onDeleteGroup(item.key)}
                                        label={""}
                                        primary={true}
                                        icon={<FontIcon className="material-icons">delete</FontIcon>}
                                        style={style.raisedButton}
                                    />
                                </Field>
                            </Group>
                        )
                    })
                }
            </Group>
        )
    }
}

const Group = styled.div`
    border: 1px dashed #29363d;
    margin-top: 10px;
    padding: 10px;
`;

const Field = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

ProductRefsGroup.propTypes = {
    mainLabel: PropTypes.string,
    primaryButtonLabel: PropTypes.string,
    suppliersList: PropTypes.array,
    items: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.any,
        itemId: PropTypes.string,
        supplierId: PropTypes.string
    })),
    onAddGroup: PropTypes.func,
    onDeleteGroup: PropTypes.func,
    onItemChange: PropTypes.func,
};

export default injectIntl(ProductRefsGroup);