import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import _ from "lodash";
import moment from "moment";
import Truncate from "react-truncate";
import Popup from "reactjs-popup";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { SingleDatePicker } from "react-dates";
import { HoverText, HoverImage, ImageComponent, ImageContainer } from "../../components/Form/main/styles";

export const ORDER_LIST_ITEM_CONTEXT_TYPES = {
  CATALOG: "catalog",
  ORDER_SUMMARY: "orderSummary",
  NOT_FOUND: "notFound",
  NOT_FOUND_CATALOG: "notFoundCatalog",
  MODAL: "modal",
  MODAL_ADD: "modalAdd",
  MODAL_EDIT: "modalEdit",
  READ_ONLY: "read_only",
  REPLACE_WIDGET: "replace",
  PO: "po",
  PO_SUMMARY: "poSummary",
  PRICE_LIST: "priceList",
  PRODUCT_FORM: "productForm",
};

import FontIcon from "material-ui/FontIcon";
import IconButton from "material-ui/IconButton";
import ConfirmButton from "../../components/ConfirmButton";
import {
  PRODUCT_ACTION_TYPES,
  ACCEPTANCE_STATUSES,
  RENTAL_INTERVALS,
} from "shared/src/constants/orders";

import { fonts, colors } from "../../styles/styleguide";

import CountPicker from "../../components/Form/CountPicker";
import IconInput, { IconWrapper } from "../../components/Form/IconInput";
import { ProductAction } from "../../models/order";
import { ORDER_MANAGEMENT_CONTEXT } from "shared/src/constants/orders";
import { getLatestActionString } from "shared/src/selectors/orders";
import { InputGroup, InputLabel } from "../../styles/stylesheet";
import { addNotFoundProduct } from "shared/src/modules/orders";

const IMAGE_WRAPPER_WIDTH = 142;
const INNER_ROW_MARGIN = 20;
const POPOVER_WIDTH = 399;

class CatalogItem extends Component {
  constructor() {
    super();
    this.state = {
      isOpenItemActions: false,
      currentAction: {
        type: null,
        value: null,
      },
      countAction: null,
      discountAction: null,
      selectedRoom: null,
      spacialPriceAction: null,
      wrapperWidth: undefined,
      wrapperHeight: undefined,
      isOpenDeliveryDate: false,
      afterDeliveryDateSelection: null,
      deliveryDate: new Date(),
      focused: false,
      noteValue: null,
      selectedRentInterval: 0,
    };

    this.wrapper = React.createRef();
  }

  componentDidMount() {
    window.addEventListener("resize", this.getWrapperDimensions);
    this.getWrapperDimensions();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.wrapperWidth &&
      this.state.wrapperWidth !== prevState.wrapperWidth
    ) {
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.getWrapperDimensions);
  }

  getWrapperDimensions = () => {
    const boundingClientRect = this.wrapper.current.getBoundingClientRect();
    this.setState({
      wrapperWidth: boundingClientRect.width,
      wrapperHeight: boundingClientRect.height,
    });
  };

  openDeliveryDateSelection = (after) => {
    this.setState({
      isOpenDeliveryDate: true,
      afterDeliveryDateSelection: after,
    });
  };

  closeDeliveryDateSelection = () => {
    this.setState({
      isOpenDeliveryDate: false,
      afterDeliveryDateSelection: null,
    });
  };

  submitAction = () => {
    const {
      currentAction,
      countAction,
      discountAction,
      spacialPriceAction,
      selectedRoom,
      noteAction,
      selectedRentInterval,
    } = this.state;

    const {
      setProductDiscount,
      setProductSpacialPrice,
      setProductRoom,
      context,
      formContext,
      room,
      count,
      // onAddItem,
      onAddPOItem,
      onAddOrderItem,
      addNotFoundProduct,
      item,
      setProductNote,
      setRentInterval,
    } = this.props;

    let isRentalProduct = item ? item.isRentalProduct : false;

    let rentInterval = null;

    if (isRentalProduct) {
      rentInterval = selectedRentInterval || item.userSelectedRentInterval || 0;
    }

    // console.log("formContext", formContext)
    // console.log("context", context)
    // console.log("onAddPOItem", !!onAddPOItem)
    // console.log("onAddOrderItem", !!onAddOrderItem)
    // console.log("addNotFoundProduct", !!addNotFoundProduct)

    switch (formContext) {
      case ORDER_MANAGEMENT_CONTEXT.CONTRACTOR: {
        if (context === ORDER_LIST_ITEM_CONTEXT_TYPES.ORDER_SUMMARY) {
          if (countAction) {
            this.onQuantityChange(countAction);
          }
          if (selectedRoom) {
            setProductRoom(selectedRoom);
          }
        } else if (
          context === ORDER_LIST_ITEM_CONTEXT_TYPES.CATALOG ||
          context === ORDER_LIST_ITEM_CONTEXT_TYPES.NOT_FOUND_CATALOG
        ) {
          let theRoom = selectedRoom || room || null;
          let theCount = countAction || count || 1;
          //console.log("adding item", item, theCount, theRoom);


          if (!!addNotFoundProduct) {
            addNotFoundProduct(theRoom, theCount, noteAction)
          } else {
            onAddOrderItem(theRoom, theCount, null, noteAction || null, rentInterval)
          }

          // onAddItem(theRoom, theCount, rentInterval);
        }
        break;
      }
      case ORDER_LIST_ITEM_CONTEXT_TYPES.PO: {
        let theCount = 1;
        if (countAction) {
          theCount = countAction;
        }
        if (count) {
          theCount = count;
        }
        let specialPrice = null;
        let discount = null;
        let note = null;

        if (spacialPriceAction) {
          if (spacialPriceAction === "clear") {
            specialPrice = null;
          } else {
            specialPrice = spacialPriceAction;
          }
        }

        if (noteAction) {
          if (noteAction === "clear") {
            note = null;
          } else {
            note = noteAction;
          }
        }

        if (discountAction) {
          if (discountAction === "clear") {
            discount = null;
          } else {
            discount = discountAction;
          }
        }

        // console.log("discount", discount)
        // console.log("specialPrice", specialPrice)
        // console.log("note", note)
        // console.log("theCount", theCount)

        // onAddItem(theCount, discount, specialPrice, note, rentInterval);
        onAddPOItem(theCount, rentInterval)
        // onAddItem(theCount, rentInterval);
        break;
      }
      default: {
        if (context === ORDER_LIST_ITEM_CONTEXT_TYPES.CATALOG) {
          const productAction = ProductAction({
            type: PRODUCT_ACTION_TYPES.ADDED_BY_SUPPLIER,
            data: null,
          });
          // let theRoom = selectedRoom || room;
          let theCount = countAction || count || 1;

          let note = null;
          if (noteAction) {
            if (noteAction === "clear") {
              note = null;
            } else {
              note = noteAction;
            }
          }

          // console.log("theCount", theCount)
          // console.log("productAction", productAction)
          // console.log("note", note)
          // console.log("rentInterval", rentInterval)

          onAddOrderItem(null, theCount, productAction, note, rentInterval)

          // onAddItem(theRoom, theCount, productAction, note, rentInterval);
        } else {
          console.log("countAction", countAction)
          console.log("currentAction", currentAction)

          if (countAction) {
            let extraAction = null;
            if (currentAction.type) {
              switch (currentAction.type) {
                case PRODUCT_ACTION_TYPES.DELAY:
                  extraAction = PRODUCT_ACTION_TYPES.DELAY;
                  break;
                case PRODUCT_ACTION_TYPES.CANCELED:
                  extraAction = PRODUCT_ACTION_TYPES.CANCELED;
                  break;
              }
            }
            if (extraAction === PRODUCT_ACTION_TYPES.DELAY) {
              this.openDeliveryDateSelection((deliveryDate) => {
                this.onQuantityChange(countAction, extraAction, deliveryDate);
                // this.onDelay(deliveryDate);
              });
            } else {
              this.onQuantityChange(countAction, extraAction);
              // this.onCancel();
            }
          } else {
            switch (currentAction.type) {
              case PRODUCT_ACTION_TYPES.DELAY:
                this.openDeliveryDateSelection((deliveryDate) => {
                  this.onDelay(deliveryDate);
                });
                break;
              case PRODUCT_ACTION_TYPES.CANCELED:
                this.onCancel();
                break;
              case PRODUCT_ACTION_TYPES.REPLACE:
                this.onReplace();
                break;
            }
          }

          if (spacialPriceAction) {
            if (spacialPriceAction === "clear") {
              setProductSpacialPrice(null);
            } else {
              setProductSpacialPrice(spacialPriceAction);
            }
          }

          if (discountAction) {
            if (discountAction === "clear") {
              setProductDiscount(null);
            } else {
              setProductDiscount(discountAction);
            }
          }

          if (noteAction) {
            if (noteAction === "clear") {
              setProductNote(null);
            } else {
              setProductNote(noteAction);
            }
          }

          if (
            !isNaN(selectedRentInterval) &&
            selectedRentInterval !== null &&
            selectedRentInterval !== 0
          ) {
            setRentInterval(rentInterval);
          }
        }
      }
    }
    this.closeItemActions();
  };

  closeItemActions = () => {
    this.setState({
      isOpenItemActions: false,
      currentAction: {
        type: null,
        value: null,
      },
      countAction: null,
      discountAction: null,
    });
  };

  onQuantityChange = (quantity, extraAction, deliveryDate) => {
    const {
      setProductAction,
      item,
      setProductQuantity,
      formContext,
      itemIndex,
    } = this.props;

    setProductQuantity(quantity);

    if (formContext === ORDER_MANAGEMENT_CONTEXT.SUPPLIER) {
      const productAction = ProductAction({
        type: PRODUCT_ACTION_TYPES.QUANTITY_CHANGE,
        data: {
          original: item.count,
          quantity,
          extraAction: extraAction || null,
          id: item.id,
          deliveryDate: deliveryDate || null,
          index: itemIndex,
        },
      });
      setProductAction(productAction);
    }

    this.closeItemActions();
  };

  onDelay = (deliveryDate) => {
    console.log("onDelay")

    const { setProductAction, item, itemIndex } = this.props;

    console.log("item", item)

    const productAction = ProductAction({
      type: PRODUCT_ACTION_TYPES.DELAY,
      data: {
        id: item.id,
        deliveryDate: deliveryDate || null,
        quantity: item.count,
        index: itemIndex,
      },
    });

    console.log("productAction", productAction)

    setProductAction(productAction);

    this.closeItemActions();
  };

  onCancel = () => {
    const { setProductAction, item } = this.props;

    // if item is added by supplier delete it, else set it as an action
    //   console.log("on cancel", item, item.actions)
    if (item.actions && item.actions.length > 0) {
      const i = _.findIndex(item.actions, {
        type: PRODUCT_ACTION_TYPES.ADDED_BY_SUPPLIER,
      });
      if (i !== -1) {
        this.props.onRemove();
        this.closeItemActions();
        return;
      }
    }

    const productAction = ProductAction({
      type: PRODUCT_ACTION_TYPES.CANCELED,
      data: { id: item.id },
    });

    console.log("productAction", productAction)

    setProductAction(productAction);

    this.closeItemActions();
  };

  onReplace = () => {
    this.props.onReplace();
    this.closeItemActions();
  };

  renderModalItem = () => {
    const { imgSrc, catalogId, price, name, hidePrices } = this.props;

    return (
      <div
        ref={this.wrapper}
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div style={{ marginTop: 15 }}>
          <img style={{ maxWidth: 150, maxHeight: 100 }} src={imgSrc} />
        </div>
        <div style={{ marginTop: 15 }}>
          <Name style={{ fontSize: 18 }}>{name}</Name>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-evenly",
            marginTop: 15,
          }}
        >
          <Detail style={{ fontSize: 14 }}>{`מק"ט: ${catalogId}`}</Detail>
          {price && !hidePrices ? <Price>&#8362;{price}</Price> : null}
        </div>
      </div>
    );
  };

  renderForCatalog = () => {
    const {
      imgSrc,
      catalogId,
      price,
      name,
      item,
      packageInfo,
    } = this.props;

    const { countAction } = this.state;
    // let truncateWidth = this.getTruncateWidth();

    return (
      <CatalogItemComponent
        wrapperRef={this.wrapper}
        imgSrc={imgSrc}
        catalogId={catalogId}
        price={price}
        count={countAction}
        onCountChange={(value) => {
          this.setState({ countAction: value });

          if (Number(value) > Number(countAction)) {
            this.setState({
              currentAction: {
                type: null,
                value: null,
              },
            });
          } else if (Number(value) < Number(countAction)) {
            //let newValue = count - value;
            if (!this.state.currentAction.type) {
              this.setState({
                currentAction: {
                  type: PRODUCT_ACTION_TYPES.CANCELED,
                  value: null,
                },
              });
            } else {
              this.setState({
                currentAction: {
                  type: this.state.currentAction.type,
                  value: null,
                },
              });
            }
          }
        }}
        isRent={item.isRentalProduct}
        packageInfo={packageInfo}
        name={name}
        onAddItem={this.submitAction}
      />
    );
  };

  renderPackageInfo = () => {
    const { packageInfo } = this.props;
    let truncateWidth = this.getTruncateWidth();

    return packageInfo ? (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <TextMd style={{ marginRight: 5, marginLeft: 5 }}>מארז</TextMd>
        <Popup
          trigger={
            <TextLgBold>
              <Truncate
                width={truncateWidth ? truncateWidth - 61 - 23 : 0}
                trimWhitespace={true}
                ellipsis={<span>...</span>}
              >
                {packageInfo}
              </Truncate>
            </TextLgBold>
          }
          on="hover"
          position="top"
          closeOnDocumentClick
        >
          <span>{packageInfo}</span>
        </Popup>
      </div>
    ) : null;
  };

  renderNotFoundItem = () => {
    const {
      imgSrc,
      count,
      room,
      name,
      note,
      disabled,
      onReplace,
      onRemove,
      formContext,
    } = this.props;

    let truncateWidth = 0;
    const { wrapperWidth } = this.state;
    if (wrapperWidth) {
      truncateWidth = wrapperWidth - INNER_ROW_MARGIN - IMAGE_WRAPPER_WIDTH;
    }

    return (
      <ItemWrapper ref={this.wrapper}>
        <ImageWrapper>
          <Image style={{ maxWidth: 100, maxHeight: 100 }} src={imgSrc} />
        </ImageWrapper>
        <div style={{ marginRight: INNER_ROW_MARGIN }}>
          <Popup
            trigger={
              <Name style={{ height: 46 }}>
                <Truncate
                  lines={2}
                  width={truncateWidth}
                  trimWhitespace={true}
                  ellipsis={<span>...</span>}
                >
                  {"שם: ".concat(name)}
                </Truncate>
              </Name>
            }
            on="hover"
            position="top"
            closeOnDocumentClick
          >
            <span>{name}</span>
          </Popup>
          {!!note && (
              <div
                  style={{
                    display: "flex",
                    height: 46,
                  }}
              >
                <TextMd>{"הערה: ".concat(note)}</TextMd>
              </div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <TextLgBold>{count}</TextLgBold>
            <TextMd style={{ marginLeft: 5 }}>יחידות</TextMd>
          </div>
        </div>
        {
          onRemove && (
              formContext === ORDER_MANAGEMENT_CONTEXT.SUPPLIER
                  ? (
                    <ActionButtonComponent style={{ top: 0 }} onClick={onRemove}>
                      <img src={"img/icons/trash-10-24.png"} />
                    </ActionButtonComponent>
                  ) : (
                    <ActionButton background={"#d62c36"} onClick={onRemove}>
                      <img src={"img/icons/trash.png"} />
                    </ActionButton>
                  )
          )
        }
        {!disabled && (
          <ActionButton onClick={onReplace}>
            <img src={"img/icons/add_yellow.svg"} />
          </ActionButton>
        )}
      </ItemWrapper>
    );
  };

  renderNotFoundItemForCatalog = () => {
    const {
      imgSrc,
      name,
    } = this.props;

    const { isOpenItemActions } = this.state;

    let truncateWidth = 0;
    const { wrapperWidth } = this.state;
    if (wrapperWidth) {
      truncateWidth = wrapperWidth - INNER_ROW_MARGIN - IMAGE_WRAPPER_WIDTH;
    }

    return (
      <ItemWrapper ref={this.wrapper}>
        <ImageWrapper>
          <Image style={{ maxWidth: 100, maxHeight: 100 }} src={imgSrc} />
        </ImageWrapper>
        <div style={{ marginRight: INNER_ROW_MARGIN }}>
          <Popup
            trigger={
              <Name style={{ height: 46 }}>
                <Truncate
                  lines={2}
                  width={truncateWidth}
                  trimWhitespace={true}
                  ellipsis={<span>...</span>}
                >
                  {name}
                </Truncate>
              </Name>
            }
            on="hover"
            position="top"
            closeOnDocumentClick
          >
            <span>{name}</span>
          </Popup>
        </div>
        <ActionButton
          onClick={(e) => {
            //console.log("Open Popover")
            this.setState({ isOpenItemActions: true });
          }}
        >
          <img src={"img/icons/add_yellow.svg"} />
        </ActionButton>
        {isOpenItemActions && this.renderContractorPopover()}
      </ItemWrapper>
    );
  };

  renderContractorPopover = () => {
    const {
      imgSrc,
      catalogId,
      count,
      price,
      room,
      rooms,
      name,
      context,
      onRemove,
      negativeCount,
      item,
    } = this.props;

    const { countAction, selectedRoom, noteAction } = this.state;
    const noteValue = noteAction
      ? noteAction !== "clear"
        ? noteAction
        : ""
      : item
      ? item.note
      : "";

    const MARGIN = 10;
    const PADDING = 10;
    const nameWidth =
      POPOVER_WIDTH - IMAGE_WRAPPER_WIDTH - (2 * MARGIN + 2 * PADDING);

    return (
      <ActionsPopover>
        <ActionsPopoverRow>
          <ImageWrapper>
            <Image style={{ maxWidth: 100, maxHeight: 100 }} src={imgSrc} />
          </ImageWrapper>
          <Popup
            trigger={
              <Name>
                <Truncate
                  lines={4}
                  width={nameWidth}
                  trimWhitespace={true}
                  ellipsis={<span>...</span>}
                >
                  {`${name} ${noteValue ? noteValue : ""}`}
                </Truncate>
              </Name>
            }
            on="hover"
            position="top"
            closeOnDocumentClick
          >
            <span>{`${name} ${noteValue ? noteValue : ""}`}</span>
          </Popup>
        </ActionsPopoverRow>
        {room && rooms && rooms.length > 0 && (
          <ActionsPopoverRow>
            <TextMd>חדר</TextMd>
            <IconInput
              type={"select"}
              onChange={(value) => {
                this.setState({
                  selectedRoom: _.find(rooms, { id: value }),
                });
              }}
              value={selectedRoom ? selectedRoom.id : room ? room.id : null}
              options={rooms}
              valueAccessor={"id"}
              labelAccessor={"name"}
              icon={<img src={"img/icons/home.svg"} width={27} />}
            />
          </ActionsPopoverRow>
        )}
        <ActionsPopoverRow>
          <TextMd>שינוי כמות</TextMd>
          <CountPicker
            width={160}
            value={countAction || count}
            negativeCount={negativeCount}
            onValueChange={(value) => {
              this.setState({ countAction: value });
            }}
          />
        </ActionsPopoverRow>
        {this.renderRentalIntervalInput()}
        {this.renderNoteInput()}
        <ActionButton
          onClick={(e) => {
            this.closeItemActions();
          }}
          style={{ left: 65, zIndex: 4 }}
        >
          <img src={"img/icons/close.svg"} />
        </ActionButton>
        {context === ORDER_LIST_ITEM_CONTEXT_TYPES.ORDER_SUMMARY && (
          <ActionButton
            background={"#d62c36"}
            onClick={onRemove}
            style={{ right: "calc( 50% - 27px )", zIndex: 4 }}
          >
            <img src={"img/icons/trash.png"} />
          </ActionButton>
        )}
        <ActionButton
          background={"#fecd08"}
          onClick={(e) => {
            this.submitAction();
          }}
          style={{ right: 65, zIndex: 4 }}
        >
          <img src={"img/icons/v_transparent.svg"} />
        </ActionButton>
      </ActionsPopover>
    );
  };

  isDelay = (currentAction) => {
    return (
      currentAction.type === PRODUCT_ACTION_TYPES.DELAY ||
      (currentAction.type === PRODUCT_ACTION_TYPES.QUANTITY_CHANGE &&
        currentAction.data.extraAction === PRODUCT_ACTION_TYPES.DELAY)
    );
  };

  renderActionsPopover = (context) => {
    const {
      count,
      item,
      imgSrc,
      name,
      onRemove,
      disableCount,
      itemActions,
      formContext,
      negativeCount,
    } = this.props;

    const {
      currentAction,
      countAction,
      discountAction,
      spacialPriceAction,
      noteAction,
    } = this.state;

    let shouldDisableReplace = false;
    if (
      (spacialPriceAction && spacialPriceAction != item.spacialPrice) ||
      (countAction && countAction != count) ||
      (discountAction && discountAction != item.discount)
    ) {
      shouldDisableReplace = true;
    }

    const noteValue = noteAction
      ? noteAction !== "clear"
        ? noteAction
        : ""
      : item.note;
    const spacielPriceCurrentValue = spacialPriceAction
      ? spacialPriceAction !== "clear"
        ? spacialPriceAction
        : ""
      : item.spacialPrice;
    const discountCurrentValue = discountAction
      ? discountAction !== "clear"
        ? discountAction
        : ""
      : item.discount;

    let quantityActionValue = null;
    let delta = null;

    if (countAction) {
      quantityActionValue = countAction;
      delta = count - quantityActionValue;
    } else if (
      currentAction.type &&
      currentAction.type === PRODUCT_ACTION_TYPES.QUANTITY_CHANGE
    ) {
      quantityActionValue = currentAction.data.quantity;
      delta = currentAction.data.original - quantityActionValue;
    } else if (
      itemActions &&
      itemActions.length > 0 &&
      itemActions[0].type === PRODUCT_ACTION_TYPES.QUANTITY_CHANGE
    ) {
      quantityActionValue = itemActions[0].data.quantity;
      delta = itemActions[0].data.original - quantityActionValue;
    }

    const countPickerValue =
      !isNaN(countAction) && countAction !== null ? countAction : count;
    const MARGIN = 10;
    const PADDING = 10;
    const nameWidth =
      POPOVER_WIDTH - IMAGE_WRAPPER_WIDTH - (2 * MARGIN + 2 * PADDING);

    return (
      <ActionsPopover>
        <ActionsPopoverRow>
          <ImageWrapper>
            <Image style={{ maxWidth: 100, maxHeight: 100 }} src={imgSrc} />
          </ImageWrapper>
          <Popup
            trigger={
              <Name>
                <Truncate
                  lines={4}
                  width={nameWidth}
                  trimWhitespace={true}
                  ellipsis={<span>...</span>}
                >
                  {`${name} ${noteValue ? noteValue : ""}`}
                </Truncate>
              </Name>
            }
            on="hover"
            position="top"
            closeOnDocumentClick
          >
            <span>{`${name} ${noteValue ? noteValue : ""}`}</span>
          </Popup>
        </ActionsPopoverRow>
        <ActionsPopoverRow>
          <TextMd>מחיר מיוחד</TextMd>
          <IconInput
            onChange={(value) => {
              this.setState({
                spacialPriceAction: value,
                discountAction: null,
                currentAction: {
                  type: null,
                  value: null,
                },
              });
            }}
            value={spacielPriceCurrentValue}
            icon={<img src={"img/price.png"} width={27} />}
            clearValueHandler={
              spacielPriceCurrentValue
                ? () => {
                    this.setState({
                      spacialPriceAction: "clear",
                    });
                  }
                : null
            }
          />
        </ActionsPopoverRow>

        {!disableCount && (
          <ActionsPopoverRow>
            <TextMd>שינוי כמות</TextMd>
            <CountPicker
              width={160}
              negativeCount={negativeCount}
              value={countPickerValue}
              onValueChange={(value) => {
                this.setState({ countAction: value });
                if (Number(value) > Number(count)) {
                  this.setState({
                    currentAction: {
                      type: null,
                      value: null,
                    },
                  });
                } else if (Number(value) < Number(count)) {
                  //let newValue = count - value;
                  if (!this.state.currentAction.type) {
                    this.setState({
                      currentAction: {
                        type: PRODUCT_ACTION_TYPES.CANCELED,
                        value: null,
                      },
                    });
                  } else {
                    this.setState({
                      currentAction: {
                        type: this.state.currentAction.type,
                        value: null,
                      },
                    });
                  }
                }
              }}
            />
          </ActionsPopoverRow>
        )}

        {![
          ORDER_LIST_ITEM_CONTEXT_TYPES.PO,
          ORDER_LIST_ITEM_CONTEXT_TYPES.PO_SUMMARY,
        ].includes(context) && (
          <ActionsPopoverRow style={{ backgroundColor: "#ebebeb" }}>
            <ActionTextButton
              disabled={discountAction || countAction > count}
              onClick={() => {
                currentAction.value = null;
                currentAction.type = PRODUCT_ACTION_TYPES.DELAY;
                this.setState({ currentAction });
              }}
              style={
                this.isDelay(currentAction) ? { fontWeight: "bold" } : null
              }
            >
              דחיית מוצר {delta > 0 ? `(${delta})` : ""}
            </ActionTextButton>
            <TextMd>|</TextMd>
            <ActionTextButton
              disabled={discountAction || countAction > count}
              onClick={() => {
                currentAction.value = null;
                currentAction.type = PRODUCT_ACTION_TYPES.CANCELED;
                this.setState({ currentAction });
              }}
              style={
                currentAction.type === PRODUCT_ACTION_TYPES.CANCELED
                  ? { fontWeight: "bold" }
                  : null
              }
            >
              ביטול {delta > 0 ? `(${delta})` : ""}
            </ActionTextButton>
            <TextMd>|</TextMd>
            <ActionTextButton
              disabled={shouldDisableReplace}
              onClick={() => {
                currentAction.value = null;
                currentAction.type = "replace";
                this.setState({ currentAction });
              }}
              style={
                currentAction.type === PRODUCT_ACTION_TYPES.REPLACE
                  ? { fontWeight: "bold" }
                  : null
              }
            >
              החלפה
            </ActionTextButton>
          </ActionsPopoverRow>
        )}

        {this.renderRentalIntervalInput()}
        {this.renderNoteInput()}

        <ActionButton
          onClick={(e) => {
            this.closeItemActions();
          }}
          style={{ left: 65, zIndex: 4 }}
        >
          <img src={"img/icons/close.svg"} />
        </ActionButton>
        {context === ORDER_LIST_ITEM_CONTEXT_TYPES.PO_SUMMARY && (
          <ActionButton
            background={"#d62c36"}
            onClick={onRemove}
            style={{ right: "calc( 50% - 27px )", zIndex: 4 }}
          >
            <img src={"img/icons/trash.png"} />
          </ActionButton>
        )}
        <ActionButton
          background={"#fecd08"}
          onClick={(e) => {
            this.submitAction();
          }}
          style={{ right: 65, zIndex: 4 }}
        >
          <img src={"img/icons/v_transparent.svg"} />
        </ActionButton>
      </ActionsPopover>
    );
  };

  renderRentalIntervalInput = () => {
    const { item } = this.props;
    if (!item) {
      return null;
    }

    const { isRentalProduct, userSelectedRentInterval } = item;

    const { selectedRentInterval } = this.state;

    if (!isRentalProduct) {
      return null;
    }

    return (
      <ActionsPopoverRow>
        <TextMd>השכרה לפרק זמן</TextMd>
        <IconInput
          type={"select"}
          onChange={(value) => {
            this.setState({
              selectedRentInterval: Number(value),
            });
          }}
          value={
            selectedRentInterval
              ? selectedRentInterval
              : userSelectedRentInterval
              ? userSelectedRentInterval
              : 0
          }
          options={RENTAL_INTERVALS.map((interval, index) => {
            return { id: index, name: interval };
          })}
          valueAccessor={"id"}
          labelAccessor={"name"}
        />
      </ActionsPopoverRow>
    );
  };

  renderNoteInput = () => {
    const { formContext, context, item } = this.props;

    if (!item) {
      return null;
    }

    const { noteAction } = this.state;

    const noteValue = noteAction
      ? noteAction !== "clear"
        ? noteAction
        : ""
      : item.note;

    if (
      !(
        [
          ORDER_MANAGEMENT_CONTEXT.SUPPLIER,
          ORDER_LIST_ITEM_CONTEXT_TYPES.PO,
          ORDER_LIST_ITEM_CONTEXT_TYPES.PO_SUMMARY,
        ].includes(formContext) ||
        [
          ORDER_MANAGEMENT_CONTEXT.SUPPLIER,
          ORDER_LIST_ITEM_CONTEXT_TYPES.PO,
          ORDER_LIST_ITEM_CONTEXT_TYPES.PO_SUMMARY,
        ].includes(context)
      )
    ) {
      return null;
    }

    return (
      <ActionsPopoverRow>
        <TextMd>הערה</TextMd>
        <IconInput
          onChange={(value) => {
            this.setState({
              noteAction: value,
            });
          }}
          value={noteValue}
          icon={<img src={"img/icons/edit.svg"} width={27} />}
          clearValueHandler={
            noteValue
              ? () => {
                  this.setState({
                    noteAction: "clear",
                  });
                }
              : null
          }
        />
      </ActionsPopoverRow>
    );
  };

  renderSummaryItem = () => {
    const {
      imgSrc,
      catalogId,
      count,
      price,
      name,
      context,
      formContext,
      itemActions,
      disabled,
      itemAcceptance,
      item,
      isPreview,
    } = this.props;

    const {
      isOpenDeliveryDate,
      afterDeliveryDateSelection,
      deliveryDate,
      countAction,
      noteAction,
    } = this.state;

    const { isOpenItemActions } = this.state;
    const itemCount = count;

    // let totalPrice = Number(price) * Number(itemCount);
    // if (item.spacialPrice) {
    //   totalPrice = Number(item.spacialPrice) * Number(itemCount);
    // } else if (item.discount && !isNaN(item.discount) && item.discount > 0) {
    //   totalPrice = totalPrice - totalPrice * (item.discount / 100);
    // }
    //
    // totalPrice = Math.round(totalPrice * 100) / 100;
    // let truncateWidth = this.getTruncateWidth();

    const {
      str,
      latestAction,
      latestActionType,
      showUndoAction,
    } = this.getItemActionString(
      itemActions,
      itemAcceptance,
      itemCount,
      formContext
    );

    const { onUndoAction } = this.props;

    return (
      <div>
        <SummaryItemComponent
          wrapperRef={this.wrapper}
          imgSrc={imgSrc}
          catalogId={catalogId}
          name={name}
          // packageInfo={packageInfo}
          price={item.discount || price}
          count={countAction || count}
          note={!!noteAction && noteAction !== "clear" ? noteAction : item.note}
          onActionClick={(e) => {
            this.setState({ isOpenItemActions: true });
          }}
          isOpenItemActions={isOpenItemActions && !showUndoAction}
          renderActionsPopover={
            formContext === ORDER_MANAGEMENT_CONTEXT.CONTRACTOR
              ? this.renderContractorPopover
              : this.renderActionsPopover
          }
          disableButton={disabled}
          context={context}
          isRent={item.isRentalProduct}
          showUndoAction={showUndoAction}
          onUndoAction={onUndoAction}
          isChangedBySupplier={!!latestActionType}
          actionMessage={str}
          isPreview={isPreview}
        />

        {
          <Modal
            isOpen={isOpenDeliveryDate}
            toggle={() => {
              this.closeDeliveryDateSelection();
            }}
          >
            <ModalHeader>אנא בחרו תאריך לדחייה</ModalHeader>
            <ModalBody>
              <InputGroup>
                <InputLabel>תאריך אספקה</InputLabel>
                <div style={{ position: "relative" }}>
                  <IconWrapper style={{ top: 0, right: 8 }}>
                    <img src={"img/icons/date.svg"} />
                  </IconWrapper>
                  <SingleDatePicker
                    date={moment(deliveryDate)} // momentPropTypes.momentObj or null
                    onDateChange={(date) =>
                      this.setState({ deliveryDate: moment(date).toDate() })
                    } // PropTypes.func.isRequired
                    focused={this.state.focused} // PropTypes.bool
                    onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
                    id="deliveryDate" // PropTypes.string.isRequired,
                    isRTL={true}
                    disableScroll={true}
                    small={true}
                    displayFormat={"D/MM/YY"}
                  />
                </div>
              </InputGroup>
            </ModalBody>
            <ModalFooter>
              <ConfirmButton
                label={"אישור"}
                onClick={() => {
                  afterDeliveryDateSelection(deliveryDate);
                  this.closeDeliveryDateSelection();
                }}
              />
            </ModalFooter>
          </Modal>
        }
      </div>
    );
  };

  rendePriceListItem = () => {
    const { imgSrc, catalogId, price, name } = this.props;

    let truncateWidth = this.getTruncateWidth();

    return (
      <ItemWrapper ref={this.wrapper}>
        <ImageWrapper>
          <Image style={{ maxWidth: 100, maxHeight: 100 }} src={imgSrc} />
        </ImageWrapper>
        <div style={{ marginRight: INNER_ROW_MARGIN }}>
          <Popup
            trigger={
              <Name style={{ height: 46 }}>
                <Truncate
                  lines={2}
                  width={truncateWidth}
                  trimWhitespace={true}
                  ellipsis={<span>...</span>}
                >
                  {name}
                </Truncate>
              </Name>
            }
            on="hover"
            position="top"
            closeOnDocumentClick
          >
            <span>{name}</span>
          </Popup>

          <div style={{ display: "flex", flexDirection: "row" }}>
            <CatalogIdLabel style={{ marginLeft: 5 }}>מק"ט</CatalogIdLabel>
            <Popup
              trigger={
                <TextMd>
                  <Truncate
                    width={truncateWidth ? truncateWidth - 61 - 23 : 0}
                    trimWhitespace={true}
                    ellipsis={<span>...</span>}
                  >
                    {catalogId}
                  </Truncate>
                </TextMd>
              }
              on="hover"
              position="top"
              closeOnDocumentClick
            >
              <span>{catalogId}</span>
            </Popup>
          </div>

          {this.renderPackageInfo()}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <TextMd>מחיר</TextMd>
            <TextLgBold style={{ marginRight: 5 }}>{price}</TextLgBold>
            <TextMd>&#8362;</TextMd>
          </div>
        </div>
      </ItemWrapper>
    );
  };

  renderReplaceWigtetItem = () => {
    const {
      imgSrc,
      catalogId,
      count,
      price,
      name,
      setProductQuantity,
      onRemoveItem,
    } = this.props;

    return (
      <ItemWrapper ref={this.wrapper} style={{ border: "1px solid #13ba90" }}>
        <ImageWrapper>
          <Image style={{ maxWidth: 100, maxHeight: 100 }} src={imgSrc} />
        </ImageWrapper>
        <div style={{ marginRight: INNER_ROW_MARGIN }}>
          <Name>{name}</Name>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <CatalogIdLabel style={{ marginRight: 5 }}>מק"ט</CatalogIdLabel>
            <TextMd style={{ marginRight: 5 }}>{catalogId}</TextMd>
          </div>
          {this.renderPackageInfo()}
          {price ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <TextMd>מחיר</TextMd>
              <TextLgBold style={{ marginRight: 5 }}>
                {Math.round(Number(price) * Number(count) * 100) / 100}
              </TextLgBold>
              <TextMd>&#8362;</TextMd>
            </div>
          ) : null}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <CountPicker
              width={140}
              value={count}
              onValueChange={setProductQuantity}
            />
          </div>
        </div>
        <ActionButton
          onClick={onRemoveItem}
          style={{ backgroundColor: "#13ba90" }}
        >
          <img src={"img/icons/minus_green.svg"} />
        </ActionButton>

        <img
          style={{ position: "absolute", left: 25, top: 25 }}
          src={"img/icons/v_green.svg"}
        />
      </ItemWrapper>
    );
  };

  renderForPOCatalog = () => {
    const {
      imgSrc,
      catalogId,
      price,
      name,
      packageInfo,
      item,
    } = this.props;

    const { countAction } = this.state;

    // let truncateWidth = this.getTruncateWidth();

    return (
      <CatalogItemComponent
        wrapperRef={this.wrapper}
        imgSrc={imgSrc}
        catalogId={catalogId}
        price={price}
        count={countAction}
        onCountChange={(value) => {
          this.setState({ countAction: value });

          if (Number(value) > Number(count)) {
            this.setState({
              currentAction: {
                type: null,
                value: null,
              },
            });
          } else if (Number(value) < Number(count)) {
            //let newValue = count - value;
            if (!this.state.currentAction.type) {
              this.setState({
                currentAction: {
                  type: PRODUCT_ACTION_TYPES.CANCELED,
                  value: null,
                },
              });
            } else {
              this.setState({
                currentAction: {
                  type: this.state.currentAction.type,
                  value: null,
                },
              });
            }
          }
        }}
        isRent={item.isRentalProduct}
        packageInfo={packageInfo}
        name={name}
        onAddItem={this.submitAction}
      />
    );
  };

  renderForPOSummay = () => {
    const {
      imgSrc,
      catalogId,
      count,
      price,
      // room,
      name,
      // packageInfo,
      context,
      formContext,
      // showAdd,
      itemActions,
      // disabled,
      itemAcceptance,
      item,
      // setProductQuantity,
      // hidePrices,
      // hideDiscounts,
      // disableCount,
    } = this.props;

    const { isOpenItemActions, noteAction } = this.state;

    let totalPrice = Number(price) * Number(count);
    if (item.spacialPrice) {
      totalPrice = Number(item.spacialPrice) * Number(count);
    } else if (item.discount && !isNaN(item.discount) && item.discount > 0) {
      totalPrice = totalPrice - totalPrice * (item.discount / 100);
    }

    // totalPrice = Math.round(totalPrice * 100) / 100;
    // let truncateWidth = this.getTruncateWidth();
    //
    // const {
    //   str,
    //   latestAction,
    //   latestActionType,
    //   showUndoAction,
    // } = this.getItemActionString(
    //   itemActions,
    //   itemAcceptance,
    //   count,
    //   formContext
    // );

    return (
      <SummaryItemComponent
        wrapperRef={this.wrapper}
        imgSrc={imgSrc}
        catalogId={catalogId}
        name={name}
        price={item.spacialPrice || price}
        count={count}
        note={!!noteAction && noteAction !== "clear" ? noteAction : item.note}
        onActionClick={(e) => {
          this.setState({ isOpenItemActions: true });
        }}
        isOpenItemActions={isOpenItemActions}
        renderActionsPopover={this.renderActionsPopover}
        context={context}
        isRent={item.isRentalProduct}
      />
    );
  };

  getTruncateWidth = () => {
    let truncateWidth = 0;
    const { wrapperWidth } = this.state;
    if (wrapperWidth) {
      truncateWidth = wrapperWidth - INNER_ROW_MARGIN - IMAGE_WRAPPER_WIDTH;
    }
    return truncateWidth;
  };

  getItemActionString = (
    itemActions,
    itemAcceptance,
    itemCount,
    formContext
  ) => {
    // console.log("getItemActionString")
    // console.log("itemActions", itemActions)

    const latestAction =
      itemActions && itemActions.length
        ? itemActions[itemActions.length - 1]
        : null;

    let str = "";
    if (itemAcceptance && itemAcceptance.status) {

      switch (itemAcceptance.status) {
        case ACCEPTANCE_STATUSES.rejected:
          str = "לא סופק";
          break;
        case ACCEPTANCE_STATUSES.returned:
          str = "החזרה";
          break;
        case ACCEPTANCE_STATUSES.approved:
          str = "סופק";
          if (itemCount !== itemAcceptance.count) {
            str =
              str +
              "\n" +
              "שינוי כמות" +
              "\n" +
              ` ${itemCount} -> ${itemAcceptance.count}`;
          }
          break;
        default:
          return null;
      }

      if (latestAction) {
        str += "\n" + getLatestActionString(latestAction, itemActions);
      }
    } else {
      if (latestAction) {
        str = getLatestActionString(latestAction, itemActions);
      }
    }

    const latestActionType = latestAction ? latestAction.type : null;

    const showUndoAction =
      !!latestActionType &&
      formContext === ORDER_MANAGEMENT_CONTEXT.SUPPLIER &&
      // latestActionType === PRODUCT_ACTION_TYPES.DELAY ||
      !(
        latestActionType === PRODUCT_ACTION_TYPES.ADDED_BY_SUPPLIER ||
        latestActionType === PRODUCT_ACTION_TYPES.DELAY ||
        latestActionType === PRODUCT_ACTION_TYPES.MERGED_FROM_ORDER ||
        (latestActionType === PRODUCT_ACTION_TYPES.QUANTITY_CHANGE &&
          latestAction.data.extraAction === PRODUCT_ACTION_TYPES.DELAY) ||
        latestActionType === PRODUCT_ACTION_TYPES.ADD_ON_ITEM
      ) &&
      !(itemAcceptance && itemAcceptance.status);
    return {
      str,
      latestAction,
      latestActionType,
      showUndoAction,
    };
  };

  renderItemAction = (itemActions, itemAcceptance, itemCount, formContext) => {
    if ((!itemActions || !itemActions.length) && !itemAcceptance) {
      return null;
    }

    const { onUndoAction } = this.props;

    const {
      str,
      latestAction,
      latestActionType,
      showUndoAction,
    } = this.getItemActionString(
      itemActions,
      itemAcceptance,
      itemCount,
      formContext
    );

    return (
      <ItemActionContainer>
        {showUndoAction && (
          <ItemActionUndo>
            <IconButton
              tooltip={"ביטול פעולה אחרונה"}
              onClick={() => {
                onUndoAction();
              }}
            >
              <FontIcon className="material-icons" color={"#d8953b"}>
                undo
              </FontIcon>
            </IconButton>
          </ItemActionUndo>
        )}
        <div style={{ position: "absolute", left: 0, right: 0, bottom: 0 }}>
          {latestAction &&
          ![
            PRODUCT_ACTION_TYPES.ADD_ON_ITEM,
            PRODUCT_ACTION_TYPES.MERGED_FROM_ORDER,
          ].includes(latestActionType) ? (
            <div style={{ position: "absolute", right: 0, bottom: 18 }}>
              <img src={"img/icons/supplierChangeBadge.png"} />
            </div>
          ) : null}
          {str ? (
            <div
              style={{
                position: "absolute",
                left: 0,
                right: 0,
                top: -10,
                padding: 10,
                backgroundColor: colors.charcoal,
                borderBottomLeftRadius: 21,
                borderBottomRightRadius: 21,
                display: "flex",
                flexDirection: "row",
                fontFamily: fonts.OpenSansHebrew,
                fontSize: 14,
                color: "#fff",
                whiteSpace: "pre",
              }}
            >
              {str}
            </div>
          ) : null}
        </div>
      </ItemActionContainer>
    );
  };

  renderForProductForm = () => {
    const { imgSrc, catalogId, name, onDelete } = this.props;

    let truncateWidth = 0;
    const { wrapperWidth } = this.state;
    if (wrapperWidth) {
      truncateWidth = wrapperWidth - INNER_ROW_MARGIN - IMAGE_WRAPPER_WIDTH;
    }

    return (
      <ItemWrapper ref={this.wrapper} style={{ paddingLeft: 15 }}>
        <ImageWrapper>
          <Image style={{ maxWidth: 100, maxHeight: 100 }} src={imgSrc} />
        </ImageWrapper>
        <div style={{ marginRight: INNER_ROW_MARGIN }}>
          <Popup
            trigger={
              <Name style={{ height: 46 }}>
                <Truncate
                  lines={2}
                  width={truncateWidth}
                  trimWhitespace={true}
                  ellipsis={<span>...</span>}
                >
                  {name}
                </Truncate>
              </Name>
            }
            on="hover"
            position="top"
            closeOnDocumentClick
          >
            <span>{name}</span>
          </Popup>
          {catalogId ? (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <CatalogIdLabel style={{ marginLeft: 5 }}>מק"ט</CatalogIdLabel>
              <Popup
                trigger={
                  <TextMd>
                    <Truncate
                      width={truncateWidth ? truncateWidth - 61 - 23 : 0}
                      trimWhitespace={true}
                      ellipsis={<span>...</span>}
                    >
                      {catalogId}
                    </Truncate>
                  </TextMd>
                }
                on="hover"
                position="top"
                closeOnDocumentClick
              >
                <span>{catalogId}</span>
              </Popup>
            </div>
          ) : null}
        </div>
        {onDelete ? (
          <div
            style={{
              position: "absolute",
              top: 15,
              left: 15,
              cursor: "pointer",
              zIndex: 5,
            }}
            onClick={onDelete}
          >
            <img src={"img/icons/delete-outline.svg"} />
          </div>
        ) : null}
      </ItemWrapper>
    );
  };

  render() {
    const { context } = this.props;

    switch (context) {
      case ORDER_LIST_ITEM_CONTEXT_TYPES.CATALOG:
        return this.renderForCatalog();
      case ORDER_LIST_ITEM_CONTEXT_TYPES.MODAL:
        return this.renderModalItem();
      case ORDER_LIST_ITEM_CONTEXT_TYPES.ORDER_SUMMARY:
        return this.renderSummaryItem();
      case ORDER_LIST_ITEM_CONTEXT_TYPES.REPLACE_WIDGET:
        return this.renderReplaceWigtetItem();
      case ORDER_LIST_ITEM_CONTEXT_TYPES.NOT_FOUND:
        return this.renderNotFoundItem();
      case ORDER_LIST_ITEM_CONTEXT_TYPES.NOT_FOUND_CATALOG:
        return this.renderNotFoundItemForCatalog();
      case ORDER_LIST_ITEM_CONTEXT_TYPES.PO:
        return this.renderForPOCatalog();
      case ORDER_LIST_ITEM_CONTEXT_TYPES.PO_SUMMARY:
        return this.renderForPOSummay();
      case ORDER_LIST_ITEM_CONTEXT_TYPES.PRICE_LIST:
        return this.rendePriceListItem();
      case ORDER_LIST_ITEM_CONTEXT_TYPES.PRODUCT_FORM:
        return this.renderForProductForm();
      default:
        break;
    }

    const { imgSrc, catalogId, count, price, name, hidePrices } = this.props;

    let truncateWidth = 0;
    const { wrapperWidth } = this.state;
    if (wrapperWidth) {
      truncateWidth = wrapperWidth - INNER_ROW_MARGIN - IMAGE_WRAPPER_WIDTH;
    }

    return (
      <ItemWrapper ref={this.wrapper} style={{ paddingLeft: 15 }}>
        <ImageWrapper>
          <Image style={{ maxWidth: 100, maxHeight: 100 }} src={imgSrc} />
        </ImageWrapper>
        <div style={{ marginRight: INNER_ROW_MARGIN }}>
          <Popup
            trigger={
              <Name style={{ height: 46 }}>
                <Truncate
                  lines={2}
                  width={truncateWidth}
                  trimWhitespace={true}
                  ellipsis={<span>...</span>}
                >
                  {name}
                </Truncate>
              </Name>
            }
            on="hover"
            position="top"
            closeOnDocumentClick
          >
            <span>{name}</span>
          </Popup>
          {catalogId ? (
            <div style={{ display: "flex", flexDirection: "row" }}>
              <CatalogIdLabel style={{ marginLeft: 5 }}>מק"ט</CatalogIdLabel>
              <Popup
                trigger={
                  <TextMd>
                    <Truncate
                      width={truncateWidth ? truncateWidth - 61 - 23 : 0}
                      trimWhitespace={true}
                      ellipsis={<span>...</span>}
                    >
                      {catalogId}
                    </Truncate>
                  </TextMd>
                }
                on="hover"
                position="top"
                closeOnDocumentClick
              >
                <span>{catalogId}</span>
              </Popup>
            </div>
          ) : null}
          {price && !hidePrices ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <TextMd>מחיר</TextMd>
              <TextLgBold style={{ marginRight: 5 }}>
                {Math.round(Number(price) * Number(count) * 100) / 100}
              </TextLgBold>
              <TextMd>&#8362;</TextMd>
            </div>
          ) : null}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <TextLgBold style={{ marginLeft: 5 }}>{count}</TextLgBold>
            <TextMd>יחידות</TextMd>
          </div>
        </div>
      </ItemWrapper>
    );
  }
}

const SUMMARY_ITEM_HEIGHT = 60;

const SummaryItemComponent = ({
  wrapperRef,
  imgSrc,
  catalogId,
  name,
  price,
  count,
  note,
  onActionClick,
  isOpenItemActions,
  renderActionsPopover,
  disableButton,
  context,
  isRent,
  showUndoAction = false,
  onUndoAction,
  isChangedBySupplier,
  actionMessage,
  isPreview,
}) => (
  <ItemContainer
    ref={wrapperRef}
    style={{
      height: SUMMARY_ITEM_HEIGHT * (1 + (isChangedBySupplier ? .5 : 0) + (!!note ? .5 : 0)) + "px",
      margin: "5px",
      // width: "100%",
      // flexGrow: 1,
    }}
  >
    <Popup
      trigger={
        <ImageContainer height={SUMMARY_ITEM_HEIGHT}>
          <ImageComponent src={imgSrc} height={SUMMARY_ITEM_HEIGHT} />

          {(isRent || isChangedBySupplier) && (
            <div
              style={{
                position: "absolute",
                zIndex: 1,
                marginTop: SUMMARY_ITEM_HEIGHT * 1.2 + "px",
              }}
            >
              {isChangedBySupplier ? (
                <SummaryBadgeComponent src={"img/icons/supplierChangeBadge.png"} />
              ) : (
                <SummaryBadgeComponent src={"img/icons/rentBadge.png"} />
              )}
            </div>
          )}
        </ImageContainer>
      }
      on="hover"
      position="top"
      closeOnDocumentClick
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <HoverImage src={imgSrc} height={SUMMARY_ITEM_HEIGHT} />
      </div>
    </Popup>

    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        justifyContent: "center",
        paddingRight: "12px",
        paddingLeft: "12px",
      }}
    >
      <SummaryItemRowContainer>
        <NameView name={name} isCatalog={isPreview} />
        <div style={{margin: "4px"}}/>
        <SeconderyText style={isPreview ? { width: 100 } : { maxWidth: 100 }}>{catalogId}</SeconderyText>
      </SummaryItemRowContainer>


      {!!note && <SummaryRowSeparator />}
      {!!note && (
          <SummaryItemRowContainer style={{ justifyContent: "flex-start" }}>
            <BoldSeconderyText>{"הערה:"}</BoldSeconderyText>
            <div style={{ margin: "4px" }}/>
            <NameView name={note} isCatalog={isPreview} />
          </SummaryItemRowContainer>
      )}

      <SummaryRowSeparator />

      <SummaryItemRowContainer>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "37.5%",
            justifyContent: "flex-start",
          }}
        >
          <BoldSeconderyText>
            <span dir={"ltr"}>{price}</span>
            <span>{"₪"}</span>
          </BoldSeconderyText>
          <div style={{ margin: "4px" }} />
          <SeconderyText>{"ליחידה"}</SeconderyText>
        </div>

        <SeconderyText>{"|"}</SeconderyText>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "25%",
            justifyContent: "center",
          }}
        >
          <BoldSeconderyText>
            <span dir={"ltr"}>{count}</span>
          </BoldSeconderyText>
          <div style={{ margin: "4px" }} />
          <SeconderyText>{"פריטים"}</SeconderyText>
        </div>

        <SeconderyText>{"|"}</SeconderyText>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "37.5%",
            justifyContent: "flex-end",
          }}
        >
          <SeconderyText>{'סה"כ'}</SeconderyText>
          <div style={{ margin: "4px" }} />
          <BoldSeconderyText>
            <span dir={"ltr"}>{Number(price * count).toFixed(2)}</span>
            <span>{"₪"}</span>
          </BoldSeconderyText>
        </div>
      </SummaryItemRowContainer>

      {isChangedBySupplier && <SummaryRowSeparator />}
      {isChangedBySupplier && (
        <SummaryItemRowContainer
          style={{ justifyContent: "center", backgroundColor: "#efefef" }}
        >
          <ChangedBySupplierMessage>{actionMessage}</ChangedBySupplierMessage>
        </SummaryItemRowContainer>
      )}
    </div>

    {!disableButton && (
        showUndoAction ? (
            <NewActionButton onClick={onUndoAction} height={SUMMARY_ITEM_HEIGHT}>
              <FontIcon className="material-icons" color={"#d8953b"}>
                undo
              </FontIcon>
            </NewActionButton>
        ) : (
            <NewActionButton onClick={onActionClick} height={SUMMARY_ITEM_HEIGHT}>
              <img src={"img/icons/edit_yellow.svg"} />
            </NewActionButton>
        )
    )}

    {!disableButton && isOpenItemActions && (
      <div style={{ position: "absolute", left: "100px" }}>
        {renderActionsPopover(context)}
      </div>
    )}
  </ItemContainer>
);

const SUMMARY_ROW_WIDTH = "100%";

const SummaryItemRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  min-height: ${SUMMARY_ITEM_HEIGHT / 2 - 0.5}px;
  max-width: ${SUMMARY_ROW_WIDTH}px;
  justify-content: space-between;
  align-items: center;
`;

const CATALOG_ITEM_HEIGHT = 48;
const ICON_HEIGHT = 18;

export const ImageView = ({ src, isRent = false }) => (
    <Popup
        trigger={
          <ImageContainer height={CATALOG_ITEM_HEIGHT}>
            <ImageComponent src={src} height={CATALOG_ITEM_HEIGHT} />

            {isRent && (
                <div
                    style={{
                      position: "absolute",
                      zIndex: 1,
                    }}
                >
                  <CatalogBadgeComponent src={"img/icons/rentBadge.png"} />
                </div>
            )}
          </ImageContainer>
        }
        on="hover"
        position="top"
        closeOnDocumentClick
    >
      <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
      >
        <HoverImage src={src} height={SUMMARY_ITEM_HEIGHT} />
      </div>
    </Popup>
)

const CatalogItemComponent = ({
  wrapperRef,
  imgSrc,
  catalogId,
  count,
  price,
  onCountChange,
  name,
  packageInfo,
  onAddItem,
  isRent,
}) => (
  <ItemContainer
    ref={wrapperRef}
    style={{ height: CATALOG_ITEM_HEIGHT + "px" }}
  >
    <ImageView src={imgSrc} isRent={isRent}/>

    <SeconderyText style={{ width: 100 }}>{catalogId}</SeconderyText>

    <NameView name={name} isCatalog={true} />

    <div
      style={{
        display: "flex",
        width: "40px",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <PackageView packageInfo={packageInfo} />
    </div>

    <div style={{ cursor: "pointer" }}>
      <CountPicker
        width={100}
        value={count}
        negativeCount={true}
        onValueChange={onCountChange}
      />
    </div>

    <div style={{ display: "flex", flexDirection: "row", width: 80 }}>
      <BoldSeconderyText>{price}</BoldSeconderyText>
      <SeconderyText>{"₪"}</SeconderyText>
    </div>

    <NewActionButton
      onClick={(e) => {
        onAddItem(count || 1);
      }}
      height={CATALOG_ITEM_HEIGHT}
    >
      <img
        style={{ width: 32, height: 32, objectFit: "contain" }}
        src={"img/icons/add_yellow.svg"}
      />
    </NewActionButton>
  </ItemContainer>
);

export const NameView = ({ name, isCatalog }) => (
  <Popup
    trigger={
      <MainText style={isCatalog ? { width: MAIN_TEXT_WIDTH } : { maxWidth: MAIN_TEXT_WIDTH }}>
        <Truncate
          lines={1}
          width={MAIN_TEXT_WIDTH}
          trimWhitespace={true}
          ellipsis={<span>...</span>}
        >
          {name}
        </Truncate>
      </MainText>
    }
    on="hover"
    position="top"
    closeOnDocumentClick
  >
    <HoverText>{name}</HoverText>
  </Popup>
);

const PackageView = ({ packageInfo }) => (
  <div style={{ height: ICON_HEIGHT, width: ICON_HEIGHT }}>
    {packageInfo && (
      <Popup
        trigger={
          <ImageComponent
            src={"img/icons/info_black.svg"}
            height={ICON_HEIGHT}
          />
        }
        on="hover"
        position="top"
        closeOnDocumentClick
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <HoverText>{packageInfo}</HoverText>
        </div>
      </Popup>
    )}
  </div>
);

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  box-shadow: -6.9px 4px 4px 0 rgba(213, 213, 213, 0.23);
  border: solid 1px rgba(209, 209, 209, 0.45);
  background-color: #ffffff;
`;

const IconContainer = styled.div`
  display: flex;
  width: ${(props) => props.height}px;
  height: ${(props) => props.height}px;
  justify-content: center;
  align-items: center;
`;

const CatalogBadgeComponent = styled.img`
  height: ${CATALOG_ITEM_HEIGHT * 0.8}px;
`;

const SummaryBadgeComponent = styled.img`
  height: ${SUMMARY_ITEM_HEIGHT * 0.8}px;
`;

const IMAGE_HEIGHT = 40;

const SummaryRowSeparator = styled.div`
  height: 1px;
  background-color: #eeeeee
`;

const ChangedBySupplierMessage = styled.div`
  font-family: OpenSansHebrew;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 1.2px;
  white-space: nowrap;
  color: ${colors.charcoal};
`;

const MAIN_TEXT_WIDTH = 340;

const MainText = styled.div`
  font-family: OpenSansHebrew;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 1.2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${colors.charcoal};
`;

const BoldSeconderyText = ({ children }) => (
  <SeconderyText style={{ fontWeight: "bold" }}>{children}</SeconderyText>
);

export const SeconderyText = styled.div`
  font-family: OpenSansHebrew;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 1px;
  text-align: center;
  color: ${colors.charcoal};
`;

const NewActionButton = styled.div`
  height: ${(props) => props.height * 0.85}px;
  width: ${(props) => props.height * 0.85}px;
  background-color: ${(props) =>
    props.background ? props.background : colors.charcoal};
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ItemWrapper = styled.div`
  border-radius: 15px;
  box-shadow: -16.5px 9.5px 40px 0 rgba(205, 205, 205, 0.25);
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  position: relative;
  padding-top: 15px;
  padding-bottom: 15px;
`;

const ImageWrapper = styled.div`
  width: ${IMAGE_WRAPPER_WIDTH}px;
  height: 123px;
  background-color: #ececec;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  max-width: 100px;
  max-height: 100px;
`;

const Name = styled.div`
  font-family: ${fonts.OpenSansHebrew};
  font-size: 16px;
  color: ${colors.charcoal};
`;

const TextLgBold = styled(Name)`
  font-weight: bold;
`;

const CatalogIdLabel = styled.div`
  font-family: ${fonts.OpenSansHebrew};
  font-size: 14px;
  color: #a2a4a4;
`;

const TextMd = styled.div`
  font-family: ${fonts.OpenSansHebrew};
  font-size: 16px;
  color: ${colors.charcoal};
`;

const ActionTextButton = styled.button`
  border: 0;
  outline: 0;
  background: none;
  font-family: ${fonts.OpenSansHebrew};
  font-size: 18px;
  color: ${(props) => (props.disabled ? colors.greyish : colors.charcoal)};
  &:focus {
    outline: none;
  }
`;

const ActionButtonComponent = styled.div`
  height: 55px;
  width: 55px;
  position: absolute;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 16px;
`

const ActionButton = styled(ActionButtonComponent)`
  background-color: ${(props) =>
    props.background ? props.background : colors.charcoal};
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  bottom: 0;
`;

// const ActionButton = styled.div`
//   height: 55px;
//   width: 55px;
//   background-color: ${(props) =>
//     props.background ? props.background : colors.charcoal};
//   border-top-right-radius: 50%;
//   border-top-left-radius: 50%;
//   position: absolute;
//   bottom: 0;
//   left: 16px;
//   cursor: pointer;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;

const ActionsPopover = styled.div`
  position: absolute;
  bottom: 0;
  z-index: 2;
  left: -50px;
  width: ${POPOVER_WIDTH}px;
  border-radius: 10px;
  box-shadow: -16.5px 9.5px 40px 0 rgba(205, 205, 205, 0.61);
  background-color: #ffffff;
  border: 1px solid ${colors.charcoal};
  padding-bottom: 65px;
  white-space: nowrap;
`;

const ActionsPopoverRow = styled.div`
  margin: 10px;
  padding: 10px;
  border-bottom: 1px solid ${colors.charcoal};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const Detail = styled.div`
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  text-align: right;
  color: #bdbdbd;
`;

const Price = styled.div`
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  text-align: right;
  color: #ff9f45;
`;

const ItemActionContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const ItemActionUndo = styled.div`
  position: absolute;
  top: 0;
  left: 25px;
`;

CatalogItem.propTypes = {
  imgSrc: PropTypes.string,
  catalogId: PropTypes.string,
  count: PropTypes.string,
  price: PropTypes.number,
  // room: PropTypes.string,
  // rooms: PropTypes.array,
  name: PropTypes.string,
  context: PropTypes.oneOf(Object.values(ORDER_LIST_ITEM_CONTEXT_TYPES)),
  // onAddItem: PropTypes.func,
  onUndoAction: PropTypes.func,
  onRemoveItem: PropTypes.func,
  itemActions: PropTypes.array,
  itemAcceptance: PropTypes.any,
  showAdd: PropTypes.bool,
  isInOrder: PropTypes.object,
  disabled: PropTypes.bool,
  setProductAction: PropTypes.func,
  setProductQuantity: PropTypes.func,
  setProductRoom: PropTypes.func,
  setProductDiscount: PropTypes.func,
  setProductSpacialPrice: PropTypes.func,
  onRemove: PropTypes.func,
  hidePrices: PropTypes.bool,
  hideCount: PropTypes.bool,
  hideDiscounts: PropTypes.bool,
  disableCount: PropTypes.bool,
  negativeCount: PropTypes.bool,
  packageInfo: PropTypes.string,
};

CatalogItem.defaultProps = {
  hideDiscounts: true,
  disableCount: false,
  negativeCount: false,
  hideCount: false,
};

export default CatalogItem;
