import React from 'react';
import styled from 'styled-components';

export default ({text, maxWidth}) => {
    const Ellipsis = styled.div`
          width: ${maxWidth}px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
    `;

    return (<Ellipsis>{text}</Ellipsis>)
}