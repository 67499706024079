import React, {Component} from 'react';
import PropTypes from 'prop-types'
import styled from 'styled-components';
import SupplierSelect from "./FirebaseObjectListSelect";
import TextFieldWithButton from "./TextFieldWithButton";
import FontIcon from 'material-ui/FontIcon';
import IconButton from 'material-ui/IconButton';
import Loader from "../Loader";

import {noProductThumb} from "shared/src/constants/config";

class ProductSearch extends Component{

    constructor() {
        super();
        this.state = {
            supplier: null,
        }
    }

    componentDidMount(){
        if(this.props.singleSupplier){

        }
    }

    render() {

        const {supplier} = this.state;
        const {suppliers, onSearch, onMakatSearch, results, onClear, onResultSelection, isLoading, singleSupplier} = this.props;
        console.log("isloading related",isLoading, singleSupplier);
        return (
            <div style={{position: "relative"}}>
                <SearchWrapper>
                    {singleSupplier ? (null) : (
                        <SupplierSelect
                            onChange={(supplier)=>{this.setState({supplier})}}
                            value={supplier}
                            list={suppliers}
                            iconStyle={{color: "#20A8D8"}}
                            labelStyle={{color: "#707070", fontSize: 16}}
                            underlineStyle={{borderColor: "#20A8D8"}}
                            style={{width: "100%"}}
                        />
                    )}

                    <TextFieldWithButton
                        hintText={"חיפוש בקטלוג לפי שם"}
                        buttonClickHandler={(value)=>{onSearch({query: value, supplierId: singleSupplier || supplier})}}
                        buttonRenderer={ (onClick)=>
                            (<IconButton onClick={onClick}>
                                <FontIcon className="material-icons">search</FontIcon>
                            </IconButton>)
                        }
                        clearValueOnSubmit={false}
                        underlineStyle={{borderColor: "#20A8D8"}}
                    />

                    {
                        <TextFieldWithButton
                            hintText={'חיפוש בקטלוג לפי מק"ט'}
                            buttonClickHandler={(value)=>{onMakatSearch({query: value, supplierId: singleSupplier || supplier})}}
                            buttonRenderer={ (onClick)=>
                                (<IconButton onClick={onClick}>
                                    <FontIcon className="material-icons">search</FontIcon>
                                </IconButton>)
                            }
                            clearValueOnSubmit={false}
                            underlineStyle={{borderColor: "#20A8D8"}}
                        />
                    }
                </SearchWrapper>


                {results.length > 0 && <Overlay onClick={()=>{ onClear()}}/>}
                {results.length > 0 && (
                        <ResultsWrapper>
                            {
                                results.map((result, i)=> (
                                    <Product
                                        key={`product_result_${i}`}
                                        img={result.images && result.images.length ? result.images[0].downloadURL : noProductThumb}
                                        label={result.name}
                                        catalogId={result.catalogId}
                                        onClick={()=>{
                                            onResultSelection(result, result.supplier)
                                            onClear()
                                        }}
                                    />
                                ))
                            }
                        </ResultsWrapper>
                )}
                {isLoading && (
                    <ResultsWrapper>
                        <LoaderWrapper>
                            <Loader/>
                        </LoaderWrapper>
                    </ResultsWrapper>
                )}

            </div>
        )
    }
}

export const Product = ({img, label, catalogId, onClick, supplier}) =>{
    return(
        <StyledProduct onClick={onClick}>
            <div className={"imgWrapper"}>
                <img src={img}/>
            </div>
            <div className={"textWrapper"}>
                <div className={"label"}>{label}</div>
                <div className={"catId"}>{catalogId}</div>
                {supplier && <div className={"supplier"}>{supplier}</div>}
            </div>
        </StyledProduct>
    )
};

const SearchWrapper = styled.div`
    
`;

const ResultsWrapper = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    bottom: -250px;
    height: 250px;
    overflow-y: auto;
    z-index: 10000;
`;

const Overlay = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
`

const LoaderWrapper = styled.div`
    background: #fff;
`;

const StyledProduct = styled.div`
    padding: 2px 20px;
    background: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    .imgWrapper{
        display: flex;
        flex-direction: column;
        width: 82px;
        height: 82px;
        img{
            width: 100%;
            height: auto;
        }
    }
    .textWrapper{
        display: flex;
        flex-direction: column;
        padding-right: 9px;
        .label{
            display: flex;
            flex-direction: row;
            color: #404040;
            font-size: 18px;
        }
        .catId{
            display: flex;
            flex-direction: row;
            color: #707070;
            font-size: 14px;
            font-weight: 200;
        }
        .supplier{
            display: flex;
            flex-direction: row;
            color: #707070;
            font-size: 12px;
        }
    }
    &:hover{
        background: #F6F5F5;
    }
`;

ProductSearch.propTypes = {
    suppliers: PropTypes.array,
    singleSupplier: PropTypes.object,
    onSearch: PropTypes.func,
    onMakatSearch: PropTypes.func,
    results: PropTypes.array,
    onClear: PropTypes.func,
    onResultSelection: PropTypes.func,
    isLoading: PropTypes.bool
};

export default ProductSearch;