import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types'
//import {List, ListItem} from 'material-ui/List';
import {Card, CardActions, CardHeader, CardMedia, CardTitle, CardText} from 'material-ui/Card';
import {injectIntl, FormattedMessage} from 'react-intl';

import Elipsis from "../../components/Elipsis"
import {noProductThumb} from "shared/src/constants/config";

class CatalogListItem extends React.Component {

    constructor() {
        super()
        this.renderGroup = this.renderGroup.bind(this);
    }

    renderGroup(groupName, group, isImages){
        if(group.length){
            return(
                <Group>
                    <h4>{groupName}</h4>
                    {group.map((item)=>{
                        if(item.isGroup) {
                            return this.renderGroup(item.name, item.value || []);
                        } else {
                            return (
                                <div>
                                    <ItemLabel>{isImages ? item.label : item.name} </ItemLabel>
                                    <span>{isImages ? <img src={item.path}/> : item.value}</span>
                                </div>
                            )
                        }

                    })}
                </Group>
            )
        }
    }

    render() {
        const {item, onItemEdit, onItemDelete, intl, showAdminButtons, onDuplicateItem} = this.props;
   //     item.images && item.images.length > 0 ? console.log("images", item.images) : null;
        return (
           // <ListItem style={{padding: 0}}>
                <Card initiallyExpanded={false} style={{padding: "0 !important"}}>
                    <CardHeader showExpandableButton={true} style={{padding: 0}}>
                        <Item>
                            <ItemSpecifier style={{width: 100}}><Elipsis text={item.catalogId} maxWidth={80}/></ItemSpecifier>
                            <ItemSpecifier style={{width: 45}}><img src={item.images && item.images.length > 0 ? item.images[0].downloadURL : noProductThumb}/></ItemSpecifier>
                            <ItemSpecifier style={{width: 80}}>{item.default_price}₪</ItemSpecifier>
                            <ItemSpecifier style={{width: 300}}>{item.name}</ItemSpecifier>
                            {showAdminButtons && <ItemAction onClick={() => onItemEdit(item)}><i className={"fa fa-edit"}/></ItemAction>}
                            {showAdminButtons && <ItemAction onClick={() => onDuplicateItem(item)}><i className={"fa fa-copy"}/></ItemAction>}
                            {/*showAdminButtons && <ItemAction onClick={() => onItemDelete(item)}><i className={"fa fa-remove"}/></ItemAction>*/}

                        </Item>
                    </CardHeader>
                    <CardText expandable={true} style={{padding: 0}}>
                        <RTL>
                            <div><ItemLabel><FormattedMessage id="product.id"/></ItemLabel> : {item.catalogId}</div>
                            <div><ItemLabel><FormattedMessage id="product.name"/></ItemLabel> : {item.name}</div>
                            <div><ItemLabel><FormattedMessage id="product.price"/></ItemLabel> : {item.default_price}</div>
                            <div><ItemLabel><FormattedMessage id="product.description"/></ItemLabel> : {item.description}</div>
                            {item.longDescription && (
                                <div><ItemLabel><FormattedMessage id="product.longDescription"/></ItemLabel> : {item.longDescription}</div>
                            )}

                            <Images>
                                {item.images ? item.images.map((img) => (<img src={img.downloadURL}/>)) : null}
                            </Images>
                            {item.spec && this.renderGroup("",item.spec)}
                            {item.warnings && this.renderGroup(intl.formatMessage({id: "product.warnings"}),item.warnings)}
                            {item.generalInfo && this.renderGroup(intl.formatMessage({id: "product.generalInfo"}),item.generalInfo)}
                            {item.relatedProducts && this.renderGroup(intl.formatMessage({id: "product.relatedProducts"}),item.relatedProducts)}
                            {item.badges && this.renderGroup(intl.formatMessage({id: "product.badges"}),item.badges, true)}

                        </RTL>
                    </CardText>
                </Card>
           // </ListItem>
        )
    }
}

const Item = styled.div`
    display: flex;
    flex-direction: row;
`;

const ItemSpecifier = styled.div`
    display: flex;
    flex-direction: column;
    img{
        max-height: 40px;
        max-width: 40px;
    }
    margin: 10px;
`;

const ItemAction = styled.div`
    display: flex;
    flex-direction: column;
    cursor: pointer;
    margin: 10px;
`;

const RTL = styled.div`
    direction: rtl;
    text-align: right;
`;

const Images = styled.div`
    display: flex;
    flex-direction: row;
    img{
        display: flex;
        flex-direction: column;
        margin: 10px;
        max-height: 70px;
        max-width: 70px;
        width: auto;
        height: auto;

    }
`;

const Group = styled.div`
    padding: 10px;
  
`;

const ItemLabel = styled.span`
    font-weight: 800;
`;

CatalogListItem.PropTypes = {
    item: PropTypes.object,
    onItemExpand: PropTypes.func,
    onItemEdit: PropTypes.func,
    onItemDelete: PropTypes.func,
    onDuplicateItem: PropTypes.func,
    showAdminButtons: PropTypes.bool
};

CatalogListItem.defaultProps = {
    showAdminButtons: true
}

export default injectIntl(CatalogListItem);
