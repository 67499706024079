import React  from 'react';
import {Col, Form, FormGroup, Label, Input} from 'reactstrap';

const WebSearchInput = ({query, onSubmit, onQueryUpdate, setFocused}) => {
    const handleKeyDown = (event) => {
        const ENTER_KEY = 13;
        if (event.keyCode === ENTER_KEY) {
            event.preventDefault();
            onSubmit();
        }
    };

    return (
        <FormGroup>
            <Col sm={12}>
                <Input type="text"
                       name="search"
                       id="searchInput"
                       placeholder="Search"
                       onKeyDown={handleKeyDown}
                       value={query}
                       onChange={(event, value) => onQueryUpdate(event.target.value)}
                       onFocus={()=>{setFocused(true)}}
                       onBlur={()=>{ console.log("blur"); setTimeout(setFocused(false), 100)}}
                />
            </Col>
        </FormGroup>

    );

};

/*
WebSearchInput.propTypes = {
    query: React.PropTypes.string.isRequired,
    onSubmit: React.PropTypes.func.isRequired,
    onQueryUpdate: React.PropTypes.func.isRequired
};
*/

export default WebSearchInput;