import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types'
import moment from "moment";
import _ from "lodash";
import { fonts, colors } from "../styles/styleguide";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { firebaseConnect, getVal } from "react-redux-firebase";


class ShippingCert extends Component {


    renderItem = (item, index) => {
        let count = item.count;
        return (
            <tr style={{pageBreakInside: "avoid", pageBreakAfter: "auto", borderBottom: `1px solid #e2e1e1`, fontSize: 22}} key={index}>
                <td style={{paddingTop: 5, paddingBottom: 5}}>{item.catalogId}</td>
                <td style={{paddingTop: 5, paddingBottom: 5}}>{ `${item.name} ${item.note ? item.note : ""}`}</td>
                <td dir={"ltr"} style={{paddingTop: 5, paddingBottom: 5, textAlign: "right"}}>{count}</td>
            </tr>
        )
    }

    renderShippingCert = (printLabel, showPreview) => {

        const {
            shippingCert,
            headerMarginTop,
        } = this.props;

        const {
            scId,
            orderId,
            contractorId,
            contractorName,
            contractorAddress,
            projectId,
            supplierId,
            taxId,
            date,
            producedDate,
            items,
            note,
            contractorErpId,
            producedBy,
        } = shippingCert;

        // const showItemNotes = this.showItemNotes();
        const showTaxId = !(taxId && taxId.startsWith("_NO_TAX_ID"));

        return (
            <div dir="rtl"
                 className={showPreview ? "" : "d-none d-print-block"}
                 style={{
                     padding: 50,
                     fontFamily: fonts.OpenSansHebrew,
                     color: colors.black,
                 }}>
                <div style={{display: "flex", flexDirection: "row", alignItems: "flex-end"}}>
                    <div style={{flex: 6}}>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-start"}}>
                            <div style={{fontSize: 26, marginRight: 30}}>
                                <span style={{fontWeight: "bold"}}>{`תעודת משלוח `}</span>
                                <span>{`${scId || "טרם התקבל"}`}</span>
                            </div>
                        </div>
                    </div>
                    <div style={{flex: 2, marginRight: 10, position: "relative"}}>
                        <div style={{
                            fontWeight: "bold",
                            fontSize: 24,
                        }}>
                            {printLabel}
                        </div>
                    </div>
                    <div style={{flex: 1}}></div>
                </div>

                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-end",
                }}>
                    <div style={{
                        flex: 6,
                        backgroundColor: "#f2f1f1",
                        paddingTop: 20,
                        paddingBottom: 20,
                        paddingRight: 30,
                        height: 170,
                        fontSize: 20,
                        position: "relative",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                    }}>
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <div style={{fontWeight: "bold"}}>לכבוד</div>
                            <div>{contractorName}</div>
                        </div>
                        <div style={{fontWeight: "600", fontSize: 20}}>
                            <div style={{display: "flex", flexDirection: "row", alignItems:contractorErpId ? "flex-end" : "flex-start", justifyContent: contractorErpId ? "space-between" : "flex-start"}}>
                                <div> {`ע.מ ${showTaxId ? taxId : ""}`}</div>
                                {contractorErpId && (<div style={{fontSize: 20, paddingLeft: 20}}>{ `(מפתח: ${contractorErpId})`}</div>)}
                            </div>
                        </div>
                        <div>{contractorAddress}</div>
                        <div style={{
                            position: "absolute",
                            width: 10,
                            height: "100%",
                            right: 0,
                            bottom: 0,
                            backgroundColor: colors.yellow
                        }}/>
                    </div>
                    <div style={{
                        flex: 2,
                        marginRight: 10,
                        backgroundColor: "#f2f1f1",
                        paddingTop: 10,
                        paddingBottom: 10,
                        paddingRight: 10,
                        height: 185,
                        fontSize: 20,
                    }}>
                        <div style={{
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-around",
                        }}>
                            {!!producedBy && (
                                <div style={{display: "flex"}}>
                                    <div>{'הונפק ע"י:'}</div>
                                    <div style={{ margin: "4px" }}/>
                                    <div style={{ fontWeight: "bold" }}>{producedBy.displayName}</div>
                                </div>
                            )}
                            <div style={{display: "flex"}}>
                                <div>{"תאריך:"}</div>
                                <div style={{ margin: "4px" }}/>
                                <div style={{ fontWeight: "bold" }}>
                                    {moment(date || producedDate || Date.now()).format("DD.MM.YYYY")}
                                </div>
                            </div>
                            <div style={{display: "flex"}}>
                                <div>{"שעה:"}</div>
                                <div style={{ margin: "4px" }}/>
                                <div style={{fontWeight: "bold"}}>
                                    {moment(date ||producedDate || Date.now()).format("HH:mm")}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{flex: 1, display: "flex", justifyContent: "flex-end"}}>
                        <img style={{height: 100}} src={"img/powerd_by_punct.png"}/>
                    </div>
                </div>

                <div style={{marginTop: 40}}>
                    <table style={{
                        borderCollapse: "collapse",
                        borderBottom: `2px solid ${colors.black}`,
                        width: "100%",
                        pageBreakAfter: "auto"
                    }}>
                        <thead style={{display: "table-header-group"}}>
                        <tr style={{pageBreakInside: "avoid", pageBreakAfter: "auto", fontSize: 24, borderBottom: `2px solid ${colors.black}`}}>
                            <th>מק"ט</th>
                            <th>שם פריט</th>
                            <th>כמות</th>
                        </tr>
                        </thead>
                        <tbody>
                        {items.map((i, index) => this.renderItem(i, index))}
                        </tbody>
                    </table>
                </div>
                <div style={{marginTop: 40, whiteSpace: "pre-wrap", fontSize: 24}}>
                    <div style={{fontWeight: "bold"}}>הערות</div>
                    {note}
                </div>
                <div style={{display: "flex", flexDirection: "row", justifyContent: "space-around", marginTop: 40, fontSize: 24}}>
                    <div>שם המקבל: _____________________</div>
                    <div>חתימה: _____________________</div>
                </div>
                <div style={{pageBreakAfter: "always"}}></div>
            </div>
        )
    }

    render() {

        const {
            headerMarginTop,
        } = this.props;

        const {
            copies
        } = this.props;


        return (
            <Fragment>
                <style dangerouslySetInnerHTML={{__html: `
                    @page{
                        size: A4 portrait;
                        margin-top: ${headerMarginTop}px;
                    }
                `}} />
                {copies.map(((copy, index) => this.renderShippingCert(copy.label, copy.showPreview)))}
            </Fragment>

        );
    }
}

ShippingCert.propTypes = {
    shippingCert: PropTypes.object,
    headerMarginTop: PropTypes.number,
    printLabel: PropTypes.string, // "מקור", "עותק",
    copies: PropTypes.array
};

ShippingCert.defaultProps = {
    copies: [{
        label: "מקור", showPreview: true
    }, {
        label: "עותק 1", showPreview: false
    }, {
        label: "עותק 2", showPreview: false
    }]
}

const mapStateToProps = (state, props) => {
    const supplierId = _.get(props, "shippingCert.supplierId");
    return {
        headerMarginTop:  getVal(state.firebase.data, `suppliersPrivateData/${supplierId}/printMarginTop`, 0),
    }
};

const mapDispatchToProps = dispatch => bindActionCreators({

}, dispatch);

export default compose(
    connect(
        mapStateToProps, mapDispatchToProps
    ),
    firebaseConnect((props) => {
        const supplierId = _.get(props, "shippingCert.supplierId");
        return [`suppliersPrivateData/${supplierId}/printMarginTop`]
    })

)(ShippingCert);



