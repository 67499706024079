import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types'

import styled from 'styled-components';
import { Row, Col } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import SignatureCanvas from 'react-signature-canvas'

class SignatureModal extends Component {

    constructor() {
        super()
        this.state = {
            signatureName: "",
            signatureError: "",
            step: 0,
            change: null
        }

        this.sigCanvas = null;
        this.nameInput = null;

    }

    _handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.onSignatureEnd()
        }
    }

    onSignatureEnd = () => {
        if (this.sigCanvas.isEmpty()) {
            // todo: show error message
            if (this.nameInput) {
                this.nameInput.blur();
            }
            return;
        }

        const signatureImage = this.sigCanvas.toDataURL();
        console.log("signatureImage", signatureImage)

        this.props.onSignatureEnd({name: this.state.signatureName, image: signatureImage}, () => {
            this.sigCanvas && this.sigCanvas.clear()
        });
    }

    nextStep = () => {
        this.setState({step: this.state.step + 1})
    }

    prevStep = () => {
        this.setState({step: this.state.step - 1})
    }

    isValidStep = () => {
        const {step, signatureName} = this.state;
        switch (step){
            case 0:
                if(signatureName){
                    return true;
                }
                return false;
            case 1:
                if(this.sigCanvas){
                    return !this.sigCanvas.isEmpty();
                }
                return false;
            default:
                return false;
        }
    }

    renderStep = () => {
        const {step} = this.state;

        switch (step){
            case 0:
                return (
                    <InputGroup>
                        <InputLabel>שם החותם:</InputLabel>
                        <input
                            ref={ref => this.nameInput = ref}
                            style={{width: "100%", height: 34}}
                            value={this.state.signatureName}
                            onKeyPress={this._handleKeyPress}
                            onChange={(e) => {
                                this.setState({signatureName: e.target.value})
                            }}/>
                        <span style={{color: "#D96364"}}>{this.state.signatureError}</span>
                    </InputGroup>
                );
            case 1:
                return (
                    <Fragment>
                        <SignatureCanvas penColor='green'
                                         canvasProps={{
                                             height: 200,
                                             className: 'sigCanvas',
                                             style: {width: "100%"},
                                             onKeyPress: (e) => {
                                                 this._handleKeyPress(e)
                                             },
                                             tabIndex: "0"
                                         }}
                                         ref={(ref) => {
                                             this.sigCanvas = ref
                                         }}
                                         onEnd={()=>{this.setState({change: (new Date()).getTime()})}}
                        />
                        <SendButton onClick={() => {
                            this.sigCanvas.clear()
                            this.setState({change: (new Date()).getTime()})
                        }}>נקה חתימה</SendButton>

                    </Fragment>
                );
            default:
                return null;
        }
    }


    render() {

        const {isModalOpen, closeHandler} = this.props;
        const {step} = this.state;

        const disabled = !this.isValidStep();

        console.log("render", step, disabled)

        return (
            <Modal isOpen={isModalOpen} size={"lg"}>
                <ModalHeader>חתימה</ModalHeader>
                <ModalBody>
                    {this.renderStep()}
                </ModalBody>
                <ModalFooter>
                    {step === 0 ? (
                        <SendButton disabled={disabled} onClick={()=> !disabled && this.nextStep()}>הבא</SendButton>
                    ) : (
                        <SendButton disabled={disabled} onClick={()=> !disabled && this.onSignatureEnd()}>אישור</SendButton>
                    ) }
                    { step === 1 ? (<SendButton onClick={this.prevStep}>חזור</SendButton>) : null}
                    <SendButton onClick={closeHandler}>ביטול</SendButton>
                </ModalFooter>
            </Modal>
        );
    }
}

SignatureModal.propTypes = {
    onSignatureEnd: PropTypes.func,
    isModalOpen: PropTypes.bool,
    closeHandler: PropTypes.func
};

SignatureModal.defaultProps = {};

const InputLabel = styled.div`
font-family: 'Rubik', sans-serif;
font-size: 14px;
color: #4F4F4F;
margin-bottom: 6px;
`;

const InputGroup = styled.div`
margin-top: 10px;
`;

const SendButton = styled.div`
border-radius: 3px;
font-family: 'Rubik', sans-serif;
font-size: 16px;
text-align: center;
color: #FFFFFF;
height: 50px;
line-height: 50px;
padding: 0 11px;
cursor:  ${props => props.disabled ? "default" : "pointer"};
box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);
background-color: ${props => props.disabled ? "#818181" : "#319CD8"};
`;



export default SignatureModal;