import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import { colors, fonts } from "../../styles/styleguide";

class TogglePicker extends Component {


    render() {
        const {
            options,
            labelAccessor,
            valueAccessor,
            onChange,
            value
        } = this.props
        return (
            <Container>
                {options.map((item, index) => {
                    return (<Item key={item[valueAccessor]} selected={item[valueAccessor] === value} onClick={()=>{onChange(item[valueAccessor])}}>{item[labelAccessor]}</Item>)
                })}
            </Container>
        );
    }
}

const Container = styled.div`
  display: inline-flex;
  flex-direction: row;
  background-color: #f3f3f3;
 border-radius: 25px;
  border: solid 1px rgba(46, 66, 61, 0.34);
  background-color: #f3f3f3;
  height: 50px;
  font-family: ${fonts.OpenSansHebrew};
  font-size: 21px;
  align-items: center;
  padding-right: 3px;
  padding-left: 3px;
`

const Item = styled.div`
cursor: pointer;
background-color: ${props => props.selected ? colors.yellow : "none"};
border-radius: ${props => props.selected ? "21.5px" : 0};
height: 43px;
line-height: 43px;
padding-right: 30px;
padding-left: 30px;
`

TogglePicker.propTypes = {
    options: PropTypes.array,
    labelAccessor: PropTypes.string,
    valueAccessor: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.any
};

TogglePicker.defaultProps = {
    labelAccessor: "label",
    valueAccessor: "value",
}

export default TogglePicker;