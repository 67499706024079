import React, {Component} from 'react';
import PropTypes from 'prop-types'
import styled from 'styled-components';
import {colors, fonts} from "../styles/styleguide";

class AddButton extends Component {
    render() {
        const {label, onClick, backgroundColor} = this.props;
        return (
            <Button backgroundColor={backgroundColor} onClick={onClick}>
                <Img src={"img/icons/add_black.svg"}/>
                <Label>{label}</Label>
            </Button>
        );
    }
}

AddButton.propTypes = {
    label: PropTypes.string,
    onClick: PropTypes.func,
    backgroundColor: PropTypes.string
};

AddButton.defaultProps = {
    backgroundColor: "#eaeaea"
};

const Button = styled.div`
  height: 50px;
  object-fit: contain;
  border-radius: 25px;
 border: solid 2px #464646;
  background-color: ${props => props.backgroundColor};
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-right: 10px;
  padding-left: 10px;
`;

const Img = styled.img`
  width: 29px;
  height: 29px;
  object-fit: contain;
`;

const Label = styled.div`
  font-family: ${fonts.OpenSansHebrew};
  font-size: 21px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 1.1px;
  margin-right: 5px;
`;


export default AddButton;