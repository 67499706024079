import React, { Component, Fragment, useMemo, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import moment from "moment";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import { bindActionCreators, compose } from "redux";
import { firebaseConnect, getVal } from "react-redux-firebase";
import styled from "styled-components";
import memoize from "memoize-one";
import {
	saveOrder,
	sendOrder,
	readyOrder,
	setFieldValue,
	addProduct,
	addNotFoundProduct,
	removeNotFoundProduct,
	removeProduct,
	setProductAction,
	setProductQuantity,
	setProductRoom,
	undoProductAction,
	initOrderById,
	deleteOrder,
	replaceItem,
	acceptOrder,
	produceOrder,
	onOrderNotesUpdated,
	onProductQuantityUpdated,
	lockOrder,
} from "shared/src/modules/orders";
import { fetchItems } from "shared/src/api/orderItems"
import {
	getOrderTotalPrice,
	getItemCount,
	getItemPackage,
} from "shared/src/selectors/orders";
import { mergeOrdersDone, getShippingCert } from "shared/src/api/orders";

import { showOrderFormCatalog } from "shared/src/selectors/orders/view-control-layer";

import {
	getCatalog,
	updateQuery,
	clearCatalog,
	getPopularItems,
	setFocused,
	CATALOG_TYPES,
} from "shared/src/modules/catalog";

import { print } from "../../utils/print";
import { Logger } from "../../store";

import { SingleDatePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import { findDOMNode } from "react-dom";

import ReactTable from "react-table";
import { Row, Col } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import OrderListItem, { ORDER_LIST_ITEM_CONTEXT_TYPES } from "./orderListItem";

import { erpEnabled, extractLatestStatus, mergeOrders } from "shared/src/utils/orders";
import {
	ORDER_STATUSES,
	noProductImageUrl,
	ORDER_MANAGEMENT_CONTEXT,
	comparatorStrings,
} from "shared/src/constants/orders";

import OrderDraftPrint from "./orderDraftPrint";
import ROUTES from "../../constants/routes";
import {
	shouldDisableOrderFormItems,
	shouldEnableSave,
	shouldEnableSelectDriver,
} from "shared/src/selectors/orders/view-control-layer";

import { connectSearchHistory } from "shared/src/modules/autoComplete";
import DriverSelect from "./driverSelect";
// import InnerCatalog from "./innerCatalog";
import Catalog from "../../components/Form/main/Catalog";

import Drawer from "../../components/Drawer";

import { isMobileOrTablet } from "../../utils/UA";

import {
	Card,
	CardTitle,
	InputLabel,
	InputGroup,
	InputError,
} from "../../styles/stylesheet";
import IconInput, { IconWrapper } from "../../components/Form/IconInput";

import ReplaceItemModal from "./replaceItemModal";

import { colors, fonts } from "../../styles/styleguide";
import { PRODUCT_ACTION_TYPES } from "shared/src/constants/orders";
import { ProductAction } from "../../models/order";
import ConfirmButton from "../../components/ConfirmButton";
import RoundButton from "../../components/RoundButton";
import Confirm from "../../components/Confirm";
import ShippingCert from "../../components/ShippingCert";

import DeliveryOptionsModal from "./deliveryOptionsModal";
import LoadingSpinner from "../../components/Loader";
import GenericProductForm from "./GenericProductForm";
import { uuidWithoutSpace } from "shared/src/utils";

import {
	Container,
	ContainerHeader,
	ContainerList,
	SendButton,
	HoverText,
	ImageComponent,
} from "../../components/Form/main/styles";
import Popup from "reactjs-popup";
import ActionButtonsContainer from "../../components/Form/main/ActionButtonsContainer";
import FormContainer from "../../components/Form/main/FormContainer";
import SummaryContainer from "../../components/Form/main/SummaryContainer";

class OrderForm extends Component {
	constructor() {
		super();
		this.state = {
			focused: false,
			returnDateFocused: false,
			isModalOpen: false,
			isReplaceModalOpen: false,
			replaceModalItem: {},
			modalItem: null,
			supplierId: null,
			contractorId: null,
			modalContext: null,
			printModalOpen: false,
			isPrinting: false,
			collapsedCatalog: false,
			fulfillMode: false,
			isOpenMergeModal: false,
			isOpenDrawer: false,
			drawerContext: null,
			mergedOrders: {},
			orderSnapshotBeforeMerge: null,
			isMergeUndoConfimOpen: false,
			isOpenDeliveryOptionsModal: false,
			sendOnSubmitDeliveryOptionsModal: false,
			isOpenShippingCertPreview: false,
			shippingCert: {},
			isPreparingShippingCertPrint: false,
			shippingCertCopies: 2,
			producingSC: false,
			isOpenGenericProductModal: false,
			addReplacementCallback: null,
			isSearching: false,
			// shouldOpen: false,
		};
		this.componentRef = null;
		this.supplierContextInitialized = false;

		this.save = _.debounce(this.save, 1000, { leading: true, trailing: false });
	}

	componentDidMount() {
		const {
			      getPopularItems,
			      order,
			      match: { params },
			      context,
			      connectSearchHistory,
		      } = this.props;
		const { contractorId, projectId, supplierId, orderId } = params;

		if (contractorId && supplierId) {
			getPopularItems({ contractorId, supplierId });
			if (context === ORDER_MANAGEMENT_CONTEXT.CONTRACTOR) {
				connectSearchHistory({ contractorId });
			} else {
				connectSearchHistory({ supplierId });
			}

			this.setState({ contractorId, supplierId });
		}

		if (context === ORDER_MANAGEMENT_CONTEXT.SUPPLIER && order && order.id) {
			this.initSupplierContext(order);
		}
	}

	componentDidUpdate( prevProps, prevState, snapshot ) {

		const {
			      order,
			      ordersLoading,
			      ordersContextLoaded,
			      match: { params },
			      initOrderById,
			      context,
			      getPopularItems,
			      lockOrder,
			      allOrders,
		      } = this.props;
		const { contractorId, projectId, supplierId, orderId } = params;
		if (!prevState.contractorId || !prevState.supplierId) {
			if (contractorId && supplierId) {
				getPopularItems({
					contractorId,
					supplierId,
					catalogName: CATALOG_TYPES.ORDERS,
				});
				this.setState({ contractorId, supplierId });
			}
		}
		if (
			!order.id &&
			(
				ordersLoading !== prevProps.ordersLoading ||
				ordersContextLoaded !== prevProps.ordersContextLoaded
			) &&
			!ordersLoading &&
			ordersContextLoaded
		) {
			initOrderById(orderId);
			this.fetchScreenData({
				contractorId,
				projectId,
				supplierId,
				orderId,
				context,
			});
		}
		if (
			context === ORDER_MANAGEMENT_CONTEXT.SUPPLIER &&
			order &&
			order.id &&
			(
				!_.isEqual(order, prevProps.order) ||
				(
					this.props.contractorErpId &&
					this.props.contractorErpId !== prevProps.contractorErpId
				)
			)
		) {
			this.initSupplierContext(order);
		}
	}

	initSupplierContext = async ( order ) => {
		const { lockOrder, firebase, contractorErpId, profile, roles } = this.props;
		if (order.scId) {
			const shippingCert = await getShippingCert(
				firebase,
				order,
				this.props.match.params.supplierId
			);
			this.setState({ shippingCert });
		} else {
			this.setState({
				shippingCert: this.prepareShippingCertData(
					order,
					order.items,
					null,
					null,
					contractorErpId,
					profile,
					roles.user_id,
				),
			});
		}

		const status = extractLatestStatus(order.status);
		const needToLock = ![
			ORDER_STATUSES.CLOSED,
			ORDER_STATUSES.CANCELED,
			ORDER_STATUSES.DRAFT,
			ORDER_STATUSES.LOCKED,
			ORDER_STATUSES.READY,
			ORDER_STATUSES.PRODUCED,
		].includes(status);
		if (needToLock && !this.supplierContextInitialized) {
			lockOrder({ currentOrder: order });
		}

		const disableSave = !shouldEnableSave(
			ORDER_MANAGEMENT_CONTEXT.SUPPLIER,
			status,
			order
		);
		if (disableSave && !this.state.producingSC) {
			this.setState({ shippingCertCopies: 0 });
		}

		this.supplierContextInitialized = true;
	};

	componentWillUnmount() {
		const { clearCatalog } = this.props;
		clearCatalog(CATALOG_TYPES.ORDERS);
	}

	fetchScreenData = ( {
		contractorId,
		projectId,
		supplierId,
		orderId,
		context,
	} ) => {
		const { fetchItems, connectSearchHistory } = this.props;
		fetchItems({ contractorId, projectId, supplierId, orderId });
		if (context === ORDER_MANAGEMENT_CONTEXT.CONTRACTOR) {
			connectSearchHistory({ contractorId });
		} else {
			connectSearchHistory({ supplierId });
		}
	};

	addItemToOrder = ( item, room, quantity, action, note ) => {
		const { addProduct } = this.props;

		addProduct(item, room, false, quantity, action, note);
	};

	addGenericProduct = ( { name, count, price, isRental = false } ) => {
		console.log("addGenericProduct")

		const { addProduct, match } = this.props;
		const params = match ? match.params : {};
		const { projectId } = params;

		const item = {
			id: uuidWithoutSpace(),
			name,
			catalogId: 1,
			default_price: price,
			isGeneric: true,
			isRentalProduct: isRental,
		};

		const action = ProductAction({
			type: PRODUCT_ACTION_TYPES.ADDED_BY_SUPPLIER,
			data: null,
		});

		// console.log("Adding generic product", item);

		if (this.state.addReplacementCallback) {
			this.state.addReplacementCallback(item, null, count, action, null, isRental);
		} else {
			addProduct(item, { id: projectId, name: "כללי" }, false, count, action);
		}
	};

	removeItemFromOrder = ( itemIndex ) => {
		const { removeProduct } = this.props;
		removeProduct(itemIndex);
	};

	getRoomsArray = () => {
		const {
			      order,
			      projects,
			      match: { params },
		      } = this.props;
		const { contractorId, projectId, supplierId, orderId } = params;

		const project = _.find(projects, { id: projectId });
		if (!project) {
			return [];
		}
		const rooms = _.cloneDeep(project.rooms);
		rooms.unshift({ id: projectId, name: "כללי" });
		return rooms;
	};

	printOrder = () => {
		//console.log("printing", this.componentRef)
		if (Logger) {
			// log all print items
			const { order } = this.props;
			const printItems = [];
			order.items.forEach(( item ) => {
				if (this.shouldPrintItem(item)) {
					printItems.push(item);
					if (!_.isEmpty(item.addOnProducts)) {
						item.addOnProducts.forEach(( addon, j ) => {
							if (addon) {
								printItems.push(addon);
							}
						});
					}
				}
			});
			Logger.setExtraContext({
				filteredItems: printItems,
				orderItems: order.items,
			});
			Logger.captureMessage("Printing Order", { level: "info" });
			Logger.setExtraContext();
		}
		let content = this.componentRef;
		const contentNodes = findDOMNode(content);
		print(contentNodes, 0, ( error ) => {
			toastr.error(error);
		});
	};

	renderSuppliers = ( selectedProject, projects, returnArray = false ) => {
		let suppliers = [];

		if (selectedProject && selectedProject.id) {
			let project = _.find(projects, { id: selectedProject.id });

			const sortedSuppliers =
				      project && project.suppliers
					      ? project.suppliers.sort(( a, b ) => comparatorStrings(a.name, b.name))
					      : [];

			if (returnArray) {
				return sortedSuppliers;
			}

			sortedSuppliers.map(( supplier ) => {
				suppliers.push(
					<option key={ supplier.id } value={ supplier.id }>
						{ supplier.name }
					</option>
				);
			});
		}

		return suppliers;
	};

	renderCatalog = ( latestStatus, useZIndex = true ) => {
		const {
			      isLoadingCatalog,
			      query,
			      catalog,
			      updateQuery,
			      getCatalog,
			      context,
			      order,
			      match,
			      addNotFoundProduct,
			      hidePrices,
		      } = this.props;

		const params = match ? match.params : {};
		const { contractorId, projectId, supplierId, orderId } = params;

		const disabledItems = shouldDisableOrderFormItems(
			context,
			latestStatus,
			order
		);

		return (
			<Catalog
				autoCompleteList={ this.props.searchHistory }
				query={ query }
				updateQueryHandler={ updateQuery }
				getCatalogHandler={ getCatalog }
				context={ context }
				catalog={ catalog }
				isLoadingCatalog={ isLoadingCatalog }
				orderId={ orderId }
				contractorId={ contractorId }
				supplierId={ supplierId }
				itemsPerRow={ 1 }
				disabledCatalogItems={ disabledItems }
				onAdToCart={ ( item, room, quantity, action, note ) => {
					this.addItemToOrder(item, room || null, quantity, action, note);
				} }
				shouldUseZIndex={ useZIndex }
				onAddGenericProduct={
					context === ORDER_MANAGEMENT_CONTEXT.SUPPLIER
						? () => {
							this.setState({ isOpenGenericProductModal: true });
						}
						: null
				}
				formContext={ context }
				// rooms={this.getRoomsArray()}
				// defaultRoom={{ id: projectId, name: "כללי" }}
				addNotFoundProduct={
					context === ORDER_MANAGEMENT_CONTEXT.CONTRACTOR
						? addNotFoundProduct
						: null
				}
				hidePrices={ hidePrices }
			/>
		);
	};

	openRepleceModal = ( item, index, fulfillMode = false ) => {
		this.setState({
			isReplaceModalOpen: true,
			replaceModalItem: item,
			replaceModalItemIndex: index,
			fulfillMode,
		});
	};

	getSummaryItemList = ( { order, latestStatus, isPreview = false } ) => {
		const {
			      undoProductAction,
			      context,
			      setProductAction,
			      setProductQuantity,
			      setFieldValue,
			      match,
			      setProductRoom,
			      hidePrices,
			      onProductQuantityUpdated,
			      removeNotFoundProduct,
		      } = this.props;

		const params = match ? match.params : {};
		const { projectId } = params;

		const disabledItems = shouldDisableOrderFormItems(
			context,
			latestStatus,
			order
		);

		let rows = [];
		let numOfAddonItemsAddedToList = 0;
		// let orderItemsCount = getNumberOfCartItems(order.items);


		const addItemToOrderSummay = ( component ) => {
			rows.push(
				component
			);
		};

		order.items.forEach(( item, index ) => {
			const itemCount =
				      item.acceptance && item.acceptance.count
					      ? item.acceptance.count
					      : item.count;

			addItemToOrderSummay(
				<OrderListItem
					key={ `item_${ item.id }_${ item.room ? item.room.id : index }_${ index }` }
					catalogId={ item.catalogId }
					imgSrc={ item.downloadURL || noProductImageUrl }
					name={ item.name }
					count={ itemCount }
					price={ !!item.spacialPrice ? Number(item.spacialPrice) : item.default_price }
					context={ ORDER_LIST_ITEM_CONTEXT_TYPES.ORDER_SUMMARY }
					negativeCount={ context === ORDER_MANAGEMENT_CONTEXT.SUPPLIER }
					item={ item }
					packageInfo={ getItemPackage(item) }
					disabled={
						disabledItems ||
						isPreview ||
						(
							context === ORDER_MANAGEMENT_CONTEXT.CONTRACTOR &&
							latestStatus === ORDER_STATUSES.LOCKED
						)
					}
					isPreview={ isPreview }
					setProductQuantity={ ( quantity ) => {
						setProductQuantity({ index, quantity });
						onProductQuantityUpdated({ index, quantity });
					} }
					onRemove={ () => {
						this.removeItemFromOrder(index);
					} }
					setProductDiscount={ ( discount ) => {
						setFieldValue(`newOrder.items[${ index }].discount`, discount);
					} }
					setProductSpacialPrice={ ( price ) => {
						setFieldValue(`newOrder.items[${ index }].spacialPrice`, price);
					} }
					setProductNote={ ( note ) => {
						setFieldValue(`newOrder.items[${ index }].note`, note);
					} }
					setRentInterval={ ( interval ) => {
						setFieldValue(
							`newOrder.items[${ index }].userSelectedRentInterval`,
							interval
						);
					} }
					hidePrices={ hidePrices }
					formContext={ context }
					itemActions={ item.actions }
					itemAcceptance={ item.acceptance || item.delivery }
					onUndoAction={ () => undoProductAction(index) }
					rooms={ this.getRoomsArray() }
					room={ item.room || { id: projectId, name: "כללי" } }
					setProductAction={ ( action ) => setProductAction(index, action) }
					setProductRoom={ ( room ) => setProductRoom({ index, room }) }
					onReplace={ () => {
						this.openRepleceModal(item, index);
					} }
					onAddItem={ ( item, room, quantity, action ) => {
						this.addItemToOrder(item, room, quantity, action);
					} }
					itemIndex={ index }
				/>
			);

			if (!_.isEmpty(item.addOnProducts)) {
				item.addOnProducts.forEach(( addon, j ) => {
					if (addon) {
						const image = _.get(
							addon,
							"images[0].downloadURL",
							noProductImageUrl
						);

						addItemToOrderSummay(
							<OrderListItem
								key={ `item_${ item.id }_${
									item.room ? item.room.id : index
								}_${ index }_addon_${ j }` }
								catalogId={ addon.catalogId }
								imgSrc={ image }
								name={ addon.name }
								count={ getItemCount(item) }
								price={ addon.default_price }
								room={ item.room || { id: projectId, name: "כללי" } }
								context={ ORDER_LIST_ITEM_CONTEXT_TYPES.ORDER_SUMMARY }
								formContext={ context }
								itemActions={ [
									{ type: PRODUCT_ACTION_TYPES.ADD_ON_ITEM, data: item },
								] }
								item={ _.cloneDeep(addon) }
								packageInfo={ getItemPackage(addon) }
								itemAcceptance={ addon.acceptance || addon.delivery }
								disabled={ true }
								rooms={ this.getRoomsArray() }
								hidePrices={ hidePrices }
							/>
						);
						numOfAddonItemsAddedToList++;
					}
				});
			}
		});

		// order.notFoundItems.forEach((item, index) => {
		//   rows.push(
		//       <OrderListItem
		//           imgSrc={item.downloadURL || noProductImageUrl}
		//           name={item.searchTerm}
		//           count={item.count}
		//           room={item.room}
		//           context={ORDER_LIST_ITEM_CONTEXT_TYPES.NOT_FOUND}
		//           formContext={context}
		//           item={{}}
		//           disabled={
		//             disabledItems ||
		//             isPreview ||
		//             context === ORDER_MANAGEMENT_CONTEXT.CONTRACTOR
		//           }
		//           isPreview={isPreview}
		//           onReplace={() => {
		//             this.openRepleceModal(item, rows.length + index, true);
		//           }}
		//           onRemove={
		//             context === ORDER_MANAGEMENT_CONTEXT.CONTRACTOR &&
		//             !disabledItems
		//             ? () => {
		//               removeNotFoundProduct(index);
		//             }
		//             : null
		//           }
		//       />
		//   )
		// })

		return rows.reverse()
	}

	renderOrderSummary = ( order, latestStatus, isPreview = false ) => {
		const {
			      productCount,
			      totalPrice,
			      onPrintClick,
			      SummaryItemList
		      } = this.getSummaryProps({ order, latestStatus, isPreview })

		return (
			<div
				style={ {
					display: "flex",
					flexDirection: "column",
					width: "95%",
					marginTop: "50px",
				} }
			>
				<CardTitle
					style={ {
						display: "flex",
						flexDirection: "row",
						color: colors.charcoal,
					} }
				>
					{ "סיכום" }

					<Popup
						trigger={
							<div
								style={ { marginRight: "16px", cursor: "pointer" } }
								onClick={ () => {
									console.log("watermelon1")
									onPrintClick()
								} }
							>
								<ImageComponent
									src={ "img/icons/print.svg" }
									height={ 30 }
									onClick={ () => {
										console.log("watermelon2")
										onPrintClick()
									} }
								/>
							</div>
						}
						on="hover"
						position="top"
						closeOnDocumentClick
					>
						<div
							style={ {
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
							} }
						>
							<HoverText>{ "הצג לפני הדפסה" }</HoverText>
						</div>
					</Popup>
				</CardTitle>

				<Container>
					<div
						style={ {
							backgroundColor: "#f9f9f9",
							borderRadius: "15px",
						} }
					>
						<ContainerHeader style={ { justifyContent: "space-around" } }>
							<div>
                <span dir={ "ltr" } style={ { fontWeight: "bold" } }>
                  { productCount }
                </span>
								<span>{ ` פריטים` }</span>
							</div>

							<span>|</span>

							<div style={ { fontWeight: "bold" } }>
								<span dir={ "ltr" }>{ totalPrice }</span>
								<span>{ "₪" }</span>
							</div>
						</ContainerHeader>
					</div>

					<ContainerList>{ SummaryItemList }</ContainerList>
				</Container>
			</div>
		);
	};

	replaceItem = (
		item,
		itemIndex,
		replacements,
		replacementReason,
		discount
	) => {
		const { replaceItem } = this.props;
		replaceItem(item, itemIndex, replacements, replacementReason, discount);
	};

	renderNotFountItems = ( order, latestStatus, isPreview = false ) => {
		const { setFieldValue, context, removeNotFoundProduct } = this.props;

		const disabledItems = shouldDisableOrderFormItems(
			context,
			latestStatus,
			order
		);

		let itemsPerRow = isPreview || isMobileOrTablet() ? 1 : 3;
		let colSize = Math.ceil(12 / itemsPerRow);
		let row = [];
		let rows = [];

		order.notFoundItems.forEach(( item, index ) => {
			console.log("notFoundItem", index, item)

			if (!item.fulfilled) {
				row.push(
					<Col
						md={ colSize }
						key={ `not_found_item_${ item.id }_${
							item.room ? item.room.id : index
						}_${ index }` }
					>
						<OrderListItem
							imgSrc={ item.downloadURL || noProductImageUrl }
							name={ item.searchTerm }
							count={ item.count }
							room={ item.room }
							note={item.note}
							context={ ORDER_LIST_ITEM_CONTEXT_TYPES.NOT_FOUND }
							formContext={ context }
							item={ {} }
							disabled={
								disabledItems ||
								isPreview ||
								context === ORDER_MANAGEMENT_CONTEXT.CONTRACTOR
							}
							onReplace={ () => {
								this.openRepleceModal(item, index, true);
							} }
							onRemove={
								// context === ORDER_MANAGEMENT_CONTEXT.CONTRACTOR &&
								!disabledItems
									? () => {
										if (context === ORDER_MANAGEMENT_CONTEXT.CONTRACTOR) {
											removeNotFoundProduct(index);
										} else {
											setFieldValue(
												`newOrder.notFoundItems[${ index }].fulfilled`,
												true
											);
										}
									}
									: null
							}
						/>
					</Col>
				);
			}
			if (
				row.length === itemsPerRow ||
				index + 1 === order.notFoundItems.length
			) {
				rows.push(<Row style={ { marginBottom: 20 } }>{ row }</Row>);
				row = [];
			}
		});

		return rows;
	};

	save = ( onSuccessCB, shouldStay = false ) => {
		const { order, saveOrder, context, mergeOrdersDone, match } = this.props;
		const { orderSnapshotBeforeMerge, mergedOrders } = this.state;

		const latestStatus = extractLatestStatus(order.status);
		const disableSave = !shouldEnableSave(context, latestStatus, order);

		if (!disableSave) {
			let isMerged = false;
			if (orderSnapshotBeforeMerge) {
				isMerged = true;
			}

			saveOrder({
				currentOrder: order,
				onSuccess: () => {
					if (orderSnapshotBeforeMerge) {
						const params = match ? match.params : {};
						const { contractorId, projectId, supplierId, orderId } = params;
						mergeOrdersDone({
							contractorId,
							projectId,
							supplierId,
							fromOrderIds: Object.keys(mergedOrders),
							toOrderId: orderId,
						});
					}
					this.setState({
						orderSnapshotBeforeMerge: null,
						mergedOrders: {},
					});
					if (onSuccessCB && typeof onSuccessCB === "function") {
						onSuccessCB();
					} else {
						toastr.success("הזמנה נשמרה בהצלחה");
						if (shouldStay) {
							return;
						}
						this.props.history.push(ROUTES.ORDERS_MANAGEMENT);
					}
				},
				onError: ( e ) => {
					console.log("error save order", e);
					toastr.error(
						"אירעה שגיאה בעת הנסיון לשמור את ההזמנה, אנא נסו שוב",
						"במידה והבעיה חוזרת אנא פנו למנהל המערכת"
					);
				},
				isMerged,
			});
		} else if (!shouldStay) {
			this.props.history.push(ROUTES.ORDERS_MANAGEMENT);
		}
	};

	closeDrawer = () => {
		this.setState({
			isOpenDrawer: false,
			drawerContext: null,
		});
	};

	renderDrawerContext = () => {
		const { drawerContext } = this.state;

		const order = drawerContext;
		if (!order) {
			return null;
		}

		const latestStatus = extractLatestStatus(order.status);

		const { productCount, totalPrice, SummaryItemList } = this.getSummaryProps({
			order,
			latestStatus,
			isPreview: true
		})

		return (
			<div>
				<CardTitle>פרטי הזמנה</CardTitle>

				<InputGroup>
					<InputLabel>תאריך אספקה</InputLabel>
					<div style={ { position: "relative" } }>
						<IconWrapper style={ { top: 0, right: 8 } }>
							<img src={ "img/icons/date.svg" }/>
						</IconWrapper>
						<SingleDatePicker
							disabled={
								!shouldEnableSelectDriver(drawerContext, latestStatus, order)
							}
							date={
								order.isRental
									? moment(order.rentalStartDate)
									: moment(order.deliveryDate)
							} // momentPropTypes.momentObj or null
							onDateChange={ ( date ) =>
								setFieldValue("newOrder.deliveryDate", moment(date).toDate())
							} // PropTypes.func.isRequired
							focused={ this.state.focused } // PropTypes.bool
							onFocusChange={ ( { focused } ) => this.setState({ focused }) } // PropTypes.func.isRequired
							id="deliveryDate2" // PropTypes.string.isRequired,
							isRTL={ true }
							withPortal={ true }
							appendToBody={ true }
							disableScroll={ true }
							small={ true }
							block={ true }
							displayFormat={ "D/MM/YY" }
							isOutsideRange={ () => false }
						/>
					</div>
				</InputGroup>

				<InputGroup>
					<InputLabel>הערות לקוח</InputLabel>
					<textarea
						disabled={ true }
						style={ {
							width: "100%",
							minHeight: 72,
							borderRadius: 3,
							border: "1px solid #E0E0E0",
							resize: "none",
						} }
						value={ order.note }
					/>
				</InputGroup>

				{
					order.notFoundItems.length > 0 ? (
						<Row>
							<Col md={ 12 }>
								<CardTitle>פריטים שלא נמצאו</CardTitle>
								<Card>{ this.renderNotFountItems(order, latestStatus, true) }</Card>
							</Col>
						</Row>
					) : null
				}

				{
					// this.renderOrderSummary(order, latestStatus, true)
				}

				<SummaryContainer
					productCount={ productCount }
					totalPrice={ totalPrice }
					isCatalog={ false }
					// onPrintClick={onPrintClick}
				>
					{ SummaryItemList }
				</SummaryContainer>
			</div>
		);
	};

	addOrderToMergeQueue = ( order ) => {
		const { mergedOrders } = this.state;
		mergedOrders[ order.id ] = order;
		this.setState({ mergedOrders });
	};

	removeOrderFromMergeQueue = ( order ) => {
		const { mergedOrders } = this.state;
		delete mergedOrders[ order.id ];
		this.setState({ mergedOrders });
	};

	mergeOrders = () => {
		const { order, setFieldValue } = this.props;
		const { mergedOrders } = this.state;
		this.setState({
			orderSnapshotBeforeMerge: order,
		});
		const mergedOrder = mergeOrders(Object.values(mergedOrders), order);
		setFieldValue("newOrder", mergedOrder);
	};

	undoMerge = () => {
		const { orderSnapshotBeforeMerge } = this.state;
		const { setFieldValue } = this.props;
		setFieldValue("newOrder", orderSnapshotBeforeMerge);
		this.setState({
			orderSnapshotBeforeMerge: null,
			mergedOrders: {},
		});
	};

	sendOrder = () => {
		const { saveOrder, order, sendOrder } = this.props;
		saveOrder({
			currentOrder: order,
			onSuccess: () => {
				sendOrder(
					null,
					() => {
						toastr.success("הזמנה נשלחה בהצלחה");
						this.props.history.push(ROUTES.ORDERS_MANAGEMENT);
					},
					( e ) => {
						toastr.error(
							"אירעה שגיאה בעת הנסיון לשמור את ההזמנה, אנא נסו שוב",
							"במידה והבעיה חוזרת אנא פנו למנהל המערכת"
						);
					}
				);
			},
			onError: ( e ) => {
				toastr.error(
					"אירעה שגיאה בעת הנסיון לשמור את ההזמנה, אנא נסו שוב",
					"במידה והבעיה חוזרת אנא פנו למנהל המערכת"
				);
			},
		});
	};

	shouldPrintItem = ( item ) => {
		if (item.actions && item.actions.length > 0) {
			const latestAction = item.actions[ item.actions.length - 1 ];
			if (
				[ PRODUCT_ACTION_TYPES.DELAY, PRODUCT_ACTION_TYPES.CANCELED ].includes(
					latestAction.type
				)
			) {
				return false;
			}
		}
		return true;
	};

	openShippingCertPreview = () => {
		this.setState({ isOpenShippingCertPreview: true });
	};

	closeShippingCertPreview = () => {
		this.setState({ isOpenShippingCertPreview: false });
	};

	closeGenericProductModal = () => {
		this.setState({ isOpenGenericProductModal: false });
	};

	submitGenericProduct = ( { name, price, count, isRental } ) => {
		// console.log("submitGenericProduct")
		// console.log("name", name)
		// console.log("price", price)
		// console.log("count", count)
		// console.log("isRental", isRental)

		this.addGenericProduct({ name, price, count, isRental });
		this.closeGenericProductModal();
	};

	buildItemListForPrint = (
		orderItems,
		addItemCallback,
		includeAddOnProducts
	) => {
		const list = [];
		orderItems.forEach(( item, index ) => {
			if (this.shouldPrintItem(item)) {
				addItemCallback && addItemCallback(item);
				list.push(item);
				if (includeAddOnProducts && !_.isEmpty(item.addOnProducts)) {
					item.addOnProducts.forEach(( addon, j ) => {
						if (addon) {
							addItemCallback &&
							addItemCallback(addon, getItemCount(item));
							addon[ "count" ] = getItemCount(item);
							list.push(addon);
						}
					});
				}
			}
		});

		return list;
	};

	prepareShippingCertData = memoize(
		( order, items, certId = null, date = null, contractorErpId = null, profile, user_id ) => {
			const shippingCert = {
				scId: order.scId,
				orderId: order.id,
				contractorId: _.get(order, "contractor.id", ""),
				contractorName: _.get(order, "contractor.name", ""),
				contractorAddress: _.get(order, "contractor.address", ""),
				projectId: _.get(order, "project.id", ""),
				supplierId: _.get(order, "supplier.id", ""),
				taxId: _.get(order, "contractor.taxId", ""),
				date: order.scDate ? new Date(order.scDate) : null,
				producedBy: {
					displayName: profile.displayName || "",
					phoneNumber: profile.phoneNumber || null,
					email: profile.email || null,
					uId: user_id,
				},
				items: [],
				note: "",
				agent: _.get(order, "agent", null),
				contractorErpId,
			};

			const extractItem = ( item, parentCount = null ) => {
				let count = parentCount || getItemCount(item);

				let totalItemPrice = item.default_price;
				if (item.spacialPrice) {
					totalItemPrice = Number(item.spacialPrice);
				}
				if (item.discount > 0) {
					totalItemPrice =
						totalItemPrice - totalItemPrice * (
							item.discount / 100
						);
				}

				return {
					catalogId: item.catalogId,
					name: item.name,
					price_data: item.price_data || null,
					count,
					price: totalItemPrice,
					isGeneric: item.isGeneric || false,
					note: item.note || null,
					spacialPrice: item.spacialPrice || null,
				};
			};

			function addItem( extractedItem ) {
				shippingCert.items.push(extractedItem);
			}

			function buildNote( order ) {
				let note = "";
				if (!order.project) {
					return "";
				}
				note =
					order.shippingAddress ||
					`${ order.project.address } (${ order.project.name })`;
				if (
					order.contactPerson &&
					(
						order.contactPerson.name || order.contactPerson.phone
					)
				) {
					note += `\n${ order.contactPerson.name } ${ order.contactPerson.phone }`;
				} else {
					if (order.orderedBy) {
						note += `\n${ order.orderedBy.displayName } ${ order.orderedBy.phoneNumber }`;
					} else if (
						order.sentBy &&
						(
							order.sentBy.displayName || order.sentBy.phoneNumber
						)
					) {
						note += `\n${ order.sentBy.displayName + " " || "" }${
							order.sentBy.phoneNumber || ""
						}`;
					}
				}
				if (order.supplierNote) {
					note += `\n${ order.supplierNote }`;
				}
				return note;
			}

			shippingCert.note = buildNote(order);

			this.buildItemListForPrint(
				items,
				( item, parentCount = null ) => {
					let extracted = extractItem(item, parentCount);
					addItem(extracted);
				},
				true
			);

			if (order.driver && order.driver.erpId) {
				addItem({
					catalogId: order.driver.erpId,
					count: 1,
					name: order.driver.displayName,
					price: "",
				});
			}

			return shippingCert;
		}
	);

	printShippingCert = () => {
		const { firebase, order, setFieldValue } = this.props;
		const { shippingCert } = this.state;
		let clone = _.cloneDeep(shippingCert);

		const latestStatus = extractLatestStatus(order.status);
		const disableSave = !shouldEnableSave(
			ORDER_MANAGEMENT_CONTEXT.SUPPLIER,
			latestStatus,
			order
		);

		this.setState({ isPreparingShippingCertPrint: true });

		if (disableSave) {
			let content = this.shippingCertRef;
			const contentNodes = findDOMNode(content);
			print(contentNodes, 0, ( error ) => {
				toastr.error(error);
			});
			this.setState({ isPreparingShippingCertPrint: false });
			this.redirectOnClose = true;
			this.closeShippingCertPreview();
		} else {
			this.setState(
				{
					producingSC: true,
				},
				() => {
					this.save(() => {
						produceOrder({
							order: shippingCert,
							firebase,
						})
							.then(( cert ) => {
								// console.log("cert", cert)

								clone.scId = cert.scId;
								clone.date = cert.producedDate;

								setFieldValue("newOrder.scId", cert.scId);
								setFieldValue("newOrder.orderId", cert.scId);
								setFieldValue("newOrder.scDate", cert.producedDate);
								setFieldValue("newOrder.status.produced", cert.producedDate);

								return clone
							})
							.then(( cert ) => {
								this.setState({ shippingCert: cert }, () => {
									window.requestAnimationFrame(() => {
										let content = this.shippingCertRef;
										const contentNodes = findDOMNode(content);
										print(contentNodes, 0, ( error ) => {
											toastr.error(error);
										});
										this.setState({ isPreparingShippingCertPrint: false });
										this.redirectOnClose = true;
										this.closeShippingCertPreview();
									});
								});
							})
							.catch(( e ) => {
								console.error("produce order error", e);
								toastr.error(
									"אירעה שגיאה בעת הנסיון להנפיק תעודת משלוח, אנא נסו שוב",
									"במידה והבעיה חוזרת אנא פנו למנהל המערכת"
								);
								this.setState({
									isPreparingShippingCertPrint: false,
									producingSC: false,
								});
							})
							.finally(() => {
								this.closeShippingCertPreview();
							});
					}, true);
				}
			);
		}
	};

	getShippingCertCopies = memoize(
		( numberOfCopies, disableSave, producingSC ) => {
			const copies = [];

			if (disableSave && !producingSC) {
				copies.push({ label: "עותק משוחזר", showPreview: true });
			} else {
				copies.push({ label: "מקור", showPreview: true });
			}

			for (let i = 0; i < numberOfCopies; i++) {
				copies.push({ label: `עותק ${ i + 1 }`, showPreview: false });
			}

			return copies;
		}
	);

	canProduceSC = memoize(( items ) => {
		if (!items || !items.length || items.length === 0) {
			return false;
		}

		let numberOfCancelledItems = 0;
		items.forEach(( i ) => {
			if (i.actions && i.actions.length > 0) {
				const latestAction = i.actions[ i.actions.length - 1 ];
				if (latestAction.type === PRODUCT_ACTION_TYPES.CANCELED) {
					numberOfCancelledItems++;
				}
			}
		});
		if (numberOfCancelledItems === items.length) {
			return false;
		}

		return true;
	});

	renderSupplierActionButtons = ( latestStatus ) => {
		const { context, order, erpIntegrationEnabled, readyOrder } = this.props;
		const { orderSnapshotBeforeMerge } = this.state;

		const disableSave = !shouldEnableSave(context, latestStatus, order);

		return ( { setShouldOpen } ) => (
			<ActionButtonsContainer>
				{ !!orderSnapshotBeforeMerge && (
					<RoundButton
						label={ "ביטול איחוד" }
						onClick={ () => {
							this.setState({ isMergeUndoConfimOpen: true });
						} }
						backgroundColor={ "#fff" }
					/>
				) }

				<SendButton
					style={ { marginLeft: 10, marginRight: 10 } }
					disabled={ !shouldEnableSelectDriver(context, latestStatus, order) }
					backgroundColor={ "#57d96e" }
					onClick={ this.save }
				>
					{ latestStatus === ORDER_STATUSES.CANCELED ? "יציאה" : "שמירה ויציאה" }
				</SendButton>

				{
					![ ORDER_STATUSES.CANCELED, ORDER_STATUSES.CLOSED ].includes(latestStatus) && (
						<SendButton
							style={ { marginLeft: 10, marginRight: 10 } }
							disabled={ disableSave || !shouldEnableSelectDriver(context, latestStatus, order) }
							backgroundColor={ "#57d96e" }
							onClick={ () => this.save(null, true) }
						>
							{ "שמירה" }
						</SendButton>
					)
				}

				{ erpIntegrationEnabled && latestStatus !== ORDER_STATUSES.CANCELED && (
					<SendButton
						style={ { marginLeft: 10, marginRight: 10 } }
						backgroundColor={ "#5069d9" }
						onClick={ () => {
							const disabled = disableSave
								? !order.scId
								: !(
									order.agent && order.agent.id
								) ||
								!this.canProduceSC(order.items);

							if (disabled) {
								setShouldOpen(true)
								// this.setState({ shouldOpen: true });
							} else {
								this.openShippingCertPreview();
							}
						} }
					>
						{ disableSave ? "הדפסת תעודת משלוח" : "הנפקת תעודת משלוח" }
					</SendButton>
				) }

				<SendButton
					style={ { marginLeft: 10, marginRight: 10 } }
					onClick={ () => {
						this.setState({ printModalOpen: true });
					} }
				>
					הדפס
				</SendButton>

				{ order.isInStore && ![ ORDER_STATUSES.CANCELED, ORDER_STATUSES.CLOSED ].includes(latestStatus) && (
					<SendButton
						style={ { marginLeft: 10, marginRight: 10 } }
						backgroundColor={ "#fecd08" }
						onClick={ () => {
							//console.log("on click instor save")
							if (!!order.sentBy && !!order.sentBy.displayName) {
								readyOrder({
									currentOrder: order,
									onSuccess: () => {
										toastr.success("ההזמנה נשלחה לאישור הלקוח בהצלחה");
										this.props.history.push(ROUTES.ORDERS_MANAGEMENT);
									},
									onError: ( e ) => {
										toastr.error(
											"אירעה שגיאה בעת הנסיון לשמור את ההזמנה, אנא נסו שוב",
											"במידה והבעיה חוזרת אנא פנו למנהל המערכת"
										);
									},
								});
							} else {
								setShouldOpen(true)
							}
						} }
					>
						שמירה ושליחה לאישור לקוח
					</SendButton>
				) }

				{ ![ ORDER_STATUSES.PRODUCED, ORDER_STATUSES.CLOSED, ORDER_STATUSES.CANCELED ].includes(latestStatus)
				&& (
					<SendButton
						style={ { marginLeft: 10, marginRight: 10, backgroundColor: "red" } }
						onClick={ () => {
							this.setState({ deleteModalOpen: true });
						} }
					>
						מחיקה
					</SendButton>
				) }
			</ActionButtonsContainer>
		);
	};

	getSummaryProps = ( { order, latestStatus, isPreview = false } ) => {
		const { price, count } = getOrderTotalPrice(order, true);
		let totalPrice = 0;

		if (order.isRental) {
			totalPrice = order.rentalTotalPrice;
		} else {
			totalPrice = price;
		}

		const onPrintClick = () => {
			console.log("onPrintClick")

			this.setState({ printModalOpen: true });
		}

		const SummaryItemList = this.getSummaryItemList({ order, latestStatus, isPreview })

		return {
			productCount: count,
			totalPrice,
			onPrintClick,
			SummaryItemList,
		}
	}

	// renderCatalogAndSummary = ({
	//   order,
	//   latestStatus,
	//   shouldRenderCatalog,
	//   useZIndex = true,
	// }) =>
	//   shouldRenderCatalog ? (
	//     <div
	//       style={{
	//         display: "flex",
	//         width: "100%",
	//         flexDirection: "row",
	//         // justifyContent: "space-between",
	//       }}
	//       onFocus={() => {
	//         this.setState({ isSearching: true });
	//       }}
	//       onBlur={() => {
	//         this.setState({ isSearching: false });
	//       }}
	//     >
	//       {this.renderCatalog(latestStatus, useZIndex)}
	//
	//       <div
	//         style={{
	//           display: "flex",
	//           flex: 1,
	//           justifyContent: "flex-end",
	//         }}
	//       >
	//         {this.renderOrderSummary(order, latestStatus)}
	//       </div>
	//     </div>
	//   ) : (
	//     this.renderOrderSummary(order, latestStatus)
	//   );

	renderSupplierDetails = ( {
		order,
		latestStatus,
		contractorId,
		supplierId,
		projects,
		context,
		acceptance,
		disableSave
	} ) => {
		const {
			      contractors,
			      supplierAgents,
			      setFieldValue,
			      onOrderNotesUpdated,
		      } = this.props

		const customerOptions =
			      context === ORDER_MANAGEMENT_CONTEXT.SUPPLIER && contractors
				      ? Object.keys(contractors).map(( cId ) => {
					      return { label: contractors[ cId ].name, value: cId };
				      })
				      : [];

		return (
			<div>
				<Card>
					<Row>
						<Col sm={ 6 } md={ 6 } lg={ 2 }>
							<InputGroup>
								<InputLabel>שם לקוח</InputLabel>
								<IconInput
									type={ "select" }
									options={ customerOptions }
									value={ contractorId }
									onChange={ ( value ) => {
										setFieldValue("contractorId", value);
									} }
									disabled={ true }
									icon={ <img src={ "img/icons/person_outline.svg" }/> }
									valueAccessor={ "value" }
									labelAccessor={ "label" }
								/>
							</InputGroup>
						</Col>
						<Col sm={ 6 } md={ 6 } lg={ 2 }>
							<InputGroup>
								<InputLabel>פרוייקט</InputLabel>
								<IconInput
									type={ "select" }
									options={ contractorId ? projects : [] }
									value={ order.project ? order.project.id : "" }
									onChange={ ( value ) => {
										setFieldValue(
											"newOrder.project",
											value ? _.find(projects, { id: value }) : null
										);
									} }
									disabled={ true }
									icon={ <img src={ "img/icons/home.svg" }/> }
									valueAccessor={ "id" }
									labelAccessor={ "name" }
								/>
							</InputGroup>
						</Col>
						<Col sm={ 6 } md={ 6 } lg={ 3 } xl={ 2 }>
							<InputGroup>
								<InputLabel>שם מזמין</InputLabel>
								<IconInput
									type={ "text" }
									value={ order.sentBy ? order.sentBy.displayName : "" }
									onChange={ ( value ) => {
										setFieldValue("newOrder.sentBy.displayName", value);
									} }
									disabled={ true }
									icon={ <img src={ "img/icons/person.svg" }/> }
								/>
							</InputGroup>
						</Col>
						<Col sm={ 6 } md={ 6 } lg={ 2 }>
							<InputGroup>
								<InputLabel>טלפון</InputLabel>
								<IconInput
									type={ "text" }
									value={ order.sentBy ? order.sentBy.phoneNumber : "" }
									onChange={ ( value ) => {
										setFieldValue("newOrder.sentBy.phoneNumber", value);
									} }
									disabled={ true }
									icon={ <img src={ "img/icons/phone.svg" }/> }
								/>
							</InputGroup>
						</Col>
						<Col sm={ 6 } md={ 6 } lg={ 4 }>
							<InputGroup>
								<InputLabel>כתובת</InputLabel>
								<IconInput
									type={ "text" }
									value={ order.project ? order.project.address : "" }
									disabled={ true }
									icon={ <img src={ "img/icons/location.svg" }/> }
								/>
							</InputGroup>
						</Col>
					</Row>
					<Row>
						<Col sm={ 6 } md={ 6 } lg={ 2 }>
							<InputGroup>
								<InputLabel>תאריך אספקה</InputLabel>
								<div style={ { position: "relative" } }>
									<IconWrapper style={ { top: 0, right: 8 } }>
										<img src={ "img/icons/date.svg" }/>
									</IconWrapper>
									<SingleDatePicker
										disabled={
											!shouldEnableSelectDriver(
												context,
												latestStatus,
												order
											)
										}
										date={
											order.isRental
												? moment(order.rentalStartDate)
												: moment(order.deliveryDate)
										} // momentPropTypes.momentObj or null
										onDateChange={ ( date ) =>
											setFieldValue(
												"newOrder.deliveryDate",
												moment(date).toDate()
											)
										} // PropTypes.func.isRequired
										focused={ this.state.focused } // PropTypes.bool
										onFocusChange={ ( { focused } ) =>
											this.setState({ focused })
										} // PropTypes.func.isRequired
										id="deliveryDate" // PropTypes.string.isRequired,
										isRTL={ true }
										withPortal={ true }
										appendToBody={ true }
										disableScroll={ true }
										small={ true }
										block={ true }
										displayFormat={ "D/MM/YY" }
										isOutsideRange={ () => false }
									/>
								</div>
							</InputGroup>
						</Col>
						{ order.isRental && (
							<Col sm={ 6 } md={ 6 } lg={ 2 }>
								<InputGroup>
									<InputLabel>תאריך החזרה</InputLabel>
									<div style={ { position: "relative" } }>
										<IconWrapper>
											<img src={ "img/icons/date.svg" }/>
										</IconWrapper>
										<SingleDatePicker
											disabled={ false }
											date={
												order.returnDate
													? moment(order.returnDate)
													: moment(order.status.sent)
											} // momentPropTypes.momentObj or null
											onDateChange={ ( date ) =>
												setFieldValue(
													"newOrder.returnDate",
													moment(date).toDate()
												)
											} // PropTypes.func.isRequired
											focused={ this.state.returnDateFocused } // PropTypes.bool
											onFocusChange={ ( { focused } ) =>
												this.setState({ returnDateFocused: focused })
											} // PropTypes.func.isRequired
											id="returnDate" // PropTypes.string.isRequired,
											isRTL={ true }
											withPortal={ true }
											disableScroll={ true }
											appendToBody={ true }
											small={ true }
											block={ true }
											displayFormat={ "D/MM/YY" }
											isOutsideRange={ () => false }
										/>
									</div>
								</InputGroup>
							</Col>
						) }
						<Col sm={ 6 } md={ 6 } lg={ 2 }>
							<InputGroup>
								<InputLabel>
									{ order.isRental ? "מס תעודת השכרה" : "מס תעודת משלוח" }
								</InputLabel>
								<IconInput
									type={ "text" }
									disabled={
										context === ORDER_MANAGEMENT_CONTEXT.CONTRACTOR ||
										disableSave
									}
									value={ order.orderId }
									onChange={ ( value ) => {
										setFieldValue("newOrder.orderId", value);
									} }
									onBlur={ onOrderNotesUpdated }
									icon={ <img src={ "img/icons/list.svg" }/> }
								/>
							</InputGroup>
						</Col>
						{ !order.isRental && !order.isInStore && (
							<Col sm={ 6 } md={ 6 } lg={ 2 }>
								<InputGroup>
									<InputLabel>בחירת נהג</InputLabel>
									<DriverSelect
										disabled={
											!shouldEnableSelectDriver(
												context,
												latestStatus,
												order
											)
										}
										onChange={ ( value ) => {
											setFieldValue("newOrder.driver", value);
										} }
										selectedDriver={ order.driver }
										supplierId={ supplierId }
									/>
								</InputGroup>
							</Col>
						) }

						<Col sm={ 6 } md={ 6 } lg={ 2 }>
							<InputGroup>
								<InputLabel>בחירת סוכן</InputLabel>
								<IconInput
									type={ "select" }
									options={ supplierAgents || [] }
									disabled={ disableSave }
									onChange={ ( value ) => {
										const agent =
											      supplierAgents[
												      _.findIndex(supplierAgents, { id: value })
												      ];
										setFieldValue("newOrder.agent", agent);
									} }
									value={ order.agent ? order.agent.id : null }
									icon={ <img src={ "img/icons/driver.svg" }/> }
									labelAccessor={ "name" }
									valueAccessor={ "id" }
								/>
							</InputGroup>
						</Col>

						{ latestStatus === ORDER_STATUSES.CLOSED &&
						order.acceptance &&
						order.acceptance.signature ? (
							<Col md={ 6 }>
								<InputGroup>
									<InputLabel>התקבל ע"י</InputLabel>
									<textarea
										disabled={ true }
										style={ {
											width: "100%",
											minHeight: 72,
											borderRadius: 3,
											border: "1px solid #E0E0E0",
											resize: "none",
										} }
										value={ order.acceptance.signature.name }
										onChange={ ( e ) => {
										} }
									/>
								</InputGroup>
							</Col>
						) : null }
						{ !order.isRental && order.selectedDelivery && (
							<Col sm={ 6 } md={ 6 } lg={ 3 }>
								<InputGroup>
									<InputLabel>סוג משלוח</InputLabel>
									<IconInput
										type={ "text" }
										onClick={ () => {
											this.setState({
												isOpenDeliveryOptionsModal: true,
											});
										} }
										value={
											order.selectedDelivery
												? order.selectedDelivery.label
												: ""
										}
										disabled={
											![
												ORDER_STATUSES.CLOSED,
												ORDER_STATUSES.PRODUCED,
												ORDER_STATUSES.CANCELED,
												ORDER_STATUSES.LOCKED,
											].includes(latestStatus)
										}
										icon={ <img src={ "img/icons/location.svg" }/> }
									/>
								</InputGroup>
							</Col>
						) }
						{ order.status && order.status.approved && (
							<Col sm={ 6 } md={ 6 } lg={ 2 }>
								<TotalItems>{ `נשלח לספק בתאריך ${ moment(
									order.status.approved
								).format("D/M/YY") } בשעה ${ moment(
									order.status.approved
								).format("HH:mm:ss") }` }</TotalItems>
							</Col>
						) }
						{ !!order.status && !!order.status.canceled && !!order.canceledBy && (
							<Col sm={ 6 } md={ 6 } lg={ 2 }>
								<TotalItems>
									{
										'בוטל ע"י '
										+ order.canceledBy.displayName
										+ "\nבתאריך: "
										+ moment(order.status.canceled).format("D/M/YY")
										+ "\nבשעה: "
										+ moment(order.status.canceled).format("HH:mm:ss")
									}
								</TotalItems>
							</Col>
						) }
					</Row>
					{ order.initiatedBySupplier && (
						<Fragment>
							<Row>
								<Col sm={ 6 } md={ 6 } lg={ 2 }>
									<TotalItems>{ 'הזמנה זו נוצרה ע"יי הספק' }</TotalItems>
								</Col>
								{ order.openedBy && order.openedBy.displayName && (
									<Col sm={ 6 } md={ 6 } lg={ 2 }>
										<TotalItems>{ `נפתחה עי"י ${ order.openedBy.displayName }` }</TotalItems>
									</Col>
								) }
							</Row>

							<Row>
								{ order.contactPerson && order.contactPerson.name && (
									<Col md={ 4 }>
										<InputGroup>
											<InputLabel>איש קשר</InputLabel>
											<IconInput
												type={ "text" }
												value={ _.get(order, "contactPerson.name", null) }
												onChange={ ( value ) => {
													setFieldValue(
														"newOrder.contactPerson.name",
														value
													);
												} }
												disabled={ disableSave }
												icon={ <img src={ "img/icons/person.svg" }/> }
											/>
										</InputGroup>
									</Col>
								) }

								{ order.contactPerson && order.contactPerson.phone && (
									<Col md={ 4 }>
										<InputGroup>
											<InputLabel>טלפון</InputLabel>
											<IconInput
												type={ "text" }
												value={ _.get(order, "contactPerson.phone", null) }
												onChange={ ( value ) => {
													setFieldValue(
														"newOrder.contactPerson.phone",
														value
													);
												} }
												disabled={ disableSave }
												icon={ <img src={ "img/icons/phone.svg" }/> }
											/>
										</InputGroup>
									</Col>
								) }
								{ order.shippingAddress && (
									<Col md={ 4 }>
										<InputGroup>
											<InputLabel>כתובת למשלוח</InputLabel>
											<IconInput
												type={ "text" }
												value={ _.get(order, "shippingAddress", null) }
												onChange={ ( value ) => {
													setFieldValue(
														"newOrder.shippingAddress",
														value
													);
												} }
												disabled={ disableSave }
												icon={ <img src={ "img/icons/location.svg" }/> }
											/>
										</InputGroup>
									</Col>
								) }
							</Row>
						</Fragment>
					) }
				</Card>

				<CardTitle>הערות</CardTitle>
				<Card>
					<Row>
						{ acceptance && acceptance.notes ? (
							<Col md={ acceptance && acceptance.notes ? 4 : 6 }>
								<InputGroup>
									<InputLabel>הערות סגירה</InputLabel>
									<div
										style={ {
											width: "100%",
											borderRadius: 3,
											border: "1px solid #E0E0E0",
											minHeight: 72,
											whiteSpace: "pre",
										} }
									>
										{ acceptance.notes }
									</div>
								</InputGroup>
							</Col>
						) : null }

						<Col md={ acceptance && acceptance.notes ? 4 : 6 }>
							<InputGroup>
								<InputLabel>הערות לקוח</InputLabel>
								<div
									style={ {
										width: "100%",
										borderRadius: 3,
										border: "1px solid #E0E0E0",
										minHeight: 72,
										whiteSpace: "pre",
									} }
								>
									{ order.note }
								</div>
							</InputGroup>
						</Col>
						<Col md={ acceptance && acceptance.notes ? 4 : 6 }>
							<InputGroup>
								<InputLabel>הערות ספק</InputLabel>
								<textarea
									disabled={ disableSave }
									style={ {
										width: "100%",
										borderRadius: 3,
										border: "1px solid #E0E0E0",
										resize: "none",
									} }
									value={ order.supplierNote }
									onChange={ ( e ) => {
										setFieldValue(
											"newOrder.supplierNote",
											e.target.value
										);
									} }
								/>
							</InputGroup>
						</Col>
					</Row>
				</Card>
			</div>
		)
	}

	renderSupplierForm = ( {
		order,
		latestStatus,
		contractorId,
		supplierId,
		projects,
		context,
		acceptance,
		shouldRenderCatalog,
	} ) => {
		const {
			      relatedOrders,
			      setFieldValue,
			      // contractors,
			      // supplierAgents,
			      isLoadingCatalog,
			      catalog,
			      query,
			      printHeaderMarginTop,
			      allOrders,
			      updateQuery,
			      getCatalog,
		      } = this.props;

		const {
			      orderSnapshotBeforeMerge,
			      isOpenShippingCertPreview,
			      shippingCertCopies,
			      shippingCert,
			      isPreparingShippingCertPrint,
			      isOpenGenericProductModal,
			      producingSC,
		      } = this.state;

		const disableSave = !shouldEnableSave(context, latestStatus, order);

		const relateds = [ ...relatedOrders ]

		// if (!!order.id) {
		//   const orderShippingAddress = order.shippingAddress || order.project.address
		//
		//   allOrders.forEach(
		//       item => {
		//         if (![
		//           ORDER_STATUSES.CANCELED,
		//           ORDER_STATUSES.CLOSED,
		//           ORDER_STATUSES.PRODUCED
		//         ].includes(extractLatestStatus(item.status))) {
		//           const itemShippingAddress = item.shippingAddress || item.project.address
		//
		//           if (orderShippingAddress === itemShippingAddress && order.id !== item.id) {
		//             relateds.push(item);
		//           }
		//         }
		//       }
		//   )
		// }

		const isFDO = order.isFDO
		const ActionButtonsComponent = this.renderSupplierActionButtons(latestStatus)
		const DetailsComponentCard = this.renderSupplierDetails({
			order,
			latestStatus,
			contractorId,
			supplierId,
			projects,
			context,
			acceptance,
			disableSave
		})

		const detailsProps = {
			isFDO,
			ActionButtonsComponent,
			DetailsComponentCard,
		}

		const summaryProps = this.getSummaryProps({ order, latestStatus })
		const Catalog = shouldRenderCatalog && this.renderCatalog(latestStatus)
		const NotFoundItemList = order.notFoundItems.filter(item => !item.fulfilled).length
			!== 0
			&& this.renderNotFountItems(order, latestStatus, false)

		// console.log("order.notFoundItems", order.notFoundItems)
		console.log("this.state.printModalOpen1", this.state.printModalOpen)

		return (
			<Fragment>
				<div>
					{ !order.isRental &&
					!order.driver &&
					!orderSnapshotBeforeMerge &&
					relateds.length > 0 ? (
						<Col>
							<Row className="justify-content-center">
								<DramaticMessage
									onClick={ () => this.setState({ isOpenMergeModal: true }) }
								>{ `שימו לב! יש ${ relateds.length } הזמנות לאותה כתובת - לצפייה לחצו כאן` }</DramaticMessage>
							</Row>
						</Col>
					) : null }

					{ order.unsavedChanges && (
						<Col>
							<Row className="justify-content-center">
								<DramaticMessage>
									{ "שימו לב! נעשו שינויים בהזמנה זו שטרם נשמרו" }
								</DramaticMessage>
							</Row>
						</Col>
					) }

					<FormContainer
						detailsProps={ detailsProps }
						NotFoundItemList={ NotFoundItemList }
						summaryProps={ summaryProps }
						Catalog={ Catalog }
					/>

					<ReplaceItemModal
						supplierId={ supplierId }
						isLoadingCatalog={ isLoadingCatalog }
						contractorId={ contractorId }
						orderId={ order.id }
						query={ query }
						catalog={ catalog }
						closeHandler={ () => {
							this.setState({
								isReplaceModalOpen: false,
								replaceModalItem: {},
								addReplacementCallback: null,
							});

							clearCatalog(CATALOG_TYPES.ORDERS);
						} }
						onSubmit={ ( replacements, replacementReason, discount ) => {
							//console.log("sebmit replacement", replacements, replacementReason, discount);
							if (this.state.fulfillMode) {
								replacements.forEach(( item ) => {
									const productAction = ProductAction({
										type: PRODUCT_ACTION_TYPES.ADDED_BY_SUPPLIER,
										data: {
											searchTerm: this.state.replaceModalItem.searchTerm,
											notFoundIndex: this.state.replaceModalItemIndex,
											count: this.state.replaceModalItem.count,
										},
									});
									this.addItemToOrder(
										item,
										this.state.replaceModalItem.room || null,
										item.count,
										productAction
									);
									setFieldValue(
										`newOrder.notFoundItems[${ this.state.replaceModalItemIndex }].fulfilled`,
										true
									);
								});
							} else {
								this.replaceItem(
									this.state.replaceModalItem,
									this.state.replaceModalItemIndex,
									replacements,
									replacementReason,
									discount
								);
							}

							this.setState({
								isReplaceModalOpen: false,
								replaceModalItem: {},
								replaceModalItemIndex: null,
								fulfillMode: false,
								addReplacementCallback: null,
							});
							clearCatalog(CATALOG_TYPES.ORDERS);
						} }
						isOpen={ this.state.isReplaceModalOpen }
						updateQuery={ updateQuery }
						getCatalog={ getCatalog }
						item={ this.state.replaceModalItem }
						fulfillMode={ this.state.fulfillMode }
						onAddGenericProduct={
							context === ORDER_MANAGEMENT_CONTEXT.SUPPLIER
								? ( addReplacementCallback ) => {
									this.setState({
										isOpenGenericProductModal: true,
										addReplacementCallback,
									});
								}
								: null
						}
					/>
				</div>
				<Modal
					size={ "lg" }
					isOpen={ this.state.isOpenMergeModal }
					toggle={ () => {
						this.setState({ isOpenMergeModal: false });
					} }
				>
					<ModalBody>
						<ReactTable
							data={ relateds }
							columns={ [
								{
									id: "orderId",
									Header: "מס תעודת משלוח",
									accessor: "orderId", // String-based value accessors!,
									/*Cell: props => <EditableOrderId rowProps={props} setOrderId={this.setOrderId}
									 saveOrderId={this.props.saveOrderId}/>, // Custom cell components!*/
								},
								{
									id: "notes",
									Header: "הערות",
									accessor: ( o ) => o.note, // String-based value accessors!,
								},
								{
									id: "deliveryDate",
									Header: "תאריך אספקה",
									accessor: "deliveryDate", // Custom value accessors!
									Cell: ( props ) => {
										return moment(props.value).format("D/MM/YY");
									},
								},
								{
									id: "total",
									Header: "סכום",
									accessor: ( o ) =>
										o.isRental
											? o.rentalTotalPrice
											: o.summary
											? o.summary.sum
											: undefined, // String-based value accessors!
								},
								{
									id: "address", // Required because our accessor is not a string
									Header: "כתובת",
									accessor: ( o ) => `${ o.project.address } (${ o.project.name })`,
								},
								{
									id: "preview",
									Header: "",
									width: 50,
									//  accessor: o => o.note, // String-based value accessors!,
									Cell: ( props ) => {
										return (
											<div
												onClick={ () => {
													this.setState({
														isOpenDrawer: true,
														drawerContext: props.original,
													});
												} }
											>
												<i className={ "fa fa-eye" }/>
											</div>
										);
									}, // Custom cell components!
									sortable: false,
									filterable: false,
								},
								{
									id: "merge",
									Header: "איחוד",
									width: 50,
									//  accessor: o => o.note, // String-based value accessors!,
									Cell: ( props ) => {
										return (
											<input
												type={ "checkbox" }
												checked={ Object.keys(this.state.mergedOrders)
												                .includes(props.original.id) }
												onChange={ ( e ) => {
													const checked = e.target.checked;

													if (checked) {
														this.addOrderToMergeQueue(props.original);
													} else {
														this.removeOrderFromMergeQueue(props.original);
													}
												} }
											/>
										);
									}, // Custom cell components!
									sortable: false,
									filterable: false,
								},
							] }
							showPagination={ false }
							pageSize={ relateds.length }
							filterable={ false }
						/>
					</ModalBody>
					<ModalFooter>
						<ConfirmButton
							onClick={ () => {
								if (!_.isEmpty(this.state.mergedOrders)) {
									this.mergeOrders();
								}
								this.setState({ isOpenMergeModal: false });
							} }
							label={ "איחוד" }
							disabled={ _.isEmpty(this.state.mergedOrders) }
						/>
						<RoundButton
							label={ "ביטול" }
							onClick={ () => {
								this.setState({
									isOpenMergeModal: false,
									orderSnapshotBeforeMerge: null,
									mergedOrders: {},
								});
							} }
							backgroundColor={ "#fff" }
						/>
					</ModalFooter>
				</Modal>
				<Drawer
					open={ this.state.isOpenDrawer }
					right={ false }
					width={ 600 }
					drawerStyle={ { backgroundColor: "#fff" } }
					onChange={ ( isOpen ) => {
						if (!isOpen) {
							this.closeDrawer();
						}
					} }
				>
					<div style={ { padding: "20px" } }>
						<button onClick={ this.closeDrawer }>{ "סגור" }</button>
						{ this.renderDrawerContext() }
					</div>
				</Drawer>
				<Confirm
					isOpen={ this.state.isMergeUndoConfimOpen }
					onConfirm={ () => {
						this.undoMerge();
						this.setState({ isMergeUndoConfimOpen: false });
					} }
					onReject={ () => {
						this.setState({ isMergeUndoConfimOpen: false });
					} }
					title={ "האם אתם בטוחים?" }
					body={
						"אתם עומדים לבטל את איחוד ההזמנות, במידה וביצעתם שינויים נוספים בהזמנה לאחר האיחוד הם ימחקו."
					}
					confirmButtonLabel={ "כן, תבטלו" }
					rejectButtonLabel={ "לא" }
				/>
				<Modal
					size={ "xl" }
					isOpen={ isOpenShippingCertPreview }
					toggle={ () => {
						this.closeShippingCertPreview();
					} }
					onClosed={ () => {
						if (this.redirectOnClose) {
							this.props.history.push(ROUTES.ORDERS_MANAGEMENT);
						}
					} }
				>
					<ModalBody>
						<div
							style={ {
								display: "flex",
								flexDirection: "row",
								alignItems: "flex-end",
							} }
						>
							<SendButton
								style={ { flex: 6 } }
								onClick={ () =>
									this.setState({ isPrinting: true }, this.printShippingCert)
								}
							>
								הנפקה והדפסה
							</SendButton>
							<SendButton
								style={ {
									flex: 2,
									marginRight: 20,
									backgroundColor: colors.red,
								} }
								onClick={ this.closeShippingCertPreview }
							>
								ביטול
							</SendButton>
							{ !disableSave && (
								<div style={ { flex: 2, marginRight: 20 } }>
									<InputGroup>
										<InputLabel>{ "מס' עותקים" }</InputLabel>
										<IconInput
											type={ "select" }
											options={ [
												{ label: 0, value: 0 },
												{ label: 1, value: 1 },
												{ label: 2, value: 2 },
												{ label: 3, value: 3 },
											] }
											value={ shippingCertCopies }
											onChange={ ( value ) => {
												this.setState({ shippingCertCopies: value });
											} }
											icon={ <img src={ "img/icons/list.svg" }/> }
											valueAccessor={ "value" }
											labelAccessor={ "label" }
										/>
									</InputGroup>
								</div>
							) }
						</div>

						<div ref={ ( el ) => (
							this.shippingCertRef = el
						) }>
							<ShippingCert
								headerMarginTop={ printHeaderMarginTop || 0 }
								printLabel={ "מקור" }
								shippingCert={ shippingCert }
								copies={ this.getShippingCertCopies(
									shippingCertCopies,
									disableSave,
									producingSC
								) }
							/>
						</div>
					</ModalBody>
					{ isPreparingShippingCertPrint && <LoadingSpinner/> }
				</Modal>

				<Modal
					isOpen={ isOpenGenericProductModal }
					toggle={ () => {
						this.closeGenericProductModal();
					} }
					onClosed={ () => {
						if (this.redirectOnClose) {
							this.props.history.push(ROUTES.ORDERS_MANAGEMENT);
						}
					} }
				>
					<ModalBody>
						{ isOpenGenericProductModal && (
							<GenericProductForm
								onSubmit={ this.submitGenericProduct }
								onCancel={ this.closeGenericProductModal }
							/>
						) }
					</ModalBody>
				</Modal>
			</Fragment>
		);
	};

	renderContractorActionButtons = ( latestStatus ) => {
		const { context, order, deliveryOptionsBySupplier, saveOrder } = this.props;

		const disableSave = !shouldEnableSave(context, latestStatus, order);

		return ( { setShouldOpen } ) => (
			<ActionButtonsContainer>
				<SendButton
					style={ { marginLeft: 10, marginRight: 10 } }
					onClick={ () => {
						if (disableSave) {
							this.props.history.push(ROUTES.ORDERS_MANAGEMENT);
						} else {
							saveOrder({
								currentOrder: order,
								onSuccess: () => {
									toastr.success("הזמנה נשמרה בהצלחה");
									this.props.history.push(ROUTES.ORDERS_MANAGEMENT);
								},
								onError: ( e ) => {
									toastr.error(
										"אירעה שגיאה בעת הנסיון לשמור את ההזמנה, אנא נסו שוב",
										"במידה והבעיה חוזרת אנא פנו למנהל המערכת"
									);
								},
							});
						}
					} }
				>
					סגור ושמור
				</SendButton>

				{ latestStatus === ORDER_STATUSES.READY && order.isInStore && (
					<SendButton
						style={ { marginLeft: 10, marginRight: 10 } }
						backgroundColor={ colors.yellow }
						onClick={ () => {
							const { setFieldValue, acceptOrder, auth } = this.props;
							setFieldValue("newOrder.acceptance", {
								location: "office",
								auth: auth.uid,
							});
							acceptOrder({
								autoAcceptAllItems: true,
								successCallback: () => {
									toastr.success("הזמנה נשלחה בהצלחה");
									this.props.history.push(ROUTES.ORDERS_MANAGEMENT);
								},
								errorCallback: () => {
									toastr.error(
										"אירעה שגיאה בעת הנסיון לשמור את ההזמנה, אנא נסו שוב",
										"במידה והבעיה חוזרת אנא פנו למנהל המערכת"
									);
								},
							});
						} }
					>
						{ "אישור הזמנה" }
					</SendButton>
				) }

				{ [
					ORDER_STATUSES.DRAFT,
					// ORDER_STATUSES.SENT,
					ORDER_STATUSES.RENT
				].includes(latestStatus) && (
					<SendButton
						style={ { marginLeft: 10, marginRight: 10 } }
						backgroundColor={ colors.yellow }
						disabled={ disableSave }
						onClick={ () => {
							if (!disableSave) {
								if (
									!order.isRental &&
									!order.isInStore &&
									!_.isEmpty(deliveryOptionsBySupplier) &&
									!order.selectedDelivery
								) {
									this.setState({
										isOpenDeliveryOptionsModal: true,
										sendOnSubmitDeliveryOptionsModal: true,
									});
									return;
								}

								this.sendOrder();
							} else {
								setShouldOpen(true)
							}
						} }
					>
						{ order.isRental ? "החזרת הזמנה" : "שלח הזמנה" }
					</SendButton>
				) }

				{ [ ORDER_STATUSES.DRAFT, ORDER_STATUSES.SENT ].includes(latestStatus) && (
					<SendButton
						style={ { marginLeft: 10, marginRight: 10, backgroundColor: "red" } }
						onClick={ () => {
							this.setState({ deleteModalOpen: true });
						} }
					>
						מחיקה
					</SendButton>
				) }
			</ActionButtonsContainer>
		);
	};

	renderContractorDetails = ( {
		order,
		contractorId,
		latestStatus,
		projects,
		context,
		acceptance,
		shouldRenderCatalog,
	} ) => {
		const { setFieldValue, onOrderNotesUpdated } = this.props;

		return (
			<Card>
				{ order.isInStore ? (
					<Row>
						<Col>
							<div
								style={ {
									textAlign: "center",
									fontFamily: fonts.OpenSansHebrew,
									fontSize: 18.8,
									color: colors.charcoal,
									backgroundColor: colors.sunflowerYellow,
									marginBottom: 10,
								} }
							>
								{ "שימו לב! הזמנה זו נוצרה בחנות" }
							</div>
						</Col>
					</Row>
				) : null }

				<Row>
					<Col md={ 6 } lg={ 2 }>
						<InputGroup>
							<InputLabel>פרוייקט</InputLabel>
							<IconInput
								type={ "select" }
								options={ contractorId ? projects : [] }
								value={ order.project ? order.project.id : "" }
								onChange={ ( value ) => {
									setFieldValue(
										"newOrder.project",
										value ? _.find(projects, { id: value }) : null
									);
								} }
								disabled={ true }
								icon={ <img src={ "img/icons/home.svg" }/> }
								valueAccessor={ "id" }
								labelAccessor={ "name" }
							/>
						</InputGroup>
					</Col>
					<Col md={ 6 } lg={ 2 }>
						<InputGroup>
							<InputLabel>ספק</InputLabel>
							<IconInput
								type={ "select" }
								options={ this.renderSuppliers(
									order.project,
									projects,
									true
								) }
								value={ order.supplier ? order.supplier.id : "" }
								onChange={ ( value ) => {
									setFieldValue(
										"newOrder.supplier",
										value ? _.find(projects, { id: value }) : null
									);
								} }
								disabled={ true }
								icon={ <img src={ "img/icons/home.svg" }/> }
								valueAccessor={ "id" }
								labelAccessor={ "name" }
							/>
						</InputGroup>
					</Col>
					<Col md={ 6 } lg={ 2 }>
						<InputGroup>
							<InputLabel>תאריך אספקה</InputLabel>
							<div style={ { position: "relative" } }>
								<IconWrapper>
									<img src={ "img/icons/date.svg" }/>
								</IconWrapper>
								<SingleDatePicker
									disabled={
										!shouldEnableSelectDriver(
											context,
											latestStatus,
											order
										)
									}
									date={ moment(order.deliveryDate) } // momentPropTypes.momentObj or null
									onDateChange={ ( date ) =>
										setFieldValue(
											"newOrder.deliveryDate",
											moment(date).toDate()
										)
									} // PropTypes.func.isRequired
									focused={ this.state.focused } // PropTypes.bool
									onFocusChange={ ( { focused } ) =>
										this.setState({ focused })
									} // PropTypes.func.isRequired
									id="deliveryDate" // PropTypes.string.isRequired,
									isRTL={ true }
									//withPortal={true}
									//appendToBody={true}
									//disableScroll={true}
									small={ true }
									block={ true }
									displayFormat={ "D/MM/YY" }
									isOutsideRange={ () => false }
								/>
							</div>
						</InputGroup>
					</Col>
					<Col md={ 6 } lg={ 2 }>
						<InputGroup>
							<InputLabel>מס תעודת משלוח</InputLabel>
							<IconInput
								type={ "text" }
								disabled={ context === ORDER_MANAGEMENT_CONTEXT.CONTRACTOR }
								value={ order.orderId }
								onChange={ ( value ) => {
									setFieldValue("newOrder.orderId", value);
								} }
								icon={ <img src={ "img/icons/list.svg" }/> }
							/>
						</InputGroup>
					</Col>
					<Col md={ 6 } lg={ 4 }>
						<InputGroup>
							<InputLabel>הערות ספק</InputLabel>
							<textarea
								disabled={ context === ORDER_MANAGEMENT_CONTEXT.CONTRACTOR }
								style={ {
									width: "100%",
									borderRadius: 3,
									border: "1px solid #E0E0E0",
									resize: "none",
								} }
								value={ order.supplierNote }
								onChange={ ( e ) => {
									setFieldValue("newOrder.supplierNote", e.target.value);
								} }
							/>
						</InputGroup>
					</Col>
				</Row>
				<Row>
					{ acceptance && acceptance.notes ? (
						<Col md={ acceptance && acceptance.notes ? 4 : 6 }>
							<InputGroup>
								<InputLabel>הערות סגירה</InputLabel>
								<textarea
									disabled={ true }
									style={ {
										width: "100%",
										minHeight: 72,
										borderRadius: 3,
										border: "1px solid #E0E0E0",
										resize: "none",
									} }
									value={ acceptance.notes }
								/>
							</InputGroup>
						</Col>
					) : null }
					<Col md={ acceptance && acceptance.notes ? 4 : 6 }>
						<InputGroup>
							<InputLabel>הערות לקוח</InputLabel>
							<textarea
								disabled={
									context === ORDER_MANAGEMENT_CONTEXT.SUPPLIER ||
									!shouldRenderCatalog
								}
								style={ {
									width: "100%",
									minHeight: 72,
									borderRadius: 3,
									border: "1px solid #E0E0E0",
									resize: "none",
								} }
								value={ order.note }
								onChange={ ( e ) => {
									setFieldValue("newOrder.note", e.target.value);
								} }
								onBlur={ onOrderNotesUpdated }
							/>
						</InputGroup>
					</Col>
					<Col md={ acceptance && acceptance.notes ? 4 : 6 }>
						<InputGroup>
							<InputLabel>משהו נוסף?</InputLabel>
							<textarea
								disabled={
									context === ORDER_MANAGEMENT_CONTEXT.SUPPLIER ||
									!shouldRenderCatalog
								}
								style={ {
									width: "100%",
									minHeight: 72,
									borderRadius: 3,
									border: "1px solid #E0E0E0",
									resize: "none",
								} }
								value={ order.anythingElse }
								onChange={ ( e ) => {
									setFieldValue("newOrder.anythingElse", e.target.value);
								} }
								onBlur={ onOrderNotesUpdated }
							/>
						</InputGroup>
					</Col>
					<Col sm={ 6 } md={ 6 } lg={ 3 } xl={ 2 }>
						<InputGroup>
							<InputLabel>שם מזמין</InputLabel>
							<IconInput
								type={ "text" }
								value={ order.sentBy ? order.sentBy.displayName : "" }
								onChange={ ( value ) => {
									setFieldValue("newOrder.sentBy.displayName", value);
								} }
								disabled={ true }
								icon={ <img src={ "img/icons/person.svg" }/> }
							/>
						</InputGroup>
					</Col>
					{ order.status && order.status.approved && (
						<Col sm={ 6 } md={ 6 } lg={ 2 }>
							<TotalItems>{ `נשלח לספק בתאריך ${ moment(
								order.status.approved
							).format("D/M/YY") } בשעה ${ moment(
								order.status.approved
							).format("HH:mm:ss") }` }</TotalItems>
						</Col>
					) }

					{ order.status && order.status.sent && !order.status.approved && (
						<Col sm={ 6 } md={ 6 } lg={ 2 }>
							<TotalItems>{ `נשלח לאישור בתאריך ${ moment(
								order.status.sent
							).format("D/M/YY") } בשעה ${ moment(order.status.sent).format(
								"HH:mm:ss"
							) }` }</TotalItems>
						</Col>
					) }

					{ !order.status && !!order.openedBy && !!order.openedBy.date && (
						<Col sm={ 6 } md={ 6 } lg={ 2 }>
							<TotalItems>
								{ 'נוצר ע"י '.concat(
									order.openedBy.displayName,
									" בתאריך ",
									moment(order.openedBy.date).format("D/M/YY"),
									" בשעה ",
									moment(order.openedBy.date).format("HH:mm:ss")
								) }
							</TotalItems>
						</Col>
					) }
				</Row>

				{ order.acceptance && order.acceptance.signature && (
					<Row>
						<Col md={ 12 }>
							<InputGroup>
								<InputLabel>נחתם ע"י</InputLabel>
								<input
									disabled={ true }
									style={ { width: "100%", height: 34 } }
									value={ order.acceptance.signature.name }
								/>
							</InputGroup>
						</Col>
					</Row>
				) }
				{ order.initiatedBySupplier && (
					<Row>
						<Col sm={ 6 } md={ 6 } lg={ 2 }>
							<TotalItems>{ 'הזמנה זו נוצרה ע"יי הספק' }</TotalItems>
						</Col>
						{ order.openedBy && order.openedBy.displayName && (
							<Col sm={ 6 } md={ 6 } lg={ 2 }>
								<TotalItems>{ `נפתחה עי"י ${ order.openedBy.displayName }` }</TotalItems>
							</Col>
						) }
					</Row>
				) }
			</Card>
		)
	}

	renderContractorForm = ( {
		order,
		contractorId,
		latestStatus,
		projects,
		context,
		acceptance,
		shouldRenderCatalog,
	} ) => {
		const isFDO = order.isFDO
		const ActionButtonsComponent = this.renderContractorActionButtons(latestStatus)
		const DetailsComponentCard = this.renderContractorDetails({
			order,
			latestStatus,
			contractorId,
			projects,
			context,
			acceptance,
			shouldRenderCatalog,
		})

		const detailsProps = {
			isFDO,
			ActionButtonsComponent,
			DetailsComponentCard,
		}

		const summaryProps = this.getSummaryProps({ order, latestStatus })
		const Catalog = shouldRenderCatalog && this.renderCatalog(latestStatus)

		const NotFoundItemList = order.notFoundItems.filter(item => !item.fulfilled).length
			!== 0
			&& this.renderNotFountItems(order, latestStatus, false)
		// console.log("order.notFoundItems", order.notFoundItems)

		return (
			<Fragment>
				<FormContainer
					detailsProps={ detailsProps }
					NotFoundItemList={ NotFoundItemList }
					summaryProps={ summaryProps }
					Catalog={ Catalog }
				/>

				{
					// <PageContainer>
					//   <CollapseDetails
					//       isFDO={order.isFDO}
					//       isSearching={this.state.isSearching}
					//       shouldOpen={this.state.shouldOpen}
					//       onOpen={() => {
					//         this.setState({ shouldOpen: false });
					//       }}
					//       actionsView={this.renderContractorActionButtons(latestStatus)}
					//   >
					//     {this.renderContractorDetails({
					//       order,
					//       contractorId,
					//       latestStatus,
					//       projects,
					//       context,
					//       acceptance,
					//       shouldRenderCatalog,
					//     })}
					//   </CollapseDetails>
					//
					//   {
					//     // order.notFoundItems.length > 0 ? (
					//     //     <Col md={12}>
					//     //       <CardTitle>פריטים שלא נמצאו</CardTitle>
					//     //       <Card>{this.renderNotFountItems(order, latestStatus)}</Card>
					//     //     </Col>
					//     // ) : null
					//   }
					//
					//   {this.renderCatalogAndSummary({
					//     order,
					//     latestStatus,
					//     shouldRenderCatalog,
					//     useZIndex: isMobileOrTablet() ? 1 : 3,
					//   })}
					// </PageContainer>
				}
			</Fragment>
		);
	};

	render() {
		const {
			      order,
			      saveOrder,
			      setFieldValue,
			      projects,
			      match,
			      context,
			      updateQuery,
			      getCatalog,
			      deliveryOptionsBySupplier,
			      clearCatalog,
			      printHeaderMarginTop = 0
		      } = this.props;

		const { isOpenDeliveryOptionsModal } = this.state;

		const params = match ? match.params : {};
		const { contractorId, projectId, supplierId, orderId } = params;

		const latestStatus = extractLatestStatus(order.status);

		const shouldRenderCatalog = showOrderFormCatalog(
			context,
			latestStatus,
			order
		);
		const acceptance = order.acceptance || null;

		// console.log("order1", order)
		console.log("this.state.printModalOpen2", this.state.printModalOpen)

		return (
			<div style={ { backgroundColor: "#eeeeee" } }>
				{ context === ORDER_MANAGEMENT_CONTEXT.SUPPLIER
					? this.renderSupplierForm({
						order,
						latestStatus,
						contractorId,
						supplierId,
						projects,
						context,
						// disableSave,
						acceptance,
						shouldRenderCatalog,
						clearCatalog,
						updateQuery,
						getCatalog,
					})
					: this.renderContractorForm({
						order,
						latestStatus,
						contractorId,
						projects,
						context,
						acceptance,
						shouldRenderCatalog,
					}) }

				<Modal
					isOpen={ this.state.printModalOpen }
					toggle={ () => {
						this.setState({ printModalOpen: false });
					} }
				>
					<ModalBody>
						<SendButton
							onClick={ () =>
								this.setState({ isPrinting: true }, this.printOrder)
							}
						>
							הדפסה
						</SendButton>
						<div style={ { marginTop: -printHeaderMarginTop } }/>
						<OrderDraftPrint
							ref={ ( el ) => (
								this.componentRef = el
							) }
							deliveryDate={ order.deliveryDate }
							address={
								_.get(order, "shippingAddress", "") ||
								_.get(order, "project.address", "")
							}
							contactPersonInSite={
								_.get(order, "contactPerson.name", "") ||
								_.get(order, "sentBy.displayName", "")
							}
							contactPhoneNumber={
								_.get(order, "contactPerson.phone", "") ||
								_.get(order, "sentBy.phoneNumber", "")
							}
							customerName={
								order && order.contractor ? order.contractor.name : ""
							}
							items={ this.buildItemListForPrint(order.items, null, false) }
							notes={ order.note }
							supplierNotes={ order.supplierNote }
							anythingElse={ order.anythingElse }
							whoOrdered={ order && order.sentBy ? order.sentBy.displayName : "" }
							isPrinting={ true }
							headerMarginTop={ printHeaderMarginTop || 0 }
							shouldPrintItem={ this.shouldPrintItem }
							notFoundItems={ order.notFoundItems }
						/>
					</ModalBody>
				</Modal>
				<Modal
					isOpen={ this.state.deleteModalOpen }
					toggle={ () => {
						this.setState({ deleteModalOpen: false });
					} }
				>
					<ModalHeader>מחיקת הזמנה</ModalHeader>
					<ModalBody>אתם עומדים למחוק הזמנה זו, האם אתם בטוחים?</ModalBody>
					<ModalFooter>
						<SendButton
							backgroundColor={ "#D8444F" }
							onClick={ () => {
								this.props.deleteOrder();
								this.setState({ deleteModalOpen: false });
								this.props.history.push("/orders");
							} }
						>
							כן, תמחקו
						</SendButton>
						<SendButton
							onClick={ () => {
								this.setState({ deleteModalOpen: false });
							} }
						>
							לא, התחרטתי
						</SendButton>
					</ModalFooter>
				</Modal>
				<DeliveryOptionsModal
					value={ order.selectedDelivery }
					onChange={ ( selectedDelivery ) => {
						setFieldValue("newOrder.selectedDelivery", selectedDelivery);
					} }
					isOpen={ isOpenDeliveryOptionsModal }
					onSubmit={ () => {
						if (this.state.sendOnSubmitDeliveryOptionsModal) {
							this.sendOrder();
						} else {
							saveOrder({});
						}
					} }
					closeHandler={ () => {
						this.setState({
							isOpenDeliveryOptionsModal: false,
							sendOnSubmitDeliveryOptionsModal: false,
						});
					} }
					options={ deliveryOptionsBySupplier || [] }
				/>
			</div>
		);
	}
}

OrderForm.propTypes = {
	context: PropTypes.string,
};

const mapStateToProps = ( state, props ) => {
	const context = state.ordersManagement.context;
	const order = state.orders.newOrder;
	const erpIntegrationEnabled = erpEnabled({
		context,
		firebaseData: state.firebase.data,
		contractorId: props.match.params.contractorId,
		supplierId: props.match.params.supplierId
	});

	console.log("erpIntegrationEnabled", erpIntegrationEnabled)

	return {
		context,
		ordersContextLoaded: state.orders.ordersContextLoaded,
		ordersLoading: state.orders.ordersLoading,
		itemsLoading: state.orders.itemsLoading,
		order: order,
		contractors: state.orders.contractors,
		projects: state.orders.projects,
		contractorId: state.orders.contractorId,
		profile: state.firebase.profile,
		roles: state.roles.roles,
		isLoading: state.orders.itemsLoading,
		isLoadingCatalog: state.catalog.isLoading,
		query: state.ordersCatalog.query,
		catalog: state.ordersCatalog.catalog,
		searchHistory: state.autoComplete.searchHistory,
		auth: state.firebase.auth,
		relatedOrders: state.orders.relatedOrders,
		allOrders: state.orders.orders,
		deliveryOptionsBySupplier: getVal(
			state.firebase.data,
			`deliveryOptionsBySupplier/${
				props.match.params.supplierId ? props.match.params.supplierId : null
			}`,
			[]
		),
		hidePrices:
			context === ORDER_MANAGEMENT_CONTEXT.CONTRACTOR
				? getVal(
				state.firebase.data,
				`contractors/${ props.match.params.contractorId }/settings/hidePrices`,
				false
				)
				: false,
		erpIntegrationEnabled: erpIntegrationEnabled,
		contractorErpId: erpIntegrationEnabled
			? getVal(
				state.firebase.data,
				`suppliersPrivateData/${ props.match.params.supplierId }/contractors/${ props.match.params.contractorId }/erpId`,
				false
			)
			: null,
		printHeaderMarginTop:
			context === ORDER_MANAGEMENT_CONTEXT.SUPPLIER
				? getVal(
				state.firebase.data,
				`suppliersPrivateData/${ props.match.params.supplierId }/printMarginTop`,
				0
				)
				: 0,
		supplierAgents:
			context === ORDER_MANAGEMENT_CONTEXT.SUPPLIER
				? getVal(
				state.firebase.data,
				`suppliersPrivateData/${ props.match.params.supplierId }/agents`,
				[]
				)
				: [],
	};
};

// const erpEnabled = ( state, props ) => {
//     const context = state.ordersManagement.context;
//     return context === ORDER_MANAGEMENT_CONTEXT.SUPPLIER
//         ? getVal(
//         state.firebase.data,
//         `suppliersPrivateData/${ props.match.params.supplierId }/integration/enable`,
//         false
//         ) &&
//         getVal(
//             state.firebase.data,
//             `suppliersPrivateData/${ props.match.params.supplierId }/contractors/${ props.match.params.contractorId }/erpId`,
//             false
//         )
//         : false;
// };

const mapDispatchToProps = ( dispatch ) =>
	bindActionCreators(
		{
			saveOrder,
			setFieldValue,
			getCatalog,
			addProduct,
			addNotFoundProduct,
			removeNotFoundProduct,
			removeProduct,
			updateQuery,
			setProductAction,
			setProductQuantity,
			// setProductAcceptance,
			getPopularItems,
			fetchItems,
			undoProductAction,
			deleteOrder,
			initOrderById,
			setProductRoom,
			setFocused,
			sendOrder,
			connectSearchHistory,
			readyOrder,
			replaceItem,
			acceptOrder,
			clearCatalog,
			mergeOrdersDone,
			produceOrder,
			onOrderNotesUpdated,
			onProductQuantityUpdated,
			lockOrder,
		},
		dispatch
	);

const DramaticMessage = styled.span`
  background-color: ${ colors.yellow };
  text-align: center;
  font-family: ${ fonts.OpenSansHebrew };
  font-size: 18.8px;
  color: ${ colors.charcoal };
  margin-bottom: 10px;
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
`;

const TotalItems = styled.div`
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  color: #828282;
  margin-top: 11px;
`;

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	firebaseConnect(( props ) => {
		const paths = [
			`deliveryOptionsBySupplier/${
				props.match.params.supplierId ? props.match.params.supplierId : null
			}`,
			`contractors/${ props.match.params.contractorId }/settings/hidePrices`,
		];
		const context = { props };
		// if (context === ORDER_MANAGEMENT_CONTEXT.SUPPLIER) {
		paths.push(
			`suppliersPrivateData/${ props.match.params.supplierId }/integration/enable`
		);
		paths.push(
			`suppliersPrivateData/${ props.match.params.supplierId }/contractors/${ props.match.params.contractorId }/erpId`
		);
		paths.push(
			`suppliersPrivateData/${ props.match.params.supplierId }/printMarginTop`
		);
		paths.push(`suppliersPrivateData/${ props.match.params.supplierId }/agents`);

		//   }
		return paths;
	})
)(OrderForm);
