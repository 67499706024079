import React, {Component} from 'react';
import PropTypes from 'prop-types'
import moment from "moment";
import {getItemCount} from "shared/src/selectors/orders";

import {PRODUCT_ACTION_TYPES} from "shared/src/constants/orders";
import _ from "lodash";

class OrderDraftPrint extends Component{

    constructor() {
        super()
    }

    renderItems = (items) => {
        const list = [];
        let i=0;
        items.forEach((item, index) => {
            if(this.props.shouldPrintItem(item)){
                list.push(this.renderItem(item, i))
            }
        })
        return list
    }

    renderItem = (item, index, parentCount = null) => {
        let count = parentCount || getItemCount(item);
        return (
            <tr style={{pageBreakInside: "avoid", pageBreakAfter: "auto"}} key={index}>
                <th style={{border: "1px solid black"}} scope="row">{item.catalogId}</th>
                <td style={{border: "1px solid black"}}>{!!item.note ? item.name + " " + item.note : item.name}</td>
                <td style={{border: "1px solid black"}}>{count}</td>
            </tr>
        )
    }

    renderNotFoundItems = (notFoundItem, index) => (
        (
            <tr style={{pageBreakInside: "avoid", pageBreakAfter: "auto"}} key={index}>
                <td style={{border: "1px solid black"}}>{!!notFoundItem.note ? notFoundItem.searchTerm + " " + notFoundItem.note : notFoundItem.name}</td>
                <td style={{border: "1px solid black"}}>{notFoundItem.count}</td>
            </tr>
        )
    )

    renderOrder =(showOnScreen, shouldBreakPage)=>{
        const {
            items,
            deliveryDate,
            customerName,
            whoOrdered,
            address,
            contactPhoneNumber,
            contactPersonInSite,
            notes,
            anythingElse,
            supplierNotes,
            headerMarginTop,
            notFoundItems,
        } = this.props;

        console.log("headerMarginTop", headerMarginTop)
	    const filteredNotFoundItems = !!notFoundItems && notFoundItems.filter(item => !item.fulfilled)

        return (
            <div dir="rtl" style={{marginTop: headerMarginTop + "px", padding: 50, pageBreakBefore: shouldBreakPage ? "always" : "auto"}} className={showOnScreen ? "" : "d-none d-print-block"}>
                <div style={{textAlign: "center"}}>
                    <strong>- פנימי -</strong>
                </div>
                <div style={{textAlign: "right"}}>
                    <strong>טיוטת הזמנה</strong>
                </div>
                <div>
                    <div style={{textAlign: "left"}}>תאריך: {moment(deliveryDate).format("D/MM/YY")}</div>
                </div>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div style={{display: "flex", width: "50%"}}>
                        <strong>שם לקוח:</strong>
                        <span style={{marginRight: 10}}>{customerName}</span>
                    </div>
                    <div style={{display: "flex", width: "50%"}}>
                        <strong>שם מזמין:</strong>
                        <span style={{marginRight: 10}}>{whoOrdered}</span>
                    </div>
                </div>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div  style={{display: "flex", width: "50%"}}>
                        <strong>כתובת למשלוח:</strong>
                        <span style={{marginRight: 10}}>{address}</span>
                    </div>
                    <div  style={{display: "flex", width: "50%"}}>
                        <strong>טלפון:</strong>
                        <span style={{marginRight: 10}}>{contactPhoneNumber}</span>
                    </div>
                </div>
                <div>
                    <div>
                        <strong>איש קשר באתר:</strong>
                        <span style={{marginRight: 10}}>{contactPersonInSite}</span>
                    </div>
                </div>
                <div>
                    <div>
                        <strong>הערות:</strong>
                        <span style={{marginRight: 10}} dangerouslySetInnerHTML={{ __html: notes ? notes.replace(/(?:\r\n|\r|\n)/g, '<br>') : "" }}/>
                    </div>
                </div>
                <div>
                    <div>
                        <strong>משהו נוסף:</strong>
                        <span style={{marginRight: 10}} dangerouslySetInnerHTML={{ __html: anythingElse ? anythingElse.replace(/(?:\r\n|\r|\n)/g, '<br>') : "" }}/>
                    </div>
                </div>
                <div>
                    <div>
                        <strong>הערות ספק:</strong>
                        <span style={{marginRight: 10}} dangerouslySetInnerHTML={{ __html: supplierNotes ? supplierNotes.replace(/(?:\r\n|\r|\n)/g, '<br>') : "" }}/>
                    </div>
                </div>
	            <br/>
                <div>
                    <div>
                        <strong>מוצרים:</strong>
                        <span style={{marginRight: 10}} dangerouslySetInnerHTML={{ __html: supplierNotes ? supplierNotes.replace(/(?:\r\n|\r|\n)/g, '<br>') : "" }}/>
                    </div>
                </div>
                <div>
                    <div>
                        <table style={{borderCollapse: "collapse", border: "1px solid black", width: "100%", pageBreakAfter: "auto"}}>
                            <thead style={{display: "table-header-group"}}>
                            <tr style={{pageBreakInside: "avoid", pageBreakAfter: "auto"}}>
                                <th style={{border: "1px solid black"}}>מק"ט</th>
                                <th style={{border: "1px solid black"}}>שם פריט</th>
                                <th style={{border: "1px solid black"}}>כמות</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.renderItems(items)}
                            </tbody>
                        </table>
                    </div>
                </div>
	            {
	            	!!filteredNotFoundItems && !!filteredNotFoundItems.length && (
			            <div style={{display: "flex", flexDirection: "column"}}>
				            <br/>
				            <div>
					            <strong>מוצרים שלא נמצאו:</strong>
					            <span style={{marginRight: 10}} dangerouslySetInnerHTML={{ __html: supplierNotes ? supplierNotes.replace(/(?:\r\n|\r|\n)/g, '<br>') : "" }}/>
				            </div>
				            <div>
					            <table style={{borderCollapse: "collapse", border: "1px solid black", width: "100%", pageBreakAfter: "auto"}}>
						            <thead style={{display: "table-header-group"}}>
						            <tr style={{pageBreakInside: "avoid", pageBreakAfter: "auto"}}>
							            <th style={{border: "1px solid black"}}>שם פריט</th>
							            <th style={{border: "1px solid black"}}>כמות</th>
						            </tr>
						            </thead>
						            <tbody>
						            {filteredNotFoundItems.map((item, index) => this.renderNotFoundItems(item,index))}
						            </tbody>
					            </table>
				            </div>
			            </div>
		            )
	            }
            </div>
        )
    }

    render() {

        const {
            items,
            isPrinting,
            notes,
            anythingElse,
            supplierNotes,
            headerMarginTop
        } = this.props;

        const notesLines = notes ? (notes.match(/\r?\n/g) || '').length + 1 : 1;
        const anythingElseLines = anythingElse ? (anythingElse.match(/\r?\n/g) || '').length + 1 : 1;
        const supplierNotesLines = supplierNotes ? (supplierNotes.match(/\r?\n/g) || '').length + 1 : 1;

        const shouldBreakPage = items.length + notesLines + anythingElseLines + supplierNotesLines > 8;

        return (
            <div className={"A4"} style={{height: "100%"}}>
                <div style={
                    shouldBreakPage ? {} : {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%"
                }}>
                    {this.renderOrder(true)}
                    {this.renderOrder(false, shouldBreakPage)}
                </div>

            </div>
        )
    }
}

OrderDraftPrint.propTypes = {
    items: PropTypes.array,
    deliveryDate: PropTypes.string,
    customerName: PropTypes.string,
    whoOrdered: PropTypes.string,
    address: PropTypes.string,
    contactPhoneNumber: PropTypes.string,
    contactPersonInSite: PropTypes.string,
    notes: PropTypes.string,
    anythingElse: PropTypes.string,
    supplierNotes: PropTypes.string,
    headerMarginTop: PropTypes.number,
    shouldPrintItem: PropTypes.func,
};

OrderDraftPrint.defaultProps = {
    headerMarginTop: 0
}

export default OrderDraftPrint;