import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types'

import styled from 'styled-components';
import { Row, Col } from 'reactstrap';
import { ACCEPTANCE_STATUSES, noProductImageUrl, PRODUCT_ACTION_TYPES } from "shared/src/constants/orders";
import { DELIVERY_ACCEPTANCE_KEY } from "shared/src/modules/deliveries";
import FontIcon from 'material-ui/FontIcon';
import IconButton from "material-ui/IconButton"
import Toggle from 'material-ui/Toggle';
import { getLatestActionString } from "shared/src/selectors/orders"
import { colors, fonts } from "../../styles/styleguide";


class DeliveryItem extends Component {

    constructor() {
        super()
        this.state = {
            isEditing: false,
            defaultCount: 1,
            count: 1,
        };

        this.input = null;
    }

    onEndEditing = () => {
        const {item, onAcceptanceChange} = this.props;
        this.setState({isEditing: false});
        const status = this.getAcceptanceStatus(item);
        let count = this.state.defaultCount;
        if (!isNaN(this.state.count) && this.state.count > 0) {
            count = this.state.count;
        }
        onAcceptanceChange({quantity: count, status})
    }

    focusCount = (count) => {
        this.setState({isEditing: true, defaultCount: count, count}, () => {
            if (this.input) {
                console.log("input", this.input);
                this.input.focus();
            }
        })
    }

    getCount(item) {
        return item[DELIVERY_ACCEPTANCE_KEY] ? item[DELIVERY_ACCEPTANCE_KEY].count : item.count;
    }

    isAccepted(item) {
        return item[DELIVERY_ACCEPTANCE_KEY] ? item[DELIVERY_ACCEPTANCE_KEY].status === ACCEPTANCE_STATUSES.approved : true;
    }

    getAcceptanceStatus = (item) => {
        return item[DELIVERY_ACCEPTANCE_KEY] ? item[DELIVERY_ACCEPTANCE_KEY].status : ACCEPTANCE_STATUSES.approved;
    }

    renderItemAction = (latestAction, allActions) => {

        if (!latestAction) {
            return null;
        }

        let str = getLatestActionString(latestAction, allActions);
        console.log(str)

        return (
            <ItemActionContainer>
                <ItemAction>
                    {str}
                </ItemAction>
            </ItemActionContainer>
        )

    }

    acceptanceStatusLabelMap = (item) => {
        let status = "";
        if (item[DELIVERY_ACCEPTANCE_KEY]) {
            status = item[DELIVERY_ACCEPTANCE_KEY].status;
        }
        switch (status) {
            case ACCEPTANCE_STATUSES.approved:
                return "סופק";
            case ACCEPTANCE_STATUSES.rejected:
                return "לא סופק";
            case ACCEPTANCE_STATUSES.returned:
                return "החזרה";
        }
        return "סופק";
    }

    _handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.onEndEditing()
        }
    }

    render() {
        const {item, index, onAcceptanceChange, onDeleteReturnedItem} = this.props;
        const {isEditing} = this.state;

        if (!item) {
            return null;
        }

        let latestAction = null;
        if (item.actions && item.actions.length > 0) {
            latestAction = item.actions[item.actions.length - 1]
            console.log("latestAction", latestAction);
        }

        const count = this.getCount(item);
        const acceptanceStatus = this.isAccepted(item);

        const acceptanceStatusLabel = this.acceptanceStatusLabelMap(item);
        return (
            <Fragment>
                <Row style={{paddingTop: 10}}>
                    <Col md={12}>
                        <ItemWrapper>
                            <Col md={2}>
                                <div style={{minWidth: 70}}>
                                    <img style={{maxWidth: 70, maxHeight: 100, paddingLeft: 4}}
                                         src={item.downloadURL || noProductImageUrl}/>
                                </div>
                            </Col>
                            <Col md={5}>
                                <div>
                                    <Name>{item.name}</Name>
                                    <Detail>{`מק"ט: ${item.catalogId}`}</Detail>
                                    {/*<Detail>{item.room.name}</Detail>*/}
                                </div>
                            </Col>
                            <Col md={2}>
                                {!isEditing && (
                                    <div>
                                        <Count onClick={() => {
                                            this.focusCount(count);
                                        }}> {count} יח{item[DELIVERY_ACCEPTANCE_KEY] && item[DELIVERY_ACCEPTANCE_KEY].count !== item.count && (
                                            <Detail>{`( ${item.count} במקור)`}</Detail>
                                        )}</Count>
                                        <IconButton disabled={false}
                                                    tooltip={"עריכה"}
                                                    onClick={() => {
                                                        this.focusCount(count);
                                                    }}>
                                            <FontIcon className="material-icons" color={"#6e6e6e"}>edit</FontIcon>
                                        </IconButton>
                                    </div>
                                )}
                                {isEditing && (
                                    <div style={{position: "relative", width: "100%", marginTop: 16}}>
                                        <CountInput innerRef={x => {
                                            this.input = x
                                        }} style={{width: "100%"}} onKeyPress={this._handleKeyPress} value={this.state.count}
                                                    type={"number"} onChange={(e) => {
                                            this.setState({count: e.target.value})
                                        }} onBlur={() => {
                                            this.onEndEditing()
                                        }}/>
                                        <IconButton style={{position: "absolute", right: -10, top: -10}} disabled={false}
                                                    tooltip={"סיום"} onClick={() => {
                                            this.onEndEditing()
                                        }}>
                                            <FontIcon className="material-icons" color={"#32d930"}>done</FontIcon>
                                        </IconButton>
                                    </div>
                                )}


                            </Col>
                            <Col md={2}>
                                {item[DELIVERY_ACCEPTANCE_KEY] && item[DELIVERY_ACCEPTANCE_KEY].status === ACCEPTANCE_STATUSES.returned ? (
                                    <span>{acceptanceStatusLabel} <FontIcon onClick={onDeleteReturnedItem}
                                                                            className="material-icons"
                                                                            color={"#d62c36"}>delete</FontIcon></span>
                                ) : (
                                    <Toggle
                                        label={acceptanceStatusLabel}
                                        labelPosition="right"
                                        style={{paddingTop: 16}}
                                        toggled={acceptanceStatus}
                                        onToggle={(e, isInputChecked) => {
                                            onAcceptanceChange({
                                                quantity: count,
                                                status: isInputChecked ? ACCEPTANCE_STATUSES.approved : ACCEPTANCE_STATUSES.rejected
                                            })
                                        }}
                                    />
                                )}

                            </Col>
                        </ItemWrapper>
                    </Col>


                </Row>
                <Row>
                    <Col md={12}>
                        {latestAction ? this.renderItemAction(latestAction, item.actions) : null}
                    </Col>
                </Row>
            </Fragment>

        );
    }

}

DeliveryItem.propTypes = {
    item: PropTypes.object,
    index: PropTypes.number,
    onAcceptanceChange: PropTypes.func,
    onDeleteReturnedItem: PropTypes.func
};
const ItemWrapper = styled.div`
  border-radius: 15px;
  box-shadow: -16.5px 9.5px 40px 0 rgba(205, 205, 205, 0.25);
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
   position: relative;
   padding-top: 15px;
   padding-bottom: 15px;
   width: 100%;
`

const Name = styled.div`
  font-family: 'Rubik', sans-serif;
  font-size: 16px;
  color: #000000;
`;

const Detail = styled.div`
  font-family: 'Rubik', sans-serif;
  font-size: 14px;
  text-align: right;
  color: #BDBDBD;
`;

const Count = styled.div`
  font-family: 'Rubik', sans-serif;
  font-size: 18px;
  text-align: right;
  color: #319CD8;
  display: inline-block;
  cursor: pointer;
`;

const CountInput = styled.input`
  font-family: 'Rubik', sans-serif;
  font-size: 18px;
  text-align: right;
  color: #319CD8;
`;

const ItemActionContainer = styled.div`
   background-color: ${colors.charcoal};
   border-bottom-left-radius: 21px;
   border-bottom-right-radius: 21px;
   display: flex;
   flex-direction: row;
   font-family: ${fonts.OpenSansHebrew};
   box-shadow: -16.5px 9.5px 40px 12px rgba(205,205,205,0.25);
   margin-bottom: 15px;             
`

const ItemAction = styled.div`
  font-family: ${fonts.OpenSansHebrew};
  font-size: 14px;
   margin-top: 10px;
   color: #fff;
   position: relative;
   height: 100%;
   text-align: right;
   vertical-align: middle;
   white-space: pre;
   margin-right: 15px;
   
`;


export default DeliveryItem;