import React, { Component } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import { firebaseConnect, isLoaded } from 'react-redux-firebase'
import { compose } from 'redux'
import { cloneDeep, isEqual } from "lodash"
import { getContractorIds } from "shared/src/selectors/roles";

import Contractors from "./contractors"
import ContractorForm from "./contractorForm";

import ROUTES from "../../constants/routes"

class ContractorsManagement extends Component {

    render() {
        return (
            <div>
                <Switch>
                    <Route exact path={ROUTES.CONTRACTORS} name="contractors management" render={(props) => ( <Contractors {...props}/>)}/>
                    <Route path={ROUTES.CONTRACTOR} name="contractor Form" render={(props) => (<ContractorForm  {...props}/>)}/>
                </Switch>

            </div>
        );
    }
}

ContractorsManagement.propTypes = {
    contractors: PropTypes.object,
};

ContractorsManagement.defaultProps = {};

const mapStateToProps = (state) => {
    return {
        contractors: state.firebase.data.contractors,
        isApplicationOrdersAdmin: state.ordersManagement.isApplicationOrdersAdmin,
        contractorIds: getContractorIds(state.roles.roles)
    }
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default compose(
    firebaseConnect([
        'contractors'
    ]),
    connect(
        mapStateToProps, mapDispatchToProps
    )
)(ContractorsManagement);
