import React, {Component} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types'
import TextField from 'material-ui/TextField';
import FontIcon from 'material-ui/FontIcon';
import IconButton from 'material-ui/IconButton';

const style = {

    iconButton: {
        padding: 0,
        height: 21,
        width: 21,
        fontSize: 21,
        position: "absolute",
        right: -21,
        top: 5,
    },
    iconGray: {
        color: "#707070"
    },
    editIcon: {
        color: "#707070",
        width: 21,
        height: 21,
        fontSize: 21
    },
    editButton: {
        position: "absolute",
        left: -37,
        top: -5,
    },
    textField: {
        display: "flex",
        flexDirections: "column",
        fontSize: 21,
        lineHeight: "21px",
        height: "auto",
        padding: 0
    },
    underlineStyle: {
        border: 0,
    },
    addIcon:{
        padding: 0,
        height: 21,
        width: 21,
        fontSize: 21,
        verticalAlign: "middle"
    }
}

class EditableLabel extends Component{

    constructor() {
        super();
        this.state = {
            editMode: false,
            isMouseInside: false
        };

        this.toggleEdit = this.toggleEdit.bind(this);
        this.mouseEnter = this.mouseEnter.bind(this);
        this.mouseLeave = this.mouseLeave.bind(this);
    }

    toggleEdit(e) {
        const {editMode} = this.state;
        this.setState({editMode: !editMode})
    }

    mouseEnter() {
        this.setState({isMouseInside: true});
    }

    mouseLeave() {
        this.setState({isMouseInside: false});
    }

    render() {

        const {isActive, label, onLabelChange, onDelete, inputLabelText, showEdit, showDelete, disabled, viewOnly} = this.props;
        const {editMode, isMouseInside} = this.state;

        return (
            <Row>
                {!editMode &&  (
                    <Relative onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave}
                              onClick={isMouseInside && isActive && showEdit && !disabled && !viewOnly ? this.toggleEdit : null}>
                        {label || inputLabelText}
                        {isMouseInside && isActive && showEdit && !disabled && !viewOnly && (
                            <IconButton
                                style={style.editButton}
                                iconStyle={style.editIcon}
                            >
                                <FontIcon className="material-icons">edit</FontIcon>
                            </IconButton>
                        )}
                    </Relative>
                )}
                {editMode && (
                    <TextField
                        value={label}
                        hintText={inputLabelText}
                        onChange={(event, value) => {
                            onLabelChange(value)
                        }}
                        onBlur={this.toggleEdit}
                        onKeyPress={
                            (event) => {
                                const ENTER_KEY = 13;
                                if (event.charCode === ENTER_KEY) {
                                    event.preventDefault();
                                    this.toggleEdit()
                                }
                            }
                        }
                        style={style.textField}
                        underlineShow={false}
                    />
                )}
                {isActive && showDelete && !disabled && !viewOnly && (
                    <Column>
                        <IconButton
                            style={style.iconButton}
                            iconStyle={style.editIcon}
                            onClick={onDelete}
                        >
                            <FontIcon  className="material-icons">close</FontIcon>
                        </IconButton>
                    </Column>
                )}
            </Row>
        )
    }
}


const Relative = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    padding-right: 10px;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
`;



EditableLabel.propTypes = {
    isActive: PropTypes.bool,
    label: PropTypes.string,
    onLabelChange: PropTypes.func,
    onDelete: PropTypes.func,
    inputLabelText: PropTypes.string,
    showEdit: PropTypes.bool,
    showDelete: PropTypes.bool,
    disabled: PropTypes.bool,
    viewOnly: PropTypes.bool,
};

EditableLabel.defaultProps = {
    isActive: true,
    showEdit: true,
    showDelete: true,
    disabled: false,
    viewOnly: false
};

export default EditableLabel;