import React, { Component } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { bindActionCreators, compose } from 'redux';
import moment from "moment"
import _ from "lodash"
import {connectPos} from "shared/src/api/contractorPos";
import {initPoFromList} from "shared/src/modules/pos";
import {fetchPoItems} from "shared/src/api/orderItems";
import { getContractorIds} from "shared/src/selectors/roles";
import ReactTable from "react-table";
import { extractLatestStatus } from "shared/src/utils/orders";
import { getStatusLabel } from "shared/src/utils/po";
import { history } from "../../../store";
import { firebaseConnect } from "react-redux-firebase";
import { ORDER_MANAGEMENT_CONTEXT } from "shared/src/constants/orders";
import FirebaseObjectListSelect from "../../../components/Form/FirebaseObjectListSelect";
import styled from "styled-components";





import {
    filterColumn,
    filterThisWeek,
    filterToday,
    filterTomorrow, onSortChange,
    setAmountFilter,
    setDates, showAllOpen, showCancelled, showClosed
} from "shared/src/modules/ordersFilter";
import { dateFilter, simpleTextFilter, statusFilter, totalFilter, booleanFilter } from "../../../utils/filter";


import * as reactDatesConstants from "react-dates/constants";

import FontIcon from "material-ui/FontIcon";
import { DateRangePicker } from "react-dates";

class ContractorPOList extends Component {

    constructor(){
        super();
        this.state = {
            selectedContractor: null,
            focusedInput: null,
            statusFilter: null,
            showDatePicker: false,
        }

        this.dateFilterOnChangeCallback = null;
    }

    componentDidMount() {
        if(this.props.isApplicationOrdersAdmin){
            return;
        }
        const contractorIds = getContractorIds(this.props.roles);
        this.fetchScreenData({contractorIds})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!_.isEqual(prevProps.roles, this.props.roles) && !this.props.isApplicationOrdersAdmin){
            this.fetchScreenData({contractorIds: getContractorIds(this.props.roles)})
        }
    }

    onContractorSelection = (contractorId) => {
        console.log("contractor selected", contractorId)
        // setting selected contractors to match the roles selector schema
        // will use it to fetch screen data
        const selectedContractor = {contractorIds: [contractorId], projectIds: []};
        this.setState({
            selectedContractor: selectedContractor,
            contractorIds: selectedContractor
        });
        this.fetchScreenData({contractorIds: selectedContractor});
    }


    fetchScreenData = ({contractorIds}) => {
        console.log("fetchScreenData orders screen", {contractorIds});
        const {connectPos} = this.props;
        connectPos(contractorIds, console.log);
    }

    onEdit = (po) => {
        console.log("on Edit", po)
        const {initPoFromList} = this.props;
        initPoFromList(po);
        history.push(`/po/${po.supplier.id}/${po.customer.id}/${po.id}`)
    }

    showDatePicker = () => {
        this.setState({showDatePicker: true, focusedInput: reactDatesConstants.START_DATE})
    }

    _filterColumn = (columnId, value) => {
        this.props.filterColumn(columnId, value, "PO")
    }

    _notesFilter = (filter, row) => {
        return simpleTextFilter(filter, row, "note");
    };

    _customerFilter = (filter, row) => {
        return simpleTextFilter(filter, row, "customer");
    };

    specialRequestFilter = (filter, row) => {
        return simpleTextFilter(filter, row, "specialRequest");
    };

    renderDateFilterColumn = (onChange) => {
        const {startDateFilter, endDateFilter} = this.props;
        this.dateFilterOnChangeCallback = onChange;
        if (startDateFilter && endDateFilter) {
            return (
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <span
                        onClick={this.showDatePicker}>{moment(startDateFilter).format("D/MM/YY")} - {moment(endDateFilter).format("D/MM/YY")}</span>
                    <FontIcon onClick={() => {
                        this._filterColumn("date", {startDate: null, endDate: null})
                        this.props.setDates(null, null, "date", "PO");
                    }} className="material-icons pointer" color={"#6e6e6e"}>close</FontIcon>
                </div>
            )
        }
        else {
            return (
                <div className={"pointer"} onClick={this.showDatePicker}>בחרו תאריכים</div>
            )
        }
    }

    renderDatePicker = () => {
        return (<DateRangePicker
            startDate={this.props.startDateFilter} // momentPropTypes.momentObj or null,
            startDateId="startDateFilter" // PropTypes.string.isRequired,
            endDate={this.props.endDateFilter} // momentPropTypes.momentObj or null,
            endDateId="endDateFilter" // PropTypes.string.isRequired,
            onDatesChange={({startDate, endDate}) => {
                this.props.setDates(startDate, endDate, "date", "PO");
            }} // PropTypes.func.isRequired,
            focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
            onFocusChange={focusedInput => {
                this.setState({focusedInput})
            }} // PropTypes.func.isRequired,
            isRTL={true}
            isOutsideRange={day => moment(day).isBefore('2018-01-01')}
            anchorDirection={reactDatesConstants.ANCHOR_RIGHT}
            showClearDates={true}
            withPortal={true}
            appendToBody={true}
            disableScroll={true}
            small={true}
            block={true}
        />)
    }

    renderAmountFilter = (column) => {
        const {amountFilterCondition, amountFilterValue, setAmountFilter} = this.props;

        return (
            <div style={{flex: 1, flexDirection: "row"}}>
                <select
                    style={{width: 100}}
                    value={amountFilterCondition}
                    onChange={(e) => {
                        setAmountFilter({amountFilterCondition: e.target.value, amountFilterValue, filterName: "PO", column})
                    }}
                >
                    <option value={""}>בחרו תנאי</option>
                    <option value="greaterThan">גדול מ</option>
                    <option value="smallerOrEqual">קטן או שווה ל</option>
                </select>
                <input
                    style={{width: 50}}
                    value={amountFilterValue}
                    type={"number"}
                    onChange={(e) => {
                        setAmountFilter({amountFilterValue: e.target.value, amountFilterCondition, filterName: "PO", column})
                    }}/>
            </div>
        )

    }

    render() {
        const {
            pos,
            posLoading,
            posContextLoaded,
            context,
            isApplicationOrdersAdmin,
            contractors,
            filtered,
        } = this.props;

        const {selectedContractor} = this.state

        return (
            <div style={{marginTop: 20}}>

                <div>
                    <div style={{visibility: "hidden", height: 0}}>{this.renderDatePicker()}</div>
                </div>

                {isApplicationOrdersAdmin && selectedContractor && (
                    <FirebaseObjectListSelect
                        list={contractors}
                        onChange={(value) => this.onContractorSelection(value)}
                        hintText={"בחרו קבלן"}
                        value={selectedContractor ? selectedContractor.contractorIds[0] : null}
                    />
                )}

                {isApplicationOrdersAdmin && !selectedContractor && (
                    <Aligner>
                        <MiddleSelect>
                            <h4>{"בחרו קבלן"}</h4>
                            <FirebaseObjectListSelect
                                list={contractors}
                                onChange={(value) => this.onContractorSelection(value)}
                                hintText={"בחרו קבלן"}
                                value={selectedContractor ? selectedContractor.contractorIds[0] : null}
                            />
                        </MiddleSelect>
                    </Aligner>
                )}

                <ReactTable
                    data={pos || []}
                    columns={
                        [
                            {
                                id: 'date',
                                Header: 'תאריך יצירה',
                                accessor: 'createdDate', // String-based value accessors!,
                                Cell: props => {
                                    return moment(props.value).format("D/MM/YY")
                                },
                                filterMethod: (filter, row) => dateFilter(filter, row, "date"),
                                Filter: ({filter, onChange}) => this.renderDateFilterColumn(onChange)
                            },
                            {
                                id: 'isPOContract',
                                Header: 'הסכם מסגרת',
                                accessor: po => po.isPOContract,
                                Cell: props => <input type={"checkbox"} disabled={true} checked={props.value}/>,
                                filterMethod: (filter, row)  => booleanFilter(filter, row, "isPOContract"),
                                Filter: <input type="checkbox" onChange={(e) => {
                                    this._filterColumn("isPOContract", e.target.checked)
                                }}/>
                            },
                            {
                                id: 'customer',
                                Header: 'ספק',
                                accessor: po => po.supplier.name,
                                filterMethod: this._customerFilter,
                                Filter: <input type="text" ref={ref => {
                                    this.notes = ref
                                }} onChange={(e) => {
                                    this._filterColumn("customer", e.target.value)
                                }}/>
                            },
                            {
                                id: 'numOfItems',
                                Header: 'מספר פריטים',
                                accessor: po => po.summary.num, // String-based value accessors!,
                                 filterMethod: (filter, row) => totalFilter(filter, row, 'numOfItems'),
                                Filter: ({filter, onChange}) => this.renderAmountFilter('numOfItems'),
                            },
                            {
                                id: 'totalPrice',
                                Header: 'סה"כ עלות',
                                accessor: po => po.summary.sum, // String-based value accessors!,
                                 filterMethod: (filter, row) => totalFilter(filter, row, 'totalPrice'),
                                Filter: ({filter, onChange}) => this.renderAmountFilter('totalPrice'),
                            },
                            {
                                id: 'note',
                                Header: 'הערות',
                                accessor: 'note', // String-based value accessors!,
                                filterMethod: this._notesFilter,
                                Filter: <input type="text" ref={ref => {
                                    this.notes = ref
                                }} onChange={(e) => {
                                    this._filterColumn("note", e.target.value)
                                }}/>
                            },
                            {
                                id: 'specialRequest',
                                Header: 'הנחיות מיוחדות',
                                accessor: 'specialRequest', // String-based value accessors!,
                                filterMethod: this.specialRequestFilter,
                                Filter: <input type="text" ref={ref => {
                                    this.notes = ref
                                }} onChange={(e) => {
                                    this._filterColumn("specialRequest", e.target.value)
                                }}/>
                            },
                        ]}
                    showPagination={false}
                    pageSize={pos ? pos.length : 0}
                    filterable={true}
                    filtered={  filtered}
                    getTdProps={(state, rowInfo, column, instance) => {

                        const returnedObj = {
                            onClick: (e, handleOriginal) => {

                                console.log("A Td Element was clicked!");
                                console.log("it produced this event:", e);
                                console.log("It was in this column:", column);
                                console.log("It was in this row:", rowInfo);
                                console.log("It was in this table instance:", instance);
                                this.onEdit(rowInfo.original);

                                // IMPORTANT! React-Table uses onClick internally to trigger
                                // events like expanding SubComponents and pivots.
                                // By default a custom 'onClick' handler will override this functionality.
                                // If you want to fire the original onClick handler, call the
                                // 'handleOriginal' function.
                                if (handleOriginal) {
                                    handleOriginal();
                                }
                            }
                        };



                        return returnedObj;
                    }}
                />
            </div>
        );
    }
}

const Aligner = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const MiddleSelect = styled.div`
    max-width: 50%;
    border: 1px dashed;
    margin-top: 100px;
    padding: 10px;
`;


ContractorPOList.propTypes = {};

ContractorPOList.defaultProps = {};

const mapStateToProps = (state) => {
    return {
        roles: state.roles.roles,
        pos: state.pos.pos,
        posLoading: state.pos.posLoading,
        posContextLoaded: state.pos.posContextLoaded,
        contractors: state.firebase.data.contractors,
        context: state.ordersManagement.context,
        isApplicationOrdersAdmin: state.ordersManagement.isApplicationOrdersAdmin,
        filtered: state.posFilter.filtered,
        filterKey: state.posFilter.filterKey,
        startDateFilter: state.posFilter.startDateFilter,
        endDateFilter: state.posFilter.endDateFilter,
        amountFilterCondition: state.posFilter.amountFilterCondition,
        amountFilterValue: state.posFilter.amountFilterValue,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({
    connectPos,
    initPoFromList,
    fetchPoItems,
    filterColumn,
    setDates,
    setAmountFilter,
    filterToday,
    filterTomorrow,
    filterThisWeek,
    showAllOpen,
    showClosed,
    showCancelled,
    onSortChange,
}, dispatch);

export default compose(
    firebaseConnect((props) => {
        return [
            `contractors`,
        ]
    }),
    connect(
        mapStateToProps, mapDispatchToProps
    )
)(ContractorPOList);
