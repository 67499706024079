import React, { useEffect, useMemo, useState } from 'react';
import { bindActionCreators, compose } from "redux";
import {
	addProduct,
	getProduct,
	isCatalogIdExist,
	updateProduct,
	getCollectionProps
} from "shared/src/modules/catalogProduct";
import { getCatalog } from "shared/src/modules/catalog";
import { firebaseConnect } from "react-redux-firebase";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import TagsInput from 'react-tagsinput'
import styled from "styled-components";
import { colors, fonts } from "../../styles/styleguide";
import IconInput, { Input } from "../../components/Form/IconInput";
import { Row } from "reactstrap";
import ImageUploader from "./ImageUploader";
import Checkbox from "material-ui/Checkbox";
import badgesOptions from "shared/src/constants/productAssets/badges/badges";
import { EditorState, ContentState, convertFromHTML, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import parse from 'html-react-parser';
import Editor from "draft-js-plugins-editor";
import createToolbarPlugin from 'draft-js-static-toolbar-plugin';
import {
	ItalicButton,
	BoldButton,
	UnderlineButton,
	UnorderedListButton,
	OrderedListButton,
} from "draft-js-buttons";

const staticToolbarPlugin = createToolbarPlugin();
const { Toolbar } = staticToolbarPlugin;

const plugins = [ staticToolbarPlugin ];

const PUNCT_CATALOG_ID = ""

const KeyToTitle = {
	main: "כותרת"
}

const KeyToName = {
	name: "שם מוצר",
	generic_name: "מוצר גנרי",
	family_name: "משפחת מוצרים",
	category_id: "קטגוריות",
	description: "תיאור",
	aka: "מילות חיפוש",
	catalog_id: 'מק"ט',
	barcode: "ברקוד"
}

// const KeyPlaceholder = {
// 	// name: "שם מוצר",
// 	// generic_name: "מוצר גנרי",
// }

const initialFormValidationState = {
	punct: {
		name: false,
		catalogId: false,
		variants: false,
		productInfo: false,
	},
	supplier: {
		name: false,
		catalogId: false
	}
}


const ProductForm = ( {
	// initialCatalogProduct,
	suppliers,
	searchResults,
	isLoadingSearch,
	history,
	match: {
		params: {
			catalogId, // supplierId
			productId,
			mode
		}
	},
	getCollectionProps,
	collectionProps,
	// updateProduct,
	// addProduct,
	// getProduct,
} ) => {
	// const context = useMemo(() => PRODUCT_FORM_CONTEXT[catalogId === PUNCT_CATALOG_ID ? PUNCT : SUPPLIER], [catalogId])
	const isPunctCatalog = useMemo(() => true || catalogId === PUNCT_CATALOG_ID, []);

	const [ productState, setProductState ] = useState({ id: productId })
	const [ collectionsState, setCollectionsState ] = useState()
	const [ formValidation, setFormValidation ] = useState(
		initialFormValidationState[ isPunctCatalog ? "punct" : "supplier" ]
	)

	const handleProductStateChange = ( { key, value } ) =>
		setProductState(state => (
			{
				...state,
				[ key ]: value
			}
		))

	const handleValidationChange = ( { key, isVerified } ) =>
		setFormValidation(state => (
			{
				...state,
				[ key ]: isVerified
			}
		))

	useEffect(() => {
		console.log("productState", productState)
	}, [ productState ]);

	useEffect(() => {
		console.log("formValidation", formValidation)
	}, [ formValidation ]);

	return (
		<div style={ { display: "flex", flex: 1, flexDirection: "column" } }>
			<Section title={ "הוספת מוצר " + "כותרת" }>
				<RowContainer>
					<NameComponent>{ KeyToName.name }</NameComponent>
					<IconInput
						type={ "text" }
						value={ productState.name }
						onChange={ value => {
							const key = "name"

							handleProductStateChange({ key, value })
							handleValidationChange({ key, isVerified: !!value })
						} }
					/>
				</RowContainer>

				{
					isPunctCatalog
						? (
							<RowContainer>
								<NameComponent>{ KeyToName.generic_name }</NameComponent>
								<IconInput
									type={ "text" }
									value={ productState.generic_name }
									onChange={ value => handleProductStateChange({ key: "generic_name", value }) }
								/>
							</RowContainer>
						) : (
							<RowContainer>
								<NameComponent>{ KeyToName.family_name }</NameComponent>
								<IconInput
									type={ "text" }
									disabled={ true }
									value={ productState.family_name }
									onChange={ value => handleProductStateChange({ key: "family_name", value }) }
								/>
							</RowContainer>
						)
				}

				<RowContainer>
					<NameComponent>{ KeyToName.category_id }</NameComponent>
					<TagsInput
						value={ productState.category_id || [] }
						disabled={ !isPunctCatalog }
						onChange={ value => handleProductStateChange({ key: "category_id", value }) }
						renderInput={ props => {
							const { onChange, value, addTag, ...other } = props

							return (
								<Input
									type='text'
									onChange={ onChange }
									value={ value }
									{ ...other }
								/>
							)
						} }
						renderTag={ props => {
							const {
								      tag,
								      key,
								      disabled,
								      onRemove,
								      classNameRemove,
								      getTagDisplayValue,
								      ...other
							      } = props

							return (
								<Tag key={ key } { ...other }>
									{ getTagDisplayValue(tag) }
									{ !disabled && (
										<a className={ classNameRemove }
										   onClick={ ( e ) => onRemove(key) }
										/>
									) }
								</Tag>
							)
						} }
					/>
				</RowContainer>

				<RowContainer>
					<NameComponent>{ KeyToName.description }</NameComponent>
					<IconInput
						type={ "textarea" }
						value={ productState.description }
						onChange={ value => handleProductStateChange({ key: "description", value }) }
					/>
				</RowContainer>

				<RowContainer>
					<NameComponent>{ KeyToName.aka }</NameComponent>
					<TagsInput
						value={ productState.aka || [] }
						disabled={ !isPunctCatalog }
						onChange={ value => handleProductStateChange({ key: "aka", value }) }
						renderInput={ props => {
							const { onChange, value, addTag, ...other } = props

							return (
								<Input
									type='text'
									onChange={ onChange }
									value={ value }
									{ ...other }
								/>
							)
						} }
						renderTag={ props => {
							const {
								      tag,
								      key,
								      disabled,
								      onRemove,
								      classNameRemove,
								      getTagDisplayValue,
								      ...other
							      } = props

							return (
								<Tag key={ key } { ...other }>
									{ getTagDisplayValue(tag) }
									{ !disabled && (
										<a className={ classNameRemove }
										   onClick={ ( e ) => onRemove(key) }
										/>
									) }
								</Tag>
							)
						} }
					/>
				</RowContainer>
			</Section>

			<Section title={ "מלאי" }>
				<RowContainer>
					<NameComponent>{ KeyToName.catalog_id }</NameComponent>
					<IconInput
						type={ "text" }
						value={ productState.catalog_id }
						onChange={ value => {
							const key = "catalog_id"

							handleProductStateChange({ key, value })
							handleValidationChange({ key, isVerified: !!value })
						} }
					/>
				</RowContainer>

				<RowContainer>
					<NameComponent>{ KeyToName.barcode }</NameComponent>
					<TagsInput
						value={ productState.barcode || [] }
						onChange={ value => handleProductStateChange({ key: "barcode", value }) }
						renderInput={ props => {
							const { onChange, value, addTag, ...other } = props

							return (
								<Input
									type='number'
									onChange={ onChange }
									value={ value }
									{ ...other }
								/>
							)
						} }
						renderTag={ props => {
							const {
								      tag,
								      key,
								      disabled,
								      onRemove,
								      classNameRemove,
								      getTagDisplayValue,
								      ...other
							      } = props

							return (
								<Tag key={ key } { ...other }>
									{ getTagDisplayValue(tag) }
									{ !disabled && (
										<a className={ classNameRemove }
										   onClick={ ( e ) => onRemove(key) }
										/>
									) }
								</Tag>
							)
						} }
					/>
				</RowContainer>
			</Section>

			<div className={ "images-row" }>
				<Row className={ "align-items-end" }>
					{ productId && catalogId && (
						<ImageUploader/>
					) }
				</Row>
			</div>

			{
				isPunctCatalog && (
					<Variants
						productVariantsState={ productState.variants }
						handleVariantsStateChange={ value => handleProductStateChange({ key: "variants", value }) }
						handleVariantsValidationChange={ isVerified =>
							handleValidationChange({ key: "variants", isVerified })
						}
						handleCollectionsStateChange={ setCollectionsState }
						handleCollectionsValidationChange
						collectionProps={ collectionProps }
					/>
				)
			}

			<ProductInfo
				productRef={ productState.productRef }
				isPunctCatalog={ isPunctCatalog }
				handleProductInfoChange={ value => handleProductStateChange({ key: "productInfo", value }) }
				handleProductInfoValidationChange={ isVerified =>
					handleValidationChange({ key: "productInfo", isVerified })
				}
			/>
		</div>
	)
};

const Section = ( { title, children } ) => (
	<SectionContainer>
		<div>{ title }</div>
		{ children }
	</SectionContainer>
)

const Variants = ( {
	productVariantsState,
	handleVariantsStateChange,
	handleVariantsValidationChange,
	handleCollectionsStateChange,
	collectionProps,
} ) => {
	const [ variantsState, setState ] = useState(productVariantsState || [
		{
			name: "מידה",
			values: [ "S", "M", "L" ]
		},
		{
			name: "צבע",
			values: [ "שחור", "לבן" ]
		},
		{
			name: "בד",
			values: [ "כותנה", "פוליאסטר" ]
		}
	])
	const validationState = useMemo(
		() => {
			let isValid = true
			let item;

			for (let i = 0; i < variantsState.length; i++) {
				const variant = variantsState[ i ]

				const isNameValid = !!variant.name
				const isValueValid = !!variant.values.length

				isValid = isNameValid && isValueValid

				if (!isValid) {
					item = {
						index: i,
						value: isValueValid,
						name: isNameValid,
					}

					break
				}
			}

			return isValid
				? { isValid }
				: { isValid, item }
		}
		, [ variantsState ]
	)
	const [ shouldFocusOnNew, setShouldFocusOnNew ] = useState(false)

	const deleteVariant = ( { index } ) => {
		setState(state =>
			state.reduce(
				( acc, current, i ) =>
					(
						i === index || acc.push(current)
					) && acc,
				[]
			)
		)
	}

	const addNewVariant = () =>
		new Promise(resolve => {
			setState(state => {
				const shouldAdd = state.length === 0 || (
					state[ state.length - 1 ].name !== "" && state[ state.length - 1 ].values.length !== 0
				)

				return shouldAdd
					? [
						...state,
						{ name: "", values: [] }
					]
					: state
			})

			resolve()
		}).then(() => {
			setShouldFocusOnNew(true)
		})

	const changeVariant = ( { index, key } ) => value => {
		setState(state => state.map(( item, i ) =>
			i === index
				? {
					...item,
					[ key ]: value
				}
				: item
		))
	}

	useEffect(() => {
		if (shouldFocusOnNew) {
			const variantRef = variantsState[ variantsState.length - 1 ].ref

			if (!!variantRef && variantRef.focus) {
				variantRef.focus()
			}

			setShouldFocusOnNew(false)
		}
	}, [ shouldFocusOnNew ])

	useEffect(() => {
		handleVariantsStateChange(variantsState)
	}, [ variantsState ])

	const [ collectionsState, setCollectionsState ] = useState([])

	useEffect(() => {
		if (variantsState.length > 1) {
			if (validationState.isValid) {
				setCollectionsState(state =>
					mergeCollectionProps({
						state: getCollectionState({
							variantsState,
							collectionProps
						}),
						props: state
					})
				)
			}
		} else {
			setCollectionsState([])
		}
	}, [ variantsState ])

	useEffect(() => {
		handleCollectionsStateChange(collectionsState)
	}, [ collectionsState ])

	useEffect(() => {
		handleVariantsValidationChange(validationState.isValid)
	}, [ validationState.isValid ])

	return (
		<div style={ { display: "flex", flexDirection: "column" } }>
			<Section title={ "מאפיינים" }>
				<RowContainer>
					<VariantColumn>
						<div>{ "סוג" }</div>
					</VariantColumn>

					<VariantColumn flex={ 2 }>
						<div>{ "ערכים" }</div>
					</VariantColumn>

					<VariantColumn flex={ 3 }>
						<AddButton onClick={ addNewVariant }>
							{ "הוסף חדש" }
						</AddButton>
					</VariantColumn>
				</RowContainer>

				{ variantsState.map(( variant, index ) => (
					<RowContainer key={ index }>
						<img
							onClick={ () => deleteVariant({ index }) }
							src={ "img/RmoveRelatedItem.png" }
							alt={ "delete" }
						/>

						<VariantColumn>
							<IconInput
								refHandler={ ref => {
									variant.ref = ref
								} }
								type={ "text" }
								value={ variantsState[ index ].name }
								onChange={ changeVariant({ index, key: "name" }) }
							/>

							{
								!validationState.isValid
								&& validationState.item.index === index
								&& !validationState.item.name
								&& (
									<ErrorMessage/>
								)
							}
						</VariantColumn>

						<VariantColumn flex={ 2 }>
							<TagsInput
								value={ variantsState[ index ].values }
								onChange={ changeVariant({ index, key: "values" }) }
								renderInput={ props => {
									const { onChange, value, addTag, ...other } = props

									return (
										<Input
											type='text'
											onChange={ onChange }
											value={ value }
											{ ...other }
										/>
									)
								} }
								renderTag={ props => {
									const {
										      tag,
										      key,
										      disabled,
										      onRemove,
										      classNameRemove,
										      getTagDisplayValue,
										      ...other
									      } = props

									return (
										<Tag key={ key } { ...other }>
											{ getTagDisplayValue(tag) }
											{ !disabled && (
												<a className={ classNameRemove }
												   onClick={ ( e ) => onRemove(key) }
												/>
											) }
										</Tag>
									)
								} }
							/>

							{
								!validationState.isValid
								&& validationState.item.index === index
								&& !validationState.item.value
								&& (
									<ErrorMessage message={ "לפחות אחד" }/>
								)
							}
						</VariantColumn>

						<VariantColumn flex={ 3 }/>
					</RowContainer>
				)) }
			</Section>

			{
				!!collectionsState.length && (
					<Section title={ "אפשרויות" }>
						<RowContainer>
							<VariantColumn>
								<div>{ "סוג" }</div>
							</VariantColumn>

							<VariantColumn flex={ 2 }>
								<div>{ "ברקוד" }</div>
							</VariantColumn>

							<VariantColumn flex={ 3 }>
								<div>{ "תמונות" }</div>
							</VariantColumn>
						</RowContainer>

						{
							collectionsState.map(( item, index ) => (
								<RowContainer key={ item.id || item.name }>
									<VariantColumn style={ { fontWeight: "bold" } }>
										{ "".concat(index + 1, ".  ", item.name) }
									</VariantColumn>

									<VariantColumn flex={ 2 }>
										<TagsInput
											value={ item.barcode || [] }
											onChange={ barcode => {
												setCollectionsState(state => state.map(( collection, cIndex ) =>
													cIndex === index
														? { ...collection, barcode }
														: collection
												))
											} }
											renderInput={ props => {
												const { onChange, value, addTag, ...other } = props

												return (
													<Input
														type='number'
														onChange={ onChange }
														value={ value }
														{ ...other }
													/>
												)
											} }
											renderTag={ props => {
												const {
													      tag,
													      key,
													      disabled,
													      onRemove,
													      classNameRemove,
													      getTagDisplayValue,
													      ...other
												      } = props

												return (
													<Tag key={ key } { ...other }>
														{ getTagDisplayValue(tag) }
														{ !disabled && (
															<a className={ classNameRemove }
															   onClick={ ( e ) => onRemove(key) }
															/>
														) }
													</Tag>
												)
											} }
										/>
									</VariantColumn>

									<VariantColumn flex={ 3 }>

									</VariantColumn>
								</RowContainer>
							))
						}
					</Section>
				)
			}
		</div>
	)
}

const ProductInfo = ( { productRef, isPunctCatalog, handleProductInfoChange, handleProductInfoValidationChange } ) => {
	const [ productInfo, setProductInfo ] = useState(extractProductInfo(productRef || PRODUCT_REF_EXAMPLE))
	const isBadges = useMemo(() =>
		!!productInfo && productInfo.length !== 0 && !!productInfo[ productInfo.length - 1 ].isBadge
		, [ productInfo ]
	)

	const [ validationState, setValidationState ] = useState(productInfo.map(item => true))
	const isInfoValid = useMemo(() =>
		validationState.length === 0 || validationState.reduce(( acc, current ) => acc && current, true)
		, [ validationState ]
	);

	useEffect(() => {
		handleProductInfoChange(productInfo)
	}, [ productInfo ]);

	useEffect(() => {
		console.log("isInfoValid", isInfoValid)

		handleProductInfoValidationChange(isInfoValid)
	}, [ isInfoValid ])

	return (
		<Section title={ "מידע נוסף" }>
			<InfoIconPNG
				name={ "plus-5" }
				text={ "הוסף מידע נוסף" }
				onClick={ e => {
					const newSection = {
						name: "",
						isInput: true,
						value: {
							isInput: true,
							stringifyHtmlValue: ""
						}
					}

					let index = -1;

					if (productInfo.length !== 0) {
						if (!!productInfo[ productInfo.length - 1 ].isBadge) {
							if (productInfo.length !== 1) {
								if (!!productInfo[ productInfo.length - 2 ].name) {
									index = productInfo.length - 2
								}
							} else {
								index = productInfo.length - 1
							}
						} else {
							index = productInfo.length - 1
						}
					} else {
						index = 0
					}

					if (index !== -1) {
						setValidationState(state => {
							const newState = [ ...state ]

							newState.splice(index, 0, false)

							return newState
						})

						setProductInfo(state => {
							const newState = [ ...state ]
							const newSection = {
								name: "",
								isInput: true,
								value: {
									isInput: true,
									stringifyHtmlValue: ""
								}
							}

							newState.splice(index, 0, newSection)

							return newState
						})
					}
				} }
			/>

			{ productInfo.map(( section, index ) => (
				(
					!isBadges || index !== productInfo.length - 1
				) && (
					<InfoSection
						key={ index }
						index={ index }
						name={ section.name }
						isInput={ section.isInput }
						value={ section.value }
						setProductInfo={ isPunctCatalog && setProductInfo }
						handleValidationChange={ isVerified =>
							setValidationState(state =>
								state.map(( s, sIndex ) =>
									sIndex === index
										? isVerified
										: s
								)
							)
						}
					/>
				)
			)) }

			{
				<BadgeSection
					isDisabled={ !isPunctCatalog }
					onBadgeChange={ ( { badge, isInputChecked } ) => {
						setProductInfo(state =>
							isInputChecked
								? isBadges
								? state.map(( section, sIndex ) =>
									sIndex === state.length - 1
										? {
											...section,
											values: [
												...section.values,
												badge
											]
										}
										: section
								)
								: [
									...state,
									{
										name: "התקנים",
										isBadge: true,
										values: [ badge ]
									}
								]
								: state[ state.length - 1 ].values.length === 1
								? state.reduce(( acc, currentSection, sIndex, array ) => (
										sIndex === state.length - 1 || acc.push(currentSection)
									) && acc
									, []
								)
								: state.map(( section, sIndex ) =>
									sIndex === state.length - 1
										? {
											...section,
											values: section.values.reduce(( acc, currentBadge ) => (
													currentBadge.value === badge.value || acc.push(currentBadge)
												) && acc
												, []
											)
										}
										: section
								)
						)
					} }
				>
					{ isBadges ? productInfo[ productInfo.length - 1 ].values : [] }
				</BadgeSection>
			}
		</Section>
	)
}

const InfoSubSection = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%
`

const InfoSectionTitle = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
`

const InfoContentContainer = styled.div`
  display: flex;
  flex: 3;
`

const InfoIconPNG = ( {
	name,
	// isTitle,
	onClick,
	text = null
} ) => (
	<ButtonContainer>
		{
			!!text && (
				<div
					onClick={ onClick }
					style={ {
						fontWeight: "bold",
						textDecoration: "underline",
						padding: "0 0 0 4px",
						cursor: "pointer"
					} }
				>
					{ text }
				</div>
			)
		}

		<img
			onClick={ onClick }
			style={ { cursor: "pointer" } }
			src={
				"".concat(
					"img/icons/",
					name,
					"-",
					"16",
					// isTitle ? "24" : "16",
					".png"
				)
			}
		/>
	</ButtonContainer>
)

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 0 4px 8px;
  height: 16px;
`

const InfoNoteBold = styled.span`
  font-weight: bold;
  overflow: visible;
  white-space: pre;
`

const InfoSection = ( { index, name, isInput, value, setProductInfo, handleValidationChange } ) => {
	const [ editorState, setEditorState ] = React.useState(() => {
			const { contentBlocks, entityMap } = convertFromHTML(value.stringifyHtmlValue)

			return EditorState.createWithContent(
				ContentState.createFromBlockArray(
					contentBlocks,
					entityMap
				)
			)
		}
	);

	const [ initialState, setInitialState ] = useState({ name, value })

	useEffect(() => {
		console.log("initialState", initialState)
	}, [ initialState ]);

	const isNameValid = useMemo(() => !!name, [ name ])
	const isValueValid = useMemo(() => !!editorState && editorState.getCurrentContent().hasText(), [ editorState ])

	useEffect(() => {
		const draftHtml = draftToHtml(convertToRaw(editorState.getCurrentContent()))

		setProductInfo(state => state.map(( section, sIndex ) =>
			sIndex === index
				? {
					...section,
					value: {
						...section.value,
						stringifyHtmlValue: draftHtml
					}
				}
				: section
		))
	}, [ editorState ])

	useEffect(() => {
		handleValidationChange(isNameValid && isValueValid)
	}, [ isNameValid, isValueValid ])

	const onEdit = ( isValue = false ) => {
		if (isValue) {
			setProductInfo(state =>
				state.map(( section, sIndex ) =>
					sIndex === index
						? (
							{
								...section,
								value: {
									...section.value,
									isInput: true
								}
							}
						)
						: section
				)
			)
		} else {
			setProductInfo(state =>
				state.map(( section, sIndex ) =>
					sIndex === index
						? { ...section, isInput: true }
						: section
				)
			)
		}
	}

	const onRemove = () => {
		setProductInfo(state =>
			state.reduce(( acc, currentSection, sIndex ) =>
				(
					sIndex === index || acc.push(currentSection)
				) && acc
				, []
			)
		)
	}

	const onSectionNameChange = ( value ) => {
		setProductInfo(state => state.map(( section, sIndex ) =>
			index === sIndex
				? {
					...section,
					name: value
				}
				: section
		))
	}

	const onDone = ( isValue = false ) => {
		setInitialState(state => isValue ? { ...state, value } : { ...state, name })

		setProductInfo(state => state.map(( section, sIndex ) =>
			index === sIndex
				? isValue
				? {
					...section,
					value: { ...section.value, isInput: false }
				}
				: {
					...section,
					isInput: false
				}
				: section
		))
	}

	const onCancel = ( isValue = false ) => {
		setProductInfo(state => state.map(( section, sIndex ) =>
			index === sIndex
				? isValue
				? {
					...section,
					value: {
						...initialState.value,
						isInput: false
					}
				}
				: {
					...section,
					name: initialState.name,
					isInput: false
				}
				: section
		))

		if (isValue) {
			const { contentBlocks, entityMap } = convertFromHTML(initialState.value.stringifyHtmlValue)

			setEditorState(
				EditorState.createWithContent(
					ContentState.createFromBlockArray(
						contentBlocks,
						entityMap
					)
				)
			)
		}
	}

	return (
		<InfoSubSection>
			<InfoSectionTitle>
				<div style={ { display: "flex", flexDirection: "row", alignItems: "center" } }>
					{
						isInput
							? (
								<div
									style={ {
										display: "flex",
										flexDirection: "column",
										width: "75%",
										alignItems: "center"
									} }
								>
									<IconInput
										type={ "text" }
										value={ name }
										autoFocus={ true }
										onKeyPress={ event => {
											if (isNameValid && event.key === "Enter") {
												onDone()
											}
										} }
										onChange={ value =>
											onSectionNameChange(value)
										}
									/>

									{ !isNameValid && (
										<ErrorMessage/>
									) }
								</div>
							)
							: <span>{ name }</span>
					}

					{ !!setProductInfo && (
						isInput
							? (
								<div style={ { display: "flex", flexDirection: "row" } }>
									<InfoIconPNG
										key={ "cancel" }
										name={ "x-mark" }
										// isTitle={ isTitle }
										onClick={ e => onCancel() }
									/>

									<div style={ { margin: "2px" } }/>

									{ isNameValid && (
										<InfoIconPNG
											key={ "done" }
											name={ "checkmark" }
											// isTitle={ isTitle }
											onClick={ e => onDone() }
										/>
									) }
								</div>
							)
							: (
								<InfoIconPNG
									key={ "edit" }
									name={ "edit-2" }
									// isTitle={ isTitle }
									onClick={ e => onEdit() }
								/>
							)
					) }
				</div>
			</InfoSectionTitle>

			<InfoContentContainer
				style={ {
					flexDirection: "column",
					justifyContent: "center",
					margin: "4px 0 4px 0"
				} }
			>
				{
					value.isInput
						? (
							<div>
								<Toolbar>
									{
										externalProps => (
											<div
												style={ {
													display: "flex",
													flexDirection: "row",
													alignItems: "center"
												} }
											>
												<InfoIconPNG
													key={ "cancel" }
													name={ "x-mark" }
													// isTitle={ isTitle }
													onClick={ e => onCancel(true) }
												/>
												{ !!isValueValid && (
													<InfoIconPNG
														key={ "done" }
														name={ "checkmark" }
														// isTitle={ isTitle }
														onClick={ e => onDone(true) }
													/>
												) }
												<BoldButton { ...externalProps }/>
												<ItalicButton { ...externalProps }/>
												<UnderlineButton { ...externalProps }/>
												{/*<CodeButton { ...externalProps }/>*/ }
												{/*<HeadlineOneButton { ...externalProps }/>*/ }
												{/*<HeadlineTwoButton { ...externalProps }/>*/ }
												{/*<HeadlineThreeButton { ...externalProps }/>*/ }
												<UnorderedListButton { ...externalProps }/>
												<OrderedListButton { ...externalProps }/>
												{/*<BlockquoteButton { ...externalProps }/>*/ }
												{/*<CodeBlockButton { ...externalProps }/>*/ }
											</div>
										)
									}
								</Toolbar>
								<div
									style={ {
										backgroundColor: "#ffffff",
										padding: "8px 20px 2px 8px",
										borderRadius: "8px",
									} }
								>
									<Editor
										editorState={ editorState }
										onChange={ editorState => setEditorState(editorState) }
										plugins={ plugins }
									/>
								</div>
								{ !isValueValid && (
									<ErrorMessage/>
								) }
							</div>
						)
						: (
							<div style={ { display: "flex", flexDirection: "column" } }>
								<InfoIconPNG
									key={ "edit" }
									name={ "edit-2" }
									// isTitle={ isTitle }
									onClick={ e => onEdit(true) }
									text={ "עריכה" }
								/>

								{ parse(value.stringifyHtmlValue) }
							</div>
						)
				}
			</InfoContentContainer>

			<div
				style={ {
					display: "flex",
					flexDirection: "row",
					flex: 2,
					alignItems: "center",
					padding: "20px"
				} }
			>
				<InfoIconPNG
					key={ "remove" }
					name={ "trash-10" }
					// isTitle={ isTitle }
					onClick={ e => onRemove({ sectionIndex: index }) }
					text={ "מחיקה" }
				/>
			</div>
		</InfoSubSection>
	)
}

const BadgeSection = ( { isDisabled, onBadgeChange, children } ) => (
	<InfoSubSection>
		<InfoSectionTitle>{ "תקנים" }</InfoSectionTitle>

		<InfoContentContainer>
			{ badgesOptions.map(badge => (
				<Checkbox
					label={ (
						<img
							style={ { maxHeight: 56, maxWidth: 56 } }
							src={ badge.path }
						/>
					) }
					disabled={ isDisabled }
					checked={ !!children && children.map(item => item.value).includes(badge.value) }
					onCheck={ ( event, isInputChecked ) => {
						onBadgeChange({ badge, isInputChecked })
					} }
				/>
			)) }
		</InfoContentContainer>

		<VariantColumn flex={ 3 }/>
	</InfoSubSection>
)

const getSectionValue = ( { list, isName = false } ) => (
	{
		isInput: false || isName,
		// htmlValue: getUnorderedList({ list, isName }),
		stringifyHtmlValue: getStringifyUnorderedList({ list, isName })
	}
)

const getUnorderedList = ( { list, isName = false } ) => (
	<ul>
		{ list.map(item => (
			<li>
				{ isName
					? <strong>{ item.name + ": " }</strong>
					: ""
				}
				{ item.value }
			</li>
		)) }
	</ul>
)

const getStringifyUnorderedList = ( { list, isName = false } ) => {
	let stringifyHtml = "<ul>"

	list.forEach(item => {
		stringifyHtml += `<li>${ isName ? `<strong>${ item.name + ": " }</strong>` : "" }${ item.value }</li>`
	})

	stringifyHtml += "</ul>"

	return stringifyHtml
}


const extractProductInfo = ( productRef ) => {
	const info = []

	if (!!productRef.generalInfo) {
		info.push({
			name: "מידע כללי",
			isInput: false,
			value: getSectionValue({ list: productRef.generalInfo, isName: true })
			// values: generalInfo,
		})
	}

	if (!!productRef.features) {
		info.push({
			name: "תכונות",
			isInput: false,
			value: getSectionValue({ list: productRef.features })
		})
	}

	if (!!productRef.warnings) {
		info.push({
			name: "אזהרות",
			isInput: false,
			value: getSectionValue({ list: productRef.warnings })
		})
	}

	if (!!productRef.spec) {
		info.push(...productRef.spec.map(spec => (
			{
				name: spec.name,
				isInput: false,
				value: getSectionValue({ list: spec.value })
			}
		)))
	}

	if (!!productRef.badges) {
		info.push({
			name: "תקנים",
			isBadge: true,
			values: productRef.badges
		})
	}

	return info
}

const getCollectionState = ( { variantsState, collectionProps } ) =>
	mergeCollectionProps({
		props: collectionProps,
		state: variantsState
			.map(( state, i ) =>
				state.values.map(( value, j ) => (
					{
						key: state.name,
						value,
						id: "".concat(state.name, ".", value),
					}
				))
			)
			.reduce(( acc, current ) =>
					mergeVariantsIntoCollection({ accState: acc, currentState: current })
				, []
			),
	})

const mergeVariantsIntoCollection = ( { accState, currentState } ) => {
	const newState = []

	if (accState.length === 0) {
		newState.push(...currentState.map(valueState => (
			{
				name: valueState.value,
				variantIds: [ valueState.id ],
			}
		)))
	} else {
		accState.forEach(variantState => {
			currentState.forEach(valueState => {
				newState.push({
					name: variantState.name.concat(", ", valueState.value),
					variantIds: variantState.variantIds.concat(valueState.id)
				})
			})
		})
	}

	return newState
}

const mergeCollectionProps = ( { state, props } ) =>
	state
		.map(collection => {
			for (let i = 0; i < props.length; i++) {
				let isMatched = true

				for (let j = 0; j < props[ i ].variantIds.length; j++) {
					isMatched = collection.variantIds.includes(props[ i ].variantIds[ j ])

					if (!isMatched) {
						break
					}
				}

				if (isMatched) {
					return {
						...collection,
						...props[ i ],
					}
				}
			}

			return collection
		})

const ErrorMessage = ( { message = null } ) => (
	<div style={ { padding: "4px 8px", fontWeight: "bold", color: "red" } }>
		{
			"לא תקין".concat(!!message ? "".concat(":", " ", message) : "")
		}
	</div>
)

const NAME_WIDTH = 200

const AddButton = styled.div`
  display: flex;
  height: 30px;
  width: 70px;
  border-radius: 4px;
  cursor: pointer;
  padding: 4px;
  font-weight: bold;
  overflow: visible;
  white-space: pre;
`

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px;
`

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 4px 0;
`

const NameComponent = styled.div`
  width: ${ NAME_WIDTH }px;
  font-size: 16px;
  font-family: ${ fonts.OpenSansHebrew };
  margin: 0 0 0 16px;
`

const Tag = styled.span`
  border-radius: 15px;
  background-color: #4d4d4d;
  font-family: ${ fonts.OpenSansHebrew };
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #ffffff;
  border: none;
`

const VariantColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: ${ ( { flex = 1 } ) => flex };
  margin: 8px 0;
`

const PRODUCT_REF_EXAMPLE = {
	badges: [
		{
			label: "תו תקן",
			path: "img/productBagdes/il/badge-red.png",
			value: "red",
		},
		{
			label: "תו תקן ירוק",
			path: "img/productBagdes/il/badge-green.png",
			value: "green",
		}
	],
	catalogId: "termo700",
	description: "דבק צמנטי גמיש מאד להדבקת אריחים↵",
	disabled: false,
	features: [
		{
			isGroup: false,
			key: "features_5a7101b8-08a0-46f6-be7c-02f382bce8d4",
			name: "הוסף שורה כאן...",
			value: "להדבקת אריחים על בטון, בלוק בטון, בלוק בטון תאי, טיח' לוח גבס וכד'.",
		},
		{
			isGroup: false,
			key: "features_ed95e14b-073c-43d1-ba13-8be5cbb3bee3",
			name: "הוסף שורה כאן...",
			value: 'מתאים לאריחים מסוג : קרמיקה ופורצלן בעלי ספיגות עד 10%, שיש, אבן טבעית, אבן "כורכרית", סיליקט'
				+ " וכד'.",
		},
		{
			isGroup: false,
			key: "features_16c0ac86-c595-45ea-bb43-f33524cf2a31",
			name: "הוסף שורה כאן...",
			value: 'עובי שכבת הדבק 5-10 מ"מ (עד 20 מ"מ בשתי שכבות).'
		},
		{
			isGroup: false,
			key: "features_e4dd7b33-c55d-457d-a122-3389683c6dd8",
			name: "הוסף שורה כאן...",
			value: "שומר על תכונותיו, ביישום בטמפרטורה עד 150°C.",
		},
		{
			isGroup: false,
			key: "features_704fdf46-87af-4f81-857d-e08c56782c93",
			name: "הוסף שורה כאן...",
			value: "ליישום פנימי וחיצוני.",
		},
		{
			isGroup: false,
			key: "features_d000319a-9740-404c-8bc4-6b75f0470286",
			name: "הוסף שורה כאן...",
			value: "תערובת מוכנה לשימוש בתוספת מים בלבד.",
		}
	],
	generalInfo: [
		{
			isGroup: false,
			key: "generalInfo_5e49f622-83e4-4bb5-9bfe-e5fb50a02207",
			name: "אריזה",
			value: 'שק, 25 ק"ג↵',
		},
		{
			isGroup: false,
			key: "generalInfo_706b2831-4db0-4d74-bc35-e098f9ea891a",
			name: "הרכב",
			value: "צמנט, אגרגטים מדורגים, שרפים ומוספים מיוחדים.",
		},
		{
			isGroup: false,
			key: "generalInfo_0eabcec4-d952-4e52-8865-6364ffc31b34",
			name: "צריכה",
			value: 'כ-1.30 ק"ג/מ"ר בעובי 1 מ"מ. התצרוכת עשויה להשתנות בהתאם לתנאי התשתית ולאופן היישום.',
		},
		{
			isGroup: false,
			key: "generalInfo_8bfa0e71-fd3e-4a98-b189-86c280a58b32",
			name: "אחסון",
			value: "יש לאחסן את המוצר במקום מוצל ומאוורר היטב.↵",
		},
		{
			isGroup: false,
			key: "generalInfo_58343a1b-dcd1-4d22-b028-851e69dbdb6e",
			name: "חיי מדף",
			value: "החומר מתאים לשימוש עד 9 חודשים מתאריך הייצור, כשהוא מאוחסן באריזתו המקורית ובתנאים המומלצים.",
		},
		{
			isGroup: false,
			key: "generalInfo_21319dc4-f395-4de3-bc3e-01165585e5cd",
			name: "תשתית",
			value: "בטון, בלוק בטון, בלוק בטון תאי, טיח, לוחות צמנט וכד'.",
		},
		{
			isGroup: false,
			key: "generalInfo_fc1dd5e4-c685-48fd-b161-669eb031ca27",
			name: "גוון",
			value: "לבן",
		},
		{
			isGroup: false,
			key: "generalInfo_da8cd415-a3a4-4c38-ba82-243304970547",
			name: "סוג הדבק",
			value: "C2TE",
		},
		{
			isGroup: false,
			key: "generalInfo_bd5cfc7d-d925-487b-ba8e-e9e971df27e0",
			name: "גמישות",
			value: "S2",
		},
		{
			isGroup: false,
			key: "generalInfo_beace8bd-edb6-4c02-8442-0e174ee17f49",
			name: "טמפרטורה",
			value: "טווח הטמפ' המותרת ליישום: 5°-35° צלזיוס.",
		},
		{
			isGroup: false,
			key: "generalInfo_48b96966-5a7c-40cd-b7f0-2219f5fc2502",
			name: "אחריות",
			value: "אחריות המפעל מוגבלת לטיב ולאיכות החומרים בלבד ואינה באה במקום אחריות המבצע. על המבצע לבדוק התאמת"
				+ " המוצר והמערכת לצרכיו ולביצוע נאות של כל העבודה. אנו שומרים לעצמנו את הזכות לעדכן או לשנות את הנתונים וההמלצות ללא הודעה מוקדמת. באחריות המבצע להתעדכן בעדכונים האחרונים.↵",
		}
	],
	id: "9baeb6ec25944e1ab3797e32a12f1f43",
	images: {
		"-L_luA8vergLLOUFr6kx": {
			downloadURL: "https://firebasestorage.googleapis.com/v0/b/ibuildit-prod.appspot.com/o/itemsBySupplier%2Fc13ee4bcf17c4983a50202bdc10e5829%2F9baeb6ec25944e1ab3797e32a12f1f43%2Fimages%2F-L_luA8vergLLOUFr6kx?alt=media&token=acc2e2d7-7784-4ca6-96e1-728c7ce2ae34",
			fullPath: "itemsBySupplier/c13ee4bcf17c4983a50202bdc10e5829/9baeb6ec25944e1ab3797e32a12f1f43/images/-L_luA8vergLLOUFr6kx",
			name: "-L_luA8vergLLOUFr6kx",
		}
	},
	isGenericProduct: true,
	isRentalProduct: false,
	isSupplementalProduct: false,
	keywords: [ "תרמוקיר", "700" ],
	name: "תרמוקיר 700 AD (פלסטומר 700)",
	packagingInfo: "84 במשטח",
	spec: [
		{
			isGroup: true,
			key: "spec_2f8b0322-dcb4-49fd-bf12-b54d677166c0",
			name: "נתונים טכניים",
			value: [
				{
					key: "specspec_2f8b0322-dcb4-49fd-bf12-b54d677166c0_270086e1-7785-47a5-b853-264021c4bfa1",
					name: "",
					value: "משך השמישות לעבודה (POT-LIFE): עד 2 שעות.",
				},
				{
					key: "specspec_2f8b0322-dcb4-49fd-bf12-b54d677166c0_2d381521-352f-4a1e-b14b-13630ed0b6fd",
					name: "",
					value: "משך הזמן החופשי (OPEN TIME): מעל 40 דקות.",
				},
				{
					key: "specspec_2f8b0322-dcb4-49fd-bf12-b54d677166c0_125acc4b-08b1-43a4-962e-1446b9a273ea",
					name: "",
					value: "מקדם ספיגות נימית : K< 0.1 Kg / m2 • h0.5.",
				},
				{
					key: "specspec_2f8b0322-dcb4-49fd-bf12-b54d677166c0_b1887237-3dc8-440a-87b0-a5bb40aac853",
					name: "",
					value: 'עיווי רוחבי (גמישות): גדול מ-5 מ"מ.'
				},
				{
					key: "specspec_2f8b0322-dcb4-49fd-bf12-b54d677166c0_588d0151-157d-485d-ba93-19e3f408406a",
					name: "",
					value: 'חוזק הידבקות בגזירה לאחר 28 יום: גדול מ- 2.5 מגפ"ס.',
				}
			]
		},
		{
			isGroup: true,
			key: "spec_3c3f03d2-9f34-4982-ad35-96f9cc89d735",
			name: "הכנת התשתית",
			value: [
				{
					key: "specspec_3c3f03d2-9f34-4982-ad35-96f9cc89d735_7178a918-a4cf-406a-8f39-9467dc64e9d1",
					name: "",
					value: 'יש לוודא שהרקע מסוגל לשאת את כל שכבות הטיח, שכבת הדבק והאריחים, עפ"י דרישות התקנים הרלונטים.'
				},
				{
					key: "specspec_3c3f03d2-9f34-4982-ad35-96f9cc89d735_c9e87d9f-3aaa-4fa1-a434-9a99ce634c1a",
					name: "",
					value: "יום לפני היישום יש לשטוף את התשתית בלחץ מים להסרת אבק, שמן, מלחים וכל גורם אחר היכול"
						+ " לגרום לכשל בהדבקה.",
				}
			]
		}
	],
	supplierId: "c13ee4bcf17c4983a50202bdc10e5829",
	unitType: "quantity",
	version: 1594907230746,
	warnings: [
		{
			isGroup: false,
			key: "generalInfo_8bd99a9f-a88c-485c-bdfa-ca40a7104788",
			name: "אזהרות",
			value: "חל איסור מוחלט על מגע ו/או הוספה של חומרים אחרים לרבות גבס ו/או מוצרים המכילים גבס למוצר זה.",
		},
		{
			isGroup: false,
			key: "warnings_eb635618-ab8f-409d-afb9-f4585ca34a6d",
			name: "אזהרה - ",
			value: "היות והחומר אלקלי, יש להגן היטב על העור והעיניים. במקרה של מגע יש לשטוף מידית במים זורמים.↵",
		},
		{
			isGroup: false,
			key: "warnings_59680cba-dd3c-4be7-a0e5-196a5c77544e",
			name: "אזהרה - ",
			value: "אריחים שהודבקו, אסור לשטוף במים ואסור שיהיו חשופים לגשם לפחות 72 שעות מגמר היישום.↵",
		},
		{
			isGroup: false,
			key: "warnings_bc4b4347-7317-4740-a7ab-2bb964348847",
			name: "אזהרה - ",
			value: "יש להדביק את האריחים על הפלסטומר המסורק כל עוד לא התייבש (רטוב על רטוב).↵",
		}
	],
}

const mapStateToProps = state => (
	{
		// initialCatalogProduct: state.catalogProduct,
		// id: state.catalogProduct.id,
		// catalogId: state.catalogProduct.catalogId,
		// name: state.catalogProduct.name,
		// productName: state.catalogProduct.productName,
		// mode: state.catalogProduct.mode,
		// images: state.catalogProduct.images,
		// category: state.catalogProduct.category,
		// unitType: state.catalogProduct.unitType,
		// description: state.catalogProduct.description,
		// price: state.catalogProduct.price,
		// supplierId: state.catalog.supplier,
		// categories: state.catalogProduct.categories,
		// longDescription: state.catalogProduct.longDescription,
		// badges: state.catalogProduct.badges,
		// spec: state.catalogProduct.spec,
		// keywords: state.catalogProduct.keywords,
		// minPrice: state.catalogProduct.minPrice,
		// scannerId: state.catalogProduct.scannerId,
		// features: state.catalogProduct.features,
		// warnings: state.catalogProduct.warnings,
		// generalInfo: state.catalogProduct.generalInfo,
		// relatedProducts: state.catalogProduct.relatedProducts,
		// isSupplementalProduct: state.catalogProduct.isSupplementalProduct,
		// isGenericProduct: state.catalogProduct.isGenericProduct,
		// disabled: state.catalogProduct.disabled,
		// isRentalProduct: state.catalogProduct.isRentalProduct,
		// productRef: state.catalogProduct.productRef,
		// addOnProducts: state.catalogProduct.addOnProducts,
		suppliers: state.firebase.data.suppliers,
		searchResults: state.relatedProductCatalog.catalog,
		isLoadingSearch: state.relatedProductCatalog.isLoading,
		collectionProps: state.catalogProduct.collectionProps || [
			{
				variantIds: [ "מידה.S", "צבע.שחור", "בד.כותנה" ],
				id: "1",
				barcode: [ "124" ],
				images: []
			},
			{
				variantIds: [ "מידה.S", "צבע.שחור", "בד.פוליאסטר" ],
				id: "2",
				barcode: [ "1424" ],
				images: []
			},
			{
				variantIds: [ "מידה.S", "צבע.לבן", "בד.כותנה" ],
				id: "3",
				barcode: [ "1424" ],
				images: []
			},
			{
				variantIds: [ "מידה.S", "צבע.לבן", "בד.פוליאסטר" ],
				id: "4",
				barcode: [ "1424" ],
				images: []
			},
			{
				variantIds: [ "מידה.M", "צבע.שחור", "בד.כותנה" ],
				id: "5",
				barcode: [ "1424" ],
				images: []
			},
			{
				variantIds: [ "מידה.M", "צבע.שחור", "בד.פוליאסטר" ],
				id: "6",
				barcode: [ "1424" ],
				images: []
			},
		],
		// variants: state.catalogProduct.variants,
		// rentInterval: state.catalogProduct.rentInterval,
		// packagingInfo: state.catalogProduct.packagingInfo
	}
);

const mapDispatchToProps = dispatch => bindActionCreators({
	// setName,
	// setProductName,
	// // mapStateToProps,
	// setId,
	// setCategory,
	// setDescription,
	// setPrice,
	// setImages,
	// addImage,
	// removeImage,
	updateProduct,
	addProduct,
	// setMode,
	// addGroupItem,
	// removeGroupItem,
	// onGroupItemChange,
	// onProductFieldChange,
	// onSubGroupItemChange,
	// addSubGroupItem,
	// removeSubGroupItem,
	// editGroupItem,
	isCatalogIdExist,
	// setProductRef,
	getCatalog,
	// clearCatalog,
	// setUnitType,
	getProduct,
	getCollectionProps,
	// setPackagingInfo,
	// addAddonProduct,
	// removeAddonProduct,
	// setProduct
}, dispatch);

export default compose(
	firebaseConnect([ 'suppliers' ]),
	connect(mapStateToProps, mapDispatchToProps)
)(injectIntl(ProductForm));