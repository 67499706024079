import React, {Component} from 'react';
import PropTypes from 'prop-types'
import styled from 'styled-components';

import TextFieldWithButton from "../../components/Form/TextFieldWithButton";

import FontIcon from 'material-ui/FontIcon';

class TagsManager extends Component{

    constructor() {
        super()
    }


    render() {

        const {addTag, removeTag, tags, limit, buttonLabel, title} = this.props;

        return (
            <Wrapper>
                <TitleWrapper>{title}</TitleWrapper>
                <TextFieldWithButton
                    buttonClickHandler={addTag}
                    buttonLabel={buttonLabel}
                    disabled={limit && tags.length > 0 && tags.length === limit}
                />
                <TagsWrapper>
                    {
                        tags.map((tag)=>(
                            <Tag
                                key={tag.key}
                                label={tag.label}
                                onDelete={()=>{removeTag(tag.key)}}
                            />
                        ))
                    }
                </TagsWrapper>
            </Wrapper>
        )
    }
}



const Tag = ({label, onDelete}) => {
    return (
        <StyledTag>
            <FontIcon onClick={onDelete} className="material-icons" style={{cursor: "pointer", fontSize: 12, fontWeight: "bold", color: "#20A8D8"}}>close</FontIcon>
            <span>{label}</span>
        </StyledTag>
    )
};

const Wrapper = styled.div`
    padding: 14px 25px;
    border-radius: 6px;
    background: #fff;
    min-height: 180px;
    
`;

const TitleWrapper = styled.div`
    font-size: 16px;
    font-weight: bold;
    color: #404040;
    padding-bottom: 5px;
    border-bottom: 1px solid #E4E5E6;
    margin-bottom: 20px;
`;

const TagsWrapper = styled.div`
    display: flex;
    margin-top: 20px;
`;

const StyledTag = styled.div`
    border: 1px solid #20A8D8;
    border-radius: 11px;
    color: #707070;
    font-size: 16px;
    padding-left: 5px;
    padding-right: 5px;
    margin-right: 5px;
    &:fist-child:{
        margin-right: 0;
    }
`;

TagsManager.propTypes = {
    title: PropTypes.string,
    buttonLabel: PropTypes.string,
    addTag: PropTypes.func,
    removeTag: PropTypes.func,
    tags: PropTypes.array,
    limit: PropTypes.number
};

export default TagsManager;