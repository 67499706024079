import React, { Component } from "react";
import PropTypes from "prop-types";
import { firebaseConnect, getVal } from "react-redux-firebase";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { Col, Row } from "reactstrap";
import {
  Card,
  CartHeader,
  InputGroup,
  InputLabel,
} from "../../../styles/stylesheet";
import ImageUploader from "../ImageUploader";
import IconInput from "../../../components/Form/IconInput";
import AddButton from "../../../components/AddButton";
import ConfirmButton from "../../../components/ConfirmButton";
import ReactTable from "react-table";
import { toastr } from "react-redux-toastr";
import { uuidWithoutSpace } from "shared/src/utils";
import _ from "lodash";

import styled from "styled-components";
import { colors, fonts } from "../../../styles/styleguide";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import {
  defaultDeliveryTypes,
  HEB_PICK_UP,
  HEB_DELIVERY,
} from "shared/src/constants/deliveries";

class Deliveries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deliveryOptions: [],
      deliveryOptionName: "",
      deliveryOptionType: "",
      // deliveryOptionPrice: "",
      deliveryOptionNameError: false,
      deliveryOptionTypeError: false,
      // deliveryOptionPriceError: false,
      isSwitchChecked: !!props.deliveryOptionsBySupplier,
      isOpenUncheckedAlert: false,
    };

    // console.log(
    //   "constructor props.deliveryOptionsBySupplier",
    //   props.deliveryOptionsBySupplier
    // );
  }

  componentDidMount() {
    this.setState({
      deliveryOptions: this.props.deliveryOptionsBySupplier || [],
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      !_.isEqual(
        prevProps.deliveryOptionsBySupplier,
        this.props.deliveryOptionsBySupplier
      )
    ) {
      this.setState({
        deliveryOptions: this.props.deliveryOptionsBySupplier || [],
      });
    }
  }

  saveDeliveryOptions = () => {
    const { deliveryOptions, isSwitchChecked } = this.state;

    const { firebase, supplierId } = this.props;

    if (!supplierId) {
      toastr.error("יש לשמור את פרטי הספק לפני ששומרים את אפשרויות המשלוח");
      return;
    }

    if (isSwitchChecked) {
      const transformedOptions = deliveryOptions.map((o) => {
        if (o.__isNew__) {
          o.value = uuidWithoutSpace();
          delete o.__isNew__;
        }
        return o;
      });

      firebase.set(
        `deliveryOptionsBySupplier/${this.props.supplierId}`,
        transformedOptions,
        (res) => {
          toastr.success("אפשרויות המשלוח נשמרו בהצלחה");
        }
      );
    } else {
      firebase.set(
        `deliveryOptionsBySupplier/${this.props.supplierId}`,
        null,
        (res) => {
          toastr.success("אפשרויות המשלוח נשמרו בהצלחה");
        }
      );
    }
  };

  addDeliveryOption = (name, type) => {
    if (!this.validateOption(name, type)) {
      return;
    }

    const option = {
      label: name,
      price: "0",
      type,
      value: uuidWithoutSpace(),
    };

    const deliveryOptions = _.cloneDeep(this.state.deliveryOptions);
    deliveryOptions.push(option);
    this.setState({
      deliveryOptions,
      deliveryOptionNameError: false,
      deliveryOptionTypeError: false,
      // deliveryOptionPriceError: false,
      deliveryOptionName: "",
      deliveryOptionType: "",
      // deliveryOptionPrice: ""
    });
  };

  validateOption = (name, type) => {
    let isValid = true;
    if (!name) {
      isValid = false;
      this.setState({
        deliveryOptionNameError: true,
      });
    }

    if (!type) {
      isValid = false;
      this.setState({
        deliveryOptionTypeError: true,
      });
    }

    // if (isNaN(parseInt(price))) {
    //     isValid = false;
    //     this.setState({
    //         deliveryOptionPriceError: true,
    //     });
    // }

    return isValid;
  };

  removeDeliveryOption = (index) => {
    const deliveryOptions = _.cloneDeep(this.state.deliveryOptions);
    deliveryOptions.splice(index, 1);
    this.setState({
      deliveryOptions,
    });
  };

  render() {
    const {
      deliveryOptions,
      deliveryOptionName,
      deliveryOptionType,
      // deliveryOptionPrice,
      deliveryOptionNameError,
      deliveryOptionTypeError,
      // deliveryOptionPriceError,
      isSwitchChecked,
    } = this.state;

    // console.log("DeliveriesOptions");
    // console.log("this.state", this.state);
    // console.log("this.props", this.props);

    return (
      <div>
        <Row>
          <Col md={12}>
            <Card>
              <CartHeader
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                אפשרויות משלוח
                <YellowSwitch
                  checked={isSwitchChecked}
                  onChange={() => {
                    if (isSwitchChecked) {
                      this.setState({ isOpenUncheckedAlert: true });
                    } else {
                      this.setState({
                        isSwitchChecked: true,
                      });
                    }
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 20,
                  }}
                >
                  <ConfirmButton
                    onClick={this.saveDeliveryOptions}
                    label={"שמירה"}
                  />
                </div>
              </CartHeader>

              {isSwitchChecked && (
                <Row>
                  <Col md={3}>
                    <InputGroup>
                      <InputLabel>{"סוג משלוח"}</InputLabel>
                      <IconInput
                        type={"text"}
                        onChange={(text) => {
                          this.setState({ deliveryOptionName: text });
                        }}
                        value={deliveryOptionName}
                        icon={<img src={"img/icons/driver.svg"} />}
                      />
                      {deliveryOptionNameError && (
                        <div style={{ color: "red" }}>יש להזין סוג משלוח</div>
                      )}
                    </InputGroup>
                  </Col>

                  <Col md={3}>
                    <InputGroup>
                      <InputLabel>{"קטגוריה"}</InputLabel>
                      {
                        // <IconInput
                        //   type={"text"}
                        //   onChange={(text) => {
                        //     this.setState({ deliveryOptionType: text });
                        //   }}
                        //   value={deliveryOptionType}
                        // />
                      }

                      {
                        <IconInput
                          type={"select"}
                          disabled={false}
                          options={Object.values(defaultDeliveryTypes)}
                          onChange={(hebType) => {
                            this.setState({
                              deliveryOptionType: hebType,
                            });
                          }}
                          value={deliveryOptionType}
                          labelAccessor={"heb"}
                        />
                      }

                      {deliveryOptionTypeError && (
                        <div style={{ color: "red" }}>
                          {"יש לבחור קטגוריה"}
                        </div>
                      )}
                    </InputGroup>
                  </Col>

                  <Col md={2} className={"align-self-end"}>
                    <AddButton
                      onClick={() => {
                        this.addDeliveryOption(
                          deliveryOptionName,
                          deliveryOptionType
                        );
                      }}
                      label={"הוספה"}
                    />
                  </Col>
                </Row>
              )}
            </Card>

            {isSwitchChecked && (
              <Row>
                <Col md={8}>
                  <ReactTable
                    style={{ minHeight: 168, marginTop: 25 }}
                    data={deliveryOptions || []}
                    columns={[
                      {
                        id: "label",
                        Header: "סוג משלוח",
                        accessor: "label", // String-based value accessors!,
                      },
                      {
                        id: "type",
                        Header: "קטגוריה",
                        accessor: "type", // String-based value accessors!,
                      },
                      {
                        id: "actions",
                        Header: "",
                        width: 50,
                        Cell: (props) => {
                          // console.log("props", props);
                          return (
                            <img
                              src={"img/icons/close_red.svg"}
                              style={{ width: 20 }}
                              onClick={() => {
                                this.removeDeliveryOption(props.index);
                              }}
                            />
                          );
                        }, //<EllipsisText text={props.value || ''} length={18}/>, // Custom cell components!
                      },
                    ]}
                    showPagination={false}
                    pageSize={deliveryOptions ? deliveryOptions.length : 0}
                    filterable={false}
                  />
                </Col>
              </Row>
            )}
          </Col>
        </Row>

        <Dialog
          open={this.state.isOpenUncheckedAlert}
          onClose={() => {
            this.setState({ isOpenUncheckedAlert: false });
          }}
        >
          <DialogTitle>{"זהירות! הנתונים ימחקו בזמן השמירה."}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {"האם את/ה בטוח/ה שברצונך למחוק את כל אפשרויות המשלוח?"}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <div style={{ backgroundColor: APP_YELLOW }}>
              <Button
                autoFocus
                onClick={() => {
                  this.setState({ isOpenUncheckedAlert: false });
                }}
              >
                <div style={{ fontWeight: "bold" }}>ביטול</div>
              </Button>
            </div>
            <Button
              onClick={() => {
                this.setState({ isSwitchChecked: false });
                this.setState({ isOpenUncheckedAlert: false });
              }}
            >
              המשך
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const APP_YELLOW = "#f5c608";

const YellowSwitch = withStyles({
  switchBase: {
    color: "#eee",
    "&$checked": {
      color: APP_YELLOW,
    },
    "&$checked + $track": {
      backgroundColor: APP_YELLOW,
    },
  },
  checked: {},
  track: {},
})(Switch);

// Deliveries.defaultProps = {
//   defaultDeliveryOptions: [
//     {
//       label: "איסוף עצמי",
//       price: 0,
//       value: "f5725fd5315f4753a6751ec6a357c255",
//     },
//     {
//       label: "משלוח",
//       price: 0,
//       value: "3a7cfc3dc8694507b8948ff5f9ede696",
//     },
//   ],
// };

Deliveries.propTypes = {
  supplierId: PropTypes.string.isRequired,
};

const mapStateToProps = (state, props) => {
  return {
    deliveryOptionsBySupplier: getVal(
      state.firebase.data,
      `deliveryOptionsBySupplier/${props.supplierId}`,
      []
    ),
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default compose(
  firebaseConnect((props) => [`deliveryOptionsBySupplier/${props.supplierId}`]),
  connect(mapStateToProps, mapDispatchToProps)
)(Deliveries);
