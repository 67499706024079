import React from 'react';

import WebSearchBox from "./WebSearchBox";
import ProductListItem from "./ProductListItem";
import CatalogItem from "../CatalogManagement/CatalogItem"
//import WebSearchResults from './WebSearchResults';
//const results = WebSearchResults(ProductListItem);

const SearchBox = WebSearchBox(CatalogItem);


class CatalogSearch extends React.Component{

    constructor() {
        super()
    }

    render() {
        return (
           <div>
              <SearchBox/>
           </div>
        )
    }
}

export default CatalogSearch;