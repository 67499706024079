import React, {Component}  from 'react';
import Loader from "../../components/Loader";

const WebSearchResults = ListItem => {
    return class extends Component {
        render() {
            const listItems = this.props.results.map((result, index) => {
                return (
                    <ListItem key={`result-${index}`} item={result} showAdminButtons={false}/>
                );
            });
            const {isLoading} = this.props;
            return (
                <ul>
                    {isLoading && (<Loader/>)}
                    {listItems}
                </ul>
            );
        };
    };
};

export default WebSearchResults;