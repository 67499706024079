import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect'
import { isSystemAdmin,
    isSupplierAdmin,
    getSupplierIds,
    haveSupplierCatalogAccess,
    haveSupplierUsersAccess,
    haveSupplierOrdersAccess,
    haveContractorOrdersAccess,
    haveContractorManagementAccess,
    isCatalogAdmin
} from "shared/src/selectors/roles";
import Loader from "./components/Loader"
import {isLoaded, isEmpty } from 'react-redux-firebase'

export const userIsAdminOrSupplier = connectedRouterRedirect({
    redirectPath: '/',
    authenticatedSelector: state => haveSupplierCatalogAccess(state.roles.roles),
    wrapperDisplayName: 'UserIsAdminOrSupplier',
    // Returns true if the user auth state is loading
    authenticatingSelector: state => !isLoaded(state.firebase.profile),
    // Render this component when the authenticatingSelector returns true
    AuthenticatingComponent: Loader
});

export const userIsSupplierUsersAdmin = connectedRouterRedirect({
    redirectPath: '/',
    authenticatedSelector: state => isSystemAdmin(state.roles.roles) || haveSupplierUsersAccess(state.roles.roles),
    wrapperDisplayName: 'UserIsAdminOrSupplier',
    // Returns true if the user auth state is loading
    authenticatingSelector: state => !isLoaded(state.firebase.profile),
    // Render this component when the authenticatingSelector returns true
    AuthenticatingComponent: Loader
});

export const userIsCatalogAdmin = connectedRouterRedirect({
    redirectPath: '/',
    authenticatedSelector: state => isCatalogAdmin(state.roles.roles),
    wrapperDisplayName: 'UserIsAdmin',
    // Returns true if the user auth state is loading
    authenticatingSelector: state => !isLoaded(state.firebase.profile),
    // Render this component when the authenticatingSelector returns true
    AuthenticatingComponent: Loader
})


export const userIsAdmin = connectedRouterRedirect({
    redirectPath: '/',
    authenticatedSelector: state => isSystemAdmin(state.roles.roles),
    wrapperDisplayName: 'UserIsAdmin',
    // Returns true if the user auth state is loading
    authenticatingSelector: state => !isLoaded(state.firebase.profile),
    // Render this component when the authenticatingSelector returns true
    AuthenticatingComponent: Loader
})

export const userIsSupplierOrdersAdmin = connectedRouterRedirect({
    redirectPath: '/',
    authenticatedSelector: state => haveSupplierOrdersAccess(state.roles.roles),
    wrapperDisplayName: 'userIsSupplierOrdersAdmin',
    // Returns true if the user auth state is loading
    authenticatingSelector: state => !isLoaded(state.firebase.profile),
    // Render this component when the authenticatingSelector returns true
    AuthenticatingComponent: Loader
});

export const userIsSupplierOrContractorOrdersAdmin = connectedRouterRedirect({
    redirectPath: '/',
    authenticatedSelector: state => haveSupplierOrdersAccess(state.roles.roles) || haveContractorOrdersAccess(state.roles.roles),
    wrapperDisplayName: 'userIsSupplierOrdersAdmin',
    // Returns true if the user auth state is loading
    authenticatingSelector: state => !isLoaded(state.firebase.profile),
    // Render this component when the authenticatingSelector returns true
    AuthenticatingComponent: Loader
});

export const userIsContractorOrdersAdmin = connectedRouterRedirect({
    redirectPath: '/',
    authenticatedSelector: state =>  haveContractorManagementAccess(state.roles.roles),
    wrapperDisplayName: 'userIsContractorOrdersAdmin',
    // Returns true if the user auth state is loading
    authenticatingSelector: state => !isLoaded(state.firebase.profile),
    // Render this component when the authenticatingSelector returns true
    AuthenticatingComponent: Loader
});


export const userIsProjectOrdersAdmin = connectedRouterRedirect({
    redirectPath: '/',
    authenticatedSelector: state =>  haveContractorOrdersAccess(state.roles.roles),
    wrapperDisplayName: 'userIsProjectOrdersAdmin',
    // Returns true if the user auth state is loading
    authenticatingSelector: state => !isLoaded(state.firebase.profile),
    // Render this component when the authenticatingSelector returns true
    AuthenticatingComponent: Loader
});


export const userIsLoggedIn = connectedRouterRedirect({
    redirectPath: '/login',
    authenticatedSelector: state => isLoaded(state.firebase.auth) && !isEmpty(state.firebase.auth) && !state.firebase.auth.isAnonymous,
    wrapperDisplayName: 'userIsLoggedIn',
    // Returns true if the user auth state is loading
    authenticatingSelector: state => !isLoaded(state.firebase.auth),
    // Render this component when the authenticatingSelector returns true
    AuthenticatingComponent: Loader,

    allowRedirectBack: false

});
