import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import {injectIntl, FormattedMessage} from 'react-intl';
import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button
} from 'reactstrap';

import styled from 'styled-components';
import {map} from "lodash";


class MediaUploader extends Component {

    constructor() {
        super();
        this.state = {
            itemToDelete: null,
            isModalOpen: false,
            selectedImage: null
        }

        this.openModal = this.openModal.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    openModal(file, key) {
        this.setState({
            isModalOpen: true,
            itemToDelete: {
                file,
                key
            }
        })
    }

    confirmDelete() {
        const {itemToDelete} = this.state;
        const {onDeleteFile} = this.props;
        onDeleteFile(itemToDelete.file, itemToDelete.key);
        this.closeModal();
    }

    closeModal() {
        this.setState({
            isModalOpen: false,
            itemToDelete: null
        })
    }

    render() {
        const {uploadedFiles, onFilesDrop, showSidePreview, showAddIconOnPreview, relativeDimensions, dropzoneHeight, dropzoneBg, accept} = this.props;
        const {isModalOpen, itemToDelete, selectedImage} = this.state;

        return (
            <div>
                <Wrapper>
                    <Dropzone style={{position: "relative", width:"100%"}} onDrop={onFilesDrop} accept={accept}>
                        <Drop relativeDimensions={relativeDimensions} dropzoneHeight={dropzoneHeight} dropzoneBg={dropzoneBg}>
                            {uploadedFiles && (
                                <div>
                                    <ResponsiveImage
                                        dropzoneHeight={dropzoneHeight}
                                        src={selectedImage ? selectedImage.downloadURL : Object.values(uploadedFiles)[0].downloadURL}
                                    />
                                </div>

                            )}
                            { uploadedFiles && showAddIconOnPreview && (
                                <UploadImageWrapper>
                                    <UploadImage src="img/AddImage.png"/>
                                </UploadImageWrapper>
                            )}
                            {!uploadedFiles && (
                                    <UploadImageWrapper>
                                        <UploadImage src="img/AddImage.png"/>
                                    </UploadImageWrapper>
                                )
                            }


                        </Drop>
                    </Dropzone>

                    {!uploadedFiles && showSidePreview &&(
                        <Preview>
                            <PreviewItem/>
                            <PreviewItem/>
                            <PreviewItem/>
                            <PreviewItem/>
                        </Preview>
                    )}

                    {
                        uploadedFiles && showSidePreview && (
                            <Preview>
                            {
                                map(uploadedFiles, (file, key) => (
                                    <PreviewItem key={file.name + key} onClick={
                                        ()=>{ this.setState({selectedImage: file})}
                                    }>
                                        <ResponsiveImage src={file.downloadURL}/>
                                        <DeleteButton onClick={(e) => {
                                            e.preventDefault();
                                            this.openModal(file, key)
                                        }}>
                                            X
                                        </DeleteButton>
                                    </PreviewItem>
                                ))
                            }
                        </Preview>)


                    }
                    {!showSidePreview && uploadedFiles && (
                        <DeleteButton onClick={(e) => {
                            e.preventDefault();
                            this.openModal(Object.values(uploadedFiles)[0], Object.keys(uploadedFiles)[0])
                        }}>
                            X
                        </DeleteButton>
                    )}
                </Wrapper>


                <Modal isOpen={isModalOpen} className={'modal-danger '}>
                    <ModalHeader><FormattedMessage id="image.deleteModal.title"/></ModalHeader>
                    <ModalBody>
                        <FormattedMessage id="image.deleteModal.question"/>
                        {itemToDelete ? (<span><ResponsiveImage src={itemToDelete.file.downloadURL}/></span>) : ""} ?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={this.confirmDelete}><FormattedMessage
                            id="product.deleteModal.confirm"/></Button>{' '}
                        <Button color="secondary" onClick={this.closeModal}><FormattedMessage
                            id="product.deleteModal.cancel"/></Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

const ImagePreview = ({src}) => {

};



const ResponsiveImage = styled.img`
    width: auto;
    height: auto;
    max-height: ${props => props.dropzoneHeight ? props.dropzoneHeight : '380px'};;
`;

const UploadImageWrapper = styled.div`
    position: absolute;
`
const UploadImage = styled.img`
    width: 160px;
    height: auto;
    display: flex;
`;

const Wrapper = styled.div`
    display: flex;
    width: 100%;
`;

const Drop = styled.div`
    display: flex;
    flex-direction: column;
    box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
    height: ${props => props.dropzoneHeight ? props.dropzoneHeight : '380px'};
    width: ${props => props.relativeDimensions ? '100%' : '400px'};
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: ${props => props.dropzoneBg};
    border-radius: 6px;
`;

const Preview = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 10px;
`;

const PreviewItem = styled.div`
    display: flex;
    position: relative;
    flex-direction: row;
    height: 93px;
    width: 72px;
    border: 1px solid #707070;
    margin-top: 3px;
    &:first-child{
        margin-top: 0px;
    }
`;

const DeleteButton = styled.div`
    position: absolute;
    bottom: 5px;
    left: -15px;
    width: 27px;
    height: 27px;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    color: #fff;
    background: #DF4B3C;
    cursor: pointer;
    font-size: 34px;
    line-height: 20px;

`;

MediaUploader.propTypes = {
    uploadedFiles: PropTypes.array,
    onFilesDrop: PropTypes.func,
    onDeleteFile: PropTypes.func,
    showSidePreview: PropTypes.bool,
    showAddIconOnPreview: PropTypes.bool,
    relativeDimensions: PropTypes.bool,
    dropzoneHeight: PropTypes.string,
    dropzoneBg: PropTypes.string,
    accept: PropTypes.string,
};

MediaUploader.defaultProps = {
    showSidePreview: true,
    showAddIconOnPreview: true,
    relativeDimensions: false,
    dropzoneHeight: "400px",
    dropzoneBg: "none",
    accept: null
}

export default injectIntl(MediaUploader);