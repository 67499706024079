import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import moment from "moment";
import _ from "lodash";
import { toastr } from "react-redux-toastr";
import { SingleDatePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import { Row, Col, ModalBody, Modal } from "reactstrap";

import {
  Container,
  ContainerHeader,
  ContainerList,
  SendButton,
  MainSendButton,
} from "../../components/Form/main/styles";
import {
  Card,
  CardTitle,
  InputLabel,
  InputGroup,
  InputError,
} from "../../styles/stylesheet";
import IconInput, { IconWrapper } from "../../components/Form/IconInput";

import { getStatusLabel } from "shared/src/utils/po";

import { PO_STATUSES } from "shared/src//constants/pos";

import ROUTES from "../../constants/routes";
import { isMobileOrTablet } from "../../utils/UA";

import OrderListItem, {
  ORDER_LIST_ITEM_CONTEXT_TYPES,
} from "../OrdersManagement/orderListItem";

import Catalog from "../../components/Form/main/Catalog"
// import Catalog from "../OrdersManagement/innerCatalog"
// import Catalog from "./catalog";
import {
  getOrderTotalPrice,
  getItemPackage,
} from "shared/src/selectors/orders";
import {
  noProductImageUrl,
  ORDER_MANAGEMENT_CONTEXT,
  ORDER_STATUSES,
  PRODUCT_ACTION_TYPES,
} from "shared/src/constants/orders";
import { colors } from "../../styles/styleguide";

import Confirm from "../../components/Confirm.js";
import { getRoutParams as getParams } from "../../utils/navigtion";
import FirebaseObjectListSelect from "../../components/Form/FirebaseObjectListSelect";
import { history } from "../../store";

const getRoutParams = () => {
  return getParams(ROUTES.PO);
};

import { withSupplierPOForm } from "shared/src/HOC/PO/form.js";
import ContractorSelectionModal from "./contractorSelectionModal";
import { uuidWithoutSpace } from "shared/src/utils";
import { FEED_TYPES } from "shared/src/constants/feed";
import LoadingSpinner from "../../components/Loader";
import { firebaseConnect, getVal } from "react-redux-firebase";
import GenericProductForm from "../OrdersManagement/GenericProductForm";
import { Creatable } from "react-select";
import ActionButtonsContainer from "../../components/Form/main/ActionButtonsContainer";
import FormContainer from "../../components/Form/main/FormContainer";

class POForm extends Component {
  constructor() {
    super();
    this.state = {
      focused: false,
      isOpenDeletePo: false,
      isOpenCancelPo: false,
      isOpenDuplicateModal: false,
      selectedCustomer: null,
      disableShippingAddress: false,
      isOpenGenericProductModal: false,
      isSearching: false,
      // shouldOpen: false,
    };

    this.projectBySupplierInitialized = false;

    this.save = _.debounce(this.save, 2000, { leading: true, trailing: false });
    this.sendAndSave = _.debounce(this.sendAndSave, 2000, {
      leading: true,
      trailing: false,
    });
    this.convertToOrder = _.debounce(this.convertToOrder, 2000, {
      leading: true,
      trailing: false,
    });
    this.onDuplicate = _.debounce(this.onDuplicate, 2000, {
      leading: true,
      trailing: false,
    });
  }

  initProjectBySupplier = (projectsBySupplier) => {
    const { supplierId, customerId, poId } = getRoutParams();
    const { setFieldValue, po } = this.props;
    const projectIds = Object.keys(projectsBySupplier);
    if (projectIds.length > 0 && projectsBySupplier[customerId]) {
      setFieldValue(
        "currentPo.selectedProject",
        projectsBySupplier[customerId]["data"]
      );
      if (!po.shippingAddress) {
        setFieldValue(
          "currentPo.shippingAddress",
          projectsBySupplier[customerId]["data"]["address"]
        );
      }
      if (projectIds.length === 1) {
        this.setState({ disableShippingAddress: false });
      } else {
        this.setState({ disableShippingAddress: true });
      }
      this.projectBySupplierInitialized = true;
    }
  };

  save = () => {
    const { po, savePo, isPOCustomer } = this.props;
    savePo({
      currentPo: po,
      isPOCustomer,
      onSuccess: () => {
        toastr.success("ההצעה נשמרה בהצלחה");
        if (po.feedType === FEED_TYPES.PO_CONTRACT) {
          history.push(ROUTES.PO_LIST);
        } else {
          history.push(ROUTES.ORDERS_MANAGEMENT);
        }
      },
      onError: (e) => {
        console.error("error saving po", e);
        toastr.error(
          "אירעה שגיאה בעת הנסיון לשמור את ההצעה, אנא נסו שוב",
          "במידה והבעיה חוזרת אנא פנו למנהל המערכת"
        );
      },
    });
  };

  sendAndSave = ({ setShouldOpen }) => {
    const { po, sendPo, savePo, validatePO, isPOCustomer } = this.props;
    if (!validatePO(po)) {
      setShouldOpen(true)
      // this.setState({ shouldOpen: true });
      return;
    }

    savePo({
      currentPo: po,
      isPOCustomer,
      onError: (e) => {
        console.error("error saving po", e);
        toastr.error(
          "אירעה שגיאה בעת הנסיון לשמור את ההצעה, אנא נסו שוב",
          "במידה והבעיה חוזרת אנא פנו למנהל המערכת"
        );
      },
      onSuccess: () => {
        setTimeout(() => {
          sendPo({
            currentPo: po,
            successCB: () => {
              toastr.success("ההצעה נשלחה בהצלחה");
              if (po.feedType === FEED_TYPES.PO_CONTRACT) {
                history.push(ROUTES.PO_LIST);
              } else {
                history.push(ROUTES.ORDERS_MANAGEMENT);
              }
            },
            failureCB: (e) => {
              console.error("error saving po", e);
              toastr.error(
                "אירעה שגיאה בעת הנסיון לשלוח את ההצעה, אנא נסו שוב",
                "במידה והבעיה חוזרת אנא פנו למנהל המערכת"
              );
            },
          });
        }, 1000);
      },
    });
  };

  convertToOrder = ({ setShouldOpen }) => {
    const {
      po,
      savePo,
      converPOToOrder,
      validatePO,
      isPOCustomer,
    } = this.props;
    const { supplierId, customerId, poId } = getRoutParams();

    if (!validatePO(po)) {
      setShouldOpen(true)
      // this.setState({ shouldOpen: true });
      return;
    }
    // po.items.reverse()
    // console.log("this po");
    // console.log(po);

    savePo({
      currentPo: po,
      isPOCustomer,
      onError: (e) => {
        console.error("error saving po", e);
        toastr.error(
          "אירעה שגיאה בעת הנסיון לשמור את ההצעה, אנא נסו שוב",
          "במידה והבעיה חוזרת אנא פנו למנהל המערכת"
        );
      },
      onSuccess: () => {
        setTimeout(() => {
          converPOToOrder(
            { supplierId, customerId, poId },
            undefined,
            undefined,
            true
          );
        }, 1000);
      },
    });
  };

  getSummaryList = ({ po }) => {
    const {
      setProductQuantity,
      removeProduct,
      setFieldValue,
      disabledItems,
    } = this.props

    const rows = [];
    let numOfAddonItemsAddedToList = 0;

    const addItemToOrderSummay = (component) => {
      rows.push(component);
    };

    po.items.forEach((item, index) => {
      addItemToOrderSummay(
          <OrderListItem
              key={`item_${item.id}_${item.room ? item.room.id : index}_${index}`}
              catalogId={item.catalogId}
              imgSrc={item.downloadURL || noProductImageUrl}
              name={item.name}
              count={item.count}
              price={item.default_price}
              context={ORDER_LIST_ITEM_CONTEXT_TYPES.PO_SUMMARY}
              negativeCount={true}
              item={item}
              packageInfo={getItemPackage(item)}
              disabled={disabledItems}
              setProductQuantity={(quantity) =>
                  setProductQuantity({ index, quantity })
              }
              onRemove={() => {
                removeProduct(index);
              }}
              setProductDiscount={(discount) => {
                setFieldValue(`currentPo.items[${index}].discount`, discount);
              }}
              setProductSpacialPrice={(price) => {
                setFieldValue(`currentPo.items[${index}].spacialPrice`, price);
              }}
              setProductNote={(note) => {
                setFieldValue(`currentPo.items[${index}].note`, note);
              }}
              setRentInterval={(interval) => {
                setFieldValue(
                    `currentPo.items[${index}].userSelectedRentInterval`,
                    interval
                );
              }}
              hidePrices={false}
              disableCount={po.isPOContract}
          />
      );

      if (!_.isEmpty(!po.isPOContract && item.addOnProducts)) {
        item.addOnProducts.forEach((addon, j) => {
          if (addon) {
            console.log("adding addon product", addon, index, j);
            const image = _.get(
                addon,
                "images[0].downloadURL",
                noProductImageUrl
            );

            addItemToOrderSummay(
                <OrderListItem
                    key={`item_${item.id}_${item.room ? item.room.id : index}_${index}_addon_${j}`}
                    catalogId={addon.catalogId}
                    imgSrc={image}
                    name={addon.name}
                    packageInfo={getItemPackage(addon)}
                    count={item.count}
                    price={addon.default_price}
                    context={ORDER_LIST_ITEM_CONTEXT_TYPES.PO_SUMMARY}
                    disabled={true}
                    item={addon}
                    itemActions={[
                      { type: PRODUCT_ACTION_TYPES.ADD_ON_ITEM, data: item },
                    ]}
                    hidePrices={false}
                    disableCount={true}
                />
            );
            numOfAddonItemsAddedToList++;
          }
        });
      }
    });

    return rows.reverse()
  }

  getSummaryProps = ({ po }) => {
    const { price, count } = getOrderTotalPrice(po, true);

    const SummaryItemList = this.getSummaryList({ po })

    return {
      productCount: count,
      totalPrice: price,
      SummaryItemList
    }
  }

  // renderSummary = () => {
  //   const {
  //     po,
  //     setFieldValue,
  //     removeProduct,
  //     setProductQuantity,
  //     disabledItems,
  //   } = this.props;
  //
  //   const rows = [];
  //   let numOfAddonItemsAddedToList = 0;
  //
  //   const { price, count } = getOrderTotalPrice(po, true);
  //
  //   const addItemToOrderSummay = (component) => {
  //     rows.push(component);
  //   };
  //
  //   po.items.forEach((item, index) => {
  //     addItemToOrderSummay(
  //       <OrderListItem
  //         key={`item_${item.id}_${item.room ? item.room.id : index}_${index}`}
  //         catalogId={item.catalogId}
  //         imgSrc={item.downloadURL || noProductImageUrl}
  //         name={item.name}
  //         count={item.count}
  //         price={item.default_price}
  //         context={ORDER_LIST_ITEM_CONTEXT_TYPES.PO_SUMMARY}
  //         negativeCount={true}
  //         item={item}
  //         packageInfo={getItemPackage(item)}
  //         disabled={disabledItems}
  //         setProductQuantity={(quantity) =>
  //           setProductQuantity({ index, quantity })
  //         }
  //         onRemove={() => {
  //           removeProduct(index);
  //         }}
  //         setProductDiscount={(discount) => {
  //           setFieldValue(`currentPo.items[${index}].discount`, discount);
  //         }}
  //         setProductSpacialPrice={(price) => {
  //           setFieldValue(`currentPo.items[${index}].spacialPrice`, price);
  //         }}
  //         setProductNote={(note) => {
  //           setFieldValue(`currentPo.items[${index}].note`, note);
  //         }}
  //         setRentInterval={(interval) => {
  //           setFieldValue(
  //             `currentPo.items[${index}].userSelectedRentInterval`,
  //             interval
  //           );
  //         }}
  //         hidePrices={false}
  //         disableCount={po.isPOContract}
  //       />
  //     );
  //
  //     if (!_.isEmpty(!po.isPOContract && item.addOnProducts)) {
  //       item.addOnProducts.forEach((addon, j) => {
  //         if (addon.item) {
  //           console.log("adding addon product", addon.item, index, j);
  //           const image = _.get(
  //             addon.item,
  //             "images[0].downloadURL",
  //             noProductImageUrl
  //           );
  //
  //           addItemToOrderSummay(
  //             <OrderListItem
  //               key={`item_${item.id}_${item.room ? item.room.id : index}_${index}_addon_${j}`}
  //               catalogId={addon.item.catalogId}
  //               imgSrc={image}
  //               name={addon.item.name}
  //               packageInfo={getItemPackage(addon.item)}
  //               count={item.count}
  //               price={addon.item.default_price}
  //               context={ORDER_LIST_ITEM_CONTEXT_TYPES.PO_SUMMARY}
  //               disabled={true}
  //               item={addon.item}
  //               itemActions={[
  //                 { type: PRODUCT_ACTION_TYPES.ADD_ON_ITEM, data: item },
  //               ]}
  //               hidePrices={false}
  //               disableCount={true}
  //             />
  //           );
  //           numOfAddonItemsAddedToList++;
  //         }
  //       });
  //     }
  //   });
  //
  //   return (
  //     <div
  //       style={{
  //         display: "flex",
  //         flexDirection: "column",
  //         width: "95%",
  //         marginTop: "50px",
  //       }}
  //     >
  //       <CardTitle style={{ color: colors.charcoal }}>
  //         {"סיכום"}
  //       </CardTitle>
  //
  //       <Container>
  //         <div style={{ backgroundColor: "#f9f9f9", borderRadius: "15px" }}>
  //           <ContainerHeader style={{ justifyContent: "space-around" }}>
  //             <div>
  //               <span dir={"ltr"} style={{ fontWeight: "bold" }}>
  //                 {count}
  //               </span>
  //               <span>{` פריטים`}</span>
  //             </div>
  //             <span>|</span>
  //             <div style={{ fontWeight: "bold" }}>
  //               <span dir={"ltr"}>{price}</span>
  //               <span>{"₪"}</span>
  //             </div>
  //           </ContainerHeader>
  //         </div>
  //
  //         <ContainerList>{rows.reverse()}</ContainerList>
  //       </Container>
  //     </div>
  //   );
  // };

  renderActionButtons = (status) => {
    const { disabledChanges, isPOCustomer, po } = this.props;
    let { supplierContractors } = this.props;
    supplierContractors = supplierContractors || [];
    const { customerId } = getRoutParams();
    const customer = _.find(supplierContractors, { id: customerId });
    let customerCantAcceptPO =
      customer && _.isEmpty(customer.poEmails) && customer.po;

    return ({ setShouldOpen }) => (
      <ActionButtonsContainer>
        <SendButton
            style={{ marginLeft: 10, marginRight: 10 }}
            disabled={disabledChanges}
            onClick={this.save}
        >
          שמירה ויציאה
        </SendButton>

        {!customerCantAcceptPO && (
            <MainSendButton
                style={{ marginLeft: 5, backgroundColor: colors.charcoal }}
                disabled={disabledChanges}
                onClick={() => {
                  this.sendAndSave({ setShouldOpen })
                }}
            >
              <img style={{ height: "25px", width: "25px" }} src={"img/icons/round-check.svg"}/>
              <div style={{ margin: "6px" }}/>

              {"שליחה ושמירה"}
            </MainSendButton>
        )}

        {isPOCustomer && (
            <SendButton
                style={{ marginLeft: 10, marginRight: 10 }}
                disabled={disabledChanges}
                onClick={() => {
                  this.convertToOrder({ setShouldOpen })
                }}
            >
              יצירת הזמנה
            </SendButton>
        )}

        {status === PO_STATUSES.SENT && (
            <SendButton
                style={{ marginLeft: 10, marginRight: 10 }}
                onClick={() => {
                  this.setState({
                    isOpenCancelPo: true,
                  });
                }}
            >
              {"ביטול הצעה"}
            </SendButton>
        )}

        {status === PO_STATUSES.DRAFT && (
            <SendButton
                style={{ marginLeft: 10, marginRight: 10, backgroundColor: "red" }}
                disabled={disabledChanges}
                onClick={() => {
                  this.setState({
                    isOpenDeletePo: true,
                  });
                }}
            >
              מחיקה
            </SendButton>
        )}

        {po.feedType === FEED_TYPES.PO_CONTRACT && (
            <SendButton
                style={{ marginLeft: 10, marginRight: 10 }}
                onClick={() => {
                  this.setState({ isOpenDuplicateModal: true });
                }}
            >
              שכפול
            </SendButton>
        )}
      </ActionButtonsContainer>
    );
  };

  closeModal = () => {
    this.setState({
      isOpenDuplicateModal: false,
      selectedCustomer: null,
    });
  };

  onDuplicate = () => {
    const {
      supplierId,
      supplierContractors,
      copyPO,
      po,
      getPOUsers,
    } = this.props;
    const { selectedCustomer } = this.state;

    const poId = uuidWithoutSpace();
    const customer = _.find(supplierContractors, { id: selectedCustomer });
    copyPO(po, customer, poId);
    getPOUsers({ customerId: selectedCustomer, supplierId });
    this.closeModal();
    history.push(`/po/${supplierId}/${selectedCustomer}/${poId}`);
    toastr.success("ההצעה שוכפלה בהצלחה", "אתם רואים כעת את ההצעה החדשה");
  };

  closeGenericProductModal = () => {
    this.setState({ isOpenGenericProductModal: false });
  };

  submitGenericProduct = ({ name, price, count, isRental }) => {
    this.addGenericProduct({ name, price, count, isRental });
    this.closeGenericProductModal();
  };

  addGenericProduct = ({ name, count, price, isRental = false }) => {
    const { addProduct } = this.props;

    const item = {
      id: uuidWithoutSpace(),
      name,
      catalogId: 1,
      default_price: price,
      isGeneric: true,
      isRentalProduct: isRental,
    };

    addProduct(item, count);
  };

  onShippingAddressChange = (newValue, actionMeta) => {
    const { setFieldValue } = this.props;
    // console.group("Value Changed");
    // console.log(newValue);
    // console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    setFieldValue("currentPo.shippingAddress", newValue.label);
  };

  renderPODetails = ({
    po,
    disabledChanges,
    latestStatus,
    customerId,
  }) => {
    const {
      setFieldValue,
      isPOCustomer,
      poUsers,
      isLoadingUsers,
      isValid,
      projectsBySupplier,
      shippingAddresses,
    } = this.props

    const { disableShippingAddress } = this.state

    const { selectedProject } = po;

    let shippingAddress = _.get(po, "shippingAddress", null);

    const customStyles = {
      container: (provided, state) => ({
        ...provided,
        border: 0,
        paddingLeft: 20,
        paddingRight: 40,
      }),
      menu: (provided, state) => ({
        ...provided,
        border: 0,
      }),
      control: (provided, state) => {
        console.log("control state", state);
        return {
          ...provided,
          border: 0,
          borderWidth: 0,
          boxShadow: 0,
        };
      },
      indicatorSeparator: () => {
        return {
          visability: "hidden",
        };
      },
      dropdownIndicator: () => {
        return {
          color: colors.charcoal,
        };
      },
    };

    return (
        <Card>
          <Row>
            <Col md={2}>
              <InputGroup>
                <InputLabel>תאריך יצירה</InputLabel>
                <div style={{ position: "relative" }}>
                  <IconWrapper style={{ top: 0, right: 8 }}>
                    <img src={"img/icons/date.svg"} />
                  </IconWrapper>
                  <SingleDatePicker
                      disabled={disabledChanges}
                      date={moment(po.createdDate)} // momentPropTypes.momentObj or null
                      onDateChange={(date) =>
                          setFieldValue(
                              "currentPo.createdDate",
                              moment(date).toDate()
                          )
                      } // PropTypes.func.isRequired
                      focused={this.state.focused} // PropTypes.bool
                      onFocusChange={({ focused }) => {
                        console.log("on focus change", focused);
                        this.setState({ focused });
                      }} // PropTypes.func.isRequired
                      id="deliveryDate2" // PropTypes.string.isRequired,
                      isRTL={true}
                      small={true}
                      block={true}
                      displayFormat={"D/MM/YY"}
                      isOutsideRange={() => false}
                      onClose={(p) => {
                        console.log("on close dates", p);
                      }}
                  />
                </div>
              </InputGroup>
            </Col>
            <Col md={2}>
              <InputGroup>
                <InputLabel>סטטוס</InputLabel>
                <IconInput
                    type={"text"}
                    value={getStatusLabel(latestStatus, po.isPOContract)}
                    disabled={true}
                    icon={<img src={"img/icons/location.svg"} />}
                />
              </InputGroup>
            </Col>
            <Col md={3}>
              <InputGroup>
                <InputLabel>לקוח</InputLabel>
                <IconInput
                    type={"text"}
                    value={_.get(po, "customer.name", "")}
                    disabled={true}
                    icon={<img src={"img/icons/location.svg"} />}
                />
              </InputGroup>
            </Col>
            <Col md={3}>
              <InputGroup>
                <InputLabel>כתובת</InputLabel>
                <IconInput
                    type={"text"}
                    value={_.get(po, "customer.address", "")}
                    disabled={true}
                    icon={<img src={"img/icons/location.svg"} />}
                />
              </InputGroup>
            </Col>
          </Row>
          <Row>
            {!isPOCustomer && !po.isPOContract && (
                <Col md={4}>
                  <InputGroup>
                    <InputLabel>המזמין</InputLabel>
                    <IconInput
                        type={"select"}
                        options={poUsers}
                        value={_.get(po, "requestedByUser.id", null)}
                        onChange={(value) => {
                          const user = _.find(poUsers, { id: value }) || null;
                          setFieldValue("currentPo.requestedByUser", user);
                        }}
                        disabled={disabledChanges}
                        icon={<img src={"img/icons/person.svg"} />}
                        valueAccessor={"id"}
                        labelAccessor={(user) => {
                          return `${user.displayName} ${user.phoneNumber || ""}`;
                        }}
                        isLoading={isLoadingUsers}
                    />
                  </InputGroup>
                  {!isValid.user && (
                      <InputError>יש לבחור את שם המזמין</InputError>
                  )}
                </Col>
            )}
          </Row>

          {isPOCustomer && !po.isPOContract && (
              <Fragment>
                <Row>
                  <Col md={4}>
                    <InputGroup>
                      <InputLabel>איש קשר</InputLabel>
                      <IconInput
                          type={"text"}
                          value={_.get(po, "contactPerson.name", null)}
                          onChange={(value) => {
                            setFieldValue("currentPo.contactPerson.name", value);
                          }}
                          disabled={disabledChanges}
                          icon={<img src={"img/icons/person.svg"} />}
                      />
                    </InputGroup>
                    {!isValid.contactPerson && (
                        <InputError>שדה חובה</InputError>
                    )}
                  </Col>
                  <Col md={4}>
                    <InputGroup>
                      <InputLabel>טלפון</InputLabel>
                      <IconInput
                          type={"text"}
                          value={_.get(po, "contactPerson.phone", null)}
                          onChange={(value) => {
                            setFieldValue("currentPo.contactPerson.phone", value);
                          }}
                          disabled={disabledChanges}
                          icon={<img src={"img/icons/phone.svg"} />}
                      />
                    </InputGroup>
                    {!isValid.contactPhone && <InputError>שדה חובה</InputError>}
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <InputGroup>
                      <InputLabel>פרוייקט</InputLabel>
                      <FirebaseObjectListSelect
                          onChange={(id) => {
                            if (id) {
                              setFieldValue(
                                  "currentPo.selectedProject",
                                  projectsBySupplier[id]["data"]
                              );
                              setFieldValue(
                                  "currentPo.shippingAddress",
                                  projectsBySupplier[id]["data"]["address"]
                              );
                              if (id === customerId) {
                                this.setState({ disableShippingAddress: false });
                              } else {
                                this.setState({ disableShippingAddress: true });
                              }
                            } else {
                              setFieldValue("currentPo.selectedProject", null);
                            }
                          }}
                          hintText={"בחירת פרוייקט"}
                          list={projectsBySupplier}
                          value={selectedProject ? selectedProject.id : null}
                          labelAccessor={(project) => {
                            return `${project.data.address} (${project.data.name})`;
                          }}
                          style={{ width: "100%", height: 34 }}
                          useMaterialDesign={false}
                          itemPath={"data"}
                          disabled={disabledChanges}
                          icon={<img src={"img/icons/driver.svg"} />}
                      />
                    </InputGroup>
                  </Col>
                  <Col md={4}>
                    <InputGroup>
                      <InputLabel>כתובת למשלוח</InputLabel>
                      {
                        <IconInput
                            renderInput={() => {
                              return (
                                  <Creatable
                                      isRtl={true}
                                      isMulti={false}
                                      onChange={this.onShippingAddressChange}
                                      options={shippingAddresses.map((value) => ({
                                        id: value,
                                        label: value,
                                      }))}
                                      value={
                                        shippingAddress
                                            ? {
                                              id: shippingAddress,
                                              label: shippingAddress,
                                            }
                                            : null
                                      }
                                      formatCreateLabel={(inputValue) => inputValue}
                                      styles={customStyles}
                                      placeholder={""}
                                      disabled={
                                        disabledChanges || disableShippingAddress
                                      }
                                  />
                              );
                            }}
                            disabled={disabledChanges || disableShippingAddress}
                            icon={<img src={"img/icons/location.svg"} />}
                        />
                      }
                    </InputGroup>
                    {!isValid.shippingAddress && (
                        <InputError>שדה חובה</InputError>
                    )}
                  </Col>
                </Row>
              </Fragment>
          )}

          <Row>
            <Col md={6}>
              <InputGroup>
                <InputLabel>הערות</InputLabel>
                <textarea
                    disabled={disabledChanges}
                    style={{
                      width: "100%",
                      minHeight: 72,
                      borderRadius: 3,
                      border: "1px solid #E0E0E0",
                      resize: "none",
                    }}
                    value={_.get(po, "note", "")}
                    onChange={(e) => {
                      setFieldValue("currentPo.note", e.target.value);
                    }}
                />
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col md={2}>
              {!isValid.items && (
                  <InputError>יש לבחור לפחות פריט אחד</InputError>
              )}
            </Col>
          </Row>
        </Card>
    )
  }

  render() {
    const {
      po,
      // setFieldValue,
      deletePo,
      setPoStatus,
      // poUsers,
      // isLoadingUsers,
      latestStatus,
      disabledChanges,
      // isPOCustomer,
      // isValid,
      updateInProgress,
      convertingTOrder,
      // projectsBySupplier,
      // shippingAddresses,
    } = this.props;
    let { supplierContractors } = this.props;
    supplierContractors = supplierContractors || [];

    const {
      isLoadingCatalog,
      query,
      catalog,
      updateQuery,
      getCatalog,
      addProduct,
    } = this.props;

    const {
      isOpenDuplicateModal,
      selectedCustomer,
      // disableShippingAddress,
      isOpenGenericProductModal,
    } = this.state;

    const { supplierId, customerId, poId } = getRoutParams();

    const { isFDO } = po;

    const ActionButtonsComponent = this.renderActionButtons(latestStatus)
    const DetailsComponentCard = this.renderPODetails({
      po,
      disabledChanges,
      latestStatus,
      customerId,
    })

    const detailsProps = {
      isFDO,
      ActionButtonsComponent,
      DetailsComponentCard,
    }

    const summaryProps = this.getSummaryProps({ po, latestStatus })

    const CatalogComponent = !disabledChanges && (
        <Catalog
            isPO={true}
            autoCompleteList={this.props.searchHistory}
            query={query}
            updateQueryHandler={updateQuery}
            getCatalogHandler={getCatalog}
            context={ORDER_MANAGEMENT_CONTEXT.SUPPLIER}
            catalog={catalog}
            isLoadingCatalog={isLoadingCatalog}
            orderId={po.id}
            contractorId={customerId}
            supplierId={supplierId}
            itemsPerRow={isMobileOrTablet() ? 1 : 3}
            disabledCatalogItems={disabledChanges}
            onAdToCart={addProduct}
            shouldUseZIndex={false}
            disableCount={po.isPOContract}
            onAddGenericProduct={() => {
              this.setState({ isOpenGenericProductModal: true });
            }}
            formContext={ORDER_LIST_ITEM_CONTEXT_TYPES.PO}
            negativeCount={true}
        />
    )

    return (
        <div>
          <FormContainer
              detailsProps={detailsProps}
              summaryProps={summaryProps}
              Catalog={CatalogComponent}
          />

          {
            // <PageContainer>
            //   {(updateInProgress || convertingTOrder) && <LoadingSpinner />}
            //   <CollapseDetails
            //       isFDO={isFDO}
            //       isSearching={this.state.isSearching}
            //       shouldOpen={this.state.shouldOpen}
            //       onOpen={() => {
            //         this.setState({ shouldOpen: false });
            //       }}
            //       actionsView={ActionButtonsComponent}
            //   >
            //     {DetailsComponentCard}
            //   </CollapseDetails>
            //
            //   {disabledChanges ? (
            //       this.renderSummary()
            //   ) : (
            //       <div
            //           style={{
            //             display: "flex",
            //             width: "100%",
            //             flexDirection: "row",
            //             // justifyContent: "space-between",
            //           }}
            //           onFocus={() => {
            //             this.setState({ isSearching: true });
            //           }}
            //           onBlur={() => {
            //             this.setState({ isSearching: false });
            //           }}
            //       >
            //         {CatalogComponent}
            //
            //         <div
            //             style={{
            //               display: "flex",
            //               flex: 1,
            //               justifyContent: "flex-end",
            //             }}
            //         >
            //           {this.renderSummary()}
            //         </div>
            //       </div>
            //   )}
            // </PageContainer>
          }

          <Confirm
              body={"  אתם עומדים למחוק הצעת מחיר זו, האם אתם בטוחים?"}
              isOpen={this.state.isOpenDeletePo}
              onConfirm={() => {
                deletePo({
                  customerId,
                  supplierId: supplierId,
                  poId,
                  successCB: () => {
                    toastr.success("הצעת המחיר נמחקה בהצלחה");
                    if (po.feedType === FEED_TYPES.PO_CONTRACT) {
                      history.push(ROUTES.PO_LIST);
                    } else {
                      history.push(ROUTES.ORDERS_MANAGEMENT);
                    }
                  },
                });
              }}
              onReject={() => {
                this.setState({ isOpenDeletePo: false });
              }}
              title={"מחיקת הצעת מחיר"}
          />
          <Confirm
              body={
                "  אתם עומדים לבטל הצעת מחיר זו, לא ניתן יהיה לערוך שינויים לאחר מכן. האם אתם בטוחים?"
              }
              isOpen={this.state.isOpenCancelPo}
              onConfirm={() => {
                setPoStatus({
                  currentPo: po,
                  status: PO_STATUSES.CANCELED,
                  successCB: () => {
                    toastr.success("הצעת המחיר בוטלה בהצלחה");
                    if (po.feedType === FEED_TYPES.PO_CONTRACT) {
                      history.push(ROUTES.PO_LIST);
                    } else {
                      history.push(ROUTES.ORDERS_MANAGEMENT);
                    }
                  },
                });
              }}
              onReject={() => {
                this.setState({ isOpenCancelPo: false });
              }}
              title={"ביטול הצעת מחיר"}
          />
          <ContractorSelectionModal
              isOpen={isOpenDuplicateModal}
              onSubmit={this.onDuplicate}
              disabled={!selectedCustomer}
              onChane={(selected, action) => {
                console.log(action, selected);
                this.setState({ selectedCustomer: selected.value });
              }}
              onClose={this.closeModal}
              options={supplierContractors.map((c) => ({
                value: c.id,
                label: c.name,
              }))}
              selectLabel={"אנא בחרו לקוח"}
          />
          <Modal
              isOpen={isOpenGenericProductModal}
              toggle={() => {
                this.closeGenericProductModal();
              }}
              onClosed={() => {
                if (this.redirectOnClose) {
                  this.props.history.push(ROUTES.ORDERS_MANAGEMENT);
                }
              }}
          >
            <ModalBody>
              {isOpenGenericProductModal && (
                  <GenericProductForm
                      onSubmit={this.submitGenericProduct}
                      onCancel={this.closeGenericProductModal}
                  />
              )}
            </ModalBody>
          </Modal>
        </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { supplierId, customerId, poId } = getRoutParams();

  return {
    projectsBySupplier: getVal(
      state.firebase.data,
      `projectsBySupplier/${supplierId}/${customerId}`,
      []
    ),
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default compose(
  withSupplierPOForm(getRoutParams),
  connect(mapStateToProps, mapDispatchToProps),
  firebaseConnect((props) => {
    const { supplierId, customerId, poId } = getRoutParams();
    const paths = [`projectsBySupplier/${supplierId}/${customerId}`];
    return paths;
  })
)(POForm);
