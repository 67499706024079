import _, {get} from "lodash"
import {FEED_TYPES} from "shared/src/constants/feed";
import { extractLatestStatus } from "shared/src/utils/orders";
import {getStatusLabel as getPOStatusLabel} from "shared/src/utils/po"

export const driver = (row) => get(row, 'driver.displayName');

export const notes = (row) => get(row, 'note');

export const contractor = (row) => row.feedType === FEED_TYPES.PO ? get(row, 'customer.name') : get(row, 'contractor.name');

export const date = (row) => row.feedType === FEED_TYPES.PO ? get(row, 'createdDate') :  get(row, 'deliveryDate');

export const approvalDate = (row) => {
    const approvalDate = get(row, 'status.approved');
  //  console.log("approvalDate", approvalDate)
    return approvalDate
}

export const address = (row) => {
    // console.log("address", row)
    const shippingAddress = get(row, "shippingAddress");
    if(shippingAddress){
        return shippingAddress;
    }
    const projectAddress = get(row, 'project.address');
    if(projectAddress)
    {
        return `${projectAddress} (${get(row, 'project.name')})`
    }
    return "";
};

export const combinedStatus = (row) => {
    let status = extractLatestStatus(row.status);
    if(row.feedType === FEED_TYPES.PO){
        return getPOStatusLabel(status);
    }else {
        return "הזמנה"
    }
}

export const status = (row) => extractLatestStatus(row.status)