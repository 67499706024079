import { matchPath } from 'react-router'
import { history } from "../store";

export const getRoutParams = (path) => {
    const match = matchPath(history.location.pathname, {
        path,
        exact: true,
        strict: false
    })

    const routeParams = match ? match.params : {};
    return routeParams;
}