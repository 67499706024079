import React from 'react';
import PropTypes from 'prop-types'
import {injectIntl, FormattedMessage, formatMessage} from 'react-intl'
import styled from 'styled-components';

import IconButton from 'material-ui/IconButton';
import FontIcon from 'material-ui/FontIcon';
import RaisedButton from 'material-ui/RaisedButton';

import TextField from 'material-ui/TextField';
import {TabHeader} from "./Tab";
import { Card, CardTitle, InputLabel, InputGroup } from "../../styles/stylesheet"

import IconInput from "./IconInput";
import { fonts } from "../../styles/styleguide";

const style = {
    raisedButton: {
        marginLeft: 10
    },
    fieldDeleteButton:{
        verticalAlign: "bottom"
    },
    fieldBDeleteIcon:{
        color: "#707070"
    }
}

class FormGroup extends React.Component {

    constructor() {
        super();
        this.state = {
            fieldNameToAdd: "",
            groupNameToAdd: {},
            focusedField: null
        }

        this.setFieldNameToAdd = this.setFieldNameToAdd.bind(this);
        this.setGroupNameToAdd = this.setGroupNameToAdd.bind(this);
    }

    setFieldNameToAdd(value) {
        this.setState({fieldNameToAdd: value});
    }

    setGroupNameToAdd(value, groupKey) {
        const {groupNameToAdd} = this.state;
        groupNameToAdd[groupKey] = value;
        this.setState({groupNameToAdd: groupNameToAdd});
    }

    onAddItem() {
        const {onAddItem, isSubGroups} = this.props;
        const {fieldNameToAdd} = this.state;
        if (isSubGroups && !fieldNameToAdd) {
            return;
        }
        onAddItem(fieldNameToAdd, isSubGroups);
        this.setFieldNameToAdd("");
    }

    onAddGroupItem(subGroupKey) {
        const {onAddSubGroupItem} = this.props;
        const {groupNameToAdd} = this.state;
        onAddSubGroupItem(groupNameToAdd[subGroupKey], subGroupKey);
        this.setGroupNameToAdd("", subGroupKey);
    }

    renderItems(items, onRemoveItem, onItemChange, isEditable, isSubGroups, subGroupKey) {
        const {intl, onSubGroupItemChange, removeSubGroupItem, disabled, viewOnly} = this.props;
        return items.map((item) => (
            isEditable && !viewOnly ? (
                <Field>
                    <div>
                        <InputLabel>{item.name}</InputLabel>
                        <IconInput
                            value={item.value}
                            type={"text"}
                            onChange={(value) => {isSubGroups ? onSubGroupItemChange(item.key, subGroupKey, value) : onItemChange(item.key, value)}}
                            placeholder={intl.formatMessage({id: "product.form.group.fieldContentPlaceholder"})}
                            disabled={disabled}
                            clearValueHandler={()=>{
                                if(!disabled){
                                    isSubGroups ? removeSubGroupItem(item.key, subGroupKey) : onRemoveItem(item.key)
                                }
                            }}
                        />
                    </div>
                </Field>
            ) : (
                <Field vertical={viewOnly} >
                    <div style={{fontWeight: viewOnly ? "bold" : "normal"}}>{item.name}</div>
                    {viewOnly && (
                        <Field vertical={viewOnly} >
                            <div>{item.value}</div>
                        </Field>
                    )}
                    {!viewOnly && (
                        <IconButton

                            tooltip={intl.formatMessage({id: "product.form.group.removeFieldButton"})}
                            onClick={() => {
                                if(!disabled){
                                    isSubGroups ? removeSubGroupItem(item.key, subGroupKey) : onRemoveItem(item.key)
                                }
                            }}
                            style={style.fieldDeleteButton}
                            iconStyle={style.fieldBDeleteIcon}
                        >
                            <FontIcon className="material-icons">close</FontIcon>
                        </IconButton>
                    )}
                </Field>
            )))


    }

    render() {

        const {mainInputLabel, title, isSubGroups, intl, items, onRemoveItem, onItemChange, isEditable, mainInputPlaceholder, primaryButtonLabel, background, disabled,viewOnly} = this.props;
        const {fieldNameToAdd} = this.state;

        return (
            <Group>
                <FlexWrapper>
                    {title && (<TabHeader label={title} isActive={true} showEdit={false} showDelete={false}  />)}
                </FlexWrapper>
                {!viewOnly && (
                    <FlexWrapper>
                        <InputGroup>
                            <InputLabel>{mainInputPlaceholder}</InputLabel>
                            <IconInput
                                value={fieldNameToAdd}
                                type={"text"}
                                onChange={this.setFieldNameToAdd}
                                placeholder={mainInputPlaceholder}
                                disabled={disabled}
                            />
                        </InputGroup>
                        <AddButton
                            onClick={()=>{
                                if(!disabled){
                                    this.onAddItem()
                                }
                            }}
                        >
                            <Img src={"img/icons/add_black.svg"}/>
                            <Label>{primaryButtonLabel || (isSubGroups ? intl.formatMessage({id: "product.form.group.addSubGroupButton"}) : intl.formatMessage({id: "product.form.group.addFieldButton"}))}</Label>
                        </AddButton>
                    </FlexWrapper>
                )}
                <AlignedWrapper vertical={viewOnly}>
                    {
                        this.renderItems(items, onRemoveItem, onItemChange, isEditable, isSubGroups, viewOnly)
                    }
                </AlignedWrapper>
            </Group>
        )
    }
}

const AddButton = styled.div`
  cursor: pointer;
   display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 7px;
`

const Img = styled.img`
  width: 29px;
  height: 29px;
  object-fit: contain;
`;

const Label = styled.div`
  font-family: ${fonts.OpenSansHebrew};
  font-size: 21px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 1px;
  margin-right: 5px;
`;
const FlexWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: ${props => props.vertical ? 'column' : 'row'};
    align-items: flex-end;
`;

const AlignedWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
`;
const Group = styled.div`
    margin-top: 10px;
    padding: 10px;
   
`;

const Field = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 5px;
`;


FormGroup.propTypes = {
    title: PropTypes.string,
    onAddItem: PropTypes.func,
    onRemoveItem: PropTypes.func,
    onItemChange: PropTypes.func,
    items: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.any,
        name: PropTypes.string,
        value: PropTypes.string
    })),
    isEditable: PropTypes.bool,
    mainInputLabel: PropTypes.string,
    mainInputPlaceholder: PropTypes.string,
    isSubGroups: PropTypes.bool,
    onAddSubGroupItem: PropTypes.func,
    onSubGroupItemChange: PropTypes.func,
    removeSubGroupItem: PropTypes.func,
    primaryButtonLabel: PropTypes.string,
    background: PropTypes.string,
    disabled: PropTypes.bool,
    viewOnly: PropTypes.bool
};

FormGroup.defaultProps = {
    isEditable: true,
    isSubGroups: false,
    disabled: false,
    viewOnly: false
};

export default injectIntl(FormGroup);
