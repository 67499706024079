import React from 'react';
import PropTypes from 'prop-types'

import TextField from 'material-ui/TextField';
import SupplierSelect from "./FirebaseObjectListSelect";
import styled from 'styled-components';

class ProductRefSelect extends React.Component{

    constructor() {
        super()
    }

    render() {

        const {
            mainLabel,  suppliersList, suppliersValue, onSupplierChange, itemIdLabel, itemIdValue, onItemIdChange,
        } = this.props;


        return (
            <div>
                <h4>{mainLabel}</h4>
                <Field>
                    <SupplierSelect
                        list={suppliersList}
                        onChange={(value) => onSupplierChange(value)}
                        value={suppliersValue}
                    />
                </Field>
                <Field>
                    <TextField
                        value={itemIdValue}
                        floatingLabelText={itemIdLabel}
                        onChange={(e, value)=>{onItemIdChange(value)}}
                    />
                </Field>
            </div>
        )
    }
}

const Group = styled.div`
    border: 1px dashed #29363d;
    margin-top: 10px;
    padding: 10px;
`;

const Field = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

ProductRefSelect.propTypes = {
    mainLabel: PropTypes.string,
    suppliersList: PropTypes.array,
    suppliersValue: PropTypes.any,
    onSupplierChange: PropTypes.func,
    itemIdLabel: PropTypes.string,
    itemIdValue: PropTypes.any,
    onItemIdChange: PropTypes.func,
}

export default ProductRefSelect;