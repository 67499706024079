

export default {
    CATALOG: "/catalog",
    CATALOG_MANAGEMENT: "/catalog-management",
    PRODUCT_MANAGEMENT: "/catalog-management/:supplierId/:productId",
    NEW_PRODUCT_MANAGEMENT: "/catalog-management/:catalogId/:productId/:mode",
    ARTICLES_MANAGEMENT: "/article-management",
    ARTICLE_FORM: "/article-management/article/:id",
    ORDERS_MANAGEMENT: "/orders",
    ORDERS_HISTORY_MANAGEMENT: "/ordersHistory",
    DELIVERIES: "/deliveries",
    DELIVERY: "/deliveries/:supplierId/:contractorId/:projectId/:orderId",
    ORDER_FORM: "/orders/:supplierId/:contractorId/:projectId/:orderId",
    SUPPLIERS: "/suppliers",
    SUPPLIER: "/suppliers/:supplierId",
    PROJECTS: "/projects",
    CONTRACTORS: "/contractors",
    CONTRACTOR: "/contractors/:contractorId",
    PO_LIST: "/po",
    PO: "/po/:supplierId/:customerId/:poId",
    PRICE_LIST: "/price-list",
    PRICE_LIST_ITEMS: "/price-list/:supplierId/:contractorId",
    DASHBOARD: "/dashboard",
    MESSAGES: "/messages",
    WELCOME: "/welcome",
    PROFILE: "/profile",
    RENTALS: "/rentals",
    INVOICE_REPORT: "/invoice-report",
    NOT_FOUND: "/404",
    UNAUTHORIZED: "/403"
}
