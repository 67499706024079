import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { firebaseConnect, getVal } from "react-redux-firebase";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { Col, Row } from "reactstrap";
import { Card, CartHeader, InputGroup, InputLabel } from "../../../styles/stylesheet";
import ImageUploader from "../ImageUploader";
import IconInput from "../../../components/Form/IconInput";

class Branding extends PureComponent {
    render() {
        const {
            supplierId,
            firebase,
            printMarginTop
        } = this.props;
        return (
            <Row>
                <Col md={12}>
                    <Card>
                        <CartHeader>מיתוג חנות</CartHeader>
                        <Row>
                            <Col md={4}>
                                <InputGroup>
                                    <InputLabel>לוגו</InputLabel>
                                    <ImageUploader
                                        supplierId={supplierId}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={2}>
                                <InputGroup>
                                    <InputLabel>{"מרווח עליון בהדפסה (px)"}</InputLabel>
                                    <IconInput
                                        type={"number"}
                                        onChange={(text) => {
                                            firebase.set(`suppliersPrivateData/${supplierId}/printMarginTop`, text)
                                        }}
                                        value={printMarginTop}
                                        icon={<img src={"img/picker/plus.png"}/>}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        );
    }
}

Branding.propTypes = {
    printMarginTop: PropTypes.number,
    firebase: PropTypes.object,
    supplierId: PropTypes.string
};


const mapStateToProps = (state, props) => {
    return {
        printMarginTop: getVal(state.firebase.data, `suppliersPrivateData/${props.supplierId}/printMarginTop`, 0),
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);


export default compose(
    firebaseConnect((props) => ([
        `suppliersPrivateData/${props.supplierId}/printMarginTop`
    ])),
    connect(
        mapStateToProps, mapDispatchToProps
    )
)(Branding);

