import moment from "moment";

//text contains
export const simpleTextFilter = (filter, row, key) => {
    return (!filter.value || filter.value === '') || row[key] && row[key].toLowerCase().indexOf(filter.value.toLowerCase()) > -1
}

export const simpleComparatorFilter = (filter, row, key) => {
   // console.log("simpleComparatorFilter", filter, row, key);
    if(!filter.value || filter.value === ''){
     //   console.log("value not set")
        return true;
    }
    console.log(row[key], filter.value.value, row[key] === filter.value.value)

    switch (filter.value.condition) {
        case "notEqual":
            const b = Array.isArray(filter.value.value) ? !filter.value.value.includes(row[key]) : row[key] !== filter.value.value;
           //console.log("notEqual", b)
            return b;
        case  "equal":{
            const newVar = Array.isArray(filter.value.value) ? filter.value.value.includes(row[key]) : row[key] === filter.value.value;
          //  console.log("equal", newVar)
            return newVar;

        }

    }

}

export const booleanFilter = (filter, row, key) => {
   // console.log("booleanFilter", row[key], (!filter.value || filter.value === '') || row[key])
    if (filter.value && filter.value.value !== null && filter.value.condition){
        switch (filter.value.condition) {
            case "notEqual":
                return filter.value.value !== row[key]
            case  "equal":{
                return filter.value.value === row[key]
            }

        }
    }
    return (!filter.value || filter.value === '') || row[key]
}

export const statusFilter = (filter, row) => {

    if (!filter.value || filter.value === '' || !filter.value.value || !filter.value.condition) {
        return true;
    }
    if (filter.value.value && filter.value.condition) {
        const status = filter.id === "combinedStatus" && filter.value.value === "הזמנה" ? row.combinedStatus : row.status;
        switch (filter.value.condition) {
            case "notEqual":
                return Array.isArray(filter.value.value) ? !filter.value.value.includes(status) : status !== filter.value.value;
            case  "equal":{
                const equal = Array.isArray(filter.value.value) ? filter.value.value.includes(status) : status === filter.value.value;
                // this is a dirty trick to hide in store orders
                if(filter.value.value === "הזמנה" && row._original.initiatedBySupplier){
                    return false;
                }
                return equal;
            }

        }
    }
    return true;
}

export const totalFilter = (filter, row, key = "total") => {

    if (!filter.value || filter.value === '' || !filter.value.value || !filter.value.condition) {
        return true;
    }
    if (filter.value.value && filter.value.condition) {
        switch (filter.value.condition) {
            case "greaterThan":
                return row[key] > filter.value.value;
            case  "smallerOrEqual":
                return row[key] <= filter.value.value;
        }
    }
    return true;
}

export const  dateFilter = (filter, row, key = "deliveryDate") => {
    console.log("dateFilter", filter, row, key);
    if(!filter.value || filter.value === '' || !filter.value.startDate || !filter.value.endDate){
        return true;
    }
    const between = moment(row[key]).isBetween(filter.value.startDate, filter.value.endDate);
    const same = moment(row[key]).isSame(filter.value.startDate);
    console.log("between", between, moment(row[key]).format("D/MM/YY"), filter.value.startDate.format("D/MM/YY"), filter.value.endDate.format("D/MM/YY"))
    if(between || same){
        return true
    }

    return  false
}

export const myOrdersFilter = (filter, row) => {
    if (!filter.value || filter.value === '' || !filter.value.value || !filter.value.condition) {
        return true;
    }
    if (filter.value.value && filter.value.condition) {
        console.log("row", row)
        const openedBy = row._original.lastUpdatedBy ? row._original.lastUpdatedBy.uid : row._original.initiatedBySupplier && row._original.openedBy ? row._original.openedBy.uid : null;
        switch (filter.value.condition) {
            case "notEqual":
                return  filter.value.value !== openedBy;
            case  "equal":
                return filter.value.value === openedBy;
            default:
                return true;
        }
    }
    return true;
}