import React, { Component } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux';

import { firebaseConnect, isLoaded } from 'react-redux-firebase'
import { compose } from 'redux'
import { cloneDeep, isEqual, isEmpty } from "lodash"
import { getContractorIds } from "shared/src/selectors/roles";
import styled from "styled-components";

import FirebaseObjectListSelect from "../../components/Form/FirebaseObjectListSelect"

import Projects from "./projects"


class ProjectsManagement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedContractor: null,
            contractors: {}
        }

    }

    componentDidMount() {
        this.initContractorsList();
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.isApplicationOrdersAdmin !== prevProps.isApplicationOrdersAdmin || !isEqual(this.props.contractorIds, prevProps.contractorIds) || !isEqual(this.props.contractors, prevProps.contractors)) {
            this.initContractorsList();
        }
    }

    initContractorsList = () => {
        let stateContractors = {};
        if (this.props.isApplicationOrdersAdmin){

            console.log("setting admin contractors")
            stateContractors = this.props.contractors;
            this.setState({contractors: this.props.contractors})
        }
        else {
            const {contractors} = this.props;
            if(!contractors){
                console.log("skipping initializing")
                return;
            }
            //const stateContractors = {};
            console.log("setting contractor contractors", this.props.contractorIds);
            this.props.contractorIds.contractorIds.map((id) => {
                stateContractors[id] = contractors[id];
            })
            this.setState({
                contractors: stateContractors
            })
        }
        if(stateContractors){
            const keys = Object.keys(stateContractors);
            if(keys.length === 1){
                this.onContractorSelection(keys[0]);
            }
        }

    }

    onContractorSelection = (contractorId) => {
        console.log("contractor selected", contractorId)
        this.setState({
            selectedContractor: contractorId
        });

    }


    render() {

        const {selectedContractor} = this.state;
        const {contractors} = this.state;

        return (
            <div>
                {selectedContractor && (
                    <FirebaseObjectListSelect
                        list={contractors}
                        onChange={(value) => this.onContractorSelection(value)}
                        hintText={"בחרו קבלן"}
                        value={selectedContractor}
                    />
                )}
                {!isEmpty(contractors) && !selectedContractor && (
                    <Aligner>
                        <MiddleSelect>
                            <h4>{"בחרו קבלן"}</h4>
                            <FirebaseObjectListSelect
                                list={contractors}
                                onChange={(value) => this.onContractorSelection(value)}
                                hintText={"בחרו קבלן"}
                                value={selectedContractor}
                            />
                        </MiddleSelect>
                    </Aligner>
                )}
                {selectedContractor && (<Projects suppliers={this.props.suppliers} contractorId={selectedContractor}/>)}
            </div>
        );
    }
}

ProjectsManagement.propTypes = {
    contractors: PropTypes.object,
};

ProjectsManagement.defaultProps = {};

const mapStateToProps = (state) => {
    return {
        contractors: state.firebase.data.contractors,
        suppliers: state.firebase.data.suppliers,
        isApplicationOrdersAdmin: state.ordersManagement.isApplicationOrdersAdmin,
        contractorIds: getContractorIds(state.roles.roles)
    }
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);


const Aligner = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const MiddleSelect = styled.div`
    max-width: 50%;
    border: 1px dashed;
    margin-top: 100px;
    padding: 10px;
`;


export default compose(
    firebaseConnect([
        'contractors',
        "suppliers"
    ]),
    connect(
        mapStateToProps, mapDispatchToProps
    )
)(ProjectsManagement);
