import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Col, Modal, ModalBody, Row } from "reactstrap";
import Select from "react-select";
import styled from "styled-components";

class ProjectSelectionModal extends PureComponent {
    render() {
        const {isOpen,
            selectLabel,
            onChane,
            options,
            onSubmit,
            onClose,
            disabled,
        } = this.props;
        return (
            <Modal isOpen={isOpen} toggle={onClose}>
                <ModalBody>
                    <Row>
                        <Col md={12}>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                placeholder={selectLabel}
                                onChange={onChane}
                                isClearable={false}
                                isRtl={true}
                                isSearchable={true}
                                name="project"
                                options={options}
                            />
                        </Col>
                    </Row>
                    <Button disabled={disabled} onClick={onSubmit}>בחירת פרוייקט</Button>
                </ModalBody>
            </Modal>
        );
    }
}

ProjectSelectionModal.propTypes = {
    isOpen: PropTypes.bool,
    selectLabel: PropTypes.string,
    onChane: PropTypes.func,
    options: PropTypes.oneOf(PropTypes.shape({
        value: PropTypes.any,
        label: PropTypes.string
    })),
    onSubmit: PropTypes.func,
    onClose: PropTypes.func,
    disabled: PropTypes.bool
};

const Button = styled.button`
    background: #319CD8;
    border-radius: 3px;
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    text-align: center;
    color: #FFFFFF;
    height: 34px;
    line-height: 34px;
    padding: 0 11px;
    cursor: pointer;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);
    border: 0 !important;
`;

export default ProjectSelectionModal;