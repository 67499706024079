import React  from 'react';
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle
} from "reactstrap"

import {injectIntl, FormattedMessage} from 'react-intl';

const ProductListItem = ({result}) => {
    return (
        <Card>
            <CardHeader>
                <CardTitle>{result.item.name}</CardTitle>
            </CardHeader>
           {/* <CardBody>
                <div><FormattedMessage id="product.id"/></div>
                <div></div>
            </CardBody>*/}
        </Card>
    );
};

/*ProductListItem.propTypes = {
    result: React.PropTypes.object.isRequired
};*/

export default injectIntl(ProductListItem);