import {ACL} from "shared/src/constants/config"
import { haveSupplierOrdersAccess,
    haveSupplierCatalogAccess,
    haveSupplierDriverAccess,
    canViewGenericCatalog,
    haveContractorOrdersAccess,
    haveContractorManagementAccess,
    isSupplierAdmin,
    isSystemAdmin
} from "shared/src/selectors/roles";
import ROUTES from "../../constants/routes"


export default {
    items: [
        {
            name: 'sidebar.catalog',
            url: ROUTES.CATALOG,
            icon: 'icon-speedometer',
            acl: canViewGenericCatalog,
            imgIcon: "img/nav/nav_catalog.svg"
        },
        {
            name: 'sidebar.catalog.management',
            url: ROUTES.CATALOG_MANAGEMENT,
            icon: 'icon-speedometer',
            acl: haveSupplierCatalogAccess,
            imgIcon: "img/nav/nuv_catalog_mgmt.svg"
        },
        {
            name: 'sidebar.articles.management',
            url: ROUTES.ARTICLES_MANAGEMENT,
            icon: 'icon-speedometer',
            acl: [ACL.news]
        },
        {
            name: 'sidebar.orders.management',
            url: ROUTES.ORDERS_MANAGEMENT,
            icon: 'icon-speedometer',
            acl: [haveSupplierOrdersAccess, haveContractorOrdersAccess],
            imgIcon: "img/nav/nav_orders_mgmt.png"
        },
        {
            name:  "sidebar.orders.history",
            url: ROUTES.ORDERS_HISTORY_MANAGEMENT,
            icon: 'icon-speedometer',
            acl: [haveContractorOrdersAccess, haveSupplierOrdersAccess]
        },
        {
            name: "sidebar.delivery.management",
            url: ROUTES.DELIVERIES,
            icon: 'icon-speedometer',
            acl: [haveSupplierOrdersAccess,haveSupplierDriverAccess],
            imgIcon: "img/nav/nav_deliveries.png"
        },
        {
            name: "sidebar.suppliers.management",
            url: ROUTES.SUPPLIERS,
            icon: 'icon-speedometer',
            acl: [isSupplierAdmin],
            imgIcon: "img/nav/nav_suppliers_mgmt.svg"
        },
        {
            name: "sidebar.projects.management",
            url: ROUTES.PROJECTS,
            icon: 'icon-speedometer',
            acl: [haveContractorOrdersAccess],
            imgIcon: "img/nav/nav_projects_mgmt.svg"
        },
        {
            name: "sidebar.contractors.management",
            url: ROUTES.CONTRACTORS,
            icon: 'icon-speedometer',
            acl: [haveContractorManagementAccess],
            imgIcon: "img/nav/nav_contractors_mgmt.svg"
        },
       /* {
            name: "sidebar.suppliers.po",
            url: ROUTES.PO_LIST,
            icon: 'icon-speedometer',
            acl: [haveSupplierOrdersAccess, haveContractorOrdersAccess],
            imgIcon: "img/nav/nav_contractors_mgmt.svg"
        },*/
        {
            name: "sidebar.priceList",
            url: ROUTES.PRICE_LIST,
            icon: 'icon-speedometer',
            acl: [haveSupplierOrdersAccess, haveContractorOrdersAccess],
            imgIcon: "img/nav/nav_orders_mgmt.png"
        },
        {
            name: "sidebar.rentals",
            url: ROUTES.RENTALS,
            icon: 'icon-speedometer',
            acl: [haveSupplierOrdersAccess],
            imgIcon: "img/nav/nav_orders_mgmt.png"
        },
        {
            name: "sidebar.reports.invoice",
            url: ROUTES.INVOICE_REPORT,
            icon: 'icon-speedometer',
            acl: [haveContractorManagementAccess],
            imgIcon: "img/nav/nav_orders_mgmt.png"
        }

    ]
};
