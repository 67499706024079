import React, { Component } from 'react';
import PropTypes from 'prop-types'

import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from 'reactstrap';
import { ORDER_LIST_ITEM_CONTEXT_TYPES } from "./orderListItem";
import OrderListItem from "./orderListItem";
// import InnerCatalog from "./innerCatalog";
import Catalog from "../../components/Form/main/Catalog";
import { noProductImageUrl, ORDER_MANAGEMENT_CONTEXT } from "shared/src/constants/orders";
import { normalizeItemScheme } from "shared/src/modules/orders";
import { fonts, colors } from "../../styles/styleguide";
import { REPLACEMENT_REASONS } from "shared/src/constants/orders";
import IconInput, { IconWrapper } from "../../components/Form/IconInput";
import IconButton from "../../components/Form/IconButton"
import styled from "styled-components";
import { getItemPackage } from "shared/src/selectors/orders";



class ReplaceItemModal extends Component {

    constructor() {
        super();
        this.state = {
            replacements: [],
            discount: 0,
            replacementReason: "",
            replacementsError: "",
            replacementReasonError: "",
        };

        this.replacementOptions = [];
        for (let reason in REPLACEMENT_REASONS) {
            this.replacementOptions.push({value: reason, label: REPLACEMENT_REASONS[reason]});
        }
    }

    addReplaceItem = (item, room, count, action, note, userSelectedRentInterval) => {
        const {replacements} = this.state;
        replacements.push(normalizeItemScheme(item, room, count, action, note, userSelectedRentInterval));
        this.setState({replacements})
    }

    changeReplaceItemQuantity = (index, quantity) => {
        const {replacements} = this.state;
        replacements[index].count = quantity;
        this.setState({replacements})
    }

    removeReplacementItem = (index) => {
        const {replacements} = this.state;
        replacements.splice(index, 1);
        this.setState({replacements})
    }

    resetWidget = () => {
        this.setState({replacements: [], discount: 0})
    }

    replacementsPrice = () => {
        const {replacements, discount} = this.state;
        let total = 0;
        replacements.forEach((item) => {
            total += item.count * item.default_price;
        })
        if (discount && discount != 0) {
            total = total * (discount / 100);
        }
        return Math.round((total) * 100) / 100;;

    }

    validate = () => {
        const {fulfillMode} = this.props;
        const {replacements, replacementReason} = this.state;
        let isValid = true;
        let replacementsError = "";
        let replacementReasonError = ""
        if(replacements.length === 0){
            replacementsError = "חובה לבחור לפחות פריט אחד להחלפה";
            isValid = false;
        }
        if(!fulfillMode && !replacementReason){
            replacementReasonError = "חובה לבחור סיבה להחלפה";
            isValid = false;
        }
        this.setState({
            replacementsError,
            replacementReasonError
        })
        return isValid;
    }

    submit = () => {
        const {replacements, replacementReason, discount} = this.state;
        const { onSubmit} = this.props;
        if(this.validate()){
            onSubmit(replacements, replacementReason, discount)
            this.resetWidget();
        }
    }


    render() {
        const {isOpen, closeHandler, onSubmit, item, orderId} = this.props;
        const {replacements, discount,
            replacementsError,
            replacementReasonError} = this.state;

        const {
            isLoadingCatalog, query, catalog, updateQuery, getCatalog, contractorId, supplierId, fulfillMode, onAddGenericProduct
        } = this.props;

        // console.log("item", item);
        // console.log("replacements", replacements);

        let itemsPerRow = 3;
        let colSize = Math.ceil(12 / itemsPerRow);
        let row = []
        let rows = [];
        replacements.forEach((item, index) => {
            row.push(<Col md={colSize} key={`item_${item.id}_${item.room ? item.room.id : index}_${index}`}>
                <OrderListItem
                    name={item.name}
                    imgSrc={item.downloadURL || noProductImageUrl}
                    price={item.default_price}
                    catalogId={item.catalogId}
                    count={item.count}
                    packageInfo={getItemPackage(item)}
                    context={ORDER_LIST_ITEM_CONTEXT_TYPES.REPLACE_WIDGET}
                    onRemoveItem={() => {
                        this.removeReplacementItem(index)
                    }}
                    setProductQuantity={(quantity) => {
                        this.changeReplaceItemQuantity(index, quantity)
                    }}
                />
            </Col>)
            if (row.length === itemsPerRow || index + 1 === replacements.length) {
                rows.push(<Row style={{marginTop: 10}}>{row}</Row>)
                row = [];
            }
        })

        return (
            <Modal isOpen={isOpen} toggle={closeHandler} style={{maxWidth: 1240}} scrollable={true}>
                <ModalHeader>
                    {fulfillMode ? "הוספת מוצר שלא נמצא" : "החלפת מוצרים"}
                </ModalHeader>
                <ModalBody>
                    <div style={{
                        backgroundColor: "#e2e2e2",
                        padding: 30,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center"
                    }}>
                        <div style={{
                            fontFamily: fonts.OpenSansHebrew,
                            fontSize: 27,
                            color: colors.charcoal,
                            marginLeft: 15
                        }}>הפריט שהתבקש
                        </div>
                        <OrderListItem
                            name={fulfillMode ? item.searchTerm : item.name}
                            imgSrc={item.downloadURL || noProductImageUrl}
                            price={fulfillMode ? null : item.default_price}
                            catalogId={fulfillMode ? null :item.catalogId}
                            count={item.count}
                            packageInfo={getItemPackage(item)}
                            context={ORDER_LIST_ITEM_CONTEXT_TYPES.READ_ONLY}/>
                        <div style={{
                            fontFamily: fonts.OpenSansHebrew,
                            fontSize: 27,
                            color: colors.charcoal,
                            marginRight: 15
                        }}>
                            <div>במקום זה בחרו חלופה</div>
                            <div style={{fontSize: 19, display: "flex", flexDirection: "row", alignItems: "center"}}>
                                <img src={"img/icons/info_black.svg"} style={{width: 19, height: 19}}/>
                                ניתן לבחור יותר מפריט אחד
                            </div>
                        </div>
                    </div>
                    {fulfillMode ? null : (
                        <div>

                            <div style={{
                                fontFamily: fonts.OpenSansHebrew,
                                fontSize: 23,
                                color: colors.charcoal,
                                marginRight: 15
                            }}>
                                <div>סיבת ההחלפה:</div>
                                <IconInput
                                    value={this.state.replacementReason}
                                    options={this.replacementOptions}
                                    type={"select"}
                                    onChange={(value)=>{this.setState({replacementReason: value})}}
                                    icon={<img src={"img/icons/question-mark.png"} style={{width: 27, position: "absolute"}}/>}
                                    width={"200px"}
                                />
                                {replacementReasonError ? (<div style={{
                                    fontFamily: fonts.OpenSansHebrew,
                                    fontSize: 17,
                                    color: colors.red,
                                    marginRight: 15
                                }}>{replacementReasonError}</div>) : null}
                            </div>
                        </div>
                    )}

                    <div>
                        {replacementsError ? (<div style={{
                            fontFamily: fonts.OpenSansHebrew,
                            fontSize: 17,
                            color: colors.red,
                            marginRight: 15
                        }}>{replacementsError}</div>) : null}
                        {replacements.length > 0 && (<div style={{
                            fontFamily: fonts.OpenSansHebrew,
                            fontSize: 27,
                            color: colors.charcoal,
                            marginLeft: 15
                        }}>הפריט שהתבקש</div>)}
                        {rows}
                    </div>
                    <div>
                        <Catalog
                            catalog={catalog}
                            query={query}
                            orderId={orderId}
                            contractorId={contractorId}
                            autoCompleteList={[]}
                            updateQueryHandler={updateQuery}
                            getCatalogHandler={getCatalog}
                            onAdToCart={(item, room, count, action, note, userSelectedRentInterval) => {
                                // console.log("item11", item)
                                // console.log("count", count)

                                this.addReplaceItem(item, room, count, action, note, userSelectedRentInterval)
                            }}
                            isLoadingCatalog={isLoadingCatalog}
                            disabledCatalogItems={false}
                            supplierId={supplierId}
                            // itemsPerRow={3}
                            simpleAdd={true}
                            context={ORDER_MANAGEMENT_CONTEXT.SUPPLIER}
                            formContext={ORDER_MANAGEMENT_CONTEXT.SUPPLIER}
                            onAddGenericProduct={onAddGenericProduct ? ()=>{onAddGenericProduct(this.addReplaceItem)} : null}
                        />
                    </div>
                </ModalBody>
                <ModalFooter style={{backgroundColor: "#e1e1e1", display: "flex", justifyContent: "space-between"}}>
                    <div>
                        <div style={{
                            fontFamily: fonts.OpenSansHebrew,
                            fontSize: 31,
                            color: colors.charcoal,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center"
                        }}>
                            <span>נבחרו</span>
                            <span style={{fontWeight: "bold", marginRight: 5}}>{replacements.length}</span>
                            <span style={{marginRight: 5}}>פריטים</span>
                            <span style={{color: "#777777", marginRight: 5}}>|</span>
                            <span style={{marginRight: 5}}>הזן הנחה באחוזים</span>
                            <span style={{width: 125, marginRight: 5}}>
                                <IconInput
                                    onChange={(value) => {
                                        this.setState({discount: value})
                                    }}
                                    value={discount}
                                    icon={<img src={"img/icons/percent.svg"} style={{width: 27, marginTop: 8}}/>}
                                />
                            </span>
                            <span style={{color: "#777777", marginRight: 5}}>|</span>
                            <span style={{marginRight: 5}}>סה"כ</span>
                            <span style={{fontWeight: "bold", marginRight: 5}}>{this.replacementsPrice()}</span>
                            <span>&#8362;</span>


                        </div>
                    </div>

                    <div style={{display: "flex", justifyContent: "flex-start", flexDirection: "row"}}>
                        <IconButton
                            label={"ביטול"}
                            onClick={() => {
                                this.resetWidget();
                                closeHandler();
                            }}
                            icon={<img src={"img/icons/undo_white.svg"}/>}

                        />
                        <div style={{marginRight: 15}}>
                            <IconButton
                                label={fulfillMode ? "אישור" : "החלף"}
                                onClick={this.submit}
                                backgroundColor={"#fecd08"}
                                icon={<img src={"img/icons/v_black.png"} style={{width: 36, height: 36}}/>}
                            />
                        </div>

                        {/*  <button onClick={() => {
                            this.resetWidget();
                            closeHandler();
                        }}>ביטול
                        </button>
                        <button onClick={() => {
                            onSubmit(replacements, discount)
                        }}>החלף
                        </button>*/}
                    </div>

                </ModalFooter>
            </Modal>
        );
    }
}

ReplaceItemModal.propTypes = {
    isOpen: PropTypes.bool,
    closeHandler: PropTypes.func,
    onSubmit: PropTypes.func,
    item: PropTypes.object,
    isLoadingCatalog: PropTypes.bool,
    query: PropTypes.string,
    catalog: PropTypes.array,
    updateQuery: PropTypes.func,
    getCatalog: PropTypes.func,
    contractorId: PropTypes.string,
    supplierId: PropTypes.string,
    fulfillMode: PropTypes.bool
};

ReplaceItemModal.defaultProps = {
    isOpen: false,
    closeHandler: () => {
    },
    onSubmit: () => {
    },
    item: {}
};

export const InputWrapper = styled.input`
  height: 43px;
  border-radius: 21.5px;
  border: 1px solid #d1d1d1;
  position: relative;
  background-color: ${props => props.disabled ? "#f0f0f0" : "#fff"};
    &:focus{
      outline: none;
    }

`;


export default ReplaceItemModal;