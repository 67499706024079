import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { forEach } from 'lodash';
import { IntlProvider, addLocaleData } from 'react-intl';

import translations from 'shared/src/constants/locales';

// Go over all of the available locales and register them
forEach(translations, (value, key) => {
    addLocaleData({
        locale: key,
        // Couldn't find any documentation about 'pluralRuleFunction', throws error if not present
        pluralRuleFunction: () => {}
    });
});

class IntlWrapper extends React.Component {
    render() {
        return (
            <IntlProvider
                locale={this.props.locale}
                key={this.props.locale}
                messages={translations[this.props.locale]}
            >
                { this.props.children }
            </IntlProvider>
        );
    }
}

IntlWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    locale: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
    locale: state.locale.locale
});

export default connect(mapStateToProps)(IntlWrapper);
