import React, {Component} from 'react';
import {
    Input,
    Button,
    Label,
    FormGroup,
} from 'reactstrap';

import {firebaseConnect} from 'react-redux-firebase'
import styled from 'styled-components';

const __html = require('./terms.html');
const termsTemplate = { __html: __html };

import RoundButton from "../../../components/RoundButton"
import IconInput, { IconWrapper } from "../../../components/Form/IconInput";

import {  InputGroup  } from "../../../styles/stylesheet";
import { colors} from "../../../styles/styleguide";
import ROUTES from "../../../constants/routes";

import AuthPage, {GoogleButton, GoogleLogo, LoginError, ContentWrapper} from "../AuthPage";
// import { Link } from "react-router-dom";


class Register extends Component {

    constructor() {
        super();
        this.state = {
            email: "",
            password: "",
            passwordConfirm: "",
            loginError: "",
            terms: false,
            displayName: "",
            phoneNumber: "",
            isValidForm : {
                email: true,
                password: true,
                passwordConfirm: true,
                passwordMatch: true,
                terms: true,
                displayName: true,
                phoneNumber: true,
            },
            isTermsModalOpen: false
        }
    }

    onLoginSuccess = (response, user) => {
        const {history, firebase} = this.props;
        let invitationId = location.href.split('invId=')[1]
        if(invitationId){
            firebase.ref(`/userInvites/${user.uid}`).set({
                inviteId: invitationId,
                status: { created: firebase.database.ServerValue.TIMESTAMP }
            })
        }

        if (response.email) {
            console.log("login success");
            history.push(ROUTES.WELCOME);
        }
    }

    validateForm = () => {
        const {email, password, passwordConfirm, terms, displayName,
            phoneNumber} = this.state;
        const isValidForm = {
            email: true,
            password: true,
            passwordConfirm: true,
            passwordMatch: true,
            terms: true,
            displayName: true,
            phoneNumber: true,

        };

        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        isValidForm.email = emailRegex.test(String(email).toLowerCase());

        if(password === ""){
            isValidForm.password = false
        }

        if(passwordConfirm === ""){
            isValidForm.passwordConfirm = false
        }

        if(password !== passwordConfirm){
            isValidForm.passwordMatch = false
        }

        if(displayName === ""){
            isValidForm.displayName = false;
        }

        const phoneNumberRegex = /^[+]?(1\-|1\s|1|\d{3}\-|\d{3}\s|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/g;
        isValidForm.phoneNumber = phoneNumberRegex.test(phoneNumber);

        isValidForm.terms = terms;

        this.setState({isValidForm});

        console.log(isValidForm);
        return isValidForm.email && isValidForm.password && isValidForm.passwordConfirm && isValidForm.passwordMatch && isValidForm.terms && isValidForm.displayName && isValidForm.phoneNumber;
    }

    onSignUp = () => {
        const {firebase} = this.props;
        const {email, password, displayName,
            phoneNumber} = this.state;
        this.setState({loginError: ""})
        if (this.validateForm()) {
            firebase.createUser({email, password}, {email, displayName, phoneNumber}).then((response) => {
                console.log("response",response)
                let user = firebase.auth().currentUser;
                this.onLoginSuccess(response, user)
            }).catch(e => {
                console.log("login error", e);
                this.setState({loginError: e.message})
            })
        }

    }

    onGoogleSignIn = () => {
        const {firebase} = this.props;
        this.setState({loginError: ""})
        firebase.login({
            provider: 'google',
            type: 'popup'
        }).then((response) => {
            this.onLoginSuccess(response)
        }).catch(e => {
            console.log("login error", e);
            this.setState({loginError: e.message})
        })
    }

    onFacebookSignIn = () => {
        const {firebase} = this.props;
        this.setState({loginError: ""})
        firebase.login({
            provider: 'facebook',
            type: 'popup'
        }).then((response) => {
            this.onLoginSuccess(response)
        }).catch(e => {
            console.log("login error", e);
            this.setState({loginError: e.message})
        })
    }

    _handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            console.log('enter pressed');
            this.onSignUp();
        }
    }


    render() {

        const {email, password, passwordConfirm, loginError, isValidForm, terms,  isTermsModalOpen, displayName, phoneNumber} = this.state;
        let invitationId = location.href.split('invId=')[1]
        let smsPath = "sms-login"
        if(invitationId){
            smsPath = "sms-login?invId=" + invitationId
        }

        return (
            <AuthPage title={"הרשמה"}>
                <ContentWrapper>

                    <GoogleButton bg={colors.vibrantGreen90} onClick={()=>{this.props.history.push(smsPath)}}>
                        <div>הרשמה באמצעות SMS</div>
                    </GoogleButton>

                    <GoogleButton style={{marginTop: 15}} onClick={this.onGoogleSignIn}>
                        <GoogleLogo src={"img/google_logo.png"}/>
                        <div>הרשמה עם גוגל</div>
                    </GoogleButton>

                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-around" , marginTop: 15}}>
                        <div style={{width: "40%", height: 1, backgroundColor: "#ababab"}}/>
                        <span style={{
                            fontFamily: "OpenSansHebrew",
                            fontSize: 21.5,
                            fontWeight: 300,
                            color: "#ababab"
                        }}>או</span>
                        <div style={{width: "40%", height: 1, backgroundColor: "#ababab"}}/>
                    </div>
                    <LoginError style={{marginTop: 15}}>{loginError}</LoginError>

                    <InputGroup style={{marginTop: 15}}>
                        <IconInput
                            type={"email"}
                            onChange={(value) => {
                                this.setState({email: value})
                            }}
                            value={email}
                            dir="ltr"
                            onKeyPress={this._handleKeyPress}
                            icon={<img src={"img/icons/person_outline.svg"} style={{width: 31}}/>}
                            placeholder={"כתובת דואר אלקטרוני"}
                        />
                    </InputGroup>
                    {!isValidForm.email && <LoginError invalid>כתובת אימייל אינה תקינה</LoginError>}

                    <InputGroup style={{marginTop: 15}}>
                        <IconInput
                            type={"password"}
                            onChange={(value) => {
                                this.setState({password: value})
                            }}
                            value={password}
                            dir="ltr"
                            onKeyPress={this._handleKeyPress}
                            icon={<img src={"img/icons/password.png"} style={{width: 31}}/>}
                            placeholder={"סיסמא"}
                        />
                    </InputGroup>
                    {!isValidForm.password && <LoginError invalid>שכחתם להכניס סיסמא?</LoginError>}

                    <InputGroup style={{marginTop: 15}}>
                        <IconInput
                            type={"password"}
                            onChange={(value) => {
                                this.setState({passwordConfirm: value})
                            }}
                            value={passwordConfirm}
                            dir="ltr"
                            onKeyPress={this._handleKeyPress}
                            icon={<img src={"img/icons/password.png"} style={{width: 31}}/>}
                            placeholder={"אישור סיסמא"}
                        />
                    </InputGroup>
                    {!isValidForm.passwordConfirm && <LoginError invalid>שכחתם להכניס סיסמא?</LoginError>}


                    {!isValidForm.passwordMatch && <LoginError>הסיסמאות שהכנסתם שונות זו מזו</LoginError>}


                    <InputGroup style={{marginTop: 15}}>
                        <IconInput
                            type={"text"}
                            onChange={(value) => {
                                this.setState({displayName: value})
                            }}
                            value={displayName}
                            dir="ltr"
                            onKeyPress={this._handleKeyPress}
                            icon={<img src={"img/icons/person.svg"} style={{width: 31}}/>}
                            placeholder={"שם"}
                        />
                        {!isValidForm.displayName && <LoginError invalid>לא סיפרת לנו איך קוראים לך</LoginError>}
                    </InputGroup>

                    <InputGroup style={{marginTop: 15}}>
                        <IconInput
                            type={"text"}
                            onChange={(value) => {
                                this.setState({phoneNumber: value})
                            }}
                            value={phoneNumber}
                            dir="ltr"
                            onKeyPress={this._handleKeyPress}
                            icon={<img src={"img/icons/phone.svg"} style={{width: 31}}/>}
                            placeholder={"טלפון"}
                        />
                        {!isValidForm.phoneNumber && <LoginError invalid>אנא ודאו שמספר הטלפון תקין</LoginError>}
                    </InputGroup>

                    <FormGroup check>
                        <Label check>
                            <Input type="checkbox" value={terms} onChange={(e)=>{this.setState({terms: !terms})}} onKeyPress={this._handleKeyPress}/>
                            <span style={{marginRight: 15}}>אני מסכים</span>
                            <a href="http://company.punct.co.il/terms.html" target="_blank">לתנאי השימוש</a>
                            {
                                // <Button style={ { padding: 0, verticalAlign: "baseline", marginRight: 3 } } color="link"
                                //         onClick={ () => {
                                //             // this.setState({isTermsModalOpen: true})
                                //         } } className="px-0"> לתנאי השימוש</Button>
                            }
                        </Label>

                    </FormGroup>
                    {(!isValidForm.terms) && <LoginError>אנא אשרו את תנאי השימוש</LoginError>}


                    <div style={{display: "flex", flexDirection: "row", alignItems: "baseline", justifyContent: "space-between" , marginTop: 15}}>
                        <div
                            onClick={() => {
                                this.props.history.push("/login")
                            }}
                            style={{
                                fontFamily: "OpenSansHebrew",
                                fontSize: 23.5,
                                fontWeight: 300,
                                color: colors.charcoal,
                                cursor: "pointer"
                            }}
                        >
                            כבר יש לכם חשבון? הכנסו מכאן
                        </div>
                    </div>


                </ContentWrapper>
                <div style={{display: "flex", flexDirection: "row", alignItems: "baseline", justifyContent: "space-between"}}>
                    <div
                        onClick={() => {
                            window.location.href = '/about';
                        }}
                        style={{
                            fontFamily: "OpenSansHebrew",
                            fontSize: 23.5,
                            fontWeight: 300,
                            color: colors.charcoal,
                            cursor: "pointer"
                        }}
                    >
                        אודות החברה
                    </div>
                </div>
                <div style={{position: "absolute", bottom: -25, left: 15}}>
                    <RoundButton
                        onClick={this.onSignUp}
                        label={"הרשמו"}
                    />
                </div>
            </AuthPage>
        );
    }
}

export default firebaseConnect()(Register);
