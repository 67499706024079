import React, { Component } from 'react';
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components';
import { fonts, colors } from "../../styles/styleguide";
import propTypes from "react-table/lib/propTypes";

class IconInput extends Component {

    constructor(){
        super()
        this.state = {
            showPassword: false,
            isHover: false
        }
    }

    hoverIn = () => {
        this.setState({isHover: true})
    }

    hoverOut = () => {
        this.setState({isHover: false})
    }

    renderSelect = () => {
        const {
            value,
            options,
            labelAccessor,
            valueAccessor,
            onChange,
            onBlur,
            refHandler,
            disabled,
            containerStyle,
            borderRadius,
            onKeyPress,
            placeholder,
            size,
            icon
        } = this.props;


        const style = Object.assign({},containerStyle,{borderRadius});

        return (
            <Select
                ref={ref => refHandler(ref)}
                value={value}
                onChange={(e) => {
                    onChange(e.target.value)
                }}
                onBlur={onBlur}
                disabled={disabled}
                onKeyPress={onKeyPress}
                size={size}
                noIcon={!icon}
            >
                <Option size={size} value={""}>{placeholder}</Option>
                {options.map((o, index) => {
                    let label = ""
                    if(typeof labelAccessor === "function"){
                        label = labelAccessor(o);
                    }else {
                        label = o[labelAccessor]
                    }
                    return (<Option size={size} value={o[valueAccessor]}>{label}</Option>)
                })}
            </Select>
        )
    }

    renderInput = () => {
        const {showPassword} = this.state;

        const {
            type,
            value,
            onChange,
            onBlur,
            refHandler,
            disabled,
            borderRadius,
            placeholder,
            onKeyPress,
            size,
            inputStyle,
            autoFocus
        } = this.props;

        return (
            <Input
                ref={ref => refHandler(ref)}
                value={value}
                onChange={(e) => {
                    onChange(e.target.value, e)
                }}
                autoFocus={autoFocus}
                type={type === "password" ? (showPassword ? "text" : "password") : type}
                onBlur={onBlur}
                disabled={disabled}
                style={Object.assign({}, inputStyle, {borderRadius})}
                placeholder={placeholder}
                onKeyPress={onKeyPress}
                size={size}
            />
        )
    }

    renderTextArea = () => {

        const {
            value,
            onChange,
            onBlur,
            refHandler,
            disabled,
            borderRadius,
            placeholder,
            onKeyPress,
        } = this.props;

        return (
            <TextArea
                ref={ref => refHandler(ref)}
                value={value}
                onChange={(e) => {
                    onChange(e.target.value, e)
                }}
                onBlur={onBlur}
                disabled={disabled}
                style={{borderRadius}}
                placeholder={placeholder}
                onKeyPress={onKeyPress}
            />
        )
    }

    render() {

        const {showPassword, isHover} = this.state;

        const {
            icon,
            type,
            value,
            disabled,
            containerStyle,
            borderRadius,
            width,
            clearValueHandler,
            wrapperTop,
            wrapperRight,
            wrapperBottom,
            onHoverEdit,
            isLoading,
            leftIcon,
            onLeftClick,
            leftIconTop,
            leftIconLeft, renderInput, size
        } = this.props;

        const style = Object.assign({},containerStyle,{borderRadius});

        let renderFunc = ()=>{};
        if(renderInput && typeof renderInput === "function"){
            renderFunc = renderInput
        }else{
            switch (type) {
                case "select":
                    renderFunc = this.renderSelect;
                    break;
                case "textarea":
                    renderFunc = this.renderTextArea;
                    break;
                default:
                    renderFunc = this.renderInput;
            }
        }



        return (
            <InputWrapper
                onMouseEnter={this.hoverIn}
                onMouseLeave={this.hoverOut}
                style={style}
                disabled={disabled}
                width={width}
                inputType={type}
                size={size}
            >
                <IconWrapper
                    wrapperTop={wrapperTop}
                    wrapperRight={wrapperRight}
                    wrapperBottom={wrapperBottom}
                >{icon}</IconWrapper>
                {renderFunc()}
                {value && type === "password" ? (
                    <div style={{position: "absolute", left: 10, top: 5, cursor: "pointer"}}  onClick={()=>{this.setState({showPassword: !showPassword})}}>
                        <i className={showPassword ? "fa fa-eye-slash" : "fa fa-eye"}/>
                    </div>
                ) : null}
                {clearValueHandler ? ( <ClearIconWrapper leftIconTop={leftIconTop} leftIconLeft={leftIconLeft} onClick={clearValueHandler}><img src={"img/icons/delete-outline.svg"} width={27}/></ClearIconWrapper>) : null}
                {onHoverEdit && isHover ? (<ClearIconWrapper leftIconTop={leftIconTop} leftIconLeft={leftIconLeft} onClick={onHoverEdit}><img src={"img/icons/edit.svg"} width={27}/></ClearIconWrapper>) : null}
                {leftIcon ? (<ClearIconWrapper leftIconTop={leftIconTop} leftIconLeft={leftIconLeft} onClick={onLeftClick}>{leftIcon()}</ClearIconWrapper>) : null}
                {isLoading && (
                    <LoaderContainer className="icon-container">
                        <Loader className="loader"></Loader>
                    </LoaderContainer>
                )}
            </InputWrapper>
        );
    }
}

IconInput.propTypes = {
    icon: PropTypes.any,
    type: PropTypes.string,
    value: PropTypes.any,
    options: PropTypes.array,
    labelAccessor: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    valueAccessor: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    refHandler: PropTypes.func,
    disabled: PropTypes.bool,
    containerStyle: PropTypes.object,
    borderRadius: PropTypes.number,
    width: PropTypes.string,
    clearValueHandler: PropTypes.func,
    wrapperTop: PropTypes.string,
    wrapperRight: PropTypes.string,
    wrapperBottom: propTypes.string,
    placeholder: PropTypes.string,
    onHoverEdit: PropTypes.func,
    onKeyPress: PropTypes.func,
    isLoading: PropTypes.bool,
    leftIcon: PropTypes.func,
    onLeftClick: PropTypes.func,
    leftIconTop: PropTypes.number,
    leftIconLeft: PropTypes.number,
    size: PropTypes.oneOf(['sm', 'lg']),
    renderInput: PropTypes.func,
    inputStyle: PropTypes.object,
};

IconInput.defaultProps = {
    type: "text",
    size: "lg",
    options: [],
    labelAccessor: "label",
    valueAccessor: "value",
    onChange: (value) => {
    },
    onBlur: () => {
    },
    refHandler: (ref) => {
    },
    clearValueHandler: null,
    disabled: false,
    containerStyle: {},
    borderRadius: 21.5,
    width: "auto",
    wrapperTop: "0",
    wrapperRight: "8px",
    wrapperBottom: "0",
    leftIconTop: 8,
    leftIconLeft: 8,
    placeholder: "",
    onHoverEdit: null,
    onKeyPress: ()=>{},
    isLoading: false,
    renderInput: null,
    inputStyle: {},
    autoFocus: false
};

export const InputWrapper = styled.div`
  height: ${props => props.inputType === "textarea" ? "auto" : props.size === "lg" ? "43px" : "26px"};
  border-radius: 21.5px;
  border: 1px solid #d1d1d1;
  position: relative;
  background-color: ${props => props.disabled ? "#f0f0f0" : "#fff"};
  width: ${props => props.width};

`;

export const Input = styled.input`
  height: 100%;
  width: 100%;
  border-radius: 21.5px;
  border: 0;
  position: relative;
  font-family: ${fonts.OpenSansHebrew};
  color: ${colors.charcoal};
   background-color: ${props => props.disabled ? "#f0f0f0" : "#fff"};
  font-size: 16px;
  padding-right: 42px;
      line-height: ${props => props.size === "lg" ? "43px" : "26px"};
    &:focus{
      outline: none;
    }
    
    @media (min-width: 320px) and (max-width: 767px) {
      font-size: 13px;
    }
`;

export const TextArea = styled.textarea`
  height: 100%;
  width: 100%;
  border-radius: 21.5px;
  border: 0;
  position: relative;
  font-family: ${fonts.OpenSansHebrew};
  color: ${colors.charcoal};
   background-color: ${props => props.disabled ? "#f0f0f0" : "#fff"};
  font-size: 16px;
  padding-right: 42px;
  line-height: 43px;
    &:focus{
      outline: none;
    }
    
    @media (min-width: 320px) and (max-width: 767px) {
      font-size: 13px;
    }
`;

export const Select = styled.select`
  height: 100%;
  width:  ${props => props.noIcon ? "100%" : "calc( 100% - 47px )"};
  border-radius: 21.5px;
  border: 0;
  position: relative;
  font-family: ${fonts.OpenSansHebrew};
  color: ${colors.charcoal};
   background-color: ${props => props.disabled ? "#f0f0f0" : "#fff"};
  font-size:  ${props => props.size === "lg" ? "17px" : "14px"}17px;
    margin-right: ${props => props.noIcon ? "0" : "33px"};
    line-height: ${props => props.size === "lg" ? "43px" : "26px"};
    padding-left: 10px;
     &:focus{
      outline: none;
    }
`;

const Option = styled.option`
  font-family: ${fonts.OpenSansHebrew};
  color: ${colors.charcoal};
`

export const IconWrapper = styled.div`
    right: ${props => props.wrapperRight};
    top: ${props => props.wrapperTop};
    bottom: ${props => props.wrapperBottom};
    position: absolute;
    z-index: 2;
    display: flex;
    align-items: center;
`;

export const ClearIconWrapper = styled.div`
  left: ${props => props.leftIconLeft}px;
  top: ${props => props.leftIconTop}px;
  position: absolute;
   z-index: 2;
   cursor: pointer;
`;

const LoaderContainer = styled.div`
  position: absolute;
  left: 10px;
  top: calc(50% - 10px);
`;

export const around = keyframes`
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
`

const Loader = styled.div`
    position: relative;
  height: 20px;
  width: 20px;
  display: inline-block;
  animation: ${around} 5.4s infinite;
  
  &::after, &::before{
      content: "";
      background: white;
      position: absolute;
      display: inline-block;
      width: 100%;
      height: 100%;
      border-width: 2px;
      border-color: #333 #333 transparent transparent;
      border-style: solid;
      border-radius: 20px;
      box-sizing: border-box;
      top: 0;
      left: 0;
      animation:  ${around} 0.7s ease-in-out infinite;
  }
  
  &::after{
      animation: ${around} 0.7s ease-in-out 0.1s infinite;
      background: transparent;
  }
`;





export default IconInput;