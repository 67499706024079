import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isMobileOrTablet } from "../../utils/UA";
import { Col, Row } from "reactstrap";
import OrderListItem, { ORDER_LIST_ITEM_CONTEXT_TYPES } from "../OrdersManagement/orderListItem";
import { noProductImageUrl } from "shared/src/constants/orders";
import { getItemPackage } from "shared/src/selectors/orders";
const mobileOrTablet = isMobileOrTablet();
class Products extends Component {
    render() {
        const {items} = this.props;
        if(!items){
            return null
        }


        let itemsPerRow = mobileOrTablet ? 1 : 3;
        let colSize = Math.ceil(12 / itemsPerRow);
        let row = []
        let ids = "";
        let rows = [];

        items.map((item, index) => {
            ids += item.id;
            const price = !isNaN(item.spacialPrice) && item.spacialPrice !== null ? parseFloat(item.spacialPrice) : item.default_price
            row.push(<Col md={colSize} key={item.id}>
                <OrderListItem
                    catalogId={item.catalogId}
                    imgSrc={item.downloadURL || noProductImageUrl}
                    name={item.name}
                    packageInfo={getItemPackage(item)}
                    count={1}
                    price={price}
                    context={ORDER_LIST_ITEM_CONTEXT_TYPES.PRICE_LIST}
                    item={item}
                    disabled={true}
                    hidePrices={false}
                    hideDiscounts={false}
                />
            </Col>)

            if (row.length === itemsPerRow || index + 1 === items.length) {
                rows.push(<Row style={{marginTop: 30}} key={ids}>{row}</Row>)
                row = [];
                ids = "";
            }
        })
        return (
            <div>
                {rows}
            </div>
        );
    }
}

Products.propTypes = {
    items: PropTypes.array
};

export default Products;