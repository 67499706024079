import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import {getCatalog, updateQuery, clearCatalog} from "shared/src/modules/catalog";

const SearchBox = (SearchFrame, SearchInput, SearchResults) => {
    return connect(mapStateToProps, mapDispatchToProps)(class extends Component {
     /*   static propTypes = {
            query: React.PropTypes.string.isRequired,
            onQueryUpdate: React.PropTypes.func.isRequired,
            onQuerySubmit: React.PropTypes.func.isRequired,
            results: React.PropTypes.array.isRequired
        };*/

     componentWillUnmount(){
         this.props.clearCatalog()
     }

        render() {
            return (
                <SearchFrame>
                    <SearchInput query={this.props.query}
                                 onQueryUpdate={value => this.props.onQueryUpdate(value)}
                                 onSubmit={() => this.props.search({query: this.props.query})}
                    />
                    {this.props.didSearched && this.props.results.length === 0 && !this.props.isLoading && (
                        <div>לא נמצאו תוצאות חיפוש</div>
                    )}
                    <SearchResults
                        results={this.props.results}
                        isLoading={this.props.isLoading}
                    />
                </SearchFrame>
            );
        }
    });
};

const mapStateToProps = state => ({
    query: state.catalog.query,
    results: state.catalog.catalog,
    isLoading: state.catalog.isLoading,
    didSearched: state.catalog.didSearched
})

const mapDispatchToProps = dispatch => bindActionCreators({
    onQueryUpdate: updateQuery,
    search: getCatalog,
    clearCatalog
}, dispatch);


export default SearchBox