import React, { Component } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux';

import { firebaseConnect, isLoaded } from 'react-redux-firebase'
import { compose } from 'redux'
import _, { orderBy } from "lodash"
import { uuidWithoutSpace } from "shared/src/utils";
import { isUsersAdmin } from "shared/src/selectors/roles";
import { getContractors , getContractorUsers } from "shared/src/api/contractors"
import { isApplicationOrdersAdmin } from "shared/src/selectors/roles";

import { Row, Col } from "reactstrap"
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import FontIcon from 'material-ui/FontIcon';
import styled from "styled-components";

import StickyFloatingButton from "../../components/StickyFloatingButton"
import Select, { Creatable } from 'react-select';

class Contractors extends Component {

    constructor(props) {
        super(props);

        this.defaultContractor = {
            id: null,
            address: "",
            name: "",
            contractorManagers: {}

        }

        this.state = {
            isModalOpen: false,
            modalContext: null,
            modalContractor: this.defaultContractor,
            contractors: null,
            contractorManagers: {}
        }


    }

    componentDidMount() {
        const { firebase, user} = this.props
        const contractorManagers = {}
        getContractors(firebase,user).then(contractors => {
            this.setState({contractors})
            Object.keys(contractors).forEach(c => {
                if (contractors[c].contractorManagers) {
                    getContractorUsers(firebase,Object.keys(contractors[c].contractorManagers), c).then(cms => {
                        //console.log("Cont Mgnt updated",cms)
                        contractorManagers[c] = cms
                        this.setState({contractorManagers})
                    })
                }
            })
        })
        this.setState({isAdmin: isApplicationOrdersAdmin(user)})
    }

    renderContractor = (id, contractor) => {


        return (
            <Card onClick={() => {
                //this.openContractorModal(Object.assign({}, this.defaultContractor, {id}, contractor))
                this.props.history.push(`/contractors/${id}`);
            }}>
                <Row>
                    <Col md={6}>
                        <DetailsRow>
                            <FontIcon style={{fontSize: 18}} color={"#636363"}
                                      className={"material-icons"}>location_city</FontIcon>
                            <Detail>
                                {contractor.name}
                            </Detail>
                        </DetailsRow>

                        <DetailsRow>
                            <FontIcon style={{fontSize: 18}} color={"#636363"}
                                      className={"material-icons"}>place</FontIcon>
                            <Detail>{contractor.address}</Detail>
                        </DetailsRow>

                    </Col>
                    <Col md={6}>
                        <DetailsRow>
                            {this.renderContractorPMs(contractor)}
                        </DetailsRow>
                    </Col>
                </Row>
            </Card>
        )
    }

    renderContractorPMs = (contractor) => {

        //console.log("contractor for PM", contractor)
        const toRender = [];
        if(contractor.contractorManagers && this.state.contractorManagers[contractor.id]){
            for(let key in this.state.contractorManagers[contractor.id]) {
                //console.log("contractor PM", this.state.contractorManagers[contractor.id][key])
                toRender.push(this.renderContractorPM(this.state.contractorManagers[contractor.id][key]))
            }
        }
        return toRender;
    }

    renderContractorPM = (user) => {
        user = user || {}

        const formated = `${user.displayName || ""} - ${user.email || ""} ${user.phoneNumber || ""}`
        return (
            <div>
            <FontIcon style={{fontSize: 18}} color={"#636363"}
                      className={"material-icons"}>person</FontIcon>
            <Detail>{formated}</Detail>
            </div>

        )
    }

    openContractorModal = (contractor) => {
        this.setState({
            isModalOpen: true,
            modalContractor: contractor
        })
        //console.log("modalContractor", contractor)
    }

    renderContractors = () => {
        const {contractors} = this.state;
        //console.log("contractors", contractors)

        let toRender = [];
        const list = [];

        for(let key in contractors){
            const name = contractors[key].name;
            list.push({name, key})
        }
        const sorted = orderBy(list, ["name"]);
        if (sorted) {
            toRender = sorted.map((item, index)=>{ return this.renderContractor(item.key, contractors[item.key])})
            // toRender.push(this.renderSupplier(key, suppliers[key]))
        }

        return toRender;
    }

    transformToSchema = (contractor, id) => {
        if (!contractor.id) {
            contractor.id = id;
        }
        return contractor;
    }

    transformSelectListToObject = (list) => {
        const obj = {};
        for (let key in list) {
            const row = list[key];
            if (row.__isNew__) {
                const id = uuidWithoutSpace();
                obj[id] = {id: id, name: row.label}
            }
            else {
                obj[row.value] = {id: row.value, name: row.label}
            }
        }
        return obj;
    }

    transformSelectedUserToObject = (user, isAdmin = true) => {
        //console.log("Transform", user)
       /* const obj = {
            [user[0].value]: {
                admin: 200
            }
        };
        return obj;*/


        const obj = {};
        for (let key in user) {
            const row = user[key];
            if(isAdmin){
                obj[row.value] = {admin: 200}
            }
            else {
                obj[row.value] = {id: row.value}
            }


        }
        return obj;
    }

    formatUser(obj) {
        const list = [];

        if (!obj) {
            return list
        }

        const {users} = this.state;
        //console.log("formatUser", users, obj);
        for (let key in obj) {
            const item = {value: key,label: key };
            if(users && users[key]) {
                item.label = `${users[key].displayName || ""} - ${users[key].email}`;
            }
            list.push(item);
        }

        return list;
    }

    transformObjectToSelectList = (obj, type = "suppliers") => {
        const list = [];
        for (let key in obj) {
            const row = obj[key];
            const item = {value: key};
            if (type === "suppliers") {
                // get supplier name
                const {suppliers} = this.props;
                if (suppliers[key]) {
                    item.label = suppliers[key].name
                }
                else {
                    item.label = "לא ידוע"
                }
            }
            else  if(type === "users"){
               // console.log("user item", row);
                item.label = `${row.displayName || ""} - ${row.email}`;
            }
            else if(type === "contractorUsers"){
                //console.log("user item", row);
                const {users} = this.props;
                item.label =  `${users[key].displayName || ""} - ${users[key].email}`;
            }
            else {
                item.label = row.name;
            }
            list.push(item);

        }
        return list;

    }

    onContractorManagerChange = (value) => {
        //console.log("onContractorManagerChange", value);
        const {modalContractor} = this.state;
        const contractor = _.cloneDeep(modalContractor);
        const pm = this.transformSelectedUserToObject(value);
        //console.log(pm);
        contractor.contractorManagers = pm;
        this.setState({modalContractor: contractor});

    }

    onCrewMembersChange = (value) => {
        //console.log("onCrewMembersChange", value);
        const {modalContractor} = this.state;
        const contractor = _.cloneDeep(modalContractor);
        const pm = this.transformSelectedUserToObject(value, false);
        //console.log(pm);
        contractor.users = pm;
        this.setState({modalContractor: contractor});

    }

    render() {

        const {isModalOpen, modalContractor,isAdmin} = this.state;
        const {firebase, isUsersAdmin, users} = this.props;
        //console.log("isUsersAdmin", isUsersAdmin);

        return (
            <div>
                <div>
                    {this.renderContractors()}
                </div>
                { isAdmin && (
                    <StickyFloatingButton
                    icon={"add"}
                    onPress={() => {
                        const newId = uuidWithoutSpace();
                        this.props.history.push(`/contractors/${newId}`);
                    }}
                    />
                )}
                <Modal isOpen={this.state.isModalOpen}>
                    <ModalHeader>
                        <span>{modalContractor.id ? "עריכת קבלן" : "יצירת קבלן"}</span>
                        <ModalCloseButtonWrapper onClick={() => {
                            this.setState({isModalOpen: false})
                        }}>
                            <FontIcon color={"#636363"} className={"material-icons"}>close</FontIcon>
                        </ModalCloseButtonWrapper>
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md={6}>
                                <InputGroup>
                                    <InputLabel>
                                        שם
                                    </InputLabel>
                                    <input style={{width: "100%", height: 34}} type={"text"} onChange={(e) => {
                                        const contractor = _.cloneDeep(modalContractor);
                                        contractor.name = e.target.value;
                                        this.setState({modalContractor: contractor})
                                    }}
                                           value={modalContractor.name}
                                    />
                                </InputGroup>
                            </Col>
                            <Col md={6}>
                                <InputGroup>
                                    <InputLabel>
                                        כתובת
                                    </InputLabel>
                                    <input style={{width: "100%", height: 34}} type={"text"} onChange={(e) => {
                                        const contractor = _.cloneDeep(modalContractor);
                                        contractor.address = e.target.value;
                                        this.setState({modalContractor: contractor})
                                    }}
                                           value={modalContractor.address}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <InputGroup>
                                    <InputLabel>
                                        מנהלים
                                    </InputLabel>
                                    <Select
                                        onChange={(selectedOptions) => {
                                            this.onContractorManagerChange(selectedOptions)
                                        }}
                                        value={this.transformObjectToSelectList(modalContractor.contractorManagers, "contractorUsers")}
                                        isMulti={true}
                                        options={this.transformObjectToSelectList(users, "contractorUsers")}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>

                    </ModalBody>
                    <ModalFooter>
                        <SendButton onClick={() => {
                            let id = modalContractor.id;
                            if (!modalContractor.id) {
                                // edit
                                id = uuidWithoutSpace();
                            }
                            firebase.set(`contractors/${id}`, this.transformToSchema(modalContractor, id), (result) => {
                                //console.log("complete save contractor", result)
                            });
                            this.setState({isModalOpen: false, modalContractor: this.defaultContractor});
                        }}>שמירה</SendButton>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

Contractors.propTypes = {
    contractors: PropTypes.object
};

Contractors.defaultProps = {};

const mapStateToProps = (state) => {
    return {
        // contractors: state.firebase.data.contractors,
        user: state.roles.roles,
        // users: state.firebase.data.userProfiles,
        // users: {},
        isUsersAdmin: isUsersAdmin(state.roles.roles)
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

const Card = styled.div`
    background: #fff;
    padding: 15px;
    margin-top: 17px;
    border: 2px solid #E0E0E0;
    box-sizing: border-box;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    position: relative;
    cursor: pointer;
    `;

const DetailsRow = styled.div`
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    color: #636363;
`;

const Detail = styled.span`
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    color: #636363;
    margin-right: 7px;
    vertical-align: text-bottom;
`;

const InputLabel = styled.div`
font-family: 'Rubik', sans-serif;
font-size: 14px;
color: #4F4F4F;
margin-bottom: 6px;
`;

const InputGroup = styled.div`
margin-top: 10px;
`;

const SendButton = styled.div`
border-radius: 3px;
font-family: 'Rubik', sans-serif;
font-size: 16px;
text-align: center;
color: #FFFFFF;
height: 50px;
line-height: 50px;
padding: 0 11px;
cursor:  ${props => props.disabled ? "default" : "pointer"};
box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);
background-color: ${props => props.disabled ? "#818181" : "#319CD8"};
`;

const ModalCloseButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
`;


export default compose(
    firebaseConnect((props) => ([
        // "contractors",
        "suppliers" //,
        // "userProfiles"
    ])),
    connect(
        mapStateToProps, mapDispatchToProps
    )
)(Contractors);
