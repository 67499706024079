import React, { Component } from 'react';
import PropTypes from 'prop-types'

import {
    Nav,
    Badge,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Dropdown
} from 'reactstrap';
import ROUTES from "../../constants/routes";

class TableDropDown extends Component {

    constructor(props) {
        super(props);


        this.state = {
            dropdownOpen: false
        };
    }

    toggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    render() {
        const {markUnread} = this.props;
        return (
            <Nav>
                <Dropdown  nav isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                    <DropdownToggle nav>
                        <i className="fa fa-ellipsis-v"></i>
                    </DropdownToggle>
                    <DropdownMenu style={{position: "absolute"}} right>
                        <DropdownItem onClick={markUnread}><i className="fa tasks"></i>Mark as Unread</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </Nav>

        );
    }
}

TableDropDown.propTypes = {
    markUnread: PropTypes.func
};

TableDropDown.defaultProps = {};


export default TableDropDown;