import styled from "styled-components";
import { colors, fonts } from "../../../styles/styleguide";

export const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

export const DramaticMessage = styled.span`
  background-color: ${colors.yellow};
  text-align: center;
  font-family: ${fonts.OpenSansHebrew};
  font-size: 18.8px;
  color: ${colors.charcoal};
  margin-bottom: 10px;
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
`;

export const CATALOG_CONTAINER_WIDTH = "60%";
// export const SUMMARY_CONTAINER_WIDTH = "35%";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 656px;
  height: auto;
  border-radius: 15px;
  box-shadow: -16.5px 9.5px 40px 0 rgba(205, 205, 205, 0.25);
  background-color: #ffffff;
  margin-top: 12px;
  padding-left: 21px;
  padding-right: 15px;
`;

export const ContainerHeader = styled.div`
  height: 60px;
  border-radius: 15px;
  box-shadow: -16.5px 9.5px 40px 0 rgba(205, 205, 205, 0.25);
  font-family: OpenSansHebrew;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.08;
  letter-spacing: 1.2px;
  display: flex;
  align-items: center;
  color: ${colors.charcoal};
`;

export const ContainerList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const HoverText = styled.div`
  font-family: OpenSansHebrew;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 1.2px;
  color: ${colors.charcoal};
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => props.height}px;
  height: ${(props) => props.height}px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: #ececec;
  cursor: "pointer";
`;

export const ImageComponent = styled.img`
  width: ${(props) => props.height * 0.85}px;
  height: ${(props) => props.height * 0.85}px;
  object-fit: contain;
`;

export const HoverImage = styled.img`
	width: ${(props) => props.height * 3}px
	height: ${(props) => props.height * 3}px
	object-fit: contain;
`;

export const SendButton = styled.div`
  display: flex;
  border-radius: 25px;
  border: solid 1px ${colors.charcoal};
  font-family: ${fonts.OpenSansHebrew};
  font-size: 16px;
  font-weight: bold;
  justify-content: center;
  text-align: center;
  color: ${colors.charcoal};
  height: 50px;
  padding: 12px;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);
`;

export const MainSendButton = styled.div`
  display: flex;
  justifyContent: center;
  alignItems: center;
  backgroundColor: ${colors.charcoal};
  border-radius: 25px;
  border: solid 1px #ffffff;
  font-family: ${fonts.OpenSansHebrew};
  font-size: 16px;
  font-weight: bold;
  justify-content: center;
  text-align: center;
  color: #ffffff;
  height: 50px;
  padding: 12px;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);
`;