import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { bindActionCreators, compose } from 'redux';
import moment from "moment"
import memoize from "memoize-one";
import { firebaseConnect, withFirebase } from "react-redux-firebase";

import { connectRents, updateRent } from "shared/src/api/rentsBySupplier"
import { setFieldValue, filterColumn, setDates, setAmountFilter, showAllOpen, showClosed } from "shared/src/modules/rents"
import { getSupplierIds } from "shared/src/selectors/roles";
import {totalPrice as totalPriceSelector} from "shared/src/selectors/rents";


import { dateFilter, simpleTextFilter, statusFilter, totalFilter, simpleComparatorFilter, booleanFilter } from "../../utils/filter";

import TextFilter from "../../components/Table/Filters/TextFilter";
import DateRangeFilter from "../../components/Table/Filters/DateRangeFilter";


import ReactTable from 'react-table'

import { DateRangePicker } from 'react-dates';
import * as reactDatesConstants from "react-dates/constants"
import 'react-table/react-table.css'
import 'react-dates/lib/css/_datepicker.css';
import "../OrdersManagement/react-table-overrides.scss";


import { colors, fonts } from "../../styles/styleguide";
import _ from "lodash";

import { findDOMNode } from "react-dom";
import { print } from "../../utils/print";
import { toastr } from "react-redux-toastr";
import { getShippingCert } from "shared/src/api/orders";
import { produceRent, cancelRent } from "shared/src/api/rentsBySupplier";
import { Modal, ModalBody, ModalFooter, Row, Col, Button } from "reactstrap";

import { CardTitle, InputGroup, InputLabel } from "../../styles/stylesheet";
import IconInput from "../../components/Form/IconInput";
import ShippingCert from "../../components/ShippingCert";
import LoadingSpinner from "../../components/Loader";
import IconButton from "../../components/Form/IconButton";

import OrderListItem, { ORDER_LIST_ITEM_CONTEXT_TYPES } from "../OrdersManagement/orderListItem"
import Confirm from "../../components/Confirm"

import {
    FILTERS,
    noProductImageUrl,
    ORDER_MANAGEMENT_CONTEXT,
    ORDER_STATUSES,
    RENTAL_INTERVALS
} from "shared/src/constants/orders";
import styled from "styled-components";
import FontIcon from "material-ui/FontIcon";
import AmountFilter from "../../components/Table/Filters/AmountFilter";
import Checkbox from "../../components/Form/Checkbox";
import { getDurationDiscount } from "shared/src/utils/orders";

class Rentals extends Component {

    constructor(props) {
        super(props);
        this.state = {
            endRentDialogOpen: false,
            selectedRental: null,
            isOpenShippingCertPreview: false,
            shippingCert: {},
            isPreparingShippingCertPrint: false,
            shippingCertCopies: 2,
            producingSC: false,
            isPrinting: false,
            confirmDeleteOpen: false,
            showDatePicker: false,
            focusedInput: null,
            editRentalDuration: null

        }
    }

    componentDidMount() {
        const {roles, connectRents} = this.props
        const suppliers = getSupplierIds(roles);
        let supplierId = suppliers.supplierIds[0];
        this.setState({
            suppliers,
            supplierId
        });

        connectRents(supplierId, console.log)
    }

    componentDidUpdate(prevProps, prevState) {
        if (!_.isEqual(prevState.selectedRental, this.state.selectedRental) && !_.isEmpty(this.state.selectedRental)) {
            this.initShippingCert()
        }
    }

    initShippingCert = async () => {
        const {selectedRental} = this.state
        if (selectedRental.scId) {
            const shippingCert = await getShippingCert(this.props.firebase, selectedRental, selectedRental.supplier.id);
            this.setState({shippingCert})
        } else {
            this.setState({shippingCert: this.prepareShippingCertData(selectedRental, null, null, selectedRental.customer.contractorErpId)})
        }
    }

    openRentDialog = (rental) => {
        this.setState({
            endRentDialogOpen: true,
            selectedRental: rental
        })
    }

    closeRentDialog = () => {
        this.setState({
            endRentDialogOpen: false,
            selectedRental: null
        })
    }

    setSpacialPrice = (price) => {
        this.setState({selectedRental: Object.assign({}, this.state.selectedRental, {spacialPrice: price})})
    }

    setNumOfRentalDays = (num) => {
        this.setState({selectedRental: Object.assign({}, this.state.selectedRental, {userRentalDays: num})})
    }

    onDeleteRental = () => {
        const {cancelRent, firebase} = this.props;
        const {selectedRental} = this.state;
        cancelRent({currentRent: selectedRental,firebase, onSuccess: ()=>{
                toastr.success('ההשכרה בוטלה בהצלחה')
                this.closeRentDialog();
                this.setState({confirmDeleteOpen: false})
            }, onError: ()=>{
                toastr.error('אירעה שגיאה בעת הנסיון לבטל את ההשכרה, אנא נסו שוב', "במידה והבעיה חוזרת אנא פנו למנהל המערכת");
                this.closeRentDialog();
                this.setState({confirmDeleteOpen: false})
            }})

    }

    onEndRental = () => {
        this.openShippingCertPreview()
    }

    openShippingCertPreview = () => {
        this.setState({isOpenShippingCertPreview: true})
    }

    closeShippingCertPreview = () => {
        this.setState({isOpenShippingCertPreview: false})
    }

    setUserSelectedRentInterval = (value, currentRent) => {
        const {updateRent, firebase} = this.props;
        updateRent({currentRent, userSelectedRentInterval: value, firebase})
    }


    renderUserSelectRentIntervalColumn = ({original, value}) => {
        const {editRentalDuration, selectedRentInterval} = this.state;
        if(editRentalDuration === original.id){
            return (
                <IconInput
                    type={"select"}
                    onChange={(value) => {
                        if(value !== null){
                            this.setUserSelectedRentInterval(value, original)
                        }
                        this.setState({editRentalDuration: null})
                    }}
                    value={original.userSelectedRentInterval}
                    options={RENTAL_INTERVALS.map((interval, index) => {
                        return {id: index, name: interval}
                    })}
                    valueAccessor={"id"}
                    labelAccessor={"name"}
                />
            )
        }else{
            return (
                <div
                    onClick={()=>{this.setState({editRentalDuration: original.id})}}
                    style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", cursor: "pointer", width: "100%"}}>
                    <span>{value}</span>
                    <span><img src={"img/icons/tool.svg"} width={15}/></span>
                </div>
            )
        }
    }

    getColumns = () => {

        // 1. return button
        // name
        // catalogId
        // numOfDays - .rentalDays
        // address (project) - shippingAddress || project.address
        // customer name
        // start date
        // total price - .rentalTotalPrice

        /*closeData: {
            userRentalDays
            spacialPrice
        }

        */

        const {currentFilterKey} = this.props
        let firstCol = {
                id: 'return',
                Header: '',
                accessor: '',
                Cell: this.renderOpenDialogButton
            };

        let rentalDays = {
            id: 'rentalDays',
            Header: 'מס ימים',
            accessor: 'rentalDays',
            filterMethod: (filter, row) => totalFilter(filter, row, 'rentalDays'),
            Filter: ({filter, onChange}) => this.renderAmountFilter('rentalDays'),
        };

        let totalPrice = {
            id: 'totalPrice',
            Header: 'לתשלום',
            accessor: (rent) => rent.rentalTotalPrice,
            Cell: (props) => <span>{`₪${props.value.toLocaleString()}`}</span>,
            filterMethod: (filter, row) => totalFilter(filter, row, 'totalPrice'),
            Filter: ({filter, onChange}) => this.renderAmountFilter('totalPrice'),
        };

        if(currentFilterKey === FILTERS.CLOSED){
            firstCol = {
                id: 'closed',
                Header: "מס' תעודת משלוח",
                accessor: '',
                Cell: this.renderOpenDialogButton
            }

            rentalDays.accessor = (rent) => rent.closeData && rent.closeData.userRentalDays ? rent.closeData.userRentalDays: rent.rentalDays,

            totalPrice.accessor = totalPriceSelector

        }


        return [
            firstCol,
            {
                id: 'name',
                Header: 'שם מוצר',
                accessor: (rent) => rent.item.name,
                filterMethod:  (filter, row)  => simpleTextFilter(filter, row, "name"),
                Filter: <TextFilter onChange={(value) => {
                    this.props.filterColumn("name", value)
                }}/>
            },
            {
                id: 'catalogId',
                Header: 'מק"ט',
                accessor: (rent) => rent.item.catalogId,
                filterMethod:  (filter, row)  => simpleTextFilter(filter, row, "catalogId"),
                Filter: <TextFilter onChange={(value) => {
                    this.props.filterColumn("catalogId", value)
                }}/>
            },
            {
                id: 'address',
                Header: 'כתובת',
                accessor: (rent) => rent.shippingAddress || (rent.project ? rent.project.address : ""),
                filterMethod:  (filter, row)  => simpleTextFilter(filter, row, "address"),
                Filter: <TextFilter onChange={(value) => {
                    this.props.filterColumn("address", value)
                }}/>
            },
            {
                id: 'customer',
                Header: 'לקוח',
                accessor: (rent) => rent.customer.name,
                filterMethod:  (filter, row)  => simpleTextFilter(filter, row, "customer"),
                Filter: <TextFilter onChange={(value) => {
                    this.props.filterColumn("customer", value)
                }}/>
            },
            rentalDays,
            {
                id: 'date',
                Header: 'תאריך יצירה',
                accessor: 'createdDate', // String-based value accessors!,
                Cell: props => {
                    return moment(props.value).format("D/MM/YY")
                },
                filterMethod: (filter, row) => dateFilter(filter, row, "date"),
                Filter: ({filter, onChange}) => this.renderDateFilterColumn(onChange)
            },
            {
                id: 'userSelectedRentInterval',
                Header: 'השכרה לפרק זמן',
                accessor: (rent) => RENTAL_INTERVALS[rent.userSelectedRentInterval],
                Cell: this.renderUserSelectRentIntervalColumn,
                filterMethod:  (filter, row)  => simpleTextFilter(filter, row, "userSelectedRentInterval"),
                Filter: <TextFilter onChange={(value) => {
                    this.props.filterColumn("customer", value)
                }}/>,
                style: {width: 120},
                width: 120,
            },
            totalPrice,
            {
                id: 'cancelDate',
                Header: '',
                style: {width: 0, padding: 0},
                headerStyle: {width: 0, padding: 0},
                maxWidth: 0,
                width: 0,
                expander: false,
                accessor: (rent) => rent.cancelDate,
                filterMethod: (filter, row) => simpleComparatorFilter(filter, row, 'cancelDate'),
            },
           {
                id: 'scId',
                Header: '',
               style: {width: 0, padding: 0},
               headerStyle: {width: 0, padding: 0},
               maxWidth: 0,
               width: 0,
               expander: false,
                accessor: (rent) => rent.scId,
                filterMethod: (filter, row) => simpleComparatorFilter(filter, row, 'scId'),
            },
            {
                id: 'status',
                Header: '',
                style: {width: 0, padding: 0},
                headerStyle: {width: 0, padding: 0},
                maxWidth: 0,
                width: 0,
                expander: false,
                accessor: (rent) => {return rent.scId ? ORDER_STATUSES.PRODUCED : rent.cancelDate ? ORDER_STATUSES.CANCELED : ORDER_STATUSES.RENT},
                filterMethod: (filter, row) => simpleComparatorFilter(filter, row, 'status'),
            },
        ];
    }

    prepareShippingCertData = memoize(
        (selectedRental, certId = null, date = null, contractorErpId = null) => {
            const shippingCert = {
                scId: selectedRental.scId,
                rentId: selectedRental.id,
                contractorId: _.get(selectedRental, "customer.id", ""),
                contractorName: _.get(selectedRental, "customer.name", ""),
                contractorAddress: _.get(selectedRental, "customer.address", ""),
                projectId: _.get(selectedRental, "project.id", ""),
                supplierId: _.get(selectedRental, "supplier.id", ""),
                taxId: _.get(selectedRental, "customer.taxId", ""),
                date: selectedRental.scDate ? new Date(selectedRental.scDate) : null,
                items: [],
                note: "",
                agent: _.get(selectedRental, "agent", null),
                contractorErpId: _.get(selectedRental, "customer.erpId", contractorErpId),
                source: "rental",
                spacialPrice: selectedRental.spacialPrice || null,
                userRentalDays: selectedRental.userRentalDays || null
            }

            console.log("shippingCert", shippingCert, selectedRental, contractorErpId)

            const extractItem = (item, parentCount = null) => {
                const rentalDays = selectedRental ? selectedRental.userRentalDays ? selectedRental.userRentalDays : selectedRental.rentalDays : null;
                let count = rentalDays;

                let totalItemPrice = item.default_price;
                if (item.spacialPrice) {
                    totalItemPrice = Number(item.spacialPrice)
                }
                if (item.discount > 0) {
                    totalItemPrice = totalItemPrice - totalItemPrice * (item.discount / 100)
                }



                return {
                    catalogId: item.catalogId,
                    name: item.name,
                    price_data: item.price_data || null,
                    count,
                    price: totalItemPrice,
                    isGeneric: item.isGeneric || false,
                    note: item.note || null,
                    durationDiscount: getDurationDiscount(rentalDays),
                    spacialPrice: item.spacialPrice || null
                }
            }

            function addItem(extractedItem) {
                shippingCert.items.push(extractedItem)
            }

            function buildNote(order) {
                let note = ""
                if (!order.project) {
                    return "";
                }
                note = order.shippingAddress || `${order.project.address} (${order.project.name})`;
                if (order.contactPerson && (order.contactPerson.name || order.contactPerson.phone)) {
                    note += `\n${order.contactPerson.name} ${order.contactPerson.phone}`
                } else {
                    if (order.orderedBy) {
                        note += `\n${order.orderedBy.displayName} ${order.orderedBy.phoneNumber}`
                    } else if (order.sentBy && (order.sentBy.displayName || order.sentBy.phoneNumber)) {
                        note += `\n${order.sentBy.displayName + " " || ""}${order.sentBy.phoneNumber || ""}`
                    }
                }
                if (order.supplierNote) {
                    note += `\n${order.supplierNote}`
                }
                return note;
            }

            shippingCert.note = buildNote(selectedRental);

            let extracted = extractItem(selectedRental.item);
            addItem(extracted);

            return shippingCert;
        }
    );

    printShippingCert = () => {
        const {setFieldValue, firebase} = this.props;
        const {shippingCert, selectedRental} = this.state;
        let clone = _.cloneDeep(shippingCert);

        const disableSave = shippingCert.scId ? true : false;

        this.setState({isPreparingShippingCertPrint: true})

        if (disableSave) {
            let content = this.shippingCertRef;
            const contentNodes = findDOMNode(content);
            print(contentNodes, 0, (error) => {
                toastr.error(error)
            });
            this.setState({isPreparingShippingCertPrint: false})
            this.redirectOnClose = true;
            this.closeShippingCertPreview()


        } else {
            this.setState({
                producingSC: true
            }, () => {
                produceRent({rent: clone, firebase}).then(cert => {
                    clone.scId = cert.scId;
                    clone.date = cert.producedDate;
                    console.log("printShippingCert", clone)
                    this.setState({shippingCert: clone}, () => {
                        window.requestAnimationFrame(() => {
                            let content = this.shippingCertRef;
                            const contentNodes = findDOMNode(content);
                            print(contentNodes, 0, (error) => {
                                toastr.error(error)
                            });
                            this.setState({isPreparingShippingCertPrint: false})
                            this.redirectOnClose = true;
                            this.closeShippingCertPreview()
                        })
                    })
                }).catch(e => {
                    console.error("produce order error", e)
                    toastr.error('אירעה שגיאה בעת הנסיון להנפיק תעודת משלוח, אנא נסו שוב', "במידה והבעיה חוזרת אנא פנו למנהל המערכת");
                    this.setState({isPreparingShippingCertPrint: false, producingSC: false})
                }).finally(() => {
                    this.closeShippingCertPreview();
                    this.closeRentDialog();
                })
            })

        }


    }

    getShippingCertCopies = memoize((numberOfCopies, disableSave, producingSC) => {
        const copies = [];

        if (disableSave && !producingSC) {
            copies.push({label: "עותק משוחזר", showPreview: true});
        } else {
            copies.push({label: "מקור", showPreview: true});
        }

        for (let i = 0; i < numberOfCopies; i++) {
            copies.push({label: `עותק ${i + 1}`, showPreview: false})
        }

        return copies;
    })

    showDatePicker = () => {
        this.setState({showDatePicker: true, focusedInput: reactDatesConstants.START_DATE})
    }

    renderOpenDialogButton = (row) => {
        const {currentFilterKey} = this.props
        let label =  "סיום השכרה"
        let onClick = () => this.openRentDialog(row.original)
        let cursor = "pointer"
        if(currentFilterKey === FILTERS.CLOSED){
            if(row.original.scId){
                label =  row.original.scId
                onClick = () =>{
                    this.setState({
                        selectedRental: row.original
                    }, this.onEndRental)

                }
            }else{
                label =  "בוטלה"
                onClick = () =>{}
                cursor = "initial"
            }

        }

        return (
            <div style={{
                width: "100%",
                borderTopLeftRadius: 15,
                borderBottomLeftRadius: 15,
                backgroundColor: colors.yellow,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                padding: 5,
                color: colors.charcoal,
                cursor: cursor,
                marginRight: -5
            }}
                 onClick={onClick}
            >
                {label}
                <img style={{maxHeight: 22, width: "auto", marginLeft: 5}}
                     src={"img/icons/orders/rent@2x.png"}/>
            </div>
        )
    }

    renderAmountFilter = (column) => {
        const {amountFilterCondition, amountFilterValue, setAmountFilter} = this.props;

        return (
            <AmountFilter
                amountFilterCondition={amountFilterCondition}
                amountFilterValue={amountFilterValue}
                onConditionChange={(value) =>
                    setAmountFilter({
                        amountFilterCondition: value,
                        amountFilterValue,
                        column
                    })}
                onValueChange={(value) =>
                    setAmountFilter({
                        amountFilterCondition,
                        amountFilterValue: value,
                        column
                    })}
            />)

    }

    renderDatePicker = () => {
        return (<DateRangePicker
            startDate={this.props.startDateFilter} // momentPropTypes.momentObj or null,
            startDateId="startDateFilter" // PropTypes.string.isRequired,
            endDate={this.props.endDateFilter} // momentPropTypes.momentObj or null,
            endDateId="endDateFilter" // PropTypes.string.isRequired,
            onDatesChange={({startDate, endDate}) => {
                this.props.setDates(startDate, endDate, "date");
            }} // PropTypes.func.isRequired,
            focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
            onFocusChange={focusedInput => {
                this.setState({focusedInput})
            }} // PropTypes.func.isRequired,
            isRTL={true}
            isOutsideRange={day => moment(day).isBefore('2018-01-01')}
            anchorDirection={reactDatesConstants.ANCHOR_RIGHT}
            showClearDates={true}
            withPortal={true}
            appendToBody={true}
            disableScroll={true}
            small={true}
            block={true}
        />)
    }

    renderDateFilterColumn = (onChange, key = "startDate") => {
        const {startDateFilter, endDateFilter} = this.props;
        this.dateFilterOnChangeCallback = onChange;
        if (startDateFilter && endDateFilter) {
            return (
                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                    <span
                        onClick={this.showDatePicker}>{moment(startDateFilter).format("D/MM/YY")} - {moment(endDateFilter).format("D/MM/YY")}</span>
                    <FontIcon onClick={() => {
                        this.props.setDates(null, null, "date");
                    }} className="material-icons pointer" color={"#6e6e6e"}>close</FontIcon>
                </div>
            )
        } else {
            return (
                <div className={"pointer"} onClick={this.showDatePicker}>בחרו תאריכים</div>
            )
        }
    }

    renderCloseRentalDialog = () => {
        const {
            endRentDialogOpen,
            selectedRental
        } = this.state;

        const specialPrice = selectedRental ? selectedRental.spacialPrice : null;
        const rentalDays = selectedRental ? selectedRental.userRentalDays ? selectedRental.userRentalDays : selectedRental.rentalDays : null;
        const item = selectedRental ? selectedRental.item : {};

        let orderTotalPrice =  selectedRental ? totalPriceSelector(selectedRental).toLocaleString() : 0;
        const discount = getDurationDiscount(rentalDays);
        const disableProduce = Number(rentalDays) === 0;
        console.log("disableProduce", rentalDays, disableProduce)

        return (
            <Modal size={"xl"} isOpen={endRentDialogOpen} toggle={this.closeRentDialog}>
                <ModalBody>
                    <Row>
                        <Col md={12}>
                            <CardTitle>סיום השכרה</CardTitle>
                        </Col>
                    </Row>
                    <Row className={"justify-content-center"}>
                        <Col md={6}>
                            <OrderListItem
                                item={item}
                                hideCount={true}
                                context={ORDER_LIST_ITEM_CONTEXT_TYPES.MODAL}
                                imgSrc={item.imageURL || noProductImageUrl}
                                catalogId={item.catalogId}
                                name={item.name}
                                price={item.default_price}

                            />
                        </Col>
                    </Row>
                    <Row className={"align-items-end"}>
                        <Col md={3}>
                            <InputGroup>
                                <InputLabel>מחיר מיוחד (ליום)</InputLabel>
                                <IconInput
                                    onChange={this.setSpacialPrice}
                                    value={specialPrice}
                                    icon={<img src={"img/icons/edit.svg"} width={27}/>}
                                    clearValueHandler={specialPrice ? () => {
                                        this.setSpacialPrice(null)
                                    } : null}
                                />
                            </InputGroup>
                        </Col>
                        <Col md={3}>
                            <InputGroup>
                                <InputLabel>ימי השכרה</InputLabel>
                                <IconInput
                                    type={"number"}
                                    onChange={this.setNumOfRentalDays}
                                    value={rentalDays}
                                    icon={<img src={"img/price.png"} width={27}/>}
                                />
                            </InputGroup>
                        </Col>
                        <Col md={6}>
                            <div style={{display: "flex", justifyContent: "flex-end", alignItems: "flex-end"}}>
                                <TotalPrice style={{marginLeft: 5}}>{'סה"כ לתשלום'}</TotalPrice>
                                <TotalPrice style={{marginLeft: 5}} dir={"ltr"}>&#8362;{orderTotalPrice}</TotalPrice>
                                {discount > 0 && (
                                    <Discount>{`(${discount * 100}% הנחה)`}</Discount>
                                )}
                            </div>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <div style={{height: 50}}/>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                        position: "absolute",
                        bottom: -26,
                        left: 0,
                        right: 0
                    }}>
                        <div>
                            <IconButton
                                label={"ביטול השכרה"}
                                onClick={()=>this.setState({confirmDeleteOpen: true})}
                                backgroundColor={colors.red}
                                containerStyle={{height: 50, paddingLeft: 20}}
                                icon={<img src={"img/icons/trash.png"}/>}

                            />
                        </div>
                        <div style={{display: "flex", justifyContent: "flex-start", flexDirection: "row"}}>
                            <IconButton
                                label={"סגירה"}
                                onClick={this.closeRentDialog}
                                containerStyle={{height: 50, paddingLeft: 20}}
                                icon={<img src={"img/icons/undo_white.svg"}/>}

                            />
                            <div style={{marginRight: 15}}>
                                <IconButton
                                    label={"סיום השכרה"}
                                    onClick={this.onEndRental}
                                    backgroundColor={"#fecd08"}
                                    containerStyle={{height: 50, paddingLeft: 20}}
                                    icon={<img src={"img/icons/v_black.png"} style={{width: 36, height: 36}}/>}
                                    disabled={disableProduce}
                                />
                            </div>
                        </div>

                    </div>
                </ModalFooter>
            </Modal>
        )
    }

    renderSCModal = () => {
        const {
            isOpenShippingCertPreview,
            shippingCert,
            isPreparingShippingCertPrint,
            shippingCertCopies,
            producingSC,
            isPrinting
        } = this.state;

        if(_.isEmpty(shippingCert)){
            return null;
        }

        const disableSave = shippingCert.scId ? true : false;

        return (
            <Modal size={"xl"} isOpen={isOpenShippingCertPreview} toggle={() => {
                this.closeShippingCertPreview()
            }}>
                <ModalBody>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "flex-end"}}>
                        <SendButton style={{flex: 6}}
                                    onClick={() => this.setState({isPrinting: true}, this.printShippingCert)}>הנפקה
                            והדפסה</SendButton>
                        <SendButton style={{flex: 2, marginRight: 20, backgroundColor: colors.red}}
                                    onClick={this.closeShippingCertPreview}>ביטול</SendButton>
                        {!disableSave && (
                            <div style={{flex: 2, marginRight: 20}}>
                                <InputGroup>
                                    <InputLabel>מס' עותקים</InputLabel>
                                    <IconInput
                                        type={"select"}
                                        options={[
                                            {label: 0, value: 0},
                                            {label: 1, value: 1},
                                            {label: 2, value: 2},
                                            {label: 3, value: 3},
                                        ]}
                                        value={shippingCertCopies}
                                        onChange={(value) => {
                                            this.setState({shippingCertCopies: value})
                                        }}
                                        icon={<img src={"img/icons/list.svg"}/>}
                                        valueAccessor={"value"}
                                        labelAccessor={"label"}
                                    />
                                </InputGroup>
                            </div>
                        )}
                    </div>

                    {/*    <div style={{marginTop: -printHeaderMarginTop}}></div>*/}
                    <div ref={el => (this.shippingCertRef = el)}>
                        <ShippingCert
                            printLabel={"מקור"}
                            shippingCert={shippingCert}
                            copies={this.getShippingCertCopies(shippingCertCopies, disableSave, producingSC)}
                        />
                    </div>
                </ModalBody>
                {isPreparingShippingCertPrint && (<LoadingSpinner/>)}
            </Modal>
        )
    }

    render() {

        const {rents, filtered, currentFilterKey} = this.props;
        const {
            confirmDeleteOpen,
            selectedRental,
        } = this.state;

        const itemName = _.get(selectedRental, "item.name");

        return (
            <div style={{marginTop: 20}}>
                <div>
                  <div style={{visibility: "hidden", height: 0}}>{this.renderDatePicker()}</div>
                </div>
                <div style={{display: "flex", flexDirection: "row", justifyContent: " space-between"}}>
                    <div>
                        <Button style={{fontFamily: fonts.OpenSansHebrew, color: colors.charcoal}} color="link"
                                className={currentFilterKey === FILTERS.OPEN ? "px-0 font-weight-bold" : "px-0"}
                                onClick={this.props.showAllOpen}>השכרות פתוחות</Button> |
                        <Button style={{fontFamily: fonts.OpenSansHebrew, color: colors.charcoal}} color="link"
                                className={currentFilterKey === FILTERS.CLOSED ? "px-0 font-weight-bold" : "px-0"}
                                onClick={this.props.showClosed}>השכרות סגורות</Button>
                    </div>



                </div>
                <ReactTable
                    data={rents}
                    columns={this.getColumns()}
                    showPagination={false}
                    filterable={true}
                    filtered={filtered}
                    pageSize={rents.length}
                    getTdProps={(state, rowInfo, column, instance) => {

                        const returnedObj = {
                            onClick: (e, handleOriginal) => {

                                console.log("A Td Element was clicked!");
                                console.log("it produced this event:", e);
                                console.log("It was in this column:", column);
                                console.log("It was in this row:", rowInfo);
                                console.log("It was in this table instance:", instance);
                                //  this.onEdit(rowInfo.original);

                                // IMPORTANT! React-Table uses onClick internally to trigger
                                // events like expanding SubComponents and pivots.
                                // By default a custom 'onClick' handler will override this functionality.
                                // If you want to fire the original onClick handler, call the
                                // 'handleOriginal' function.
                                if (handleOriginal) {
                                    handleOriginal();
                                }
                            }
                        };

                        return returnedObj;
                    }}
                />

                {this.renderCloseRentalDialog()}
                {this.renderSCModal()}

               <Confirm
                    isOpen={confirmDeleteOpen}
                    title={"אישור מחיקה"}
                    body={`אתם עומדים למחוק את ההשכרה של ${itemName} האם אתם בטוחים?`}
                    onConfirm={this.onDeleteRental}
                    onReject={()=> {
                        this.setState({confirmDeleteOpen: false})
                        this.closeRentDialog()
                    }}
                />

            </div>
        );
    }
}

Rentals.propTypes = {};

Rentals.defaultProps = {};

const mapStateToProps = (state) => {
    const roles = state.roles.roles;
    return {
        roles,
        rents: state.rents.rents,
        filtered: state.rents.filtered,
        startDateFilter: state.rents.startDateFilter,
        endDateFilter: state.rents.endDateFilter,
        amountFilterCondition: state.rents.amountFilterCondition,
        amountFilterValue: state.rents.amountFilterValue,
        currentFilterKey: state.rents.filterKey,
        isLoading: state.rents.rentsLoading,
        isLoaded: state.rents.rentsContextLoaded,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({
    connectRents,
    setFieldValue,
    cancelRent,
    filterColumn,
    setDates,
    setAmountFilter,
    showAllOpen,
    showClosed,
    updateRent
}, dispatch);


export default compose(
    connect(
        mapStateToProps, mapDispatchToProps
    ),
    withFirebase
)(Rentals);

const TotalPrice = styled.div`
  font-family: 'Rubik', sans-serif;
  font-size: 26px;
  color: #4F4F4F;
`;

const Discount = styled.div`
  font-family: 'Rubik', sans-serif;
  font-size: 18px;
  color: ${colors.vibrantGreen90};
`;

const SendButton = styled.button`
    border: none;
    border-radius: 3px;
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    text-align: center;
    color: #FFFFFF;
    height: 50px;
    line-height: 50px;
    padding: 0 11px;
    cursor:  ${props => props.disabled ? "default" : "pointer"};
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);
    background-color: ${props => props.disabled ? "#818181" : props.backgroundColor ? props.backgroundColor : "#319CD8"};
`