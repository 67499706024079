import React from 'react';
import PropTypes from 'prop-types'
import _ from "lodash";
import styled from 'styled-components';

import {List} from 'material-ui/List';
import Loader from "../../components/Loader";
import ArticleListItem from "./ArticleListItem";

class ArticlesList extends React.Component{

    constructor() {
        super()
    }

    render() {

        const {
            list, onItemEdit, onItemDelete, isLoading,
        } = this.props;

        return (
            <div>
                <div className="listWrapper">
                    {isLoading && (<Loader/>)}
                    {!isLoading && (
                        <List>
                            {list.map((item) => (
                                <ArticleListItem item={item.news} onItemEdit={onItemEdit} onItemDelete={onItemDelete}/>))}
                        </List>
                    )}

                </div>
            </div>
        )
    }
}

ArticlesList.propTypes = {
    list: PropTypes.array,
    onItemDelete: PropTypes.func,
    onItemEdit: PropTypes.func,
    isLoading: PropTypes.bool,
};

export default ArticlesList;