import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { connect } from "react-redux";
import { compose } from "redux";
import { bindActionCreators } from "redux";
import { injectIntl, FormattedMessage } from "react-intl";
import styled from "styled-components";
import { Switch, Route, Redirect } from "react-router-dom";
import { firebaseConnect, isLoaded, isEmpty } from "react-redux-firebase";
import { matchPath } from "react-router";
import memoize from "memoize-one";

import OrdersTable from "./ordersTable";
import {
  connectOrders,
  setFieldValue,
  saveOrder,
  createNewOrder,
  initOrderFromList,
  lockOrder,
  sendOrder,
  resetCurrentOrder,
  getErpDirectOrders
} from "shared/src/modules/orders";
import { fetchItems, fetchPoItems } from "shared/src/api/orderItems";
import { connectPos } from "shared/src/api/pos";
import { initPoFromList, createNewPo } from "shared/src/modules/pos";
import {
  getSupplierIds,
  getContractorIds,
  isApplicationOrdersAdmin,
  getSupplierId,
} from "shared/src/selectors/roles";
import { uuidWithoutSpace } from "shared/src/utils/Utils";
import {
  setFetchOrdersCalled,
  setFieldValue as setLayoutFieldValue,
} from "shared/src/modules/ordersManagement";
import {
  filterColumn,
  setDates,
  setAmountFilter,
  filterToday,
  filterTomorrow,
  filterThisWeek,
  showAllOpen,
  showFDO,
  toggleOnlyMyOrders,
  showClosed,
  showCancelled,
  onSortChange,
  FILTER_TODAY,
  FILTER_TOMORROW,
  FILTER_THIS_WEEK,
  FILTER_OPEN,
  FILTER_MY_ORDERS,
  FILTER_CLOSED,
  FILTER_CANCELLED,
  FILTER_FDO,
} from "shared/src/modules/ordersFilter";

import StickyFloatingButton from "../../components/StickyFloatingButton";

import {
  ORDER_MANAGEMENT_CONTEXT,
  comparatorStrings,
  FILTERS,
} from "shared/src/constants/orders";
import OrderForm from "./orderForm";

import FirebaseObjectListSelect from "../../components/Form/FirebaseObjectListSelect";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from "reactstrap";
import { getVal } from "react-redux-firebase";
import { FEED_TYPES } from "shared/src/constants/feed";
import ContractorSelectionModal from "../PO/contractorSelectionModal";
import CustomerSearchModal from "../../components/CustomerSearchModal";
import { getPOContractors } from "shared/src/selectors/po";
import { history } from "../../store";

import {
  Card,
  CardTitle,
  InputLabel,
  InputGroup,
  InputError,
} from "../../styles/stylesheet";

class OrdersManagement extends Component {
  constructor() {
    super();
    this.state = {
      supplierIds: null,
      contractorIds: null,
      orderDialogOpen: false,
      selectedProject: null,
      selectedSupplier: null,
      selectedCustomer: null,
    };

    this.callAfterContextInit = null;
  }

  componentDidMount() {
    const { context } = this.props;
    this.initContext(context);
    this.initFilters();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.context !== prevProps.context) {
      this.initContext(this.props.context);
    }
  }

  initFilters = () => {
    const {
      firebase,
      roles,
      filterToday,
      filterTomorrow,
      filterThisWeek,
      showAllOpen,
      showClosed,
      showCancelled,
      toggleOnlyMyOrders,
      showFDO,
    } = this.props;
    Promise.all([
      firebase
        .ref(`userSettings/${roles.user_id}/ordersFilter/default`)
        .once("value"),
      firebase
        .ref(`userSettings/${roles.user_id}/ordersFilter/myOrders`)
        .once("value"),
    ]).then((values) => {
      const defaultFilter = values[0].val();
      const myOrders = values[1].val();

      switch (defaultFilter) {
        case FILTERS.TODAY:
          filterToday();
          break;
        case FILTERS.TOMORROW:
          filterTomorrow();
          break;
        case FILTERS.THIS_WEEK:
          filterThisWeek();
          break;
        case FILTERS.OPEN:
          showAllOpen();
          break;
        case FILTERS.CLOSED:
          showClosed();
          break;
        case FILTERS.CANCELLED:
          showCancelled();
          break;
        case FILTERS.FDO:
          showFDO();
          break;
      }

      if (myOrders === true || myOrders === false) {
        toggleOnlyMyOrders(myOrders, roles.user_id);
      }
    });
  };

  initContext = (context) => {
    if (
      context === ORDER_MANAGEMENT_CONTEXT.CONTRACTOR &&
      this.props.isApplicationOrdersAdmin
    ) {
      const match = matchPath(this.props.history.location.pathname, {
        path: "/orders/:supplierId/:contractorId/:projectId/:orderId",
        exact: true,
        strict: false,
      });

      const { contractorId, projectId, supplierId, orderId } = match
        ? match.params
        : {};
      if (contractorId && !this.state.contractorIds) {
        this.onContractorSelection(contractorId);
      }
    } else if (
      context === ORDER_MANAGEMENT_CONTEXT.CONTRACTOR &&
      !this.props.isApplicationOrdersAdmin
    ) {
      const contractorIds = getContractorIds(this.props.roles);
      this.setState({
        contractorIds: contractorIds,
      });
      this.fetchScreenData({
        contractorIds,
        context: ORDER_MANAGEMENT_CONTEXT.CONTRACTOR,
      });
    } else if (context === ORDER_MANAGEMENT_CONTEXT.SUPPLIER) {
      const supplierIds = getSupplierIds(this.props.roles);
      this.setState({
        supplierIds: supplierIds,
      });
      this.fetchScreenData({
        supplierIds,
        context: ORDER_MANAGEMENT_CONTEXT.SUPPLIER,
      });
    }

    if (this.callAfterContextInit) {
      this.callAfterContextInit();
      this.callAfterContextInit = null;
    }
  };

  fetchScreenData = ({ supplierIds, contractorIds, context }) => {
    //console.log("fetch orders screen data", this.state.supplierId, this.props.fetchOrdersCalled);
    const { connectOrders, setFetchOrdersCalled, connectPos, getErpDirectOrders } = this.props;
    setFetchOrdersCalled(true);
    switch (context) {
      case ORDER_MANAGEMENT_CONTEXT.CONTRACTOR:
        connectOrders(contractorIds);
        getErpDirectOrders(contractorIds)
        break;
      case ORDER_MANAGEMENT_CONTEXT.SUPPLIER:
        connectOrders({ supplierIds: supplierIds.supplierIds });
        connectPos(supplierIds.supplierIds);
        break;
    }
  };

  createOrder = () => {
    const { createNewOrder, setFieldValue, history } = this.props;
    const { selectedProject, selectedSupplier, contractorIds } = this.state;
    createNewOrder();
    const uid = uuidWithoutSpace();
    setFieldValue("newOrder.id", uid);
    setFieldValue("newOrder.project", selectedProject);
    setFieldValue("newOrder.supplier", selectedSupplier);
    //  history.push(`/orders-management/order/${uid}`);
    //console.log('Selected project: ', selectedProject)
    history.push(
      `/orders/${selectedSupplier.id}/${selectedProject.contractorId}/${selectedProject.id}/${uid}`
    );
    this.closeModal();
  };

  createOrderRequest = (customer) => {
    const { supplierContractors, createNewPo, roles, filterKey } = this.props;

    const supplierIds = getSupplierIds(roles);
    const supplierId = supplierIds.supplierIds[0];

    const poId = uuidWithoutSpace();

    console.log("!!! createOrderRequest customerId:", customer.id);
    console.log("!!! createOrderRequest found customer:", customer);
    //    console.log("!!! createOrderRequest from source:", supplierContractors);
    const isFDO = filterKey === FILTERS.FDO;
    console.log("!!! isFDO:", isFDO);
    createNewPo(poId, customer, supplierId, null, false, isFDO);
    history.push(`/po/${supplierId}/${customer.id}/${poId}`);
  };

  editOrder = (order) => {
    const {
      fetchItems,
      roles,
      initOrderFromList,
      setFieldValue,
      history,
      lockOrder,
      context,
      fetchPoItems,
      initPoFromList,
    } = this.props;
    if (order.feedType === FEED_TYPES.ORDER) {
      initOrderFromList(order);
      setFieldValue("contractorId", order.contractor.id);
    }
    let supplierId;
    if (context === ORDER_MANAGEMENT_CONTEXT.SUPPLIER) {
      const suppliers = getSupplierIds(roles);
      supplierId = suppliers.supplierIds[0];

      if (order.feedType === FEED_TYPES.PO) {
        initPoFromList(order);
        fetchPoItems({
          customerId: order.customer.id,
          supplierId,
          poId: order.id,
        });
        history.push(`/po/${supplierId}/${order.customer.id}/${order.id}`);
        return;
      }

      fetchItems({
        supplierId,
        orderId: order.id,
        contractorId: order.contractor.id,
        projectId: order.project.id,
      });
      /* const status = extractLatestStatus(order.status);
             const needToLock = ![ORDER_STATUSES.CLOSED, ORDER_STATUSES.CANCELED, ORDER_STATUSES.DRAFT, ORDER_STATUSES.LOCKED, ORDER_STATUSES.READY, ORDER_STATUSES.PRODUCED].includes(status);
             //console.log("checking if need to lock order", order, status);
             //console.log("checking if need to lock order", needToLock);
             if (needToLock) {
                 lockOrder({currentOrder: order});
             }*/
    } else {
      supplierId = order.supplier.id;
    }

    fetchItems({
      supplierId,
      orderId: order.id,
      contractorId: order.contractor.id,
      projectId: order.project.id,
    });
    history.push(
      `/orders/${supplierId}/${order.contractor.id}/${order.project.id}/${order.id}`
    );
  };

  renderSuppliers = () => {
    const { selectedProject } = this.state;

    let suppliers = [];

    if (selectedProject && selectedProject.suppliers.length > 0) {
      const sortedSuppliers =
        selectedProject.suppliers.length > 1
          ? selectedProject.suppliers.sort((a, b) =>
              comparatorStrings(a.name, b.name)
            )
          : selectedProject.suppliers;

      sortedSuppliers.map((supplier) => {
        suppliers.push(
          <option key={supplier.id} value={supplier.id}>
            {supplier.name}
          </option>
        );
      });
    }

    return suppliers;
  };

  closeModal = () => {
    this.setState({
      orderDialogOpen: false,
      selectedProject: null,
      selectedSupplier: null,
    });
  };

  onContractorSelection = (contractorId) => {
    //console.log("contractor selected", contractorId)
    // setting selected contractors to match the roles selector schema
    // will use it to fetch screen data
    const selectedContractor = {
      contractorIds: [contractorId],
      projectIds: [],
    };
    this.setState({
      //      selectedContractor: selectedContractor,
      contractorIds: selectedContractor,
    });
    this.props.setLayoutFieldValue("selectedContractor", selectedContractor);
    this.fetchScreenData({
      contractorIds: selectedContractor,
      context: ORDER_MANAGEMENT_CONTEXT.CONTRACTOR,
    });
  };

  getSupplierId = () => {
    const { context, roles } = this.props;
    return getSupplierId(context, roles);
  };

  getProjects = memoize((projects) => {
    const sortedProjects = projects
      ? _.filter(_.orderBy(projects, ["name"]), { disabled: false })
      : [];
    return sortedProjects;
  });

  render() {
    const {
      setFieldValue,
      saveOrder,
      orders,
      isLoading,
      order,
      sendOrder,
      contractors,
      projects,
      context,
      isApplicationOrdersAdmin,
      selectedContractor,
      resetCurrentOrder,
      hidePrices,
      pos,
      roles,
    } = this.props;
    let { supplierContractors } = this.props;
    supplierContractors = supplierContractors || [];

    //console.log("render orders index", this.props);
    const { orderDialogOpen, selectedProject, selectedSupplier } = this.state;

    const match = matchPath(this.props.history.location.pathname, {
      path: "/orders/:supplierId/:contractorId/:projectId/:orderId",
      exact: true,
      strict: false,
    });

    const { contractorId, projectId, orderId } = match ? match.params : {};

    const shouldShowAddButton =
      (((isApplicationOrdersAdmin && selectedContractor) ||
        !isApplicationOrdersAdmin) &&
        context === ORDER_MANAGEMENT_CONTEXT.CONTRACTOR &&
        !projectId) ||
      (context === ORDER_MANAGEMENT_CONTEXT.SUPPLIER && !projectId);
    //console.log("shouldShowAddButton", shouldShowAddButton);

    const sortedProjects = this.getProjects(projects);
    return (
      <div style={{ position: "relative" }} key={"ordersIndex"}>
        {!projectId && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              paddingTop: 10,
            }}
          >
            <div>
              <CardTitle>ניהול הזמנות</CardTitle>
            </div>
            {context === ORDER_MANAGEMENT_CONTEXT.CONTRACTOR &&
              isApplicationOrdersAdmin && (
                <div style={{ marginRight: 20 }}>|</div>
              )}
            {context === ORDER_MANAGEMENT_CONTEXT.CONTRACTOR &&
              isApplicationOrdersAdmin && (
                <div
                  style={{
                    marginRight: 20,
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <img
                    style={{ maxHeight: 24 }}
                    src={"img/nav/nav_contractors_mgmt.svg"}
                  />
                  <InputLabel style={{ marginRight: 10 }}>קבלן</InputLabel>
                </div>
              )}
            <div style={{ marginRight: 20 }}>
              {context === ORDER_MANAGEMENT_CONTEXT.CONTRACTOR &&
                isApplicationOrdersAdmin && (
                  <FirebaseObjectListSelect
                    list={contractors}
                    onChange={(value) => this.onContractorSelection(value)}
                    hintText={"בחרו קבלן"}
                    useMaterialDesign={false}
                    value={
                      selectedContractor
                        ? selectedContractor.contractorIds[0]
                        : null
                    }
                    icon={true}
                    size={"sm"}
                  />
                )}
            </div>
          </div>
        )}
        {shouldShowAddButton && (
          <div
            style={{
              position: "absolute",
              top: context === ORDER_MANAGEMENT_CONTEXT.CONTRACTOR ? 15 : 5,
              left: 0,
            }}
          >
            <CreateButton
              label={"הזמנה חדשה"}
              onClick={() => {
                this.setState({ orderDialogOpen: true });
              }}
            />
          </div>
        )}

        {((context === ORDER_MANAGEMENT_CONTEXT.CONTRACTOR &&
          isApplicationOrdersAdmin &&
          selectedContractor) ||
          !isApplicationOrdersAdmin ||
          ORDER_MANAGEMENT_CONTEXT.SUPPLIER) && (
          <Switch>
            <Route
              exact
              path="/orders"
              name="orders management"
              render={(props) => (
                <OrdersTable
                  setOrderId={(index, value) => {
                    setFieldValue(`orders.[${index}].orderId`, value);
                  }}
                  saveOrderId={(order) => {
                    //console.log("saving order id", {currentOrder: order});
                    saveOrder({ currentOrder: order });
                  }}
                  orders={orders}
                  pos={pos}
                  isLoading={isLoading}
                  onEditItem={this.editOrder}
                  context={context}
                  selectedContractor={selectedContractor}
                  sendOrder={sendOrder}
                  filterColumn={this.props.filterColumn}
                  setDates={this.props.setDates}
                  setAmountFilter={this.props.setAmountFilter}
                  filterToday={this.props.filterToday}
                  filterTomorrow={this.props.filterTomorrow}
                  filterThisWeek={this.props.filterThisWeek}
                  showAllOpen={this.props.showAllOpen}
                  showClosed={this.props.showClosed}
                  showCancelled={this.props.showCancelled}
                  onSortChange={this.props.onSortChange}
                  filtered={this.props.filtered}
                  currentFilterKey={this.props.filterKey}
                  startDateFilter={this.props.startDateFilter}
                  endDateFilter={this.props.endDateFilter}
                  sorted={this.props.sorted}
                  amountFilterCondition={this.props.amountFilterCondition}
                  amountFilterValue={this.props.amountFilterValue}
                  roles={this.props.roles}
                  firebase={this.props.firebase}
                  resetCurrentOrder={resetCurrentOrder}
                  hidePrices={hidePrices}
                  uid={roles.user_id}
                  showOnlyMyOrders={this.props.showOnlyMyOrders}
                  toggleOnlyMyOrders={this.props.toggleOnlyMyOrders}
                  showFDO={this.props.showFDO}
                />
              )}
            />
            <Route
              path="/orders/:supplierId/:contractorId/:projectId/:orderId"
              name="order Form"
              render={(props) => (
                <OrderForm
                  {...props}
                  context={context}
                  onContractorSelection={this.onContractorSelection}
                />
              )}
            />
          </Switch>
        )}

        {/*  {shouldShowAddButton &&
                (<StickyFloatingButton
                    icon={"add"}
                    onPress={() => {
                        this.setState({orderDialogOpen: true})
                    }}
                />)}*/}
        {context === ORDER_MANAGEMENT_CONTEXT.CONTRACTOR ? (
          <Modal
            isOpen={orderDialogOpen}
            toggle={() => {
              this.closeModal();
            }}
          >
            <ModalBody>
              <Row>
                <Col md={6}>
                  <InputGroup>
                    <InputLabel>פרוייקט</InputLabel>
                    <select
                      style={{ width: "100%", height: 34 }}
                      value={selectedProject ? selectedProject.id : ""}
                      onChange={(e) => {
                        let newValue = e.target.value
                          ? _.find(projects, { id: e.target.value })
                          : "";
                        this.setState({ selectedProject: newValue });
                        // setFieldValue("newOrder.selectedProject", e.target.value ? _.find(projects , {id: e.target.value}) : null)
                      }}
                    >
                      <option value={""}></option>
                      {sortedProjects.map((p, index) => {
                        return <option value={p.id}>{p.name}</option>;
                      })}
                    </select>
                  </InputGroup>
                </Col>
                <Col md={6}>
                  <InputGroup>
                    <InputLabel>ספק</InputLabel>
                    <select
                      disabled={selectedProject ? false : true}
                      style={{ width: "100%", height: 34 }}
                      value={selectedSupplier ? selectedSupplier.id : -1}
                      onChange={(e) => {
                        let selected = e.target.value
                          ? _.find(selectedProject.suppliers, [
                              "id",
                              e.target.value,
                            ])
                          : "";
                        this.setState({ selectedSupplier: selected });
                        //setFieldValue("newOrder.selectedSupplier", selected);
                      }}
                    >
                      <option value={""}></option>
                      {this.renderSuppliers()}
                    </select>
                  </InputGroup>
                </Col>
              </Row>
              <Button
                disabled={!selectedProject || !selectedSupplier}
                onClick={this.createOrder}
              >
                צור הזמנה
              </Button>
            </ModalBody>
          </Modal>
        ) : (
          <CustomerSearchModal
            isOpen={orderDialogOpen}
            onClose={this.closeModal}
            onSubmit={(selectedCustomer) =>
              this.createOrderRequest(selectedCustomer)
            }
            supplierId={this.getSupplierId()}
          />
        )}
      </div>
    );
  }
}

const CreateButton = ({ label, onClick }) => {
  return (
    <CreateButtonContainer onClick={onClick}>
      {label}
      <div
        style={{
          height: 45,
          width: 45,
          borderRadius: "50%",
          backgroundColor: "#fff",
          position: "absolute",
          top: 10,
          right: -20,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src={"img/icons/add_black.svg"} style={{ height: 40 }} />
      </div>
    </CreateButtonContainer>
  );
};

const CreateButtonContainer = styled.button`
  border: 0 !important;
  border-radius: 10px;
  box-shadow: -17.3px 10px 21px 0 rgba(176, 176, 176, 0.1);
  background-color: #ffffff;
  position: relative;
  justify-content: "center";
  align-items: "center";
  font-family: "NachlieliCLM";
  font-size: 25px;
  font-weight: 300;
  padding: 13px 27px;
`;

const Button = styled.button`
  background: #319cd8;
  border-radius: 3px;
  font-family: "Rubik", sans-serif;
  font-size: 18px;
  text-align: center;
  color: #ffffff;
  height: 34px;
  line-height: 34px;
  padding: 0 11px;
  cursor: pointer;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);
  border: 0 !important;
`;

OrdersManagement.propTypes = {
  orders: PropTypes.array,
};

const mapStateToProps = (state) => {
  const roles = state.roles.roles;
  const context = state.ordersManagement.context;
  let supplierContractors = null;
  if (context === ORDER_MANAGEMENT_CONTEXT.SUPPLIER) {
    const supplierIds = getSupplierIds(roles);
    const supplierId = supplierIds.supplierIds[0];
    supplierContractors = getPOContractors(
      getVal(
        state.firebase.data,
        `suppliersPrivateData/${supplierId}/contractors`,
        {}
      ),
      getVal(state.firebase.data, `contractorsPublicData`, {})
    );
  }

  return {
    orders: state.orders.orders,
    pos: state.pos.pos,
    order: state.orders.newOrder,
    projects: state.orders.projects,
    roles: roles,
    isLoading: state.orders.itemsLoading,
    // fetchOrdersCalled: state.layout.fetchOrdersCalled,
    contractors: state.firebase.data.contractors,
    context: context,
    isApplicationOrdersAdmin: state.ordersManagement.isApplicationOrdersAdmin,
    selectedContractor: state.ordersManagement.selectedContractor,
    fetchOrdersCalled: state.ordersManagement.fetchOrdersCalled,
    filtered: state.ordersFilter.filtered,
    filterKey: state.ordersFilter.filterKey,
    startDateFilter: state.ordersFilter.startDateFilter,
    endDateFilter: state.ordersFilter.endDateFilter,
    sorted: state.ordersFilter.sorted,
    showOnlyMyOrders: state.ordersFilter.showOnlyMyOrders,
    amountFilterCondition: state.ordersFilter.amountFilterCondition,
    amountFilterValue: state.ordersFilter.amountFilterValue,
    contractorId:
      state.ordersManagement.selectedContractor &&
      state.ordersManagement.selectedContractor.contractorIds
        ? state.ordersManagement.selectedContractor.contractorIds[0]
        : null,
    hidePrices:
      state.ordersManagement.selectedContractor &&
      state.ordersManagement.selectedContractor.contractorIds
        ? getVal(
            state.firebase.data,
            `contractors/${state.ordersManagement.selectedContractor.contractorIds[0]}/settings/hidePrices`,
            false
          )
        : false,
    supplierContractors: supplierContractors,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      connectOrders: connectOrders,
      setFieldValue,
      saveOrder,
      createNewOrder,
      fetchItems,
      initOrderFromList,
      lockOrder,
      setFetchOrdersCalled,
      sendOrder,
      setLayoutFieldValue,
      filterColumn,
      setDates,
      setAmountFilter,
      filterToday,
      filterTomorrow,
      filterThisWeek,
      showFDO,
      showAllOpen,
      showClosed,
      showCancelled,
      onSortChange,
      resetCurrentOrder,
      connectPos,
      fetchPoItems,
      initPoFromList,
      createNewPo,
      toggleOnlyMyOrders,
      getErpDirectOrders
    },
    dispatch
  );

//export default (connect(mapStateToProps, mapDispatchToProps)(OrdersManagement));

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firebaseConnect((props) => {
    let { contractorId } = props;
    let paths = [
      "contractors",
      `contractors/${contractorId}/settings/hidePrices`,
    ];

    if (props.context === ORDER_MANAGEMENT_CONTEXT.SUPPLIER) {
      const supplierIds = getSupplierIds(props.roles);
      const supplierId = supplierIds.supplierIds[0];
      paths.push(`suppliersPrivateData/${supplierId}/contractors`);
    }
    return paths;
  })
)(injectIntl(OrdersManagement));
