import React, { Component } from 'react';
// import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from "moment";

import styled from 'styled-components';
import { Row, Col } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import FontIcon from 'material-ui/FontIcon';

import DeliveryItem from "./deliveryItem"
// import InnerCatalog from "../OrdersManagement/innerCatalog";
import Catalog from "../../components/Form/main/Catalog"

// import SignatureCanvas from 'react-signature-canvas'
import { ACCEPTANCE_STATUSES, ORDER_MANAGEMENT_CONTEXT } from "shared/src/constants/orders";
import { setProductAcceptance, initOrderById, DELIVERY_ACCEPTANCE_KEY , addProduct, setFieldValue, removeProduct} from "shared/src/modules/deliveries";
import {  acceptOrder } from "shared/src/api/driverOrders";
import { fetchDrItems } from "shared/src/api/orderItems";

import {
    getCatalog,
    updateQuery,
    getPopularItems,
} from "shared/src/modules/catalog";
import { PRODUCT_ACTION_TYPES } from "shared/src/constants/orders";
import { getOrderTotalPrice } from "shared/src/selectors/orders";

import SignatureModal from "./signatureModal"
import { CATALOG_TYPES } from "shared/src/modules/catalog";

import {getShippingAddress, getContactPerson} from "shared/src/selectors/deliveries"

class OrderAcceptance extends Component {

    constructor() {
        super()
        this.state = {
            isModalOpen: false,
            isCatalogOpen: false,
            signatureName: "",
            signatureError: "",
            location: null
        }
    }

    componentDidMount(){
        const {order, ordersLoading, ordersContextLoaded, match: {params}, initOrderById} = this.props;
        const {contractorId, projectId, supplierId, orderId} = params;
        console.log("componentDidMount OrderAcceptance ordersContextLoaded:", ordersContextLoaded, "ordersLoading: ", ordersLoading );
        if (!ordersLoading && ordersContextLoaded) {
            if(!order.id || orderId !== order.id){
                initOrderById(orderId);
            }
            this.fetchScreenData({contractorId, projectId, supplierId, orderId})
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {order, ordersLoading, ordersContextLoaded, match: {params}, initOrderById} = this.props;
        const {contractorId, projectId, supplierId, orderId} = params;
        console.log("componentDidUpdate OrderAcceptance ordersContextLoaded:", ordersContextLoaded, "ordersLoading: ", ordersLoading);
        if ((ordersLoading !== prevProps.ordersLoading || ordersContextLoaded !== prevProps.ordersContextLoaded) && !ordersLoading && ordersContextLoaded) {
            console.log("********************************ORDERS LOADED***************************************");
            if(!order.id || (prevProps.match && prevProps.match.params && orderId !== prevProps.match.params.orderId)){
                initOrderById(orderId);
            }
            this.fetchScreenData({contractorId, projectId, supplierId, orderId})
        }
    }

    fetchScreenData = ({contractorId, projectId, supplierId, orderId}) => {
        const {fetchDrItems, getPopularItems} = this.props;
        fetchDrItems({contractorId, projectId, supplierId, orderId});
        getPopularItems({contractorId, supplierId, catalogName: CATALOG_TYPES.ORDERS});
        if ("geolocation" in navigator) {

            navigator.geolocation.getCurrentPosition((position) => {
                this.setState({location: position})
                //do_something(position.coords.latitude, position.coords.longitude);
            });
        } else {
            /* geolocation IS NOT available */
        }
    }



    validateOrder = ({name, image}) => {
        const {order, setProductAcceptance} = this.props;

        if(! name){
            this.setState({signatureError: "יש להקליד את שם החותם"})
            return false;
        }
        else {
            this.setState({signatureError: ""})
        }

        order.items.map((item, index)=>{
            //if user didnt touch the item we approve it by default
            let latestAction = null;
            if (item.actions && item.actions.length > 0) {
                latestAction = item.actions[item.actions.length - 1]
            }
            if(!(latestAction && [PRODUCT_ACTION_TYPES.REPLACE, PRODUCT_ACTION_TYPES.DELAY, PRODUCT_ACTION_TYPES.CANCELED].includes(latestAction.type)) && !item[DELIVERY_ACCEPTANCE_KEY]){
                setProductAcceptance(index, {status: ACCEPTANCE_STATUSES.approved, quantity: item.count})
            }
        })
        return true;
    }

    renderOrderDetails = (order) => {

        const contactPerson = getContactPerson(order);
        const shippingAddress = getShippingAddress(order)

        return (
            <Card>
                <CardTitle>פרטי הזמנה</CardTitle>
                <Row>
                    <Col md={5}>
                        <InputGroup>
                            <InputLabel style={{fontWeight: 'bold'}}>שם לקוח:</InputLabel>
                        </InputGroup>
                    </Col>
                    <Col md={7}>
                        <InputGroup>
                            <InputLabel>{order && order.contractor && order.contractor.name}</InputLabel>
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={5}>
                        <InputGroup>
                            <InputLabel style={{fontWeight: 'bold'}}>פרוייקט:</InputLabel>
                        </InputGroup>
                    </Col>
                    <Col md={7}>
                        <InputGroup>
                            <InputLabel>{order && order.project && order.project.name}</InputLabel>
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={5}>
                        <InputGroup>
                            <InputLabel style={{fontWeight: 'bold'}}>כתובת:</InputLabel>
                        </InputGroup>
                    </Col>
                    <Col md={7}>
                        <InputGroup>
                            <InputLabel>{typeof shippingAddress === "string" ? shippingAddress : shippingAddress.name}</InputLabel>
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={5}>
                        <InputGroup>
                            <InputLabel style={{fontWeight: 'bold'}}>שם מזמין:</InputLabel>
                        </InputGroup>
                    </Col>
                    <Col md={7}>
                        <InputGroup>
                            <InputLabel>{contactPerson.displayName}</InputLabel>
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={5}>
                        <InputGroup>
                            <InputLabel style={{fontWeight: 'bold'}}>טלפון:</InputLabel>
                        </InputGroup>
                    </Col>
                    <Col md={7}>
                        <InputGroup>
                            <InputLabel>{contactPerson.phoneNumber}</InputLabel>
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={5}>
                        <InputGroup>
                            <InputLabel style={{fontWeight: 'bold'}}>אספקה:</InputLabel>
                        </InputGroup>
                    </Col>
                    <Col md={5}>
                        <InputGroup>
                            <InputLabel>{moment(order && order.deliveryDate ? order.deliveryDate : "").format("D/MM/YY")}</InputLabel>
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={5}>
                        <InputGroup>
                            <InputLabel style={{fontWeight: 'bold'}}>ת. משלוח:</InputLabel>
                        </InputGroup>
                    </Col>
                    <Col md={7}>
                        <InputGroup>
                            <InputLabel>{order && order.orderId}</InputLabel>
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <InputGroup>
                            <InputLabel style={{fontWeight: 'bold'}}>הערות לקוח:</InputLabel>
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <InputGroup>
                            <InputLabel>
                                {order && order.note}
                            </InputLabel>
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <InputGroup>
                            <InputLabel style={{fontWeight: 'bold'}}>הערות ספק:</InputLabel>
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <InputGroup>
                            <InputLabel>{order && order.supplierNote}</InputLabel>
                        </InputGroup>
                    </Col>
                </Row>
            </Card>
        )
    }

    _handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.onSignatureEnd()
        }
    }

    onSignatureEnd = ({name, image}, successCallback) => {

        console.log("Signature: ", name, image)
        const {acceptOrder, setFieldValue} = this.props;
        if(this.validateOrder({name, image})){
            setFieldValue("currentOrder.acceptance", {
                auth: this.props.roles.user_id,
                location: this.state.location,
                signature: {name, image}
            });
            acceptOrder({name, image},successCallback);
            // successCallback();
            this.props.history.push(`/deliveries`);
            this.setState({isModalOpen: false})
        }
    }


    render() {

        const {order,
            isLoadingCatalog,
            query,
            catalog,
            getCatalog,
            updateQuery,
            addProduct,
            match: {params},
            removeProduct
        } = this.props;

        const {contractorId, projectId, supplierId, orderId} = params;
        const {setProductAcceptance} = this.props;

        const {price, count} = getOrderTotalPrice(order, true);

        return (
            <div>
                <Row>
                    <Col md={4}>
                        {this.renderOrderDetails(order)}
                    </Col>
                    <Col md={8}>
                        <Card>
                            <Row>
                                <Col md={7}><CardTitle style={{paddingTop: 8}}>סיכום הזמנה</CardTitle></Col>
                            </Row>
                            <Row style={{flex: 1}}>
                                <Col md={3} style={{
                                    fontFamily: 'Rubik',
                                    fontSize: 20, fontWeight: 'Bold',
                                    color: '#828282', paddingTop: 8
                                }}>{order.items.length} מוצרים</Col>
                                <Col md={3} style={{
                                    fontFamily: 'Rubik',
                                    fontSize: 20, fontWeight: 'Bold',
                                    color: '#828282', paddingTop: 8
                                }}>{count} פריטים</Col>
                                <Col md={3} style={{align: 'center', justifyContent: 'center'}}>
                                    <SendButton style={{marginBottom: 10}} onClick={() => {
                                        this.setState({isModalOpen: true})
                                    }}>אשר הזמנה</SendButton>
                                </Col>
                               {/* <Col md={3}><SendButton style={{marginBottom: 10}} onClick={()=>{this.setState({isCatalogOpen: true})}}>החזר מוצרים</SendButton></Col>*/}
                            </Row>
                            <Row>

                            </Row>
                            <div style={{
                                height: 2,
                                width: "calc(100% + 30px)",
                                marginRight: "-15px",
                                background: "#319CD8"
                            }}></div>

                            {order && order.items && order.items.map((item, index) =>
                            {
                                let latestAction = null;
                                if (item.actions && item.actions.length > 0) {
                                    latestAction = item.actions[item.actions.length - 1]
                                        //console.log("latestAction", latestAction);
                                }
                                if(latestAction && [PRODUCT_ACTION_TYPES.DELAY].includes(latestAction.type)){
                                    return null;
                                }
                                return(
                                    <DeliveryItem
                                        item={item}
                                        index={index}
                                        onAcceptanceChange={({status, quantity}) => {
                                            setProductAcceptance(index, {status, quantity})
                                        }}
                                        onDeleteReturnedItem={()=>{removeProduct(index)}}
                                    />
                                )})}
                        </Card>
                    </Col>
                </Row>
                <SignatureModal
                    isModalOpen={this.state.isModalOpen}
                    closeHandler={()=>{this.setState({isModalOpen: false})}}
                    onSignatureEnd={this.onSignatureEnd}
                />


                <Modal isOpen={this.state.isCatalogOpen} size={"lg"}>
                    <ModalHeader>
                        <span>קטלוג</span>
                        <ModalCloseButtonWrapper onClick={()=>{this.setState({isCatalogOpen: false})}}>
                            <FontIcon color={"#636363"} className={"material-icons"}>close</FontIcon>
                        </ModalCloseButtonWrapper>
                    </ModalHeader>
                    <ModalBody>
                        <Catalog
                            orderId={orderId}
                            supplierId={supplierId}
                            catalog={catalog}
                            query={query}
                            contractorId={contractorId}
                            disabledCatalogItems={false}
                            isLoadingCatalog={isLoadingCatalog}
                            onAdToCart={(catalogProduct, room, count, action)=>{addProduct(catalogProduct, {}, true, count)}}
                            updateQueryHandler={updateQuery}
                            getCatalogHandler={getCatalog}
                            // autoCompleteList={[]}
                            // rooms={[]}
                            // defaultRoom={null}
                            context={ORDER_MANAGEMENT_CONTEXT.SUPPLIER}
                            formContext={ORDER_MANAGEMENT_CONTEXT.SUPPLIER}
                        />
                    </ModalBody>
                </Modal>


            </div>
        )
    }
}


const Card = styled.div`
background: #fff;
padding: 15px;
margin-top: 17px;
border: 2px solid #E0E0E0;
box-sizing: border-box;
box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
border-radius: 5px;
height: calc( 100% - 17px);
position: relative;
`;

const CardTitle = styled.div`
font-family: 'Rubik', sans-serif;
font-size: 24px;
color: #BDBDBD;
`;

const InputLabel = styled.div`
font-family: 'Rubik', sans-serif;
font-size: 14px;
color: #4F4F4F;
margin-bottom: 6px;
`;

const InputGroup = styled.div`
margin-top: 10px;
`;

const SendButton = styled.div`
border-radius: 3px;
font-family: 'Rubik', sans-serif;
font-size: 16px;
text-align: center;
color: #FFFFFF;
height: 50px;
line-height: 50px;
padding: 0 11px;
cursor:  ${props => props.disabled ? "default" : "pointer"};
box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);
background-color: ${props => props.disabled ? "#818181" : "#319CD8"};
`;



const ModalCloseButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
`;

const mapStateToProps = state => ({
    ordersContextLoaded: state.deliveries.ordersContextLoaded,
    ordersLoading: state.deliveries.ordersLoading,
    itemsLoading: state.deliveries.itemsLoading,
    order: state.deliveries.currentOrder,
    roles: state.roles.roles,
    isLoadingCatalog: state.ordersCatalog.isLoading,
    query: state.ordersCatalog.query,
    catalog: state.ordersCatalog.catalog,
});

const mapDispatchToProps = dispatch => bindActionCreators({
    setProductAcceptance,
    initOrderById,
    fetchDrItems,
    acceptOrder,
    getCatalog,
    updateQuery,
    removeProduct,
    getPopularItems,
    addProduct,
    setFieldValue
}, dispatch);

OrderAcceptance.propTypes = {};


export default connect(mapStateToProps, mapDispatchToProps)(OrderAcceptance);
