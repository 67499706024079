import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { compose } from "redux";
import { firebaseConnect } from "react-redux-firebase";
import { injectIntl, FormattedMessage, formatMessage } from 'react-intl'
import _ from "lodash";
import styled from 'styled-components';


import { noProductThumb, PRODUCT_MODES } from "shared/src/constants/config"
import badgesOptions from "shared/src/constants/productAssets/badges/badges";
import categoriesOptions from "shared/src/constants/productAssets/categories";
import unitTypes from "shared/src/constants/productAssets/unitTypes";

import {
	setName,
	setProductName,
	setId,
	setCategory,
	setDescription,
	setPrice,
	setImages,
	addImage,
	removeImage,
	updateProduct,
	addProduct,
	setMode,
	setPackagingInfo,
	addGroupItem,
	editGroupItem,
	removeGroupItem,
	onGroupItemChange,
	onProductFieldChange,
	onSubGroupItemChange,
	addSubGroupItem,
	removeSubGroupItem,
	isCatalogIdExist,
	setProductRef,
	setProduct,
	extractCategory,
	defaultUnitType,
	extractUnitType,
	setUnitType,
	getProduct,
	addAddonProduct,
	removeAddonProduct,
	defaultTreeValue,
	setCategoryHelper
} from "shared/src/modules/catalogProduct"
import { getCatalog, clearCatalog, CATALOG_TYPES } from "shared/src/modules/catalog";
import { toastr } from 'react-redux-toastr'
import FormGroup from "../../components/Form/FormGroup";
import Tabs from "../../components/Form/Tabs"

import List from "../../components/Form/List";
import RelatedProducts from "../../components/Form/RelatedProducts";


import Loader from "../../components/Loader"
import ImageUploader from "./ImageUploader"

import Checkbox from 'material-ui/Checkbox';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import TagsInput from 'react-tagsinput'

import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Card, CardTitle, InputLabel, InputGroup } from "../../styles/stylesheet"
import IconInput, { IconWrapper } from "../../components/Form/IconInput";
import CustomCheckbox from "../../components/Form/Checkbox"

import IconTreeSelect from "../../components/Form/IconTreeSelect"

import CollapsibleGroup from "../../components/CollapsibleGroup"
import TileAddButton from "../../components/Form/TileAddButton"
import ConfirmButton from "../../components/ConfirmButton"
import RoundIconButton from "../../components/RoundIconButton"

import OrderListItem, { ORDER_LIST_ITEM_CONTEXT_TYPES } from "../OrdersManagement/orderListItem";
import Switch from "../../components/Form/Switch"
import { colors, fonts } from "../../styles/styleguide";
import AddToList from "../../components/Form/AddToList/AddToList"
import { getItemPackage } from "shared/src/selectors/orders";

const styles = {
	titleInput: {
		color: "#404040",
		fontSize: 36,
	}
};

class ProductForm extends React.Component {

	constructor() {
		super();
		this.state = {
			selectedOption: [],
			catIdError: false,
			linkedProductModalOpen: false,
			additionalProductModalOpen: false
		}

	}

	componentDidMount() {
		const { mode, getProduct, clearCatalog, match: { params } } = this.props;
		const { supplierId, productId } = params;

		// console.log("componentDidMount")
		// console.log("mode", mode)

		if (mode === PRODUCT_MODES.EDIT) {
			// console.log("supplierId", supplierId)
			// console.log("productId", productId)

			getProduct({ path: `/itemsBySupplier/${ supplierId }/${ productId }/data` });
		}

		clearCatalog(CATALOG_TYPES.RELATED_PRODUCT);
	}


	onSave() {
		const { mode, id, updateProduct, addProduct, setMode, setProduct } = this.props;
		const { supplierId } = this.props.match.params;

		if (!this.validateProduct()) {
			toastr.warning("אין אפשרות לשמור מוצר ללא שם");
			return;
		}
		if (mode === PRODUCT_MODES.EDIT) {
			updateProduct(`itemsBySupplier/${ supplierId }/${ id }/data`);
			// this.props.history.push("/catalog-management");
			// setProduct({})
		} else {
			addProduct(`itemsBySupplier/${ supplierId }/${ id }/data`);
			// setMode(PRODUCT_MODES.EDIT);
		}

		this.props.history.push("/catalog-management");
		setProduct({})

		// this.props.history.push("/catalog-management");
	}

	validateProduct = () => {
		const { name } = this.props;
		let isValid = true;

		if (!name) {
			isValid = false;
		}

		return isValid;
	}


	addBadge( badge, badges, onChange ) {
		if (_.find(badges, [ "value", badge.value ])) {

		}
		onChange("badges", _.cloneDeep(badges).push(badge))
	}

	validateCatalogId = () => {
		const { isCatalogIdExist, catalogId, id } = this.props;
		const { supplierId } = this.props.match.params;

		isCatalogIdExist(catalogId, supplierId).then(( snapshot ) => {
			let value = snapshot.val();
			// console.log("isCatalogIdExist result", value);
			if (value === id || value === null) {
				this.setState({ catIdError: false })
			} else {
				this.setState({ catIdError: value })
			}

		}).catch((
			error => {
				console.log("isCatalogIdExist", error);

			}
		));
	}

	openLinkedProductModal = () => {
		this.setState({ linkedProductModalOpen: true })
	}

	closeLinkedProductModal = () => {
		this.setState({ linkedProductModalOpen: false })
	}

	onKeywordsChange = ( newValue ) => {
		const { onProductFieldChange } = this.props;
		onProductFieldChange("keywords", newValue)
	}

	onCategoriesChange = ( newValue ) => {
		const { onProductFieldChange } = this.props;
		onProductFieldChange("categories", newValue)
	}

	renderForm() {
		const {
			      id,
			      isEdit,
			      name,
			      productName,
			      // category,
			      description,
			      price,
			      setName,
			      setProductName,
			      setCategory,
			      setDescription,
			      setPrice,
			      intl,
			      addGroupItem,
			      removeGroupItem,
			      onGroupItemChange,
			      spec, warnings,
			      generalInfo, longDescription, badges, relatedProducts, onProductFieldChange,
			      onSubGroupItemChange, addSubGroupItem, removeSubGroupItem, editGroupItem, features,
			      catalogId, isSupplementalProduct, isGenericProduct, suppliers, disabled,
			      setProductRef,
			      productRef,
			      getCatalog,
			      clearCatalog,
			      searchResults,
			      isLoadingSearch,
			      isRentalProduct,
			      unitType, setUnitType, setPackagingInfo, packagingInfo,
			      variants,
			      rentInterval,
			      addAddonProduct,
			      removeAddonProduct,
			      addOnProducts,
			      keywords,
			      categories,
			      minPrice,
			      scannerId,
			      mode,
			      setMode,
			      getProduct
		      } = this.props;

		const { catIdError, linkedProductModalOpen, additionalProductModalOpen } = this.state;

		const { supplierId } = this.props.match.params;

		const isProductDisabled = productRef ? productRef.disabled : disabled;

		console.log("renderForm")
		console.log("categories", categories)

		return (
			<div>

				{ productRef ? (
						<Row>
							<Col md={ 12 }>
								<CardTitle>מוצר מקושר</CardTitle>
								<Card>
									<Row>
										<Col md={ 4 }>
											<ProductWrapper>
												<OrderListItem
													imgSrc={ productRef.images && productRef.images.length
														? productRef.images[ 0 ].downloadURL
														: noProductThumb }
													name={ productRef.name }
													packageInfo={ getItemPackage(productRef) }
													catalogId={ productRef.catalogId }
													context={ ORDER_LIST_ITEM_CONTEXT_TYPES.PRODUCT_FORM }
													onDelete={ () => setProductRef(null) }
												/>
											</ProductWrapper>
										</Col>
									</Row>
								</Card>
							</Col>
						</Row>
					) :
					(
						null
					) }

				<Row>
					<Col md={ 12 }>
						<div style={ {
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							margin: "25px 0 40px 0"
						} }>
							<CardTitle>פרטי מוצר</CardTitle>
							<div style={ {
								display: "flex",
								justifyContent: "center",
								alignItems: "center"
							} }>
								{ !productRef && (
									<div style={ { marginLeft: 20 } }>
										<RoundIconButton
											onClick={ this.openLinkedProductModal }
											backgroundColor={ colors.yellow }
											label={ "הוספת מוצר מקושר" }
											color={ colors.charcoal }
											iconSrc={ "img/icons/link.svg" }
											border={ "none" }
										/>
									</div>
								) }
								<Switch checked={ !isProductDisabled }
								        onChange={ ( isInputChecked ) => {
									        if (isInputChecked) {
										        onProductFieldChange("disabled", false)
									        } else {
										        onProductFieldChange("disabled", true)
									        }
								        } }
								        disabled={ productRef ? true : false }
								        label={ isProductDisabled ? "מנוטרל" : "פעיל" }
								/>
							</div>

						</div>
						<Card>
							<Row>
								<Col sm={ 6 } md={ 6 } lg={ 4 }>
									<InputGroup>
										<InputLabel>שם מוצר</InputLabel>
										<IconInput
											type={ "text" }
											value={ name }
											onChange={ ( value ) => {
												setName(value)
											} }
											icon={ <img src={ "img/icons/offers.svg" }/> }
										/>
									</InputGroup>

									<InputGroup>
										<InputLabel>{ "משפחת מוצרים" }</InputLabel>
										<IconInput
											type={ "text" }
											value={ productName }
											onChange={ ( value ) => {
												setProductName(value)
											} }
											icon={ <img src={ "img/icons/offers.svg" }/> }
										/>
									</InputGroup>

									<InputGroup>
										<InputLabel>תיאור מוצר</InputLabel>
										<IconInput
											type={ "textarea" }
											value={ productRef ? productRef.description : description }
											onChange={ ( value ) => {
												setDescription(value)
											} }
											icon={ <img src={ "img/icons/edit_round.svg" }/> }
											wrapperTop={ "10px" }
											wrapperBottom={ "auto" }
											disabled={ productRef ? true : false }
										/>
									</InputGroup>

									<InputGroup>
										<InputLabel>מארז</InputLabel>
										<IconInput
											type={ "text" }
											value={ packagingInfo }
											onChange={ ( value ) => {
												setPackagingInfo(value)
											} }
											icon={ <img src={ "img/icons/offers.svg" }/> }
										/>
									</InputGroup>

									{
										<InputGroup>
											<InputLabel>קטגוריות</InputLabel>
											<TagsInput
												value={ categories }
												onChange={ this.onCategoriesChange }
												renderTag={ ( props ) => {
													let {
														    tag,
														    key,
														    disabled,
														    onRemove,
														    classNameRemove,
														    getTagDisplayValue,
														    ...other
													    } = props
													return (
														<Tag key={ key } { ...other }>
															{ getTagDisplayValue(tag) }
															{ !disabled && (
																<a className={ classNameRemove }
																   onClick={ ( e ) => onRemove(key) }/>
															) }
														</Tag>
													)
												} }
												inputProps={ {
													placeHolder: "הוספת קטגוריות"
												} }
												disabled={ !!productRef }
											/>
										</InputGroup>
									}

									<InputGroup>
										<InputLabel>מילות חיפוש</InputLabel>
										<TagsInput
											value={ keywords }
											onChange={ this.onKeywordsChange }
											renderTag={ ( props ) => {
												let {
													    tag,
													    key,
													    disabled,
													    onRemove,
													    classNameRemove,
													    getTagDisplayValue,
													    ...other
												    } = props
												return (
													<Tag key={ key } { ...other }>
														{ getTagDisplayValue(tag) }
														{ !disabled && (
															<a className={ classNameRemove }
															   onClick={ ( e ) => onRemove(key) }/>
														) }
													</Tag>
												)
											} }
											inputProps={ {
												placeHolder: "הוספת מילות חיפוש"
											} }
											disabled={ !!productRef }
										/>
									</InputGroup>
								</Col>
								<Col sm={ 6 } md={ 6 } lg={ 2 }>
									<InputGroup>
										<InputLabel>מחיר</InputLabel>
										<IconInput
											type={ "number" }
											value={ price }
											onChange={ ( value ) => setPrice(value) }
											icon={ <img src={ "img/icons/price.svg" }/> }
										/>
									</InputGroup>

									<InputGroup>
										<InputLabel>מק"ט</InputLabel>
										<IconInput
											type={ "text" }
											value={ catalogId }
											onBlur={ this.validateCatalogId }
											onChange={ ( value ) => onProductFieldChange("catalogId", value) }
										/>
										{ catIdError && (
											<div>{ 'שגיאה! המק"ט כבר קיים במערכת, אנא הזן מק"ט אחר' }</div>
										) }
										{ catIdError && (
											<Button color="link" className={ "px-0" } onClick={ () => {
												// console.log("supplierId", supplierId)
												// console.log("catIdError", catIdError)
												// console.log("catalogId", catalogId)

												setMode(PRODUCT_MODES.EDIT)
												getProduct({ path: `/itemsBySupplier/${ supplierId }/${ catIdError }/data` });

												this.props.history.push(`/catalog-management/${ supplierId }/${ catIdError }`)
											} }>{ `לצפייה במוצר עם המק"ט  ${ catalogId }  לחצו כאן` }</Button>
										) }
									</InputGroup>

									<InputGroup>
										<InputLabel>ברקוד</InputLabel>
										<IconInput
											type={ "text" }
											value={ scannerId }
											onChange={ ( value ) => onProductFieldChange("scannerId", value) }
											icon={ <img src={ "img/icons/catalogId.svg" }
											/> }
										/>
									</InputGroup>

									<InputGroup>
										<div style={ { marginTop: 22 } }>
											<CustomCheckbox
												label={ "מוצר השכרה" }
												checked={ isRentalProduct }
												onChange={ ( event ) => {
													onProductFieldChange("isRentalProduct", event.target.checked)
												} }/>
										</div>
										{ isRentalProduct && (
											<div>
												<InputLabel>בחרו מחזור חיוב להשכרה</InputLabel>
												<RadioButtonGroup name="rentInterval"
												                  valueSelected={ rentInterval }
												                  onChange={ ( event, value ) => {
													                  onProductFieldChange("rentInterval", value)
												                  } }>
													<RadioButton
														value="day"
														label="יום"
													/>
													<RadioButton
														value="week"
														label="שבוע"

													/>
												</RadioButtonGroup>
											</div>
										) }
									</InputGroup>
								</Col>
								<Col sm={ 6 } md={ 6 } lg={ 2 }>
									<IconTreeSelect
										labels={ [ "סוג יחידה", "יחידת מידה" ] }
										setValue={ setUnitType }
										value={ productRef ? extractUnitType(productRef.unitType) : unitType }
										tree={ unitTypes }
										disabled={ productRef ? true : false }
									/>
								</Col>
								<Col sm={ 6 } md={ 6 } lg={ 4 }>
									<div className={ "images-row" }>
										<Row className={ "align-items-end" }>
											{ id && supplierId && (
												<ImageUploader/>
											) }
										</Row>
									</div>

								</Col>
							</Row>
							<Row>
								{/* <Col sm={6} md={6} lg={2}>
								 <InputGroup>
								 <InputLabel>מחיר עלות</InputLabel>
								 <IconInput
								 type={"number"}
								 value={minPrice}
								 onChange={(value) => onProductFieldChange("minPrice", value)}
								 icon={<img src={"img/icons/price.svg"}/>}
								 />
								 </InputGroup>
								 </Col>*/ }
							</Row>
							<div style={ { position: "absolute", top: -25, left: 20 } }>
								<ConfirmButton
									disabled={ !catalogId || !name || catIdError }
									onClick={ () => this.onSave() }
									label={ "שמירה" }
								/>
							</div>

						</Card>
					</Col>
				</Row>

				<Row>
					<Col md={ 12 }>
						<CardTitle>מידע כללי</CardTitle>
						<Card>
							<Row>
								<Col md={ 12 }>
									<Tabs
										items={ productRef ? (
											productRef.spec || []
										) : spec }
										disabled={ productRef ? true : false }
										isSubGroups={ true }
										onAddItem={ ( itemName, isSubGroup ) => {
											addGroupItem("spec", itemName, isSubGroup)
										} }
										onEditItem={ ( itemKey, name ) => {
											editGroupItem("spec", itemKey, name)
										} }
										onAddSubGroupItem={ ( itemName, subGroupKey ) => {
											addSubGroupItem("spec", subGroupKey, itemName)
										} }
										onSubGroupItemChange={ ( itemKey, subGroupKey, value ) => {
											onSubGroupItemChange("spec", subGroupKey, itemKey, value)
										} }
										removeSubGroupItem={ ( itemKey, subGroupKey ) => {
											removeSubGroupItem("spec", subGroupKey, itemKey)
										} }
										onRemoveItem={ ( itemKey ) => {
											removeGroupItem("spec", itemKey)
										} }
										onItemChange={ ( itemKey, value ) => onGroupItemChange("spec", itemKey, value) }
										title={ intl.formatMessage({ id: "product.techInfo" }) }
										mainInputLabel={ intl.formatMessage({ id: "product.form.group.addSubGroupLabel" }) }
										mainInputPlaceholder={ intl.formatMessage({ id: "product.form.group.addSubGroupPlaceholder" }) }
									/>
								</Col>
							</Row>


						</Card>
					</Col>
				</Row>

				<Row>
					<Col md={ 12 }>

						<Card>
							{
								// <CollapsibleGroup title={ "קטגוריות" }>
								//
								//     <Row>
								//         <Col md={ 7 }>
								//             <InputLabel>קטגוריות</InputLabel>
								//             <AddToList
								//                 defaultValue={ defaultTreeValue }
								//                 getAddToListComponent={ ( value, onChangeHandler ) => {
								//                     return (
								//                         <IconTreeSelect
								//                             label={ intl.formatMessage({ id: "product.category" }) }
								//                             labels={ [ "קטגוריה ראשית", "תת קטגוריה" ] }
								//                             setValue={ ( level, newValue ) => onChangeHandler(
								//                                 setCategoryHelper(value, Number(level), newValue)) }
								//                             tree={ categoriesOptions }
								//                             value={ value }
								//                             selectLabel={ intl.formatMessage({ id: "product.form.category.select.label" }) }
								//                             disabled={ productRef ? true : false }
								//                             direction={ "row" }
								//                         />
								//                     )
								//                 } }
								//                 getListItemComponent={ ( item ) => {
								//                     let str = [];
								//                     let parent = "";
								//                     for (let key in item) {
								//                         let name = item[ key ];
								//                         if (!name) {
								//                             continue;
								//                         }
								//                         if (parent) {
								//                             const translation = intl.formatMessage({ id: `categories.${ parent }.${ name }` });
								//                             str.push(translation)
								//                             parent = parent + "." + name;
								//                         } else {
								//                             str.push(intl.formatMessage({ id: `categories.${ name }` }))
								//                             parent = name;
								//                         }
								//
								//                     }
								//                     return (
								//                         <div>{ str.join(" -> ") }</div>
								//                     );
								//                 } }
								//                 onAddToList={ ( item ) => {
								//                     categories.push(item)
								//                     onProductFieldChange("categories", categories)
								//                 } }
								//                 onRemoveListItem={ ( item, index ) => {
								//                     categories.splice(index, 1);
								//                     onProductFieldChange("categories", categories)
								//                 } }
								//                 list={ categories }
								//                 addToListPosition={ "bottom" }
								//                 disabled={ productRef ? true : false }
								//             />
								//
								//         </Col>
								//         {
								//             // <Col md={5}>
								//             //     <InputGroup>
								//             //         <InputLabel>מילות חיפוש</InputLabel>
								//             //         <TagsInput
								//             //             value={keywords}
								//             //             onChange={this.onKeywordsChange}
								//             //             renderTag={(props) => {
								//             //                 let {tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other} = props
								//             //                 return (
								//             //                     <Tag key={key} {...other}
								//             //                     >
								//             //                         {getTagDisplayValue(tag)}
								//             //                         {!disabled &&
								//             //                         <a className={classNameRemove}
								//             //                            onClick={(e) => onRemove(key)}/>
								//             //                         }
								//             //                     </Tag>
								//             //                 )
								//             //             }}
								//             //             inputProps={{
								//             //                 placeHolder: "הוספת מילות חיפוש"
								//             //             }}
								//             //             disabled={productRef ? true : false}
								//             //         />
								//             //     </InputGroup>
								//             // </Col>
								//         }
								//     </Row>
								// </CollapsibleGroup>
							}

							<CollapsibleGroup title={ "תכונות עיקריות" }>
								<Row>
									<Col md={ 12 }>
										<div style={ { display: "flex" } }>
											<div>
												<List
													items={ productRef ? (
														productRef.features || []
													) : features }
													onAddItem={ () => {
														addGroupItem("features", "הוסף שורה כאן...")
													} }
													onRemoveItem={ ( item ) => {
														removeGroupItem("features", item.key)
													} }
													onItemChange={ ( item, value ) => onGroupItemChange(
														"features",
														item.key,
														value
													) }
													itemLabelKey={ "value" }
													inputLabelText={ "הוסף שורה כאן..." }
													addButtonLabel={ "הוספת תכונה חדשה" }
													disabled={ productRef ? true : false }
												/>
											</div>
										</div>
									</Col>
								</Row>
							</CollapsibleGroup>

							<CollapsibleGroup title={ "אזהרות" }>
								<Row>
									<Col md={ 12 }>
										<FormGroup
											items={ productRef ? (
												productRef.warnings || []
											) : warnings }
											disabled={ productRef ? true : false }
											onAddItem={ ( itemName ) => {
												addGroupItem("warnings", itemName)
											} }
											onRemoveItem={ ( itemKey ) => {
												removeGroupItem("warnings", itemKey)
											} }
											onItemChange={ ( itemKey, value ) => onGroupItemChange(
												"warnings",
												itemKey,
												value
											) }
											mainInputLabel={ intl.formatMessage({ id: "product.form.group.addFieldLabel" }) }
											mainInputPlaceholder={ intl.formatMessage({ id: "product.form.group.addFieldPlaceholder" }) }
										/>
									</Col>
								</Row>
							</CollapsibleGroup>

							<CollapsibleGroup title={ "מידע כללי" }>
								<Row>
									<Col md={ 12 }>
										<FormGroup
											items={ productRef ? (
												productRef.generalInfo || []
											) : generalInfo }
											disabled={ productRef ? true : false }
											onAddItem={ ( itemName ) => {
												addGroupItem("generalInfo", itemName)
											} }
											onRemoveItem={ ( itemKey ) => {
												removeGroupItem("generalInfo", itemKey)
											} }
											onItemChange={ ( itemKey, value ) => onGroupItemChange(
												"generalInfo",
												itemKey,
												value
											) }
											mainInputLabel={ intl.formatMessage({ id: "product.form.group.addFieldLabel" }) }
											mainInputPlaceholder={ intl.formatMessage({ id: "product.form.group.addFieldPlaceholder" }) }
										/>
									</Col>
								</Row>
							</CollapsibleGroup>

							<CollapsibleGroup title={ "מוצרים קשורים" }>
								<Row>
									<Col md={ 12 }>
										<FormGroup
											items={ productRef ? (
												productRef.relatedProducts || []
											) : relatedProducts }
											disabled={ productRef ? true : false }
											onAddItem={ ( itemName ) => {
												addGroupItem("relatedProducts", itemName)
											} }
											onRemoveItem={ ( itemKey ) => {
												removeGroupItem("relatedProducts", itemKey)
											} }
											onItemChange={ ( itemKey, value ) => onGroupItemChange(
												"relatedProducts",
												itemKey,
												value
											) }
											mainInputLabel={ intl.formatMessage({ id: "product.form.group.relatedProduct.add" }) }
											mainInputPlaceholder={ intl.formatMessage({ id: "product.form.group.relatedProduct.placeholder" }) }
											primaryButtonLabel={ intl.formatMessage({ id: "product.form.group.relatedProduct.buttonLabel" }) }
											isEditable={ false }
										/>
									</Col>
								</Row>
							</CollapsibleGroup>

							<CollapsibleGroup title={ "תקנים" }>
								<Row>
									<Col md={ 12 }>
										<FlexWrapper style={ { marginTop: 18 } }>
											{ badgesOptions.map(( badge, i ) => (
												<FlexCol key={ "badge_" + i }>
                            <span>
                                <Checkbox
	                                label={ (
		                                <div>
                                            <span><img style={ { maxHeight: 56, maxWidth: 56 } }
                                                       src={ badge.path }/></span>
		                                </div>
	                                ) }
	                                disabled={ productRef ? true : false }
	                                checked={ productRef ? (
		                                _.find(productRef.badges, [ "value", badge.value ]) ? true : false
	                                ) : (
		                                _.find(badges, [ "value", badge.value ]) ? true : false
	                                ) }
	                                onCheck={ ( event, isInputChecked ) => {
		                                let clone = _.cloneDeep(badges);
		                                if (isInputChecked) {
			                                clone.push(badge)
			                                onProductFieldChange("badges", clone)
		                                } else {
			                                let index = _.findIndex(clone, [ "value", badge.value ]);
			                                if (index !== -1) {
				                                clone.splice(index, 1);
				                                onProductFieldChange("badges", clone)
			                                }
		                                }

	                                } }
                                />

                            </span>


												</FlexCol>
											)) }
										</FlexWrapper>
									</Col>
								</Row>
							</CollapsibleGroup>

							<CollapsibleGroup title={ "מוצרים נלווים" }>
								<Row>
									<Col md={ 12 }>
										<Row>
											{ !_.isEmpty(addOnProducts) ? !addOnProducts[ 0 ] ? (
												<Loader/>
											) : (
												addOnProducts.map(( p, index ) => {
													if (!p) {
														return null;
													}
													return (
														<Col md={ 3 }>
															<ProductWrapper key={ index }>
																<OrderListItem
																	imgSrc={ p.images && p.images.length
																		? p.images[ 0 ].downloadURL
																		: noProductThumb }
																	name={ p.name }
																	catalogId={ p.catalogId }
																	context={ ORDER_LIST_ITEM_CONTEXT_TYPES.PRODUCT_FORM }
																	onDelete={ () => removeAddonProduct(index) }
																	packageInfo={ getItemPackage(p) }
																/>
															</ProductWrapper>
														</Col>
													)
												})

											) : null }
											<Col md={ 2 }>
												<TileAddButton
													onClick={ () => {
														this.setState({ additionalProductModalOpen: true })
													} }
													title={ "הוספת מוצר נלווה" }
												/>
											</Col>
										</Row>
									</Col>
								</Row>
							</CollapsibleGroup>

							<CollapsibleGroup title={ "מאפייני מוצר" }>
								<Row>
									<Col md={ 12 }>
										<Checkbox
											checked={ productRef ? productRef.isGenericProduct : isGenericProduct }
											label={ intl.formatMessage({ id: "product.form.isGenericProduct" }) }
											onCheck={ ( e, isInputChecked ) => {
												onProductFieldChange("isGenericProduct", isInputChecked)
											} }
											disabled={ productRef ? true : false }
										/>
									</Col>
								</Row>
							</CollapsibleGroup>
						</Card>
					</Col>
				</Row>


				<Modal isOpen={ linkedProductModalOpen } className={ 'modal-success ' }>
					<ModalHeader><FormattedMessage id="product.form.linkedProduct.modal.label"/></ModalHeader>
					<ModalBody>
						<RelatedProducts
							onClear={ () => {
								clearCatalog(CATALOG_TYPES.RELATED_PRODUCT)
							} }
							onResultSelection={ ( result ) => {
								setProductRef(result)
								this.closeLinkedProductModal()
							} }
							onSearch={ ( { query, supplierId } ) => getCatalog({
								query,
								supplierId,
								generic: true,
								catalogName: CATALOG_TYPES.RELATED_PRODUCT
							}) }
							onMakatSearch={ ( { query, supplierId } ) => getCatalog({
								query,
								supplierId,
								generic: true,
								catalogName: CATALOG_TYPES.RELATED_PRODUCT,
								isMakat: true
							}) }
							results={ searchResults }
							isLoading={ isLoadingSearch }
							suppliers={ suppliers }
							relatedProducts={ productRef ? [ productRef ] : [] }
							label={ intl.formatMessage({ id: "product.form.linkedProduct.modal.label" }) }
							onRemoveProduct={ () => {
								setProductRef(null)
							} }
						/>
					</ModalBody>
					<ModalFooter>
						<Button color="secondary" onClick={ this.closeLinkedProductModal }><FormattedMessage
							id="article.form.validation.modal.close"/></Button>
					</ModalFooter>
				</Modal>

				<Modal isOpen={ additionalProductModalOpen } className={ 'modal-success ' }>
					<ModalHeader>הוספת מוצר נוסף</ModalHeader>
					<ModalBody>
						<RelatedProducts
							onClear={ () => {
								clearCatalog(CATALOG_TYPES.RELATED_PRODUCT)
							} }
							onResultSelection={ ( result ) => {
								// addAddonProduct({ item: result, supplier: result.supplierId, id: result.id })
								addAddonProduct(result)
								//   this.closeLinkedProductModal()
								this.setState({ additionalProductModalOpen: false })
							} }
							onSearch={ ( { query } ) => getCatalog({
								query,
								supplierId: this.props.match.params.supplierId,
								catalogName: CATALOG_TYPES.RELATED_PRODUCT
							}) }
							results={ searchResults }
							isLoading={ isLoadingSearch }
							singleSupplier={ this.props.match.params.supplierId }
							suppliers={ suppliers }
							relatedProducts={ addOnProducts }
							label={ "מוצר נוסף" }
							onRemoveProduct={ () => {
								removeAddonProduct(addOnProducts.length - 1)
								//    setProductRef(null)
							} }
						/>
					</ModalBody>
					<ModalFooter>
						<Button color="secondary" onClick={ () => {
							this.setState({ additionalProductModalOpen: false })
						} }><FormattedMessage
							id="article.form.validation.modal.close"/></Button>
					</ModalFooter>
				</Modal>
			</div>
		)
	}


	render() {
		return (
			<div className="animated fadeIn">
				{ this.renderForm() }
			</div>
		)
	}
}


const Tag = styled.span`
  border-radius: 15px;
  background-color: #4d4d4d;
  font-family: ${ fonts.OpenSansHebrew };
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #ffffff;
  border: none;
`

const FeatuersLabel = styled.div`
  color: #1D1D1D;
  font-size: 24px;
`;

const SelectWrapper = styled.div`
  padding: 10px;
`;

const Group = styled.div`
  border: 1px dashed #29363d;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
`;

const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledButton = styled.button`
  background-color: ${ props => props.disabled ? '#707070' : '#1492D9' };
  cursor: ${ props => props.disabled ? 'disabled' : 'pointer' };
  box-shadow: 0px 1px 1px #707070;
  color: #FFFFFF;
  font-size: 21px;
  text-align: center;
  padding-left: 9px;
  padding-right: 9px;
  line-height: 36px;
  border-radius: 6px;
  cursor: pointer;
  outline: none;
  border: 0;

`;

const ProductWrapper = styled.div`
  position: relative;
`;

const ProductDeleteButton = styled.div`
  position: absolute;
  left: 0;
  top: 33%;
  cursor: pointer;
  border-radius: 50%;
  background-color: #DFD8D8;
  height: 24px;
  width: 24px;
  color: #fff;
  font-size: 16px;
  text-align: center;
  vertical-align: middle;

`

ProductForm.propTypes = {
	supplierId: PropTypes.string,
	// categories: PropTypes.object,
	onSubmit: PropTypes.func
};


const mapStateToProps = state => (
	{
		id: state.catalogProduct.id,
		catalogId: state.catalogProduct.catalogId,
		name: state.catalogProduct.name,
		productName: state.catalogProduct.productName,
		mode: state.catalogProduct.mode,
		images: state.catalogProduct.images,
		// category: state.catalogProduct.category,
		unitType: state.catalogProduct.unitType,
		description: state.catalogProduct.description,
		price: state.catalogProduct.price,
		supplierId: state.catalog.supplier,
		categories: state.catalogProduct.categories,
		longDescription: state.catalogProduct.longDescription,
		badges: state.catalogProduct.badges,
		spec: state.catalogProduct.spec,
		keywords: state.catalogProduct.keywords,
		minPrice: state.catalogProduct.minPrice,
		scannerId: state.catalogProduct.scannerId,
		features: state.catalogProduct.features,
		warnings: state.catalogProduct.warnings,
		generalInfo: state.catalogProduct.generalInfo,
		relatedProducts: state.catalogProduct.relatedProducts,
		isSupplementalProduct: state.catalogProduct.isSupplementalProduct,
		isGenericProduct: state.catalogProduct.isGenericProduct,
		disabled: state.catalogProduct.disabled,
		isRentalProduct: state.catalogProduct.isRentalProduct,
		productRef: state.catalogProduct.productRef,
		addOnProducts: state.catalogProduct.addOnProducts,
		suppliers: state.firebase.data.suppliers,
		searchResults: state.relatedProductCatalog.catalog,
		isLoadingSearch: state.relatedProductCatalog.isLoading,
		variants: state.catalogProduct.variants,
		rentInterval: state.catalogProduct.rentInterval,
		packagingInfo: state.catalogProduct.packagingInfo
	}
);

const mapDispatchToProps = dispatch => bindActionCreators({
	setName,
	setProductName,
	mapStateToProps,
	setId,
	setCategory,
	setDescription,
	setPrice,
	setImages,
	addImage,
	removeImage,
	updateProduct,
	addProduct,
	setMode,
	addGroupItem,
	removeGroupItem,
	onGroupItemChange,
	onProductFieldChange,
	onSubGroupItemChange,
	addSubGroupItem,
	removeSubGroupItem,
	editGroupItem,
	isCatalogIdExist,
	setProductRef,
	getCatalog,
	clearCatalog,
	setUnitType,
	getProduct,
	setPackagingInfo,
	addAddonProduct,
	removeAddonProduct,
	setProduct
}, dispatch);

export default compose(
	firebaseConnect([
		'suppliers'
	]),
	connect(
		mapStateToProps, mapDispatchToProps
	)
)(injectIntl(ProductForm));


//export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ProductForm));
