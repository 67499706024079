import React from "react";
import { iconStyle, inputStyle, wrapperStyle } from "./style";
import { colors, fonts } from "../../../styles/styleguide";

const AmountFilter = ({amountFilterCondition, amountFilterValue, onValueChange, onConditionChange}) => {

    return (
        <div style={Object.assign({}, wrapperStyle, {paddingRight: 60})}>
            <select
                style={{
                    backgroundColor: colors.charcoal,
                    width: 60,
                    borderRadius: 21.5,
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    bottom: 0,
                    appearance: "none",
                    "-webkit-appearance": "none",
                    backgroundImage: `url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")`,
                    backgroundRepeat:"no-repeat",
                    backgroundPositionX: 2,
                    backgroundPositionY: 2,
                    color: "#fff"
                }}
                value={amountFilterCondition}
                onChange={(e) => {
                    onConditionChange(e.target.value)
                }}
            >
                <option value={""}>

                </option>
                <option value="greaterThan">+</option>
                <option value="smallerOrEqual">-</option>
            </select>
            <input
                style={inputStyle}
                value={amountFilterValue}
                type={"number"}
                onChange={(e) => {
                    onValueChange(e.target.value)
                }}/>
        </div>
    )
}

export default AmountFilter