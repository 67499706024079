import React from 'react';
import PropTypes from 'prop-types'
import { BounceLoader } from 'halogenium';

//const Loader = () => (<BounceLoader color="#26A65B"/>);

const Loader = () => (<img src={"img/Loader1.gif"}/>);




class LoadingSpinner extends React.Component{

    constructor() {
        super()
    }

    renderLoader = () => {
        return (
            <img src={"img/Loader1.gif"} alt="loading..." style={{width: 100, height: 100}}/>
        )
    }

    render() {
        const {center} = this.props;
        if(!center){
            return this.renderLoader();
        }

        return (
            <div style={{position: "fixed", display: "flex", top: 0, left: 0, right: 0, bottom: 0, alignItems: "center", justifyContent: "center", zIndex: 999}}>
                <div style={{backgroundColor: '#000', opacity: .2, position:"absolute", top: 0, left: 0, right: 0, bottom: 0}}></div>
                {this.renderLoader()}
            </div>
        )

    }
}

LoadingSpinner.propTypes = {
    center: PropTypes.bool
};

LoadingSpinner.defaultProps = {
    center: true,
};

export default LoadingSpinner;