import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import { firebaseConnect } from 'react-redux-firebase'

import styled from 'styled-components';

import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
const __html = require('../Register/terms.html');
const termsTemplate = { __html: __html };

import SMSLoginHOC from "shared/src/HOC/smsLogin"
import { colors, fonts } from "../../../styles/styleguide";

import {
    Container,
    Row,
    Col,
    CardGroup,
    CardBody,
    Input,
    Button,
    Label,
    FormGroup,
    FormFeedback
} from 'reactstrap';
import RoundButton from "../../../components/RoundButton"
import IconInput from "../../../components/Form/IconInput";

import {  InputGroup  } from "../../../styles/stylesheet";

import Loader from "../../../components/Loader"
import AuthPage, {LoginError} from "../AuthPage";

class SMSLogin extends Component {

    constructor() {
        super();
        this.state = {
            isTermsModalOpen: false
        }

        this.setToken = ()=>{}
    }


    loginSuccess = () => {
        this.props.history.push("/");
    }

    _handleKeyPress = (e, step, getCaptchaToken, submit, register) => {
        if (e.key === 'Enter') {
            console.log('enter pressed');
          //  this.onSignUp();
            let onClick = this.getStepButtonAction(step, getCaptchaToken, submit, register)
            onClick();
        }
    }

    componentDidMount() {
        const {firebase} = this.props;
        console.log("rendering recaptcha", this.recaptcha)
        firebase.auth().languageCode = 'iw';
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(this.recaptcha, {
            'size': 'invisible',
            'callback': (response) => {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
                // ...
                console.log("response", response)
                this.verifyCallback(response)
            },
            'expired-callback': function () {
                // Response expired. Ask user to solve reCAPTCHA again.
                // ...
            }
        });
        window.recaptchaVerifier.render().then(function (widgetId) {
            window.recaptchaWidgetId = widgetId;
        }).catch(e => console.log(e));
    }


    verifyCallback = (recaptchaToken) => {
        // Here you will get the final recaptchaToken!!!
        console.log(recaptchaToken, "<= your recaptcha token")
        this.setToken(recaptchaToken)
    }


    renderStep = (step = 0, phoneNumber, code, isValid, displayName, codeError, captchaError, reset, setCode, setPhoneNumber, setToken, setName, checkedConditions, setConditions, getCaptchaToken,  submit, register) => {


        const shouldRenderCodeError = codeError && codeError.message ? true : false;
        console.log("rendering step", shouldRenderCodeError, captchaError, codeError)
        switch (step) {

            case 0:
                return (
                    <Fragment>
                        <InputGroup style={{marginTop: 15}}>
                            <IconInput
                                type={"text"}
                                onChange={(value) => {
                                    setPhoneNumber( value)
                                }}
                                value={phoneNumber}
                                dir="ltr"
                                onKeyPress={(e) => this._handleKeyPress(e, step, getCaptchaToken, submit, register)}
                                icon={<img src={"img/icons/phone.svg"} style={{width: 31}}/>}
                                placeholder={"טלפון"}
                            />
                            {!isValid.phoneNumber && <LoginError invalid>אנא ודאו שמספר הטלפון תקין</LoginError>}
                            {captchaError && captchaError.message && (<LoginError invalid>{captchaError.message}</LoginError>)}
                        </InputGroup>

                    </Fragment>

                )

            case 1:
                return (
                    null
                )
            case 2: {
                return (
                    <Fragment>
                        <div style={{
                            fontFamily: "OpenSansHebrew",
                            fontSize: 18.5,
                            fontWeight: 400,
                            color: colors.charcoal,
                            cursor: "pointer"
                        }}>{"ברגעים אלו נשלחה אליכם הודעת SMS עם קוד אימות, אנא הקלידו אותו כאן"}</div>
                        <InputGroup style={{marginTop: 15}}>
                            <IconInput
                                type={"text"}
                                onChange={(value) => {
                                    setCode( value)
                                }}
                                value={code}
                                dir="ltr"
                                onKeyPress={(e) => this._handleKeyPress(e, step, getCaptchaToken, submit, register)}
                                icon={<img src={"img/icons/phone.svg"} style={{width: 31}}/>}
                                placeholder={"קוד אימות"}
                            />
                            {!isValid.code && <LoginError invalid>אנא ודאו שהקוד אימות תקין</LoginError>}
                            {shouldRenderCodeError && (<LoginError invalid>{captchaError.message}</LoginError>)}
                        </InputGroup>
                       {/*
                        {shouldRenderCodeError ? (
                            <View>
                                <Text style={stylesheet.errorMessage}>{codeError.message}</Text>
                                <Button
                                    title={"איפוס"}
                                    onPress={reset}
                                />
                            </View>
                        ) : null}*/}
                    </Fragment>

                )
            }
            case 3: {
                return (
                    <Fragment>

                        <InputGroup style={{marginTop: 15}}>
                            <IconInput
                                type={"text"}
                                onChange={(value) => {
                                    setName( value)
                                }}
                                value={displayName}
                                dir="ltr"
                                onKeyPress={(e) => this._handleKeyPress(e, step, getCaptchaToken, submit, register)}
                                icon={<img src={"img/icons/person.svg"} style={{width: 31}}/>}
                                placeholder={"שם"}
                            />
                            {isValid && !isValid.displayName && <LoginError invalid>לא סיפרת לנו איך קוראים לך</LoginError>}
                        </InputGroup>

                        <FormGroup check>
                            <Label check>
                                <Input type="checkbox" value={checkedConditions} onChange={(e)=>{setConditions( !checkedConditions)}}  onKeyPress={(e) => this._handleKeyPress(e, step, getCaptchaToken, submit, register)}/>
                                <span style={{marginRight: 15}}>אני מסכים</span>
                                <Button style={{padding: 0, verticalAlign: "baseline", marginRight: 3}} color="link" onClick={() => {
                                    this.setState({isTermsModalOpen: true})
                                }} className="px-0"> לתנאי השימוש</Button>
                            </Label>

                        </FormGroup>
                        {isValid && isValid.checkedConditions === false && <LoginError>אנא אשרו את תנאי השימוש</LoginError>}
                    </Fragment>
                )
            }
            default:
                return null
        }
    }

    renderStepButton = (step, getCaptchaToken, submit, register) => {
        let label = "";
        let onClick = this.getStepButtonAction(step, getCaptchaToken, submit, register)
        switch (step) {
            case 0:
                label = "קבלת קוד אימות";
                break;
            case 2:
                label = "שליחה"
                break;
            case 3:
                label = "הרשמה"
                break;
            default:
                return null
        }

        return (
            <RoundButton
                onClick={onClick}
                label={label}
            />
        )
    }

    getStepButtonAction = (step, getCaptchaToken, submit, register) => {
        let onClick = () => {
        }
        switch (step) {
            case 0:
                onClick = ()=>{
                    window.recaptchaVerifier.verify()
                    getCaptchaToken()
                }
                break;
            case 2:
                onClick = submit;
                break;
            case 3:
                onClick = ()=>{
                    let invitationId = location.href.split('invId=')[1]
                    register(invitationId);
                };
                break;
            default:
                return onClick
        }
        return onClick
    }

    innerRender = (phoneNumber, code, isLoading, token, confirmationResult, codeError,
                   captchaError, user, needToRegister,isValid,
                   displayName, checkedConditions, termsOpen, getStep,  reset, setCode,
                   setPhoneNumber,  getCaptchaToken, submit, register, setToken,
                   setName, setConditions) => {
        const step = getStep();
       /* if (step === 1) {
            return this.renderStep(step, phoneNumber, code, isValid, displayName, codeError, captchaError, reset, setCode, setPhoneNumber, setToken, setName, checkedConditions, setConditions)
        }*/
       this.setToken = setToken

        return (
            <Fragment>
                <AuthPage title={"כניסה עם SMS"}>
                    {isLoading ? (
                        <Loader/>
                    ) : (
                        <Fragment>
                            <div style={{padding: 44}}>
                                {this.renderStep(step, phoneNumber, code, isValid, displayName, codeError, captchaError, reset, setCode, setPhoneNumber, setToken, setName, checkedConditions, setConditions, getCaptchaToken, submit, register)}
                                <div style={{display: "flex", flexDirection: "row", alignItems: "baseline", justifyContent: "space-between" , marginTop: 15}}>
                                    <div
                                        onClick={() => {
                                            this.props.history.push("/login")
                                        }}
                                        style={{
                                            fontFamily: "OpenSansHebrew",
                                            fontSize: 23.5,
                                            fontWeight: 300,
                                            color: colors.charcoal,
                                            cursor: "pointer"
                                        }}
                                    >
                                        כבר יש לכם חשבון? הכנסו מכאן
                                    </div>
                                </div>


                            </div>
                            {/*<div style={{display: "flex", flexDirection: "row", alignItems: "baseline", justifyContent: "space-between"}}>
                                        <div
                                            onClick={() => {
                                                window.location.href = '/about';
                                            }}
                                            style={{
                                                fontFamily: "OpenSansHebrew",
                                                fontSize: 23.5,
                                                fontWeight: 300,
                                                color: colors.charcoal,
                                                cursor: "pointer"
                                            }}
                                        >
                                            אודות החברה
                                        </div>
                                    </div>*/}
                            <div style={{position: "absolute", bottom: -25, left: 15}}>
                                {this.renderStepButton(step, getCaptchaToken, submit, register)}
                            </div>
                            <div ref={(ref)=>this.recaptcha=ref}></div>
                        </Fragment>

                    )}
                </AuthPage>
            </Fragment>
        )
    }

    render() {
        return <SMSLoginHOC
            render={this.innerRender}
            loginSuccess={this.loginSuccess}
            isOpenTerms={this.state.isTermsModalOpen}
            firebase={this.props.firebase}
        />

    }
}


SMSLogin.propTypes = {};

SMSLogin.defaultProps = {};


const mapStateToProps = (state) => ({});

const mapDispatchToProps = dispatch => bindActionCreators({

}, dispatch);


export default firebaseConnect()(connect(mapStateToProps, mapDispatchToProps)(SMSLogin));


