import React, { Component } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { bindActionCreators, compose } from 'redux';

import { firebaseConnect, getVal } from "react-redux-firebase";

import moment from "moment";
import _ from "lodash"
import ReactTable from 'react-table'

import "./react-table-overrides.scss";


class UserNotifications extends Component {
    constructor() {
        super()
        this.state = {
            rowsState: {},
            lastSelectedIndex: false,
        }
        this.rowRefs = {}
    }

    componentDidMount() {
        console.log("UserNotifications did mount");
        //mark messages as read
        const {firebase, uid} = this.props;
        firebase.ref(`messageManagement/${uid}/lastChecked`).set(new Date().getTime())
    }

    deleteMessage = (key) => {
        const {firebase, uid} = this.props;
        firebase.ref(`messages/${uid}/${key}`).remove().then((res) => {
            console.log("remove success", res)
        }).catch((e) => {
            console.log("remove failed", e)
        })
    }

    onSupplierConnectionConfirm = (messageKey, supplierId, contractorId) => {
        const {firebase} = this.props;
        firebase.ref(`contractors/${contractorId}/pendingSuppliers/${supplierId}`).once("value").then((snapshot) => {
            const supplier = snapshot.val();
            console.log("got pending supplier", supplier)
            firebase.ref(`contractors/${contractorId}/suppliers/${supplierId}`).set(supplier)
                .then((res) => {
                    console.log("onSupplierConnectionConfirm success", res)
                    firebase.ref(`contractors/${contractorId}/pendingSuppliers/${supplierId}`).remove().then((res) => {
                        console.log("remove pending supplier success", res)
                    }).catch((e) => {
                        console.log("remove pending supplier failed", e)
                    });
                    this.markMessageAsDone(messageKey);
                }).catch((e) => {
                console.log("onSupplierConnectionConfirm failed", e)
            })
        }).catch((e) => {
            console.log("onSupplierConnectionConfirm 2 failed", e)
        })
    }

    markMessageAsDone = (key) => {
        const {firebase, uid} = this.props;
        firebase.ref(`messages/${uid}/${key}/status/done`).set(firebase.database.ServerValue.TIMESTAMP).then((res) => {
            console.log("set done success", res)
        }).catch((e) => {
            console.log("set done failed", e)
        })
    }

    renderMessages = (messages) => {
        const toRender = [];
        for (let key in messages) {
            const message = messages[key];

            let messageTitle = "התראה";
            let showConnectionConfirmButton = false;
            let showDoneIcon = false;
            if (message.type && message.type === "supplierConnectionRequest") {
                messageTitle = "בקשת התחברות";
                if (!message.status.done) {
                    showConnectionConfirmButton = true;
                }
                else {
                    showDoneIcon = true
                }
            }

            toRender.push({
                title: messageTitle,
                message: message.message,
                created: message.status.created,
                showConnectionConfirmButton,
                showDoneIcon,
                original: message,
                key
            })

        }

        return toRender.reverse();
    }

    getColumns = () => {
        return [{
            id: 'title',
            Header: 'סוג ההודעה',
            width: 200,
            accessor: 'title', // String-based value accessors!,
            // Cell: props => <span>{![ORDER_STATUSES.LOCKED, ORDER_STATUSES.CLOSED, ORDER_STATUSES.CANCELED, ORDER_STATUSES.APPROVED].includes(extractLatestStatus(props.original.status)) ? this.sendToSupplierButton(props.original, extractLatestStatus(props.original.status)) : props.value}</span>, // Custom cell components!*/
            // filterMethod: this._orderIdFilter,
        }, {
            id: 'created',
            Header: 'תאריך',
            width: 200,
            accessor: m => moment(m.created).format("DD/MM/YYYY"), // String-based value accessors!,
            //  Cell: props => <EllipsisText text={props.value || ''} length={18}/>, // Custom cell components!
            //  filterMethod: this._notesFilter,
        }, {
            id: 'supplier', // Required because our accessor is not a string
            Header: 'תוכן ההודעה',
            accessor: o => o.message,
            Cell: props =>
                <div>
                    <div>{props.value}  {props.original.showDoneIcon && ( <img style={{}} src={"img/icons/v_green.svg"}/>)}</div>
                    {props.original.showConnectionConfirmButton && (
                        <div
                            onClick={()=>{this.onSupplierConnectionConfirm(props.original.key, props.original.original.supplierId, props.original.original.contractorId)}}
                            style={{
                            height: 45,
                            width: 140,
                            objectFit: "contain",
                            borderRadius: 48.5,
                            boxShadow: "-8.7px 5px 21px 0 rgba(137, 132, 132, 0.15)",
                            border: "solid 2px #ffffff",
                            backgroundColor: "#fbcd13",
                            cursor: "pointer",
                            fontFamily: "'Open Sans Hebrew', sans-serif",
                            fontSize: 14,
                            fontWeight: 800,
                            textAlign: "center",
                            lineHeight: "42px"
                        }}>אישור</div>
                    )}
                </div>

        },
            {
                id: 'actions',
                Header: '',
                width: 50,
                //  accessor: o => o.note, // String-based value accessors!,
                Cell: props => {
                    return (<i onClick={() => {
                        this.deleteMessage(props.original.key)
                    }} className={"fa fa-trash"} style={{color: "#ff3131", fontSize: 18}}/>)
                }, // Custom cell components!
                sortable: false,
                filterable: false,
            }
        ]
    }


    render() {


        const {messages} = this.props;
      //  console.log("messages", messages)
        const data = this.renderMessages(messages);

        return (
            <div style={{marginTop: 25, position: "relative",}}>
                <img src={"img/bgLogo.png"} style={{
                    position: "absolute",
                    bottom: -50,
                    right: 0,
                    width: 660,
                    height: 771,
                }}/>
                <ReactTable
                    data={data}
                    columns={this.getColumns()}
                    showPagination={false}
                    pageSize={data.length}
                    //   loading={isLoading}
                    //   filtered={filtered}
                    filterable={false}
                    //   sorted={sorted}
                    //  onSortedChange={sorted => onSortChange(sorted)}
                    getTdProps={(state, rowInfo, column, instance) => {

                        const returnedObj = {
                            onClick: (e, handleOriginal) => {
                                if (column.id === "actions") {
                                    if (handleOriginal) {
                                        handleOriginal();
                                    }
                                    return;
                                }
                                console.log("A Td Element was clicked!");
                                console.log("it produced this event:", e);
                                console.log("It was in this column:", column);
                                console.log("It was in this row:", rowInfo);
                                console.log("It was in this table instance:", instance);
                                //  this.props.onEditItem(rowInfo.original);

                                // IMPORTANT! React-Table uses onClick internally to trigger
                                // events like expanding SubComponents and pivots.
                                // By default a custom 'onClick' handler will override this functionality.
                                // If you want to fire the original onClick handler, call the
                                // 'handleOriginal' function.
                                if (handleOriginal) {
                                    handleOriginal();
                                }
                            }
                        };

                        /*      if(this.props.context === ORDER_MANAGEMENT_CONTEXT.SUPPLIER && rowInfo && rowInfo.row && (rowInfo.row.status === ORDER_STATUSES.APPROVED || rowInfo.row.status === ORDER_STATUSES.SENT || (rowInfo.original.isInStore && rowInfo.row.status === ORDER_STATUSES.LOCKED))){
                                  //console.log("row status", rowInfo.row.status, rowInfo.original.status);
                                  returnedObj.style = {fontWeight: "bold"}
                              }*/

                        return returnedObj;
                    }}
                />
            </div>
        );
    }
}

UserNotifications.propTypes = {};

UserNotifications.defaultProps = {};

const mapStateToProps = (state) => {
    return {
        messages: getVal(state.firebase, `data.messages.${state.firebase.auth.uid}`, {}),
        uid: state.roles.roles.user_id
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

const enhance = compose(
    connect(mapStateToProps, mapDispatchToProps),
    firebaseConnect((props) => ([
        `messages/${props.uid}`
    ]))
)


export default enhance(UserNotifications);