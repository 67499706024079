import { flow, set, get } from 'lodash/fp';
import { handleActions } from 'redux-actions';

export const SHOW_SIDEBAR_TOGGLE = "layout/SHOW_SIDEBAR_TOGGLE";
export const FETCH_ORDERS_CALLED = "layout/FETCH_ORDERS_CALLED";

const initialState = {
    showSideBarToggle: false,
    fetchOrdersCalled: false
};

export const layoutReducer = handleActions({
    [SHOW_SIDEBAR_TOGGLE]: (state, action) => set('showSideBarToggle', action.payload, state),

    [FETCH_ORDERS_CALLED]: (state, action) => set('fetchOrdersCalled', action.payload, state),
}, initialState);

export const setSidebarToggleDisplay = (show) => {
    return dispatch => {

        if(!show){
            if(!document.body.classList.contains('sidebar-hidden')){
                document.body.classList.add('sidebar-hidden')
            }
            if(document.body.classList.contains('sidebar-mobile-show')){
                document.body.classList.remove('sidebar-mobile-show')
            }
        }
        else {
            if(!document.body.classList.contains('sidebar-hidden')){
                document.body.classList.remove('sidebar-hidden')
            }
            if(document.body.classList.contains('sidebar-mobile-show')){
                document.body.classList.add('sidebar-mobile-show')
            }
        }



        dispatch({
            type: SHOW_SIDEBAR_TOGGLE,
            payload: show
        });
    }
};

export const setFetchOrdersCalled = (didFetched) => {
    return dispatch => {

        dispatch({
            type: FETCH_ORDERS_CALLED,
            payload: didFetched
        });
    }
};