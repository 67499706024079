import React, {Component} from 'react';
import PropTypes from 'prop-types'
import {colors} from "../styles/styleguide";
import RoundIconButton from "./RoundIconButton"

class ConfirmButton extends Component {
  render() {
      const {label, onClick, backgroundColor, disabled, disabledBackgroundColor} = this.props;
    return (
        <RoundIconButton
            onClick={onClick}
            backgroundColor={backgroundColor}
            label={label}
            disabled={disabled}
            disabledBackgroundColor={disabledBackgroundColor}
            iconSrc={"img/icons/v_transparent.svg"}
        />
    );
  }
}

ConfirmButton.propTypes = {
    label: PropTypes.string,
    onClick: PropTypes.func,
    backgroundColor: PropTypes.string,
    disabled: PropTypes.bool,
    disabledBackgroundColor: PropTypes.string
};

ConfirmButton.defaultProps = {
    backgroundColor: colors.charcoal,
    disabledBackgroundColor: colors.greyish,
    disabled: false
};



export default ConfirmButton;