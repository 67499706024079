import React, { Component } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux';

import { firebaseConnect, isLoaded } from 'react-redux-firebase'
import { compose } from 'redux'
import { cloneDeep } from "lodash"
import { uuidWithoutSpace } from "shared/src/utils";

import { Row, Col } from "reactstrap"
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import FontIcon from 'material-ui/FontIcon';
import styled from "styled-components";

import StickyFloatingButton from "../../components/StickyFloatingButton"
import ROUTES from "../../constants/routes";

import { Switch, Route, Redirect } from 'react-router-dom';

import Suppliers from "./suppliers"
import SupplierForm from "./supplierForm"

import {
    Card,
    CartHeader,
    DetailsRow,
    Detail,
} from "../../styles/stylesheet"


class SuppliersIndex extends Component {

    constructor(props) {
        super(props);

        this.defaultSupplier = {
            id: null,
            location: {
                address: ""
            },
            name: ""
        }

        this.state = {
            isModalOpen: false,
            modalContext: null,
            modalSupplier: this.defaultSupplier,
        }


    }

    renderSupplier = (id, supplier) => {

        //console.log("renderSupplier", id, supplier)
        //console.log(this.props.firebase)

        return (
            <Card onClick={() => {
                this.openSupplierModal(Object.assign({}, this.defaultSupplier, supplier, {id}))
            }}>
                <Row>
                    <Col md={6}>
                        <DetailsRow>
                            <FontIcon style={{fontSize: 18}} color={"#636363"}
                                      className={"material-icons"}>person</FontIcon>
                            <Detail>
                                {supplier.name}
                            </Detail>
                        </DetailsRow>

                        <DetailsRow>
                            <FontIcon style={{fontSize: 18}} color={"#636363"}
                                      className={"material-icons"}>place</FontIcon>
                            <Detail>{supplier.location && supplier.location.address ? supplier.location.address : "NA"}</Detail>
                        </DetailsRow>

                        <DetailsRow>
                            <FontIcon style={{fontSize: 18}} color={"#636363"}
                                      className={"material-icons"}>phone</FontIcon>
                            <Detail>{supplier.phoneNumber || "NA"}</Detail>
                        </DetailsRow>

                    </Col>
                </Row>
            </Card>
        )
    }

    openSupplierModal = (supplier) => {
        this.setState({
            isModalOpen: true,
            modalSupplier: supplier
        })
    }

    onEdit = () => {

    }

    onCreact = () => {
    }

    renderSuppliers = () => {
        const {suppliers} = this.props;
        const toRender = [];
        if (suppliers) {
            for (let key in suppliers) {
                toRender.push(this.renderSupplier(key, suppliers[key]))
            }
        }
        return toRender;
    }

    render() {

        const {isModalOpen, modalSupplier} = this.state;
        const {firebase} = this.props;

        return (
            <div>
                <Switch>
                    <Route exact path={ROUTES.SUPPLIERS} name="Suppliers management" render={(props) => ( <Suppliers {...props}/>)}/>
                    <Route path={ROUTES.SUPPLIER} name="supplier Form" render={(props) => (<SupplierForm  {...props}/>)}/>
                </Switch>

            </div>
        );
    }
}

SuppliersIndex.propTypes = {
    suppliers: PropTypes.object,
    createSupplierHandler: PropTypes.func,
    editSupplierHandler: PropTypes.func,
};

SuppliersIndex.defaultProps = {};

const mapStateToProps = (state) => {
    return {
        suppliers: state.firebase.data.suppliers
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);


export default compose(
    firebaseConnect([
        'suppliers'
    ]),
    connect(
        mapStateToProps, mapDispatchToProps
    )
)(SuppliersIndex);
