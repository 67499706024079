import {SET_ROLES} from "shared/src/modules/roles";
import analytics from "../analytics"

import {DIMENTIONS, EVENTS_CATEGORIES, ORDER_EVENTS} from "shared/src/constants/analytics"

import {
    SET_FIELD_VALUE,
    CREATE_NEW_ORDER,
    ADD_PRODUCT,
    REMOVE_PRODUCT,
    QUANTITY_UPDATED,
    SET_PRODUCT_ACTION,
    UNDO_PRODUCT_ACTION
} from "shared/src/modules/orders"

import {PRODUCT_ACTION_TYPES} from "shared/src/constants/orders"

export const analyticsMiddleware = ({dispatch, getState}) => next => action => {

    const order = getState().orders.newOrder;

    // console.log("analyticsMiddleware")
    // console.log("order", order)

    try {
        switch (action.type) {
            case SET_ROLES: {
                const roles = action.payload;
                if (roles && roles.user_id) {
                    analytics.setGAInstance(roles.user_id);
                    //todo: set user segmentation base on pared token
                    //  analytics.addDimention(DIMENTIONS.ACCOUNT_TYPES.CONTRACTOR.id, DIMENTIONS.ACCOUNT_TYPES.CONTRACTOR.name)
                }
                break;
            }
            case '@@router/LOCATION_CHANGE': {
                const {payload} = action;
                if(payload.location && payload.location.pathname){
                    console.log("Navigation Event", payload.location.pathname);
                    analytics.hit(payload.location.pathname);
                }
                break;
            }
            case CREATE_NEW_ORDER:{
                const returnedValue = next(action);
                let dimention = DIMENTIONS.ORDER(order.id);
                analytics.addDimention(dimention.id, dimention.name);
                analytics.event(EVENTS_CATEGORIES.ORDER, ORDER_EVENTS.CREATE, order.id);
                analytics.removeDimention(dimention.id);
                return returnedValue;
            }

            case ADD_PRODUCT:{
                let dimention = DIMENTIONS.ORDER(order.id);
                const {payload} = action;
                analytics.addDimention(dimention.id, dimention.name);
                if(payload.isReturn){
                    analytics.event(EVENTS_CATEGORIES.ORDER, ORDER_EVENTS.RETURN_ITEM, payload.product.id);
                }
                else {
                    analytics.event(EVENTS_CATEGORIES.ORDER, ORDER_EVENTS.ADD_ITEM, payload.product.id);
                }
                if(payload.product.actions && payload.product.actions.length > 0){
                    const latestAction = payload.product.actions[payload.product.actions.length - 1];
                    if(latestAction.type === PRODUCT_ACTION_TYPES.ADDED_BY_SUPPLIER){
                        analytics.event(EVENTS_CATEGORIES.ORDER, ORDER_EVENTS.PRODUCT_ADDED, order.items[payload].catalogId);
                    }
                }
                analytics.removeDimention(dimention.id);
                break;
            }

            case QUANTITY_UPDATED:{
                let dimention = DIMENTIONS.ORDER(order.id);
                const {payload} = action;
                analytics.addDimention(dimention.id, dimention.name);
                analytics.event(EVENTS_CATEGORIES.ORDER, ORDER_EVENTS.CHANGE_ITEM_QUANTITY, order.items[payload.index].catalogId, payload.quantity);
                analytics.removeDimention(dimention.id);
                break;
            }

            case REMOVE_PRODUCT:{
                let dimention = DIMENTIONS.ORDER(order.id);
                const {payload} = action;
                analytics.addDimention(dimention.id, dimention.name);
                analytics.event(EVENTS_CATEGORIES.ORDER, ORDER_EVENTS.REMOVE_ITEM, order.items[payload].catalogId);
                analytics.removeDimention(dimention.id);
                break;
            }

            case UNDO_PRODUCT_ACTION:{
                let dimention = DIMENTIONS.ORDER(order.id);
                analytics.addDimention(dimention.id, dimention.name);
                analytics.event(EVENTS_CATEGORIES.ORDER, ORDER_EVENTS.UNDO_PRODUCT_ACTION, "undo");
                analytics.removeDimention(dimention.id);
                break;
            }

            case SET_PRODUCT_ACTION:{
                let dimention = DIMENTIONS.ORDER(order.id);

                // console.log("dimention", dimention)
                // console.log("action", action)

                const {payload} = action;

                // console.log("payload", payload)

                const productId = payload.action.data.id;

                // console.log("productId", productId)
                // console.log("payload.action.type", payload.action.type)

                switch (payload.action.type){
                    case PRODUCT_ACTION_TYPES.ADDED_BY_SUPPLIER:
                        // set this event when adding a product, see above
                        break;
                    case PRODUCT_ACTION_TYPES.DELAY:
                        analytics.addDimention(dimention.id, dimention.name);
                        analytics.event(EVENTS_CATEGORIES.ORDER, ORDER_EVENTS.PRODUCT_DELAY, productId);
                        analytics.removeDimention(dimention.id);
                        break;
                    case PRODUCT_ACTION_TYPES.REPLACE:
                    case PRODUCT_ACTION_TYPES.CANCELED:
                        analytics.addDimention(dimention.id, dimention.name);
                        analytics.event(EVENTS_CATEGORIES.ORDER, ORDER_EVENTS.PRODUCT_CANCEL, productId);
                        analytics.removeDimention(dimention.id);
                        break;
                    case PRODUCT_ACTION_TYPES.QUANTITY_CHANGE:
                        let originalQuantity = action.data.original;
                        let quantity =  action.data.quantity;
                        let extraAction =  action.data.extraAction;
                        analytics.addDimention(dimention.id, dimention.name);
                        analytics.event(EVENTS_CATEGORIES.ORDER, ORDER_EVENTS.PRODUCT_QUANTITY_CHANGE, productId);
                        analytics.removeDimention(dimention.id);
                        break;
                    default:
                        break;
                }
            }

        }
    }catch (e) {
        console.error("Analytics Middleware Failed", e)
    }


    return next(action);

};

