import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Row, Col } from 'reactstrap';
import { Card, CardTitle, InputLabel, InputGroup, InputError } from "../../styles/stylesheet"
import IconInput, { IconWrapper } from "../../components/Form/IconInput";
import CountPicker from "../../components/Form/CountPicker";
import AddButton from "../../components/AddButton";
import RoundButton from "../../components/RoundButton";
import { colors } from "../../styles/styleguide";
import Checkbox from "../../components/Form/Checkbox";

class GenericProductForm extends Component {

    constructor(props){
        super(props);
        this.state = {
            name: "",
            price: "",
            count: 1,
            isValid: {
                name: true,
                price: true
            },
            isRental: false
        }
    }

    submit = () => {
        const {
            name,
            price,
            count,
            isRental
        } = this.state;

        if(!this.validate()){
            return;
        }

        this.props.onSubmit({
            name,
            price,
            count,
            isRental
        })
    }

    reset = () => {
        this.setState({
            name: "",
            price: "",
            count: 1,
            isValid: {
                name: true,
                price: true
            },
            isRental: false
        })
    }

    validate = () => {

        const {
            name,
            price,
        } = this.state;

        let isValid = {
            name: name ? true : false,
            price: price ? true :false,
        };

        this.setState({isValid})

        if(!isValid.name || !isValid.price){
            return false;
        }

        return true;
    }

    cancel = () => {
        this.reset();
        this.props.onCancel()
    }

    render() {

        const {
            name,
            price,
            count,
            isValid,
            isRental
        } = this.state;

        return (
            <div>
                <CardTitle>הוספת מוצר כללי</CardTitle>
                <Row>
                    <Col>
                        <InputGroup>
                            <InputLabel>שם</InputLabel>
                            <IconInput
                                type={"text"}
                                value={name}
                                onChange={(text) => this.setState({name: text})}
                                icon={<img src={"img/icons/offers.svg"}/>}
                            />
                            {!isValid.name && <InputError>שדה חובה</InputError>}
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <InputGroup>
                            <InputLabel>מחיר</InputLabel>
                            <IconInput
                                type={"number"}
                                value={price}
                                onChange={(value) => this.setState({price: value})}
                                icon={<img src={"img/icons/price.svg"}/>}
                            />
                            {!isValid.price && <InputError>שדה חובה</InputError>}
                        </InputGroup>
                    </Col>
                    <Col>
                        <InputGroup>
                            <InputLabel>כמות</InputLabel>
                            <CountPicker value={count} onValueChange={(value)=>{this.setState({count: value})}}/>
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <InputGroup>
                            <Checkbox
                                checked={isRental}
                                label={"מוצר השכרה"}
                                onChange={(event) => {
                                    this.setState({isRental: event.target.checked})
                                }}/>
                        </InputGroup>
                    </Col>
                </Row>
                <Row style={{marginTop: 30}}>
                    <Col>
                        <AddButton backgroundColor={colors.yellow} onClick={this.submit} label={"הוספה"}/>
                    </Col>
                    <Col>
                        <RoundButton backgroundColor={colors.greyish} label={"ביטול"} onClick={this.cancel} />
                    </Col>
                </Row>

            </div>
        );
    }
}

GenericProductForm.propTypes = {
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func

};

export default GenericProductForm;