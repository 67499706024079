import _ from "lodash";
import { getFirebase } from 'react-redux-firebase';
import {INIT_ORDER, SET_MULTIPLE_FILED_VALUE, setFieldValue} from "shared/src/modules/orders";
import { extractLatestStatus } from "shared/src/utils/orders"
import {
    ORDER_STATUSES,
    ORDER_MANAGEMENT_CONTEXT,
} from "shared/src/constants/orders"
import { toastr } from 'react-redux-toastr'
import { getSupplierIds } from "shared/src/selectors/roles";
import { reduceItems, getOrderItems } from "shared/src/api/orderItems";

export const currentOrderMiddleware = ({dispatch, getState}) => next => action => {

    function setRelatedOrders(orders, order) {
        //const status = extractLatestStatus(order.status);
        const orderStatus = extractLatestStatus(order.status);
        if([ORDER_STATUSES.PRODUCED, ORDER_STATUSES.CLOSED, ORDER_STATUSES.CANCELED, ORDER_STATUSES.RENT].includes(orderStatus) || order.isFDO){
            return [];
        }

        try{
            const relatedOrders = orders.filter((item) => {
                const status = extractLatestStatus(item.status);
                const isRelated = item.id !== order.id && item.project.id === order.project.id && [ORDER_STATUSES.APPROVED, ORDER_STATUSES.LOCKED].includes(status) && !item.orderId && !item.isRental && !order.orderId && !order.driver && ! item.isFDO;
                return isRelated;
            });
            console.log("related orders", relatedOrders);


            const suppliers = getSupplierIds(getState().roles.roles);
            let supplierId = suppliers.supplierIds[0];

            let promises = [];
            relatedOrders.forEach((item, index)=>{
                promises.push(getOrderItems({contractorId: item.contractor.id, projectId: item.project.id, supplierId, orderId: item.id}, getFirebase()))
            })
            Promise.all(promises).then((values)=>{
                console.log("orders items", values);
                values.forEach((snapshot, index)=>{
                    relatedOrders[index].items = reduceItems(snapshot.val(), relatedOrders[index].project.id)
                    // relatedOrders[index].items = reduceItems(snapshot.val(), `${relatedOrders[index].contractor.id}/${relatedOrders[index].project.id}`)
                })
                console.log("setting related orders");
                dispatch(setFieldValue("relatedOrders", relatedOrders));
            })
            return relatedOrders;
        }catch (e) {
            console.error("setRelatedOrders error", e)
            return [];
        }



    }

    if(action.type === INIT_ORDER){
        const order = action.payload;
        console.log("related orders middleware", order);
        const orders = getState().orders.orders;
        setRelatedOrders(orders, order);
    }

    if(action.type === SET_MULTIPLE_FILED_VALUE){
        let currentOrder = getState().orders.newOrder;
        if(action.payload.orders && currentOrder.id){
            const prevRelatedOrders = getState().orders.relatedOrders;
            const orders = action.payload.orders;
            console.log("related orders middleware", currentOrder);
            const relatedOrders = setRelatedOrders(orders, currentOrder);
            if(!_.isEqual(relatedOrders, prevRelatedOrders) && prevRelatedOrders.length < relatedOrders.length){
                toastr.info("שימו לב, התקבלה הזמנה חדשה לאותו פרוייקט של הזמנה זו")
            }

        }
    }

    return next(action);

};

