import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import _ from "lodash"
import {rolesToCompare} from "shared/src/constants/config"
import {userHaveLegitRole} from "shared/src/selectors/roles"

import {
    Container,
    Row,
    Col
    } from 'reactstrap';
import { colors, fonts } from "../../../styles/styleguide";


import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";

const SECONDS_TO_WAIT = 15;
class Welcome extends Component {

    constructor() {
        super()
        this.state = {
            intervalId: null,
            timerSeconds: 0,
            profileIsReady: false

        }
    }

    componentDidMount() {
        if (userHaveLegitRole(this.props.roles)) {
            this.setState({profileIsReady: true})
        }
        this.setIntervalX(() => {
            const {timerSeconds} = this.state
            this.setState({timerSeconds: timerSeconds + 1})
            //  console.log("timer", timerSeconds+1)
        }, 1000, SECONDS_TO_WAIT)

    }

    componentWillUnmount() {
        if (this.state.intervalId) {
            window.clearInterval(this.state.intervalId)
        }
    }

    componentDidUpdate(prevProps) {
        let profileIsReady = this.state.profileIsReady;
     //   console.log("profileIsReady", profileIsReady)
        if(!_.isEqual(prevProps.roles, this.props.roles) && userHaveLegitRole(this.props.roles)){
            console.log("now it's ready", profileIsReady)
            profileIsReady = true;
            this.setState({profileIsReady})
        }

        if (profileIsReady && this.state.timerSeconds >= 5) {
            this.props.history.push("/");
        }
    }

    setIntervalX = (callback, delay, repetitions) => {
        let x = 0;
        let intervalId = window.setInterval(function () {

            callback();

            if (++x === repetitions) {
                window.clearInterval(intervalId);
            }
        }, delay);
        this.setState({intervalId})
    }

    render() {
        const {timerSeconds, profileIsReady} = this.state;
        let addSec = profileIsReady ? 0 : 1;
        let progress = timerSeconds / (SECONDS_TO_WAIT + addSec) * 100;
        return (
            <BG>
                <img src={"img/bgLogo.png"} style={{
                    position: "absolute",
                    top: 137,
                    right: -300,
                    width: 660,
                    height: 771,
                }}/>
                <YellowStrip/>
                <Container>
                    <Row className="justify-content-center" style={{marginTop: 100}}>
                        <Col md="8">
                            <Row className="justify-content-center">
                                <div style={{display: "flex", flexDirection: "row", alignItems: "baseline"}}>
                                    <div style={{
                                        fontFamily: fonts.OpenSansHebrew,
                                        fontSize: 36,
                                        color: colors.charcoal
                                    }}>ברוכים הבאים לפלטפורמת
                                    </div>
                                    <img style={{width: "auto", height: 26, marginRight: 10}} src={"img/logo-punct.png"}/>
                                </div>
                            </Row>
                            <Row style={{marginTop: 10}} className="justify-content-center">
                                <div style={{
                                    display: "flex", flexDirection: "row", justifyContent: "space-around"
                                }}>
                                    <Text>הפלטפורמה</Text>
                                    <TextBold style={{marginRight: 5}}>תיעל</TextBold>
                                    <Text style={{marginRight: 5}}> את תהליכי המכירות שלך,</Text>
                                    <TextBold style={{marginRight: 5}}>תגדיל</TextBold>
                                    <Text style={{marginRight: 5}}> את יעילותך</Text>
                                </div>
                                <div style={{
                                    display: "flex", flexDirection: "row", justifyContent: "space-around"
                                }}>
                                    <TextBold>ותאפשר</TextBold>
                                    <Text style={{marginRight: 5}}>לך לספק שירות טוב יותר לקהל לקוחתיך.</Text>
                                </div>
                            </Row>
                            <Row style={{marginTop: 10}} className="justify-content-center">
                                <img style={{width: "auto", maxHeight: 413}} src={"img/welcome.png"}/>
                            </Row>
                            <Row className="justify-content-center">
                                <Text>אנו עובדים קשה לבנות את סביבת העבודה עבורך!
                                    אנא המתן עד סיום התהליך. הכניסה תתאפשר בהינתן הרשאות
                                </Text>
                            </Row>
                            <Row className="justify-content-center" style={{marginTop: 10}}>
                                <Col md={12}>
                                    <Progress percent={progress}
                                              theme={
                                                  {
                                                      default: {
                                                          symbol: ' ',
                                                          trailColor: colors.charcoal,
                                                          color: colors.yellow
                                                      },
                                                  }
                                              }
                                              status={"default"}
                                    />
                                </Col>

                            </Row>
                        </Col>
                    </Row>
                </Container>
            </BG>
        );
    }
}

Welcome.propTypes = {};

Welcome.defaultProps = {};

const BG = styled.div`
    display: flex;
   // min-height: 100vh;
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
   // bottom: 0;
    z-index: 2000;
`;

const YellowStrip = styled.div`
  box-shadow: -16.5px 9.5px 40px 0 rgba(150, 150, 150, 0.25);
  background-color: ${colors.yellow};
  width: 100%;
  height: 30px;
  position: absolute;
  
`

const Text = styled.div`
  font-family:  ${fonts.OpenSansHebrew};
  font-size: 24px;
  line-height: 1.17;
  letter-spacing: 0.7px;
  color: ${colors.charcoal};
`

const TextBold = styled.div`
  font-family:  ${fonts.OpenSansHebrew};
  font-weight: bold;
  font-size: 24px;
  line-height: 1.17;
  letter-spacing: 0.7px;
  color: ${colors.charcoal};
`


const mapStateToProps = ({roles}) => ({
    roles: roles.roles,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);


export default connect(
    mapStateToProps, mapDispatchToProps
)(Welcome);