import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import { firebaseReducer } from 'react-redux-firebase'
import { firestoreReducer } from 'redux-firestore'
import {catalogReducer, createCatalogReducerWithNameData, CATALOG_TYPES} from "shared/src/modules/catalog";
import {localeReducer} from 'shared/src/modules/locale';
import {rolesReducer} from 'shared/src/modules/roles';
import {catalogProductReducer} from "shared/src/modules/catalogProduct"
import {articlesReducer} from "shared/src/modules/articles";
import {articleReducer} from "shared/src/modules/article";
import {accountReducer} from "shared/src/modules/account";
import {ordersReducer} from "shared/src/modules/orders"
import {layoutReducer} from "../modules/layout"
import {ordersManagementReducer} from "shared/src/modules/ordersManagement"
import {ordersFilerReducer, createTableFilterWithNameData} from "shared/src/modules/ordersFilter"
import {autoCompleteReducer} from "shared/src/modules/autoComplete";
import {deliveriesReducer} from "shared/src/modules/deliveries";
import {ordersHistoryReducer} from "shared/src/modules/ordersHistory";
import {posReducer} from "shared/src/modules/pos";
import {priceListReducer} from "shared/src/modules/priceList";
import {rentsReducer} from "shared/src/modules/rents"

import {LOGOUT} from "shared/src/modules/account";

import {reducer as toastrReducer} from 'react-redux-toastr'


const appReducer = combineReducers({
    routing: routerReducer,
    locale: localeReducer,
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    catalog: catalogReducer,
    relatedProductCatalog: createCatalogReducerWithNameData(CATALOG_TYPES.RELATED_PRODUCT),
    ordersCatalog: createCatalogReducerWithNameData(CATALOG_TYPES.ORDERS),
    roles: rolesReducer,
    catalogProduct: catalogProductReducer,
    articles: articlesReducer,
    article: articleReducer,
    account: accountReducer,
    orders: ordersReducer,
    ordersFilter: ordersFilerReducer,
    toastr: toastrReducer,
    layout: layoutReducer,
    ordersManagement: ordersManagementReducer,
    autoComplete: autoCompleteReducer,
    deliveries: deliveriesReducer,
    ordersHistory: ordersHistoryReducer,
    pos: posReducer,
    posCatalog: createCatalogReducerWithNameData(CATALOG_TYPES.PO),
    posFilter: createTableFilterWithNameData(CATALOG_TYPES.PO),
    priceList: priceListReducer,
    rents: rentsReducer,
});

const rootReducer = (state, action) => {
    if (action.type === LOGOUT) {
        state = undefined
    }
    return appReducer(state, action)
};

export default rootReducer;
