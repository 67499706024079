import React, { Component } from 'react';
import PropTypes from 'prop-types'
import _ from "lodash";
import moment from "moment";

import { Row, Col } from "reactstrap"
import FontIcon from 'material-ui/FontIcon';
import styled from "styled-components";

import {getShippingAddress, getContactPerson} from "shared/src/selectors/deliveries"

class Deliveries extends Component {

    constructor() {
        super();
        this.state = {
            orders: []
        }
    }

    componentDidMount() {
        this.initOrders(this.props, {});
    }


    componentDidUpdate(prevProps, prevState) {
        this.initOrders(this.props, prevProps);
    }


    initOrders = (props, prevProps) => {
        if (props.orders  && !_.isEqual(prevProps.orders, props.orders)) {
            const filtered = _.filter(props.orders, (o)=> {return o.orderId});
            const sorted = _.orderBy(filtered, ["deliveryDate"]);

            this.setState({
                orders: sorted
            });
        }
    }

    renderOrder = (order) => {
        const {history} = this.props;

        const contactPerson = getContactPerson(order)
        const shippingAddress = getShippingAddress(order)

        return (

            <Card key={order.id}>
                <Row>
                    <Col md={2}>
                        <RoundButton onClick={() => {history.push(`/deliveries/${order.supplier.id}/${order.contractor.id}/${order.project.id}/${order.id}`);}}>
                            <span>מסירת הזמנה</span>
                        </RoundButton>
                    </Col>
                    <Col md={10}>
                        <DetailsRow>
                            <FontIcon color={"#636363"} className={"material-icons"}>calendar_today</FontIcon>
                            <Detail>{moment(order.deliveryDate).format("DD/M/YYYY")}</Detail>
                        </DetailsRow>
                        <DetailsRow>
                            <FontIcon color={"#636363"} className={"material-icons"}>place</FontIcon>
                            <Detail>{typeof shippingAddress === "string" ? shippingAddress : shippingAddress.name}</Detail>
                        </DetailsRow>
                        <DetailsRow>
                            <FontIcon color={"#636363"} className={"material-icons"}>person</FontIcon>
                            <Detail>
                                {contactPerson.displayName}
                                {" "}
                                {contactPerson.phoneNumber}
                            </Detail>
                        </DetailsRow>
                        <DetailsRow>
                            <FontIcon color={"#636363"} className={"material-icons"}>domain</FontIcon>
                            <Detail>
                                {order.contractor.name}
                            </Detail>
                        </DetailsRow>
                        <DetailsRow>
                            <FontIcon color={"#636363"} className={"material-icons"}>receipt</FontIcon>
                            <Detail>
                                { order.orderId ? order.orderId : ""}
                            </Detail>
                        </DetailsRow>
                        <DetailsRow>
                            <FontIcon color={"#636363"} className={"material-icons"}>drive_eta</FontIcon>
                            <Detail>
                                { order.driver ? order.driver.displayName : ""}
                            </Detail>
                        </DetailsRow>
                    </Col>
                </Row>
            </Card>


        )
    }

    render() {
        const {orders} = this.state;

        return (
            <div>
                <h3>משלוחים</h3>
                <div>
                    <div>
                        {orders.length === 0 && (<span>אין הזמנות</span>)}
                        {orders.map((order) => (this.renderOrder(order)))}
                    </div>
                </div>
            </div>
        );
    }
}

const Card = styled.div`
    background: #fff;
    padding: 15px;
    margin-top: 17px;
    border: 2px solid #E0E0E0;
    box-sizing: border-box;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    position: relative;
    `;

const DetailsRow = styled.div`
    font-family: 'Rubik', sans-serif;
    font-size: 24px;
    color: #636363;
`;

const Detail = styled.span`
    font-family: 'Rubik', sans-serif;
    font-size: 24px;
    color: #636363;
    margin-right: 7px;
    vertical-align: text-bottom;
`;

const RoundButton = styled.div`
    border-radius: 50%;
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    text-align: center;
    color: #FFFFFF;
    height: 118px;
    width:  118px;
    padding: 0 11px;
    cursor:  ${props => props.disabled ? "default" : "pointer"};
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);
    background-color: ${props => props.disabled ? "#818181" : props.backgroundColor ? props.backgroundColor : "#57d96e"};
    display: flex;
    align-items: center;
`

Deliveries.propTypes = {
    orders: PropTypes.array,
};

Deliveries.defaultProps = {};


export default Deliveries;