import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Popup from "reactjs-popup";
import { HoverText } from "./styles";

import { fonts, colors } from "../../../styles/styleguide";

const CollapseDetails = ({
  isFDO,
  isSearching,
  shouldOpen,
  onOpen,
  actionsView,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(
    isSearching === undefined ? false : !isSearching
  );

  useEffect(() => {
    if (isOpen && !!onOpen) {
      onOpen();
    }
  }, [isOpen]);

  useEffect(() => {
    if (!!isSearching) {
      setIsOpen(false);
    }
  }, [isSearching]);

  useEffect(() => {
    if (!!shouldOpen) {
      setIsOpen(true);
    }
  }, [shouldOpen]);

  const toggle = () => {
    setIsOpen((state) => !state);
  };

  return (
    <Container>
      <HeaderContainer>
        <MainText>{isFDO ? "הזמנה בדלפק" : "פרטי ההזמנה"}</MainText>
        <DropArrow isOpen={isOpen} onClick={toggle} />

        <div style={{ position: "absolute", left: 24 }}>{actionsView}</div>
      </HeaderContainer>

      {isOpen && children}
    </Container>
  );
};

const APP_YELLOW = "#f5c608";

const DropArrow = ({ isOpen, onClick }) => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: APP_YELLOW,
      borderRadius: "100%",
      // borderRadius: "8px",
      margin: "8px",
      cursor: "pointer",
    }}
    onClick={onClick}
  >
    <Popup
      trigger={
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "35px",
            width: "35px",
            padding: "6px",
          }}
        >
          {isOpen ? (
            <DropImage imgSrc={"img/icons/arrow-up.svg"} />
          ) : (
            <DropImage imgSrc={"img/icons/arrow-down.svg"} />
          )}
        </div>
      }
      on="hover"
      position="bottom"
      closeOnDocumentClick
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <HoverText>
          {"לחץ כאן על מנת " + (isOpen ? "לסגור" : "לפתוח") + " חלון זה"}
        </HoverText>
      </div>
    </Popup>
  </div>
);

const DropImage = ({ imgSrc }) => <img src={imgSrc} />;

export const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

const Container = styled.div`
  height: auto;
  width: 100%;
  backgroundColor: #eeeeee;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  width: 100%;
  margin: 12px 0;
`;

const MainText = styled.div`
  font-size: 28px;
  font-family: ${fonts.OpenSansHebrew};
  color: ${colors.charcoal};
  font-weight: bold;
`;

export default CollapseDetails;
