import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import { injectIntl, FormattedMessage } from 'react-intl';
import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button, Row, Col
} from 'reactstrap';

import styled from 'styled-components';
import { map } from "lodash";
import { colors, fonts } from "../../styles/styleguide";
import { Card, CardTitle, InputLabel, InputGroup } from "../../styles/stylesheet"

import TileAddButton from "./TileAddButton"

class MediaUploader extends Component {

    constructor() {
        super();
        this.state = {
            itemToDelete: null,
            isModalOpen: false,
            selectedImage: null
        }

        this.openModal = this.openModal.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    openModal(file, key) {
        this.setState({
            isModalOpen: true,
            itemToDelete: {
                file,
                key
            }
        })
    }

    confirmDelete() {
        const {itemToDelete} = this.state;
        const {onDeleteFile} = this.props;
        onDeleteFile(itemToDelete.file, itemToDelete.key);
        this.closeModal();
    }

    closeModal() {
        this.setState({
            isModalOpen: false,
            itemToDelete: null
        })
    }

    render() {
        const {uploadedFiles, onFilesDrop, showSidePreview, showAddIconOnPreview, relativeDimensions, dropzoneHeight, dropzoneBg, accept, colSize} = this.props;
        const {isModalOpen, itemToDelete, selectedImage} = this.state;

        return (
            <Fragment>
                <Col md={colSize}>
                    <InputGroup>
                        <InputLabel>תמונות</InputLabel>
                        <Dropzone style={{position: "relative", width: "100%"}} onDrop={onFilesDrop} accept={accept}>
                            <TileAddButton title={"הוספת תמונה"}/>
                        </Dropzone>
                    </InputGroup>
                </Col>


                {
                    uploadedFiles && showSidePreview && (


                        map(uploadedFiles, (file, key) => (
                            <Col md={colSize} key={file.name + key}>
                                <Tile>

                                    <ResponsiveImage src={file.downloadURL}/>
                                    <DeleteButton onClick={(e) => {
                                        e.preventDefault();
                                        this.openModal(file, key)
                                    }}>
                                        <img src={"img/icons/delete-outline.svg"}/>
                                    </DeleteButton>

                                </Tile>
                            </Col>
                        ))

                    )


                }


                <Modal isOpen={isModalOpen} className={'modal-danger '}>
                    <ModalHeader><FormattedMessage id="image.deleteModal.title"/></ModalHeader>
                    <ModalBody>
                        <FormattedMessage id="image.deleteModal.question"/>
                        {itemToDelete ? (<span><ResponsiveImage src={itemToDelete.file.downloadURL}/></span>) : ""} ?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={this.confirmDelete}><FormattedMessage
                            id="product.deleteModal.confirm"/></Button>{' '}
                        <Button color="secondary" onClick={this.closeModal}><FormattedMessage
                            id="product.deleteModal.cancel"/></Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        )
    }
}

const Tile = styled.div`
  border-radius: 10px;
  box-shadow: -6.9px 4px 4px 0 rgba(213, 213, 213, 0.23);
  border: solid 1px rgba(209, 209, 209, 0.45);
  background-color: #ffffff;
  width: 154px;
  height: 154px;
  position: relative;
`

const ResponsiveImage = styled.img`
   width: 100%;
    height: auto;
    max-height: 100%;
    border-radius: 10px;
`;


const DeleteButton = styled.div`
    position: absolute;
    top: 5px;
    left: 5px;
    cursor: pointer;
`;

MediaUploader.propTypes = {
    uploadedFiles: PropTypes.array,
    onFilesDrop: PropTypes.func,
    onDeleteFile: PropTypes.func,
    showSidePreview: PropTypes.bool,
    showAddIconOnPreview: PropTypes.bool,
    relativeDimensions: PropTypes.bool,
    dropzoneHeight: PropTypes.string,
    dropzoneBg: PropTypes.string,
    accept: PropTypes.string,
    colSize: PropTypes.number
};

MediaUploader.defaultProps = {
    showSidePreview: true,
    showAddIconOnPreview: true,
    relativeDimensions: false,
    dropzoneHeight: "400px",
    dropzoneBg: "none",
    accept: null,
    colSize: 6
}

export default injectIntl(MediaUploader);